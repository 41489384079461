import { Injectable } from '@angular/core';
import { PropertyWidgetContext} from '../property-widgets/property-widget.context';
import { Observable } from 'rxjs';
import { AppState, Dictionary } from '../model/reducer';
import { Pattern } from '../patterns/pattern.model';
import { PatternType } from '../plugins/pattern-type.model';
import { PatternService } from '../patterns/pattern.service';
import { select, Store } from '@ngrx/store';
import { patternsView, patternTypesView, propertyTypesView, selectedProjectView } from '../model/views';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { mapToArray } from '../common/utils/utils';
import { Project } from '../projects/project.model';
import { ResourceList } from '../patterns/pattern-attachment.model';
import { PropertyWidgetContextType } from '../property-widgets/property-widget-context-type.enum';
import { ViewAttachmentFileDiffEvent } from '../projects/publish-project/pattern-diff-view/pattern-diff-data.model';

@Injectable()
export class IssuesPropertyContext implements PropertyWidgetContext {
  type = PropertyWidgetContextType.ISSUES;
  currentProject$: Observable<Project | null>;
  patterns$: Observable<Map<string, Pattern>>;
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  allPatternResources$: Observable<Dictionary<Dictionary<ResourceList>>>;

  public toggleDiffAttachmentResource(_item: ViewAttachmentFileDiffEvent): void {/* only used in the publish context */}

  constructor(private store$: Store<AppState>, private patternService: PatternService) {
    this.patternTypes$ = this.store$.pipe(select(patternTypesView), filter(value => !_.isNil(value)));
    this.currentProject$ = this.store$.pipe(select(selectedProjectView), map((project: Project | undefined) => project || null));
    this.patterns$ = this.store$.pipe(select(patternsView));
    this.allPatternResources$ = this.patternService.getResourcesOfPatterns(
      this.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.projectKey)),
      this.patterns$.pipe(map(patterns => mapToArray(patterns))),
      this.patternTypes$,
      this.store$.pipe(select(propertyTypesView))
    );
  }
}
