<mat-table multiTemplateDataRows matSort class='adm4-mat-table align-rows-start full-width bundle-management-table' [dataSource]='tableDataSource'>
  <!-- Usage Column -->
  <ng-container [matColumnDef]="resourceTableColumns.UsageColumnName">
    <mat-header-cell *matHeaderCellDef>
      Usage
      <span class='expansion-wrapper' (click)='toggleUsedInExpansion(true)'>{{isExpandedUsage ? 'Collapse all' : 'Expand all'}}
        <i class="fa" [ngClass]="isExpandedUsage ? 'fa-minus-square-o' : 'fa-plus-square-o'" aria-hidden="true"></i>
      </span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">

      <ng-container *ngIf='element?.usages; else noUsageTemplate'>
        <ng-container *ngIf='element?.usages?.length > 1 && !element.isExpanded; else usageList'>
          <a (click)='toggleUsedInExpansion(false, element)'>{{element?.usages.length}} patterns...</a>
        </ng-container>
        <ng-template #usageList>
          <div class='usage-wrapper'>
            <div *ngFor='let usage of element.usages' class='usage-item'>
              <a (click)='navigateToPattern(usage.projectKey, usage.patternId)' [innerHTML]='usage?.label | highlight: filterText'></a>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #noUsageTemplate>
        <ng-container *ngIf='element?.isTenantScoped; else unlinked'>
          <span [class.disabled-opacity]='!isUsedResource(element)'>Unused</span>
          <i class="fa fa-info-circle help-icon" aria-hidden="true"
             [ngbTooltip]='unusedPopover' placement='right'></i>
        </ng-container>
      </ng-template>

      <ng-template #unlinked>
        <span [class.disabled-opacity]='!isUsedResource(element)'>Unlinked</span>
        <i class="fa fa-info-circle help-icon" aria-hidden="true"
           [ngbTooltip]='unLinkedPopover' placement='right'></i>
      </ng-template>

      <ng-template #unusedPopover>The secret has not been used yet.<br/>
        1. Go to your project.<br/>
        2. Create a variable in your pattern.<br/>
        3. Insert the secret reference to your variable in the Inventory.
      </ng-template>

      <ng-template #unLinkedPopover>The file reference has been removed from an inventory and is not used in any
        inventory. To clean up, please remove it.
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Kind Column -->
  <ng-container [matColumnDef]="resourceTableColumns.KindColumnName">
    <mat-header-cell *matHeaderCellDef>Kind</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div *ngIf='element.kind' class="single-line-item" [innerHTML]='element.kind | highlight: filterText'></div>
    </mat-cell>
  </ng-container>

  <!-- Resource Name Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ResourceNameColumnName">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div *ngIf='element.name' class="single-line-item" [innerHTML]='element.name | highlight: filterText'></div>
    </mat-cell>
  </ng-container>

  <!-- Secret name Name Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SecretNameColumnName">
    <mat-header-cell *matHeaderCellDef>Secret name</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div *ngIf='element.kubernetesSecretKey' class="single-line-item" [innerHTML]='element.kubernetesSecretKey | highlight: filterText'></div>
    </mat-cell>
  </ng-container>

  <!-- Expiry date Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ExpiryDateColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Expiry date</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
      <div *ngFor='let singleCertificate of element.certificates' class='single-line-item'>
        {{singleCertificate.expires | customDate}}
        <ng-container
                *ngIf='{hasWarning: checkExpirationBySeverity(singleCertificate, "warning"), hasError: checkExpirationBySeverity(singleCertificate, "error")} as expirationAlert'>
          <mat-icon *ngIf='expirationAlert.hasWarning || expirationAlert.hasError'
                    [class.warning-icon]='expirationAlert.hasWarning'
                    [class.error-icon]='expirationAlert.hasError'
                    [ngbTooltip]='getExpirationTooltip(singleCertificate)' placement='top-right'>
            {{resolveExpirationIcon(singleCertificate)}}
          </mat-icon>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Subject Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SubjectColumnName">
    <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
      <div *ngFor='let singleCertificate of element.certificates' class='single-line-item multi-line-content'>
        <div *ngIf='{subject: singleCertificate.subject, showMore: false} as subjectInfo;' class='description-content-wrapper'>
          <span [innerHTML]="(subjectInfo.showMore) ? (subjectInfo.subject | highlight: filterText) : (subjectInfo.subject | slice:0:25 | highlight: filterText) || '-'"></span>
          <span *ngIf="!subjectInfo.showMore && subjectInfo.subject?.length > 25">...</span><br>
          <a *ngIf='subjectInfo.subject?.length > 25' (click)="subjectInfo.showMore=!subjectInfo.showMore">
            {{subjectInfo.showMore ? 'Show Less' : 'Show More'}}</a>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Issuer Column -->
  <ng-container [matColumnDef]="resourceTableColumns.IssuerColumnName">
    <mat-header-cell *matHeaderCellDef>Issuer</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
      <div *ngFor='let singleCertificate of element.certificates' class='single-line-item multi-line-content'>
        <div *ngIf='{issuer: singleCertificate.issuer, showMore: false} as issuerInfo;' class='description-content-wrapper'>
          <span [innerHTML]="(issuerInfo.showMore) ? (issuerInfo.issuer | highlight: filterText) : (issuerInfo.issuer | slice:0:25 | highlight: filterText) || '-'"></span>
          <span *ngIf="!issuerInfo.showMore && issuerInfo.issuer?.length > 25">...</span><br>
          <a *ngIf='issuerInfo.issuer?.length > 25' (click)="issuerInfo.showMore=!issuerInfo.showMore">
            {{issuerInfo.showMore ? 'Show Less' : 'Show More'}}</a>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Serial Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SerialColumnName">
    <mat-header-cell *matHeaderCellDef>Serial</mat-header-cell>
      <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
        <div *ngFor='let singleCertificate of element.certificates' class='single-line-item serial-wrapper'
             [innerHTML]='singleCertificate.serial.toString() | highlight: filterText'
             [ngbTooltip]='singleCertificate.serial.toString()' placement='top'></div>
      </mat-cell>
  </ng-container>

  <!-- State Column -->
  <ng-container [matColumnDef]="resourceTableColumns.StateColumnName">
    <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div class="single-line-item">
        <mat-icon [ngbTooltip]='element.state' placement='top-right'
           [class.state-used]="element.state === 'Used'"
           [class.state-prepared]="element.state === 'Prepared'">circle</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Action Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ActionColumnName">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element" class='action-cell'>
      <div class="single-line-item">
        <button class='action-btn'
                (click)='openViewContentDialog(element, true)'>
          <mat-icon>visibility</mat-icon>
        </button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" class='certificate-item-row'
           [class.error]='isColorHighlighted(row, "error")'
           [class.warning]='isColorHighlighted(row, "warning")'></mat-row>
</mat-table>
<ng-container *ngIf="!hasDisplayableData">
  <adm4-empty-resource><span>There is no Managed Kubernetes certificate found.</span></adm4-empty-resource>
</ng-container>
