import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY,
  isBackgroundDeploymentStateLoaded,
  BackgroundDeploymentAppState,
  BackgroundDeploymentState,
} from './background-deployment.reducer';
import { DeploymentProcessState } from '@deployment-common/deployment-process.model';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';

const featureSelector: MemoizedSelector<BackgroundDeploymentAppState, BackgroundDeploymentState> = createFeatureSelector<BackgroundDeploymentState>(BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY);

export const backgroundDeploymentIdView: MemoizedSelector<BackgroundDeploymentAppState, string | null> = createSelector(
  featureSelector,
  (state: BackgroundDeploymentState): string | null => state.selectedDeploymentID,
);

export const backgroundDeploymentProjectKeyView: MemoizedSelector<BackgroundDeploymentAppState, string | null> = createSelector(
  featureSelector,
  (state: BackgroundDeploymentState): string | null => isBackgroundDeploymentStateLoaded(state) ? state.projectKey : null,
);

export const backgroundDeploymentInventoryKeyView: MemoizedSelector<BackgroundDeploymentAppState, string | null> = createSelector(
  featureSelector,
  (state: BackgroundDeploymentState): string | null => isBackgroundDeploymentStateLoaded(state) ? state.inventoryKey : null,
);

export const backgroundDeploymentStateView: MemoizedSelector<BackgroundDeploymentAppState, DeploymentProcessState | null> = createSelector(
  featureSelector,
  (state: BackgroundDeploymentState): DeploymentProcessState | null => isBackgroundDeploymentStateLoaded(state) ? state.state : null,
);

export const backgroundDeploymentStatusDetailsView: MemoizedSelector<BackgroundDeploymentAppState, DeploymentStatusModel | null> = createSelector(
  featureSelector,
  (state: BackgroundDeploymentState): DeploymentStatusModel | null => state.deploymentStatus,
);
