import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, filter, first, map, mapTo, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';

import {
  deleteBackgroundDeploymentSuccessful,
  exitBackgroundDeployment,
  loadBackgroundDeploymentStatusDetailsSuccess,
  loadBackgroundDeploymentSuccess,
  selectBackgroundDeployment,
} from './background-deployment.actions';
import { BackgroundDeploymentAppState } from './background-deployment.reducer';
import { DeploymentService } from '../deployment-wizard/deployment-dialog/deployment.service';
import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';
import { backgroundDeploymentIdView } from './background-deployment.views';
import { ModalNotificationService } from '../notification/modal-notification.service';

@Injectable()
export class BackgroundDeploymentEffects {

  load = createEffect(() => this.actions$.pipe(
    ofType(selectBackgroundDeployment),
    switchMap((action): Observable<Action> => {
      return this.deployments.getDeploymentProcess(action.deploymentID).pipe(
        map((deployment: DeploymentProcessModel): Action => {
          return loadBackgroundDeploymentSuccess({deployment});
        }),
        catchError((error): Observable<Action> => {
          console.error(`BackgroundDeploymentEffects#load, deployment for '${action.deploymentID}' could not be loaded`);
          console.error(error);
          this.notifications.openErrorDialog({headerTitle: 'Error', title: 'Deployment could not be loaded', description: 'Deployment could not be loaded'});
          return EMPTY;
        }),
      );
    }),
  ));

  loadDeploymentStatusDetails = createEffect(() => this.actions$.pipe(
    ofType(loadBackgroundDeploymentSuccess),
    switchMap((action): Observable<Action> => {
      return this.deployments.getStatusOfDeploy(action.deployment.deploymentId).pipe(
        map((deploymentStatus): Action => loadBackgroundDeploymentStatusDetailsSuccess({deploymentStatus})),
        catchError((error): Observable<Action> => {
          console.error(`BackgroundDeploymentEffects#loadDeploymentStatusDetails, deployment status for '${action.deployment.deploymentId}' could not be loaded`);
          console.error(error);
          this.notifications.openErrorDialog({
            headerTitle: 'Error', title: 'Deployment status details could not be loaded',
            description: `The Deployment of the project ${action.deployment.projectKey} to inventory ${action.deployment.inventoryKey} could not be loaded`,
          });
          return EMPTY;
        }),
      );
    }),
  ));

  exit = createEffect(() => this.actions$.pipe(
    ofType(exitBackgroundDeployment),
    switchMap((): Observable<string> =>
      this.store.select(backgroundDeploymentIdView).pipe(
        first(),
        filter((deploymentId: string | null): deploymentId is string => !!deploymentId),
      ),
    ),
    switchMap((deploymentId: string): Observable<Action> => {
      return this.deployments.deleteDeploymentProcess(deploymentId).pipe(
        mapTo(undefined),
        catchError(error => {
          console.error(`BackgroundDeploymentEffects#exit, unable to delete deployment `, deploymentId);
          console.error(error);
          this.notifications.openErrorDialog({
            headerTitle: 'Error', title: 'Deployment status details could not be loaded',
            description: `The deployment ${deploymentId} could not be deleted.`,
          });
          return of(undefined);
        }),
        mapTo(deleteBackgroundDeploymentSuccessful()),
      );
    }),
  ));


  constructor(
    private store: Store<BackgroundDeploymentAppState>,
    private actions$: Actions,
    private deployments: DeploymentService,
    private notifications: ModalNotificationService,
  ) {}
}
