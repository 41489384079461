import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { Inventory, InventoryMeta } from '../../inventory.model';
import { InventoryHelper } from '../../inventory.helper';
import { PublishInventoryChangesetHelper } from '../../publish-inventory/publish-inventory-changeset.helper';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { InventoryContext } from '../../inventory.context';
import { ConnectInventoryVersionControlDialogService } from '../../connect-inventory-version-control/connect-inventory-version-control-dialog.service';

@Component({
  selector: 'adm4-inventory-version-control-information',
  template: `
    <div class="section-title">Git Connection</div>
    <table class='settings-info-table' *ngIf='isVersionedInventory'>
      <tr>
        <td>
          <ng-container *ngIf="inventory.repository">
            <div class="key"> Repository</div>
            {{ inventory.repository }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf='inventory.path'>
            <div class="key"> Directory in repository:</div>
            {{ inventory.path }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="inventory.branch">
            <div class="key"> Branch</div>
            {{ inventory.branch }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf='commitId'>
            <div class='key'>Commit</div>
            <span [ngbTooltip]='inventoryPublishStatus' class='commit-info'>
              <span>{{ commitId }}</span>
              <em *ngIf='inventoryHasChanges' class="fa fa-info-circle help-icon" aria-hidden="true"></em>
            </span>
          </ng-container>
        </td>
      </tr>
    </table>
    <div>
      <div class='inventory-actions'>
        <label class='checkbox-label' [ngbTooltip]='getDisabledPublishRequireFlagTooltip(inventoryContext.hasAdminAccess$ | async)'
               [disableTooltip]='canChangePublishRequireFlag(inventoryContext.hasAdminAccess$ | async)' placement='bottom-left'>
          <mat-checkbox [disabled]="!canChangePublishRequireFlag(inventoryContext.hasAdminAccess$ | async)"
                        [checked]="publishRequired" (change)='onPublishRequiredChange($event)'></mat-checkbox>
          <span>Require a published project and inventory for deployment. This will also only allow the deployment to be done in the deployment wizard and not in the background.</span>
        </label>
        <ng-container *ngIf='!isVersionedInventory'>
          <span ngbTooltip="You don't have permission to change Git settings on this project." [disableTooltip]="hasInventoryModifyPermission" placement="left">
            <button type="button" class="admn4-button-ellipse-blue" [class.cursor-disabled]="!hasInventoryModifyPermission"
                    [disabled]="!hasInventoryModifyPermission" (click)="openConnectInventoryToVersionControlDialog()">
              <span class="menu-item-text">Connect to Git</span>
            </button>
          </span>
        </ng-container>
    </div>
  </div>
  `,
  styleUrls: [
    '../inventory-information-common.scss',
    '../../../common/styles/component-specific/settings-info-table.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryVersionControlInformationComponent implements OnChanges {

  @Input() inventory: Inventory;
  @Input() inventoryMeta: InventoryMeta | null;
  @Input() hasInventoryModifyPermission: boolean;

  publishRequired: boolean;

  public inventoryHasChanges: boolean = false;
  public isVersionedInventory: boolean = false;
  public inventoryPublishStatus: string = '';
  public commitId: string | undefined;

  constructor(private modalNotificationService: ModalNotificationService,
              public inventoryContext: InventoryContext,
              private connectInventoryVersionControlDialogService: ConnectInventoryVersionControlDialogService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inventory && this.inventory) {
      this.publishRequired = this.inventory.publishRequired;
      this.isVersionedInventory = InventoryHelper.isVersionedInventory(this.inventory);
    }
    if (changes.inventoryMeta) {
      this.inventoryHasChanges = !_.isNil(this.inventoryMeta) && PublishInventoryChangesetHelper.hasLocalChanges(this.inventoryMeta);
      this.inventoryPublishStatus = this.calculateInventoryPublishStatus();
    }
    if (changes.inventory || changes.inventoryMeta) {
      this.commitId = _.isNil(this.inventoryMeta) ? this.inventory.commitId : this.inventoryMeta.localHead;
    }
  }

  onPublishRequiredChange(event: MatCheckboxChange): void {
    const checked: boolean = event.checked;
    const title = checked ? 'Enable: deployment requires  published project' : 'Disable: deployment requires  published project';
    const description = checked
      ? 'Enable this setting to allow deployment on this inventory only for published projects.<br/><br/>This will also only allow the deployment to be done in the deployment wizard and not in the background.'
      : 'Disable this setting to allow projects to be deployed on this inventory, regardless if the project is published or not.<br/><br/>This will also allow the deployment to be done in the background, not only on the wizard.';
    const confirmButtonText = checked ? 'Enable' : 'Disable';

    this.modalNotificationService.openConfirmDialog({
      title: title,
      description: description
    }, {
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Cancel'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.inventoryContext.changePublishRequiredFlag(this.inventory.inventoryKey, checked);
      } else {
        event.source.checked = !checked;
      }
    });
  }

  openConnectInventoryToVersionControlDialog() {
    this.connectInventoryVersionControlDialogService.openConnectInventoryToVersionControlDialog(this.inventory);
  }

  canChangePublishRequireFlag(hasAdminAccess: boolean | null): boolean {
    return !!hasAdminAccess && _.isNil(this.inventory.originInventoryKey) && (this.isVersionedInventory || this.inventory.publishRequired);
  }

  getDisabledPublishRequireFlagTooltip(hasAdminAccess: boolean): string {
    if (!hasAdminAccess) {
      return `You don't have permission to modify this setting`;
    } else if (!this.isVersionedInventory && !this.inventory.publishRequired) {
      return 'Please connect this inventory to Git to be able to modify this setting';
    }
    return '';
  }

  private calculateInventoryPublishStatus(): string {
    if (_.isNil(this.inventoryMeta)) {
      return '';
    } else if (this.inventoryHasChanges) {
      return 'There are local changes pending to be published on this inventory';
    } else {
      return 'There are no local changes pending to be published on this inventory';
    }
  }
}
