import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { CreateTenantLevelInventoryResourceDialogPayload } from './inventory-resource-action-dialog-payload.model';
import { InventoryResourceType } from '../../../inventory/inventory.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { InventoryService } from '../../../inventory/inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../../common/helpers/error.helper';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { InventoryAttachFilePayload, InventoryAttachmentTenantResourceMode } from '../../../inventory/inventory-editor/insert-inventory-attachments-dialog/inventory-attachment-payload.model';
import { InsertInventoryAttachmentsDialogService } from '../../../inventory/inventory-editor/insert-inventory-attachments-dialog/insert-inventory-attachments-dialog.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'adm4-create-tenant-level-inventory-resource-dialog',
  template: `
    <form name='secretForm' class='secret-form' [formGroup]="form" (ngSubmit)='onFormSubmit()'>
      <adm4-modal-dialog-title class='modal-dialog-title'
                               [header]='header'
                               [showClose]='true'
                               [isFullHeightContent]='true'
                               (closeClicked)="closeDialog()">
        <div class='full-height-flex'>
          <div mat-dialog-content class='secret-content-wrapper content-container'>
            <adm4-admonition class="mb-4" severity="info" alignment="start">
              <p adm4-admonition-msg class="m-0 color-info">The {{payload.inventoryResourceType | lowercase}} will be created as a global {{payload.inventoryResourceType | lowercase}}.
                To use it in your inventory, open your inventory editor and add the reference of the created {{payload.inventoryResourceType | lowercase}} there.
              </p>
            </adm4-admonition>
            <ng-container *ngIf='fileAttachment; else defineGlobalSecretTemplate'>
              <div class='inventory-attachment'>
                <div class='inventory-attachment'>
                  <div class='inventory-attachments-header'>
                    <div class='file-name'>File name</div>
                    <ul class='uploaded-file-list'>
                      <li>{{fileAttachment.name}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #defineGlobalSecretTemplate>
              <label class='input-label'>Define global secret</label>
            <div class='form-group admn4-input-group-with-icon'>
              <input id="secretInput" class='admn4-text-input form-control' cdkFocusInitial placeholder='Please enter value' [type]="hide ? 'password' : 'text'" required [formControlName]='SECRET_TEXT_FORM_CONTROL_NAME' autocomplete="off">
              <mat-icon matSuffix (click)="hide = !hide" class='input-icon visibility-icon admn4-button-ellipse-blue'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            </div>
            </ng-template>
            <label class='input-label'>Description (optional)</label>
            <textarea class='form-control admn4-textarea-input'
                      placeholder='Add your description (max 150 characters)'
                      maxlength='150'
                      cdkFocusInitial
                      [formControlName]='DESCRIPTION_FORM_CONTROL_NAME'></textarea>
          </div>
          <div mat-dialog-actions>
            <adm4-button-bar [isSubmitDisabled]="form.invalid"
                             [submitButtonText]='resolveSubmitBtnText()'
                             (cancelClicked)='closeDialog()'></adm4-button-bar>
          </div>
        </div>
      </adm4-modal-dialog-title>
    </form>
  `,
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './inventory-resource-action-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateInventoryAttachTenantResourceDialogComponent implements OnInit, OnDestroy {
  header: string;
  inventoryResourceType: string;
  fileAttachment: File | undefined;
  public form: UntypedFormGroup;
  public SECRET_TEXT_FORM_CONTROL_NAME = 'secretText';
  public DESCRIPTION_FORM_CONTROL_NAME = 'description';
  public hide = true;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public payload: CreateTenantLevelInventoryResourceDialogPayload,
              private dialogRef: MatDialogRef<CreateInventoryAttachTenantResourceDialogComponent>,
              private fb: UntypedFormBuilder,
              private inventoryService: InventoryService,
              private toastNotificationService: ToastNotificationService,
              private modalNotificationService: ModalNotificationService,
              private insertInventoryAttachmentsDialogService: InsertInventoryAttachmentsDialogService) {
    this.inventoryResourceType = payload.inventoryResourceType;
    this.header = this.resolveHeaderByInventoryResourceType();
    this.fileAttachment = payload.file;
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    if (_.isEqual(this.inventoryResourceType, InventoryResourceType.PLAIN_TEXT_SECRET)) {
      this.form.addControl(this.SECRET_TEXT_FORM_CONTROL_NAME, new UntypedFormControl('', Validators.required));
    }
    this.form.addControl(this.DESCRIPTION_FORM_CONTROL_NAME, new UntypedFormControl('', null));
  }

  resolveHeaderByInventoryResourceType(): string {
    switch (this.inventoryResourceType) {
      case InventoryResourceType.PLAIN_TEXT_SECRET:
        return 'Create global secret';
      case InventoryResourceType.SECRET_FILE:
        return 'Upload global secret file';
      case InventoryResourceType.FILE:
      default:
        return 'Upload global file';
    }
  }

  resolveSubmitBtnText(): string {
    if (_.isEqual(this.inventoryResourceType, InventoryResourceType.PLAIN_TEXT_SECRET)) {
      return 'Create';
    }
    return 'Upload';
  }

  onFormSubmit(): void {
    if (this.fileAttachment) {
      const inventoryAttachFilePayload = new InventoryAttachFilePayload();
      inventoryAttachFilePayload.file = this.fileAttachment;
      inventoryAttachFilePayload.description = this.form.controls[this.DESCRIPTION_FORM_CONTROL_NAME].value;
      this.insertInventoryAttachmentsDialogService.handleTenantLevelSecretInsert(this.dialogRef, this.payload.isSecret ? InventoryAttachmentTenantResourceMode.tenantLevelSecretResourceFile : InventoryAttachmentTenantResourceMode.tenantLevelResourceFile,
        this.payload.tenantKey).pipe(
        take(1)
      ).subscribe((inventoryAttachmentRefs) => {
        this.displayAttachmentResourceUploadToast(inventoryAttachmentRefs);
        this.payload.onSaveCallback();
      });
      this.dialogRef.close(inventoryAttachFilePayload);
      return;
    }
    this.inventoryService.createTenantSecret(this.payload.tenantKey, this.form.controls[this.SECRET_TEXT_FORM_CONTROL_NAME].value, this.form.controls[this.DESCRIPTION_FORM_CONTROL_NAME].value).subscribe(() => {
      this.toastNotificationService.showSuccessToast(`Global ${this.inventoryResourceType.toLowerCase()} has been successfully created`, 'Successfully created');
      this.payload.onSaveCallback();
      this.closeDialog();
    }, (error: HttpErrorResponse) => {
      this.modalNotificationService.openErrorDialog({title: 'Creating global secret failed', description: ErrorHelper.getErrorDetail(error, 'Something went wrong with creating global secret')});
    });
  }

  displayAttachmentResourceUploadToast(inventoryAttachmentRefs?: string): void {
    if (_.isEmpty(inventoryAttachmentRefs)) {
      return;
    }
    this.toastNotificationService.showSuccessToast(`Global ${this.inventoryResourceType.toLowerCase()} has been successfully uploaded`, 'Successfully uploaded');
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
