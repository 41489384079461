import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BaseService } from './shared/base.service';
import { ProjectModule } from './projects/project.module';
import { PatternModule } from './patterns/pattern.module';
import { NavigationModule } from './navbar/navigation.module';
import { FooterComponent } from './footer/footer.component';
import { CommonModules } from './common/common.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { VariableModule } from './variables/variable.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { NetworkEffects } from './model/effects';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { ModalDialogModule } from './modal-dialog/modal-dialog.module';
import { ProjectEffects } from './model/project/project.effects';
import { initialState, metaReducers, reducers } from './model/reducer';
import { PatternEffects } from './model/pattern/pattern.effects';
import { VariableEffects } from './model/variables/variable.effects';
import { VersionControlEffects } from './model/version-control/version-control.effects';
import { UserStateService } from './common/services/user/user.state.service';
import { ApplicationEffects } from './model/application.effects';
import { DeploymentWizardModule } from './deployment-wizard/deployment-wizard.module';
import { BackgroundDeploymentModule } from './deployment-background/background-deployment.module';
import { DeployEffects } from './model/deploy/deploy.effects';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
import { SaverService } from './common/services/saver.service';
import { InventoryModule } from './inventory/inventory.module';
import { InventoryEffects } from './model/inventory/inventory.effects';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { LoginModule } from './user/login/login.module';
import { UserEffects } from './user';
import { ModalNotificationService } from './notification/modal-notification.service';
import { ToastNotificationService } from './notification/toast-notification.service';
import { JobService } from './shared/job.service';
import { IssuesModule } from './issues/issues.module';
import { ScrollService } from './common/services/scroll/scroll.service';
import { RouteReuseStrategy } from '@angular/router';
import { AuthenticationInterceptor } from './common/interceptors/authentication.interceptor';
import { LoadingInterceptorService } from './common/interceptors/loading-interceptor.service';
import { CustomRouteReuseStrategy } from './common/route-strategy/router-reuse-strategy';
import { DynamicLinkCreatorService } from './common/services/dynamic-link-creator.service';
import { PermissionsService } from './permissions/permissions.service';
import { ApplicationInfoService } from './footer/application-info.service';
import { WelcomeModule } from './welcome/welcome.module';
import { DirtyFormGuardConnectorService } from './common/services/dirty-form-guard-connector.service';
import { CustomSvgIconService } from './common/services/custom-svg-icon.service';
import { ApplicationTitleService } from './common/services/application-title/application-title.service';
import { ReportEffects } from './model/report/report.effects';
import { PluginService } from './plugins/plugin.service';
import { TenantService } from './tenant/tenant.service';
import { UserService } from './user/login/user.service';
import { HelpService } from './help/help.service';
import { InventoryService } from './inventory/inventory.service';
import { ProjectService } from './projects/project.service';
import { DeploymentService } from './deployment-wizard/deployment-dialog/deployment.service';
import { FileService } from './common/services/file/file.service';
import { PatternService } from './patterns/pattern.service';
import { ReportsService } from './reports/reports.service';
import { VariableService } from './variables/variable.service';
import { VersionControlService } from './version-control/version-control.service';
import { LoadingService } from './modal-dialog/loading-modal/loading.service';
import { SharedEffects } from './model/shared/shared.effects';
import { ResourcesModule } from './resources/resources.module';
import { MarketplaceModule } from './resources/marketplace/marketplace.module';
import { PublishProjectEffects } from './model/publish-project/publish-project.effects';
import { PublishInventoryEffects } from './model/publish-inventory/publish-inventory.effects';
import { LocalChangesModalNotificationService } from './notification/local-changes-modal-notification.service';
import { KeyShortcutsComponent } from './common/services/key-shortcuts.component';
import { DisplayComponentHelperService } from './common/services/display-component-helper.service';
import { MarkdownRendererService } from './common/services/markdown-renderer.service';
import { CustomSanitizerService, DomPurifyWrapper } from './common/services/custom-sanitizer.service';
import { AppLayoutComponent } from './app-layout.component';
import { SystemApiService } from './shared/system-api.service';
import { DeploymentActivityContextService } from './common/services/deployment-activity-context.service';

const monacoConfig: NgxMonacoEditorConfig = {
  // NEVISADMV4-1708: Workaround to fix the embedded deployment
  // configure base path for monaco editor
  baseUrl: environment.env === 'embedded' ? 'assets' : ''
};

@NgModule({
    declarations: [
        AppComponent,
        AppLayoutComponent,
        FooterComponent,
        KeyShortcutsComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        ProjectModule,
        PatternModule,
        AppRoutingModule,
        NavigationModule,
        CommonModules,
        environment.enableAnimations ? BrowserAnimationsModule : NoopAnimationsModule,
        VariableModule,
        IssuesModule,
        DeploymentWizardModule,
        BackgroundDeploymentModule,
        InventoryModule,
        ResourcesModule,
        MarketplaceModule,
        ToastrModule.forRoot(),
        ModalDialogModule,
        LoginModule,
        WelcomeModule,
        StoreModule.forRoot(reducers, {initialState: initialState, metaReducers: metaReducers}),
        !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50, connectInZone: true}) : [],
        EffectsModule.forRoot([
            ApplicationEffects,
            SharedEffects,
            VersionControlEffects,
            PatternEffects,
            NetworkEffects,
            ProjectEffects,
            VariableEffects,
            DeployEffects,
            InventoryEffects,
            UserEffects,
            ReportEffects,
            PublishProjectEffects,
            PublishInventoryEffects,
        ]),
        MonacoEditorModule.forRoot(monacoConfig),
    ],
    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        LoadingInterceptorService,
        // interceptors
        {provide: HTTP_INTERCEPTORS, useExisting: LoadingInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        // end of interceptors
        {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
        // backend services
        TenantService,
        BaseService,
        JobService,
        PluginService,
        PermissionsService,
        UserService,
        HelpService,
        InventoryService,
        ProjectService,
        PatternService,
        VariableService,
        DeploymentService,
        ReportsService,
        VersionControlService,
        FileService,
        SystemApiService,
        // end of backend services
        ModalNotificationService,
        LocalChangesModalNotificationService,
        ToastNotificationService,
        UserStateService,
        SaverService,
        ScrollService,
        ApplicationInfoService,
        DynamicLinkCreatorService,
        DirtyFormGuardConnectorService,
        CustomSvgIconService,
        ApplicationTitleService,
        LoadingService,
        DisplayComponentHelperService,
        MarkdownRendererService,
        DomPurifyWrapper,
        CustomSanitizerService,
        DeploymentActivityContextService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
}
