<div class="deployment-step-content full-height-flex">
	<div class="remaining-space-flex-content-wrapper">
		<div class="remaining-space-flex-content">
			<div class='step-content full-height-flex'>
				<div class="remaining-space-flex-content-wrapper">
					<div class="remaining-space-flex-content">
						<adm4-validating *ngIf='shouldDisplayValidating$ | async; else deploymentPreviewResults'
														 class='full-height'
														 [validatingText]='getValidatingText(selectedInventorySchemaType$ | async)'
														 [validationDoneText]='getValidationDoneText(selectedInventorySchemaType$ | async)'
														 [status]='planningStatus$ | async'>
                  <span class='success' *ngIf='noDeploymentNeeded$ | async'>
                    {{hostsCount$ | async}} hosts are up to date, no deployment is needed. You can <a (click)='triggerForceRedeployment(deploymentId)'>force redeployment</a>.
                  </span>
            </adm4-validating>
            <ng-template #deploymentPreviewResults>
              <adm4-deployment-preview-container class='full-height'
                                                 [planningResults]='planningOutput$ | async'
                                                 [redeploymentForced]='redeploymentForced$ | async'
                                                 [boxShadowClass]='boxShadowClass'
                                                 [patterns]='patterns$ | async'
                                                 [selectedInventorySchemaType]='selectedInventorySchemaType$ | async'
              ></adm4-deployment-preview-container>
            </ng-template>
          </div>
        </div>
        <div class="step-content-action-bar">
          <button *ngIf='canRepeatPlanning$ | async'
                  class='step-content-action-button'
                  (click)="onRepeatPlanning()">
            <span class="material-icons">replay</span>
            <span>{{getRepeatPlanningButtonText(selectedInventorySchemaType$ | async)}}</span>
          </button>
          <button *ngIf='canShowForceRedeploymentButton$ | async'
                  class='step-content-action-button'
                  (click)='triggerForceRedeployment(deploymentId)'>
            <span class="material-icons">settings_backup_restore</span>
            <span>Force redeployment</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="step-action-bar">
    <button *ngIf='isPlanningInProgress$ | async; else stepButtons'
            class='previous-step-button'
            (click)='triggerAbortPlanning()'>Abort planning
    </button>
    <ng-template #stepButtons>
      <button *ngIf='canGoBack$ | async'
              matStepperPrevious
              class='previous-step-button'>
        Validate
      </button>
      <div *ngIf='canDeploy$ | async'
           class='button-wrapper'
           [ngbTooltip]='disabledNextStepToolTip' [disableTooltip]='!(isPlanningEntirelyFailed$ | async)' placement='top'>
        <button matStepperNext
                class='next-step-button next-step-icon icon-play'
                [disabled]='isPlanningEntirelyFailed$ | async'
                (click)='deploy.emit(deploymentId)'>
          Deploy now
        </button>
      </div>
    </ng-template>
    <button *ngIf='canShowCloseButton$ | async'
            class='admn4-button-ellipse-blue close-button'
            (click)='closeWizard.emit()'>Close wizard
    </button>
    <ng-template #disabledNextStepToolTip>
          <span>
            There is nothing to be deployed. Please solve the failures and <a (click)='onRepeatPlanning()'>{{getDisabledNextStepToolTipLinkText(selectedInventorySchemaType$ | async)}}</a>.
          </span>
		</ng-template>
	</div>
</div>
