import { distinctUntilChanged, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Project, ProjectBundle, ProjectMeta } from '../projects/project.model';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ProjectService } from '../projects/project.service';
import * as _ from 'lodash';
import { Unsubscriber } from '../common/helpers/unsubscriber';
import { PermissionAssignee } from '../model/permissions/permissions.model';
import { PermissionsService } from '../permissions/permissions.service';
import { AppState } from '../model/reducer';
import { select, Store } from '@ngrx/store';
import { DeleteProject } from '../model/project';
import { hasProjectAdminAccessView, hasProjectWriteAccessView, isSelectedProjectReadonlyView } from '../model/views/permission.views';
import { allProjectsView, projectMetaView, selectedProjectView } from '../model/views';
import { ParsedBundle } from '../resources/bundle-management/bundle.model';
import { BundleService } from '../resources/bundle-management/bundle.service';
import { ProjectDependenciesHelper } from './project-dependencies/project-dependencies.helper';
import { LoadPatternTypes, LoadPropertyTypes } from '../model/pattern';
import { LoadProjectMeta } from '../model/version-control';
import { InvalidatePluginDoc } from '../model/actions';

@Injectable()
export class ProjectSettingsContext implements OnDestroy {
  private _projectBundles: BehaviorSubject<ParsedBundle[]> = new BehaviorSubject([]);
  private _allBundles: BehaviorSubject<ParsedBundle[]> = new BehaviorSubject([]);
  private _projectPermissions: BehaviorSubject<PermissionAssignee[]> = new BehaviorSubject([]);

  // backend subscriptions
  private _projectBundlesSubscription: Subscription | undefined;
  private _allBundlesSubscription: Subscription | undefined;
  private _projectPermissionsSubscription: Subscription | undefined;
  private _projectListSubscription: Subscription;

  projects$: Observable<Project[]> = this.store$.pipe(select(allProjectsView), map(_.values));
  currentProject$: Observable<Project | undefined> = this.store$.pipe(select(selectedProjectView));
  projectMeta$: Observable<ProjectMeta | null> = this.store$.pipe(select(projectMetaView));
  bundlesAssignedToProject$: Observable<ParsedBundle[]> = this._projectBundles.asObservable().pipe(distinctUntilChanged(_.isEqual));
  allBundles$: Observable<ParsedBundle[]> = this._allBundles.asObservable().pipe(distinctUntilChanged(_.isEqual));
  projectBundles$: Observable<ProjectBundle[]> = combineLatest([this.allBundles$, this.bundlesAssignedToProject$]).pipe(
    map(([allBundles, bundlesAssignedToProject]) => ProjectDependenciesHelper.getProjectBundles(allBundles, bundlesAssignedToProject))
  );
  displayProjectBundles$: Observable<ProjectBundle[]> = combineLatest([this.allBundles$, this.bundlesAssignedToProject$]).pipe(
    map(([allBundles, bundlesAssignedToProject]) => ProjectDependenciesHelper.getDisplayProjectBundles(allBundles, bundlesAssignedToProject))
  );
  projectPermissions: Observable<PermissionAssignee[]> = this._projectPermissions.asObservable().pipe(distinctUntilChanged(_.isEqual));
  hasAdminAccess$: Observable<boolean>;
  hasProjectModifyAccess$: Observable<boolean>;
  isCurrentProjectReadonly$: Observable<boolean>;

  constructor(private projectService: ProjectService,
              private permissionsService: PermissionsService,
              private bundleService: BundleService,
              private store$: Store<AppState>) {
    this.loadAllBundles();
    this.hasAdminAccess$ = this.store$.pipe(select(hasProjectAdminAccessView));
    this.hasProjectModifyAccess$ = this.store$.pipe(select(hasProjectWriteAccessView));
    this.isCurrentProjectReadonly$ = this.store$.pipe(select(isSelectedProjectReadonlyView));
    this._projectListSubscription = this.projects$.subscribe(() => this.loadAllBundles());
  }

  loadAllBundles(): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._allBundlesSubscription);

    this._allBundlesSubscription = this.bundleService.getAllBundles()
      .subscribe((allBundles) => this._allBundles.next(allBundles), () => {
        this.resetAllBundles();
      });
  }

  loadProjectBundles(projectKey: string): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._projectBundlesSubscription);

    this._projectBundlesSubscription = this.projectService.getPluginBundleForProject(projectKey)
      .subscribe((projectBundles) => {
        this._projectBundles.next(projectBundles);
        this.store$.dispatch(new LoadProjectMeta());
      }, () => {
        this.resetProjectBundles();
      });
  }

  loadProjectPermissions(target: string, operationKeys: string[]): void {
    Unsubscriber.unsubscribeFrom(this._projectPermissionsSubscription);

    this._projectPermissionsSubscription = this.permissionsService.getPermissionAssigneesForTarget(target, operationKeys, true, false)
      .subscribe((projectPermissions) => this._projectPermissions.next(projectPermissions), () => {
        this.resetProjectPermissions();
      });
  }

  resetProjectBundles(): void {
    this._projectBundles.next([]);
  }

  resetAllBundles(): void {
    this._allBundles.next([]);
  }

  resetProjectPermissions(): void {
    this._projectPermissions.next([]);
  }

  deleteProject(projectKey: string) {
    this.store$.dispatch(new DeleteProject(projectKey));
  }

  reloadPatternTypes(projectKey: string) {
    this.store$.dispatch(new LoadPatternTypes(projectKey));
  }

  reloadPropertyTypes(projectKey: string) {
    this.store$.dispatch(new LoadPropertyTypes(projectKey));
  }

  invalidatePluginDoc() {
    this.store$.dispatch(new InvalidatePluginDoc());
  }

  ngOnDestroy(): void {
    Unsubscriber.unsubscribeFrom(this._projectListSubscription);
  }
}
