import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectSummaryReportCellType, ProjectSummaryReportGraphLink, ProjectSummaryReportLink, ProjectSummaryReportPatternLink, ProjectSummaryReportPatternValue, ProjectSummaryReportPatternValues } from '../project-summary-report.model';
import { NavigationService } from '../../../../navbar/navigation.service';

@Component({
  selector: 'adm4-project-summary-report-table-cell',
  template: `
    <ng-container [ngSwitch]='cell.type'>
      <ng-container *ngSwitchCase='patternLink'>
          <div>
            <a *ngIf='cell.linkName && cell.linkId' (click)='navigateToPattern(cell.linkId)' [innerHTML]='cell.linkName | highlight : textToHighlight'></a>
          </div>
          <div class='link-description' [innerHTML]='cell.linkDescription | highlight : textToHighlight'></div>
      </ng-container>
      <ng-container *ngSwitchCase='link'>
        <a *ngIf='cell.linkName && cell.link' (click)='navigateToServices(cell.link)' [innerHTML]='cell.linkName | highlight : textToHighlight'></a><br/>
      </ng-container>
      <ng-container *ngSwitchCase='graphLink'>
        <adm4-link [linkURL]='getGraphViewLink(cell.patternId)' target='_blank'>
          <div class='icon-with-link-text-wrapper'>
            <div [innerHTML]='cell.linkName | highlight : textToHighlight'></div>
            <mat-icon class='new-tab-icon'>open_in_new</mat-icon>
          </div>
        </adm4-link>
      </ng-container>
      <ng-container *ngSwitchCase='values'>
        <div class='value' *ngFor='let value of cell.values' [innerHTML]='value | highlight : textToHighlight'></div>
      </ng-container>
      <ng-container *ngSwitchCase='value'>
        <div class='value' [innerHTML]='cell.value | highlight : textToHighlight'></div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./project-summary-report-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryReportTableCellComponent {

  @Input() cell: ProjectSummaryReportPatternLink | ProjectSummaryReportGraphLink | ProjectSummaryReportLink | ProjectSummaryReportPatternValues | ProjectSummaryReportPatternValue;
  @Input() projectKey: string;
  @Input() textToHighlight: string;

  readonly patternLink = ProjectSummaryReportCellType.patternLink;
  readonly graphLink = ProjectSummaryReportCellType.graphLink;
  readonly link = ProjectSummaryReportCellType.link;
  readonly values = ProjectSummaryReportCellType.values;
  readonly value = ProjectSummaryReportCellType.value;

  constructor(private navigationService: NavigationService) {}

  navigateToPattern(patternId: string): void {
    this.navigationService.navigateToPattern(this.projectKey, patternId);
  }

  navigateToServices(realmName: string) {
    this.navigationService.navigateToSummaryTabWithSearch(this.projectKey, 'applications', realmName);
  }

  getGraphViewLink(patternId: string): string {
    return this.navigationService.getGraphViewLink(this.projectKey, patternId);
  }
}
