import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ModalNotificationService } from '../../notification/modal-notification.service';

import { BundleManagementContext } from './bundle-management.context';
import { BundleGroup } from './bundle-group.model';
import { ParsedBundle } from './bundle.model';
import { AppState } from '../../model/reducer';
import { superAdminView } from '../../model/views';

@Component({
  selector: 'adm4-bundle-management',
  template: `
      <div class='full-height-flex'>
        <div class='bundle-management-header'>
          <adm4-column-header [styleClass]='"light-inline-header"'>
            <span>Pattern Library Management</span>
          </adm4-column-header>
        </div>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class='full-height-flex details-container'>
              <div>
                <div class='section-title'>
                  Libraries
                </div>
                <div class='bundle-uploader'>
                  <ng-template #noPermissionToUploadTooltip>
                    You don't have permission to upload new libraries
                  </ng-template>
                  <span [ngbTooltip]='noPermissionToUploadTooltip' [disableTooltip]='(bundleUploadPermitted$ | async)' placement='right'>
                  <button type='button'
                          adm4FileLoader [multiFile]='true' [acceptedExtensions]='[".jar"]' (load)='uploadBundles($event)' [disabled]='!(bundleUploadPermitted$ | async)'
                          class='admn4-button-ellipse-blue'
                          >Upload new libraries</button>
                </span>
                </div>
              </div>
              <div class='remaining-space-flex-content-wrapper'>
                <div class='remaining-space-flex-content'>
                  <adm4-bundle-management-table
                          [bundleGroups]='bundleGroups$ | async'
                          [hasSuperAdmin]="hasSuperAdmin$ | async"
                          (deleteBundle)="deleteBundle($event)"
                  ></adm4-bundle-management-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['./bundle-management.component.scss', '../../common/styles/component-specific/settings-details.scss'],
  providers: [BundleManagementContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleManagementComponent implements OnInit {
  bundleGroups$: Observable<BundleGroup[]>;
  bundleUploadPermitted$: Observable<boolean>;
  hasSuperAdmin$: Observable<boolean>;

  constructor(
    private bundleManagementContext: BundleManagementContext,
    private modalNotifications: ModalNotificationService,
    private store: Store<AppState>,
  ) {
    this.bundleGroups$ = this.bundleManagementContext.bundleGroups$;
    this.bundleUploadPermitted$ = this.bundleManagementContext.bundleUploadPermitted$;
    this.hasSuperAdmin$ = this.store.select(superAdminView);
  }

  ngOnInit(): void {
    this.bundleManagementContext.loadBundles();
  }

  uploadBundles(files: FileList): void {
    this.bundleManagementContext.uploadBundles(Array.from(files));
  }

  deleteBundle(bundle: ParsedBundle): void {
    const matDialogRef = this.modalNotifications.openConfirmDialog(
      {
        title: 'Delete library', headerTitle: 'Warning',
        description: `You are deleting <strong>${bundle.symbolicName}</strong> (version <strong>${bundle.version}</strong>).<br/>Make sure none of the projects are using this version. To create a backup, use the download option.`,
      },
      {confirmButtonText: 'Delete'},
    );
    matDialogRef.afterClosed().pipe(first()).subscribe((confirmed) => {
      if (confirmed) {
        this.bundleManagementContext.deleteBundle(bundle);
      }
    });
  }
}
