import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeploymentItemStatusModel, Status } from './deployment-status.model';
import { DeploymentStatusModel } from '../generation-status.model';
import { ValidatingHelper } from './validating.helper';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-validating',
  template: `
    <div class="full-height-flex">
      <div *ngIf='shouldShowValidating; else finishedValidation'
           class='validating'>
        <mat-spinner [diameter]='75'></mat-spinner>
          <div class='validating-details'>
            <h2 class='validating-text step-content-header'>{{validatingText}}</h2>
            <ng-container *ngIf='canShowOverallProgress'>
              <ng-container *ngTemplateOutlet='validatingDetailsNoSuccess'></ng-container>
            </ng-container>
          </div>
      </div>
      <ng-template #finishedValidation>
        <div class='validation-result'>
          <mat-icon *ngIf='isSuccessValidation' class='validation-icon done-icon'>done</mat-icon>
          <mat-icon *ngIf='hasErrorValidation' class='validation-icon error-icon'>error_outline</mat-icon>
          <div class='validating-details'>
            <h2 class='validating-text step-content-header'>{{validationDoneText}}</h2>
            <ng-container *ngTemplateOutlet='hasErrorValidation ? validatingDetailsNoSuccess : validatingDetailsSuccess'></ng-container>
          </div>
        </div>
      </ng-template>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <adm4-validating-items [validationItems]='status?.items'></adm4-validating-items>
        </div>
      </div>
    </div>
    <ng-template #validatingDetailsSuccess>
      <ng-content class='validating-text' select='.success'></ng-content>
    </ng-template>
    <ng-template #validatingDetailsNoSuccess>
      <div class='validating-text'>
        <span>Pending: {{pendingItems?.length}} </span>
        <span>Failed: {{failedItems?.length}} </span>
        <span>Done: {{doneItems?.length}} </span>
      </div>
    </ng-template>
  `,
  styleUrls: ['./validating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidatingComponent implements OnChanges {
  @Input() validatingText: string;
  @Input() validationDoneText: string;
  @Input() status: DeploymentStatusModel | null;

  pendingItems: DeploymentItemStatusModel[];
  doneItems: DeploymentItemStatusModel[];
  failedItems: DeploymentItemStatusModel[];

  canShowOverallProgress: boolean = false;
  isSuccessValidation: boolean = false;
  hasErrorValidation: boolean = false;
  shouldShowValidating: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.status && !!this.status) {
      const items = this.status?.items??[];
      this.pendingItems = items.filter(item => item.status === Status.Running || item.status === Status.Pending);
      this.doneItems = items.filter(item => item.status === Status.Done);
      this.failedItems = items.filter(item => item.status === Status.Failed);
      this.canShowOverallProgress = !_.isEmpty(this.pendingItems) || !_.isEmpty(this.doneItems) || !_.isEmpty(this.failedItems);
      this.isSuccessValidation = !!this.status && ValidatingHelper.isAllSuccess(this.status);
      this.hasErrorValidation = !!this.status && ValidatingHelper.isAnyFailed(this.status);
      this.shouldShowValidating = !this.status
        || (!ValidatingHelper.isItemDone(this.status.status) && !ValidatingHelper.isItemFailed(this.status.status));
    }
  }
}
