import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { A, NINE, SPACE, Z, ZERO } from '@angular/cdk/keycodes';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Component inspired by: https://github.com/mdlivingston/mat-select-filter
 */
@Component({
  selector: 'adm4-searchable-dropdown-input',
  template: `
    <form [formGroup]='searchForm' class="mat-filter">
      <input #input class="mat-filter-input" placeholder="{{placeholder}}"
             [formControlName]='SEARCH_INPUT_FORM_CONTROL_NAME'
             (input)='filterSearch()'
             (keydown)="handleKeydown($event)">
      <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
      <button *ngIf="searchTerm" class='clear-btn' (click)="clearSearchTerm()">
        <mat-icon class='clear-icon'>close</mat-icon>
      </button>
    </form>
    <div *ngIf="noResults" class="noResultsMessage">{{noResultsMessage}}</div>
  `,
  styleUrls: ['./searchable-dropdown.component.scss']
})
export class SearchableDropdownInputComponent<T> implements OnInit, OnChanges, OnDestroy {
  @ViewChild('input') input: ElementRef;

  @Input() sourceItems: T[] = [];
  @Input() displayMember: string;
  @Input() searchableFormatFn: (item: T) => string;
  @Input() placeholder = 'Search..';
  @Input() showSpinner = true;
  @Input() noResultsMessage = 'No results found';
  @Input() focusTrigger: Observable<void>;
  @Output() filteredResult = new EventEmitter<T[]>();
  @Output() searchText: EventEmitter<string> = new EventEmitter;

  public searchForm: UntypedFormGroup;
  public filteredItems: T[] = [];
  noResults = false;
  localSpinner = false;
  readonly SEARCH_INPUT_FORM_CONTROL_NAME = 'search-input';

  private focusEventsSubscription: Subscription;
  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.handleFocusEvents();
  }

  focusInput(): void {
    setTimeout(() => this.input?.nativeElement?.focus(),500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sourceItems'] && !_.isNil(this.sourceItems)) {
      this.updateDefaultReturnValue();
    }
  }

  private createFormGroup() {
    this.searchForm = this.fb.group({
      [this.SEARCH_INPUT_FORM_CONTROL_NAME]: this.fb.control('', [Validators.required])
    });
  }

  updateDefaultReturnValue(): void {
    this.filteredResult.emit(this.sourceItems);
  }

  filterSearch(): void {
    this.searchText.emit(this.searchTerm);
    if (this.showSpinner && !_.isEmpty(this.searchTerm)) {
      this.localSpinner = true;
    }

    if (!_.isEmpty(this.searchTerm)) {
      this.filteredItems = this.sourceItems.filter((listItem: T) => {
        const formattedListItem = this.searchableFormatFn(listItem);
        return formattedListItem.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
      this.noResults = !_.isEmpty(this.sourceItems) && _.isEmpty(this.filteredItems);
    } else {
      this.filteredItems = this.sourceItems.slice();
      this.noResults = false;
    }
    this.filteredResult.emit(this.filteredItems);

    setTimeout(() => {
      this.localSpinner = false;
      this.cdr.markForCheck();
    }, 1500);
  }

  handleKeydown(event: KeyboardEvent): void {
    // PREVENT PROPAGATION FOR ALL ALPHANUMERIC CHARACTERS IN ORDER TO AVOID SELECTION ISSUES
    let code: number | undefined;

    if (!_.isNil(event.key) && _.isEqual(event.key.length, 1)) {
      code = event.key.charCodeAt(0);
    }

    if (!_.isNil(code) && ((code >= A && code <= Z) || (code >= ZERO && code <= NINE) || (code === SPACE))) {
      event.stopPropagation();
    }
  }

  handleFocusEvents(): void {
    this.focusEventsSubscription = this.focusTrigger?.pipe(takeUntil(this.destroyed$)).subscribe(() => this.focusInput());
  }

  get searchTerm(): string {
    return this.searchForm?.value[this.SEARCH_INPUT_FORM_CONTROL_NAME];
  }

  clearSearchTerm(): void {
    this.searchForm?.controls[this.SEARCH_INPUT_FORM_CONTROL_NAME].setValue('');
    this.filterSearch();
    this.focusInput();
  }

  ngOnDestroy(): void {
    this.filteredResult.emit(this.sourceItems);
    this.searchText.emit(undefined);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
