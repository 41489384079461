<div [@deploymentDetailsExpand] *ngIf='expanded' class='deployment-details-container'>
  <div class="deleted-msg-layout">
    <div class="deleted-msg" *ngIf="historyItem.projectDeleted">
      <div class="deleted-icon-wrapper">
        <mat-icon>info</mat-icon>
      </div>

      <p *ngIf='!isKubernetesStatusScreen'>This project was deleted from nevisAdmin 4.</p>
      <p *ngIf='isKubernetesStatusScreen && !isSecondary'>This project was deleted from nevisAdmin 4.
        But the deployed services are still running. To clean them up, make a secondary deployment and promote it.</p>
      <p *ngIf='isKubernetesStatusScreen && isSecondary'>This project was deleted from nevisAdmin 4.
        But the deployed services are still running. To clean them up, rollback the deployment.</p>
    </div>

    <ng-template #actionList>
      <div class='deployment-detail-header deployment-action-list'>Actions</div>
      <p *ngFor="let action of deploymentActions" class="deployment-action-item">
        {{action.actionLabel}} by <strong>{{action.userKey}}</strong>, {{action.timestamp | customDate}}
      </p>
    </ng-template>

    <div class='deployment-details full-width'>
      <div *ngIf='historyItem.deploymentTargets && isKubernetesInventory' [class.wide-column]='isKubernetesStatusScreen'>
        <ng-container *ngIf="!isKubernetesStatusScreen">
          <ng-container *ngTemplateOutlet="actionList"></ng-container>
        </ng-container>

        <div class='deployment-detail-header'>Deployed services</div>
        <div *ngFor='let service of getDeploymentTargets(historyItem.deploymentTargets, historyItem.postfix)'
                  class='deployed-service-list-element' [class.expandable-list]='isKubernetesStatusScreen'>
          <ng-container *ngIf='!isKubernetesStatusScreen; else expandableServices'>
            {{service}}
          </ng-container>
          <ng-template #expandableServices>
            <div class='deployed-service-item' (click)='toggleDeployedServiceDropdown(service, historyItem.projectKey)'>
              <adm4-validation-indicator class='validation-indicator'
                                         *ngIf='isKubernetesStatusScreen'
                                         [isDisplayed]='true'
                                         [isValid]='checkDeployedServiceValidity("valid", service)'
                                         [isError]='checkDeployedServiceValidity("error", service)'
                                         [isWarning]='checkDeployedServiceValidity("warning", service)'
                                         [isNeutral]='checkDeployedServiceValidity("neutral", service)'
                                         [diameter]='9'>
              </adm4-validation-indicator>
              {{service}}
              <mat-icon *ngIf='isKubernetesStatusScreen && inventoryServiceHelper.hasDeployedServicePods(deployedServices, service)' class='expand-icon'>
                {{this.isDeployedServiceExpanded(service, historyItem.projectKey) ? 'expand_less' : 'expand_more'}}
              </mat-icon>
            </div>
            <div *ngIf='isDeployedServiceExpanded(service, historyItem.projectKey) && inventoryServiceHelper.hasDeployedServicePods(deployedServices, service)' class='pod-list-container'>
              <div class='pod-header'>Pods</div>
              <div *ngFor='let pod of inventoryServiceHelper.getPodsByService(deployedServices, service)' class='pod-list-item'>
                <adm4-pod-status-indicator [pod]="pod"></adm4-pod-status-indicator>
                <adm4-pod-details [pod]="pod" (viewLogs)="openPodLogsDialog.emit($event)"></adm4-pod-details>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf='!isKubernetesStatusScreen'>
          <ng-container *ngTemplateOutlet="deploymentRepoGitTagTemplate">
          </ng-container>
        </ng-container>
      </div>

      <div *ngIf='!isKubernetesStatusScreen'>
        <ng-container>
          <div class='deployment-detail-header prj-commit-id'>Publish project</div>
          <adm4-deployment-revision-information [commitId]='historyItem.projectCommitId'
                                                [revision]='projectRevision'
                                                *ngIf='historyItem.projectCommitId; else projectNotPublished'
          ></adm4-deployment-revision-information>
          <ng-template #projectNotPublished>
            <p>Project was not published to Git before deployment </p>
          </ng-template>
        </ng-container>

        <ng-container>
          <div class='deployment-detail-header inv-commit-id'>Publish inventory</div>
          <adm4-deployment-revision-information [commitId]='historyItem.inventoryCommitId'
                                                [revision]='inventoryRevision'
                                                *ngIf='historyItem.inventoryCommitId; else inventoryNotPublished'>
          </adm4-deployment-revision-information>
          <ng-template #inventoryNotPublished>
            <p>Inventory was not published to Git before deployment</p>
          </ng-template>
        </ng-container>
      </div>

      <div *ngIf='isKubernetesStatusScreen'>
        <ng-container>
          <ng-container *ngTemplateOutlet="actionList"></ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="deploymentRepoGitTagTemplate">
        </ng-container>

        <div *ngIf='shouldDisplayCanaryRoutingInfo(historyItem.canaryRouting, historyItem.deploymentState)'>

          <ng-container>
            <div class='deployment-detail-header'>Secondary (side-by-side) deployment</div>
            <div class='deployment-detail-label routing-base-label'>Routing based on</div>
            <ul class='routing-list'>
              <li *ngIf='historyItem.canaryRouting.header'>
                <div class='deployment-detail-label'>Header</div>
                <div class='routing-setting-value' [title]='historyItem.canaryRouting.header'>
                  {{historyItem.canaryRouting.header}}
                </div>
              </li>
              <li *ngIf='historyItem.canaryRouting.cookie'>
                <div class='deployment-detail-label'>Cookie name</div>
                <div class='routing-setting-value' [title]='historyItem.canaryRouting.cookie'>
                  {{historyItem.canaryRouting.cookie}}
                </div>
              <li *ngIf='historyItem.canaryRouting.query'>
                <div class='checkbox-container'>
                  <mat-checkbox [disabled]='true' [checked]='historyItem.canaryRouting.query'></mat-checkbox>
                  <label class='label-with-info input-label'>
                    <span>Auto set cookie with URL</span>
                    <span class='info-icon-container' [ngbTooltip]='queryParamInfo' placement='left'>
                      <i class="fa fa-question-circle help-icon" aria-hidden="true" title=''></i>
                    </span>
                    <ng-template #queryParamInfo>
                      <p>Sample URL with the query parameter:</p>
                      <ul>
                        <li>
                          <span>&lt;yourURL>/?canary=always // enable routing to the secondary</span>
                        </li>
                        <li>
                          <span>&lt;yourURL>/?canary=never // disable routing to the secondary</span>
                        </li>
                      </ul>
                      where "canary" is the cookie name. It is recommended to set "Header name" as well. Find further
                      details at
                      <adm4-external-link
                        [linkUrl]="'nevisadmin4/User-Guide/Deployment-of-the-Configuration/Kubernetes-Deployment/Side-by-side-Deployment/' | docLink"
                        [linkLabel]='"Side-by-side Deployment"'
                        [displayStyle]="'inline'"
                        [openInNewTab]='true'></adm4-external-link>
                    </ng-template>
                  </label>
                </div>
              </li>
              <li *ngIf='historyItem.canaryRouting.percentage'>
                <div class='deployment-detail-label'>User base (%)</div>
                <div [title]='historyItem.canaryRouting.percentage'>
                  {{historyItem.canaryRouting.percentage}}% to Secondary
                </div>
              </li>
            </ul>

          </ng-container>
        </div>

        <ng-container *ngTemplateOutlet="deploymentCommentTemplate">
        </ng-container>
      </div>

      <ng-container *ngIf='!isKubernetesStatusScreen'>
        <div>
          <ng-container *ngTemplateOutlet="deploymentCommentTemplate">
          </ng-container>
        </div>
      </ng-container>

      <!-- Wrap deployment git tag and comment sections into a reusable template as they're displayed differently in Kubernetes status screen -->
      <ng-template #deploymentRepoGitTagTemplate>
        <div class='deployment-detail-header git-tag'>Depl. repo git tag</div>
        <div class='copy-clipboard-container'>
          <div class='text-to-copy'>{{historyItem.tag}}</div>
          <mat-icon [cdkCopyToClipboard]="historyItem.tag">content_copy</mat-icon>
        </div>
      </ng-template>

      <ng-template #deploymentCommentTemplate>
        <ng-container *ngIf='deploymentComment'>
          <div class='deployment-detail-header op-comment'>Comment</div>
          <div class='comment-content'>
            {{showMore ? deploymentComment : (deploymentComment | slice:0:50)}}
            <span *ngIf="!showMore && deploymentComment.length > 50">...</span><br>
            <a *ngIf="!showMore && deploymentComment.length > 50" (click)="showMore=true">Show More</a>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="actions d-flex justify-content-end">
    <button class="admn4-button-ellipse-red" (click)="deleteK8sDeployment()" *ngIf='isKubernetesStatusScreen && !isSecondary'>Delete deployment</button>
  </div>
</div>
