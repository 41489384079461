import { InventoryDisplayData, InventoryVariable, InventoryVariableValue, VariableDetails, VariableInventoryData } from '../variable-details/variable-details.model';
import * as _ from 'lodash';
import { VariableListItem, VariableModel } from '../variable.model';
import { PropertyType } from '../../plugins/property-type.model';
import { UsageInfo } from '../../common/components/usage/usage-info';
import { Dictionary } from '../../model/reducer';

export class VariableEditorHelper {

  static convertData(projectKey: string, selectedVariable: VariableListItem, variableTypes: Dictionary<PropertyType>, inventories: VariableInventoryData[], usages: UsageInfo[]): VariableDetails {
    const variableType = variableTypes[selectedVariable.variable.className];
    const inventoryDisplayData = VariableEditorHelper.convertInventoryData(inventories);
    return {
      projectKey: projectKey,
      selectedVariable: selectedVariable,
      variableType: variableType,
      requireOverloading: selectedVariable.variable.requireOverloading,
      usages: usages,
      inventories: inventoryDisplayData
    };
  }

  static convertInventoryData(inventoryDataList: VariableInventoryData[]): InventoryDisplayData[] {
    const result: InventoryDisplayData[] = inventoryDataList.map((inventoryData: VariableInventoryData) => {
      return {
        inventoryKey: inventoryData.inventoryKey,
        color: inventoryData.color,
        hostVars: this.convertVariableValueDictionaryToInventoryVariableList(inventoryData.hosts),
        groupVars: this.convertVariableValueDictionaryToInventoryVariableList(inventoryData.groups),
        vars: {
          sourceName: null,
          value: this.convertInventoryVariableValueToString(inventoryData.vars)
        },
        schemaType: inventoryData.schemaType,
      };
    });
    return _.sortBy(result, 'inventoryKey');
  }

  static convertVariableValueDictionaryToInventoryVariableList(variableValueDictionary: Dictionary<InventoryVariableValue> | undefined): InventoryVariable[] {
    return _.isNil(variableValueDictionary) ? [] : _.entries(variableValueDictionary).map(([hostName, variableValue]: [string, InventoryVariableValue]) => {
      return {sourceName: hostName, value: this.convertInventoryVariableValueToString(variableValue)};
    });
  }

  static convertInventoryVariableValueToString(variableValue: InventoryVariableValue | undefined): string {
    if (_.isString(variableValue) || _.isNumber(variableValue) || _.isBoolean(variableValue)) {
      return `${variableValue}`;
    } else if (_.isArray(variableValue)) {
      return _.reduce(variableValue, (accumulator, currentValue) => {
        if(!_.isEmpty(accumulator)) accumulator += '\n';
        if (_.isString(currentValue)) return accumulator + currentValue;
        return accumulator + Object.entries(currentValue)[0].join(': ');
      }, '');
    } else if (_.isObject(variableValue)) {
      return JSON.stringify(variableValue);
    }
    return '';
  }

  static updateVariableList(variables: VariableModel[], updatedVariable: VariableModel): VariableModel[] {
    return _.map(variables, (v: VariableModel) => {
      let result = v;
      if (v.variableKey === updatedVariable.variableKey) {
        result = updatedVariable;
      }
      return result;
    });
  }

  static removeVariableFromList(variables: VariableModel[], variableKey: string) {
    return variables.filter((iterated: VariableModel) => {
      return iterated.variableKey !== variableKey;
    });
  }

  static isVariableNameExistingAlready(variables: VariableModel[], variableKey: string): boolean {
    return _.some(variables, (variable: VariableModel) => {
      return variable.variableKey === variableKey;
    });
  }
}
