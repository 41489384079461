import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { GlobalConstantWithUsage } from '../../../inventory/inventory.model';

export const gcFilter = (gc: GlobalConstantWithUsage, filter: string): boolean => {
  const lcFilter = filter.toLowerCase();
  return gc.name.toLowerCase().includes(lcFilter)
      || !!gc.description?.toLowerCase().includes(lcFilter)
      || gc.value.toLowerCase().includes(lcFilter)
      || gc.usedIn?.some(inventoryKey => inventoryKey.toLowerCase().includes(lcFilter)) || false;
};

@Component({
  selector: 'adm4-global-constants-table',
  template: `
    <mat-table [dataSource]="dataSource" matSort class="adm4-mat-table">
      <ng-container [matColumnDef]="COLUMNS.name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-name">
          <span>Name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let gc;" class="col-name">
          <div class="col-name-container" [ngbTooltip]="prefixedValue" placement="right">
            <div [innerHTML]="gc.name | highlight: filter"></div>
            <mat-icon class="copy-to-clipboard" matRipple matRippleCentered
                      [cdkCopyToClipboard]="'\$\{g-const://' + gc.name + '}'">content_copy</mat-icon>
          </div>
          <ng-template #prefixedValue><span>{{'\$\{g-const://' + gc.name + '}'}}</span></ng-template>
        </mat-cell>
      </ng-container>
      
      <ng-container [matColumnDef]="COLUMNS.value">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-value">
          <span>Value</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let gc;" class="col-value">
          <ng-container *ngIf="gc.value; else noValue">
            <div *ngIf='{value: gc.value, showMore: false} as valueInfo;'>
              <span [innerHTML]="(valueInfo.showMore) ? (valueInfo.value | highlight: filter) : (valueInfo.value | slice:0:25 | highlight: filter) || '-'"></span>
              <span *ngIf="!valueInfo.showMore && valueInfo.value?.length > 25">...</span><br>
              <ng-container *ngIf="valueInfo.value?.length > 25">
                <a *ngIf="!valueInfo.showMore" (click)="valueInfo.showMore=true">Show more</a>
                <a *ngIf="valueInfo.showMore " (click)="valueInfo.showMore=false">Show less</a>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noValue><span>-</span></ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="COLUMNS.usedIn">
        <mat-header-cell *matHeaderCellDef>
          <span>Used in</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let gc;">
          <adm4-constant-usage-list
            [globalConstant]="gc"
            [highlight]="filter"
            (navigateToInventory)="navigateToInventory.emit($event)"
          ></adm4-constant-usage-list>
        </mat-cell>
      </ng-container>
      
      <ng-container [matColumnDef]="COLUMNS.description">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-desc">
          <span>Description</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let gc;" class="col-desc">
          <div *ngIf='{desc: gc.description, showMore: false} as descInfo;' class='description-content-wrapper'>
            <span [innerHTML]="(descInfo.showMore) ? (descInfo.desc | highlight: filter) : (descInfo.desc | slice:0:25 | highlight: filter) || '-'"></span>
            <span *ngIf="!descInfo.showMore && descInfo.desc?.length > 25">...</span><br>
            <ng-container *ngIf="descInfo.desc?.length > 25">
              <a *ngIf="!descInfo.showMore" (click)="descInfo.showMore=true">Show more</a>
              <a *ngIf="descInfo.showMore " (click)="descInfo.showMore=false">Show less</a>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>
      
      <ng-container [matColumnDef]="COLUMNS.actions">
        <mat-header-cell *matHeaderCellDef class="action-cell">
          <span>Action</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let gc;" class="action-cell">
          <button [disabled]="!hasModifyTenantPermission" class="action-btn"
                  [title]="hasModifyTenantPermission ? 'Edit global constant' : 'You don’t have permission to edit.'"
                  (click)="editClicked(gc)"
          ><mat-icon>mode_edit</mat-icon></button>
          <button [disabled]="!hasModifyTenantPermission" class="action-btn"
                  [title]="hasModifyTenantPermission ? 'Delete global constant' : 'You don’t have permission to delete.'"
                  (click)="deleteClicked(gc)"
          ><mat-icon>delete_forever</mat-icon></button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList;"></mat-row>
    </mat-table>
  `,
  styleUrls: ['./global-constants-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalConstantsTableComponent implements OnChanges, AfterViewInit {

  readonly COLUMNS = {
    name: 'name',
    value: 'value',
    usedIn: 'usedIn',
    description: 'description',
    actions: 'actions',
  } as const;
  readonly columnList: ReadonlyArray<string> = Object.values(this.COLUMNS);

  @Input() globalConstants: GlobalConstantWithUsage[];
  @Input() filter: string | undefined;
  @Input() hasModifyTenantPermission: boolean = false;

  @Output() edit: EventEmitter<GlobalConstantWithUsage> = new EventEmitter();
  @Output() delete: EventEmitter<GlobalConstantWithUsage> = new EventEmitter();
  @Output() navigateToInventory: EventEmitter<string> = new EventEmitter();

  readonly dataSource: MatTableDataSource<GlobalConstantWithUsage> = new MatTableDataSource([]);
  @ViewChild(MatSort) tableSort: MatSort;

  constructor() {
    this.dataSource.filterPredicate = gcFilter;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.tableSort;
    // without the setTimeout, changing the sort would throw `ExpressionChangedAfterItHasBeenCheckedError`
    setTimeout(() => this.dataSource.sort!.sort({id: 'name', start: 'asc', disableClear: false}), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.globalConstants) {
      this.dataSource.data = this.globalConstants;
    }
    if (changes.filter) {
      this.dataSource.filter = this.filter || '';
    }
  }

  editClicked(gc: GlobalConstantWithUsage) {
    this.edit.emit(gc);
  }

  deleteClicked(gc: GlobalConstantWithUsage) {
    this.delete.emit(gc);
  }
}
