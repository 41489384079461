import { Injectable } from '@angular/core';
import { PropertyWidgetContext } from './property-widget.context';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { patternResourcesView, patternsView, patternTypesView, selectedPatternInstanceView, selectedProjectView } from '../model/views';
import { select, Store } from '@ngrx/store';
import { AppState, Dictionary } from '../model/reducer';
import { PatternInstance } from '../patterns/pattern-instance.model';
import { Observable } from 'rxjs/internal/Observable';
import { PatternType } from '../plugins/pattern-type.model';
import { Pattern } from '../patterns/pattern.model';
import { combineLatest } from 'rxjs';
import { Project } from '../projects/project.model';
import { ResourceList } from '../patterns/pattern-attachment.model';
import { PropertyWidgetContextType } from './property-widget-context-type.enum';
import { ViewAttachmentFileDiffEvent } from '../projects/publish-project/pattern-diff-view/pattern-diff-data.model';

@Injectable()
export class PatternEditorPropertyContext implements PropertyWidgetContext {
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  currentProject$: Observable<Project | null>;
  patterns$: Observable<Map<string, Pattern>>;
  allPatternResources$: Observable<Dictionary<Dictionary<ResourceList>>>;
  type = PropertyWidgetContextType.PATTERN_EDITOR;

  public toggleDiffAttachmentResource(_item: ViewAttachmentFileDiffEvent): void {/* only used in the publish context */}

  constructor(private store$: Store<AppState>) {
    this.patternTypes$ = this.store$.pipe(select(patternTypesView));
    this.currentProject$ = this.store$.pipe(select(selectedProjectView), map((project: Project | undefined) => project || null));
    this.patterns$ = this.store$.pipe(select(patternsView));
    this.allPatternResources$ = combineLatest([
      this.store$.pipe(select(selectedPatternInstanceView)),
      this.store$.pipe(select(patternResourcesView))]
    ).pipe(map(([pattern, patternResources]: [PatternInstance | null, Dictionary<ResourceList>]) => {
        return _.isNil(pattern) ? {} : {[pattern.patternId]: patternResources};
      })
    );
  }

}
