import { HttpErrorResponse } from '@angular/common/http';

import {
  catchError, distinctUntilChanged,
  mergeMap, switchMap,
  filter, map, mapTo,
  finalize, first,
  shareReplay,
  withLatestFrom,
  take, tap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CheckUpdatesOfProject,
  ClearLocalChangesOfProject,
  ConnectProjectToVersionControl,
  ConnectProjectToVersionControlSuccess,
  CreateProject,
  DeleteProject,
  DeleteProjectSuccess,
  FetchProjectData,
  ProjectsNowAvailable,
  GetUpdatesOfProject,
  LoadIssues,
  LoadIssuesSuccess,
  LoadProjects,
  LoadProjectsAndNavigate,
  LoadProjectsSuccess,
  NavigateToCreatedProject,
  ProjectActionTypes,
  RequestUpdatesOfProject,
  ResetProjectsAfterDeletingLast,
  SelectProject,
  StoreProjectFromLocalStorage,
} from './project.actions';

import { select, Store } from '@ngrx/store';
import { EMPTY, from as observableFrom, Observable, of, of as observableOf } from 'rxjs';
import * as _ from 'lodash';

import { EmptyAction, NevisAdminAction } from '../actions';
import { InvalidatePatternSummaryReports, LoadReports } from '../report/report.actions';
import { ProjectService } from '../../projects/project.service';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { StoreDeploymentProject } from '../deploy';
import { Project, ProjectMeta } from '../../projects/project.model';
import { AppState, Dictionary } from '../reducer';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { NavigationService } from '../../navbar/navigation.service';
import { VersionControlService } from '../../version-control/version-control.service';
import { Branch } from '../../version-control/branch.model';
import { IssueModel } from '../../common/model/issue.model';
import { LoadProjectMeta } from '../version-control';
import { LoadAllPatternInstances, LoadPatternTypes, LoadPropertyTypes, SelectPatternId } from '../pattern';
import { LoadVariables } from '../variables';
import { JobService } from '../../shared/job.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import {
  allProjectsListView,
  allProjectsView,
  projectKeyView,
  projectMetaView,
  selectedPatternInstanceView,
  selectedTenantKeyView,
} from '../views';
import { HTTP_STATUS_CONFLICT } from '../../shared/http-status-codes.constants';
import { ImportProjectDialogService } from '../../projects/import-project/import-project-dialog.service';
import { CreateProjectDialogService } from '../../projects/create-project/create-project-dialog.service';
import { ConnectProjectToVersionControlPayload } from './project-action-payload.model';
import { IssueReducingHelper } from '../../projects/project-issues/issue-reducing.helper';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { localStorageProjectKey } from '../../common/constants/local-storage-keys.constants';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { ProjectFileImportPayload } from '../../projects/file-based-import-project/project-file-import-payload.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { IImportEffectMixin, ImportEffectMixin } from '../import-effect.mixin';
import { RETURN_BUTTON_LABEL } from '../../notification/notification.constants';
import { ProjectErrorFieldSource } from '../../projects/project.constants';
import { LocalChangesModalNotificationService } from '../../notification/local-changes-modal-notification.service';
import { BranchProjectDialogService } from '../../projects/branch-project/branch-project-dialog.service';
import { PublishProjectChangesetHelper } from '../../projects/publish-project/publish-project-changeset.helper';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { LocalStatus } from '../../version-control/meta-info.model';
import { RevertProjectDialogService } from '../../projects/revert-project-component/revert-project-dialog.service';
import { Maybe } from '../../common/utils/utils';


@Injectable()
@Mixin([ImportEffectMixin])
export class ProjectEffects implements IImportEffectMixin {
  selectedTenantKey$: Observable<string> = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;

  sharedSelectedTenantKey$: Observable<string> = this.store$.pipe(
      map(selectedTenantKeyView),
      distinctUntilChanged(),
      filter((selectedTenantKey: Maybe<string>): selectedTenantKey is string => !!selectedTenantKey),
      distinctUntilChanged(),
      shareReplay(1),
  );

  /**
   *  Implemented by ImportEffectMixin
   */
  importEffect: <P, SuccessAction extends NevisAdminAction<any>, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleSuccess: (payload: P) => SuccessAction,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>,
    keyExtractFunction: (payload: P) => string,
    importedType: string,
    importSource: string
  ) => Observable<SuccessAction | ErrorAction>;

  /**
   * Whenever update happens to project (or new project has been selected) we need to reload project data,
   * project bundles(dependencies) also affect other parts of project data, so it also has to trigger update
   * @type {Observable<FetchProjectData>}
   */
  fetchProjectDataOnUpdates: Observable<FetchProjectData> = createEffect(() => this.actions$
    .pipe(
      ofType(ProjectActionTypes.SelectProject),
      map(() => new FetchProjectData())
    ));

  fetchProjectData: Observable<LoadPatternTypes | LoadPropertyTypes | LoadAllPatternInstances | LoadIssues | LoadVariables | LoadReports | LoadProjectMeta | InvalidatePatternSummaryReports>;

  checkUpdatesOfProject: Observable<RequestUpdatesOfProject>;
  clearLocalChangesOfProject: Observable<SelectProject | SelectPatternId | EmptyAction>;
  revertProject: Observable<SelectProject | SelectPatternId | EmptyAction>;
  requestUpdatesOfProject: Observable<GetUpdatesOfProject | EmptyAction>;
  importProject: Observable<LoadProjectsAndNavigate | LoadProjects | EmptyAction>;
  importProjectFile: Observable<LoadProjectsAndNavigate | EmptyAction>;
  loadIssues: Observable<LoadIssuesSuccess | EmptyAction>;
  loadProjectsIfMissing: Observable<ProjectsNowAvailable>;
  loadProjects: Observable<LoadProjectsSuccess | EmptyAction>;
  loadProjectsAndNavigate: Observable<LoadProjectsSuccess | NavigateToCreatedProject>;
  saveSelectedProjectToLocalStorage: Observable<any>;
  getUpdatesOfProject: Observable<FetchProjectData | SelectPatternId | EmptyAction>;
  storeProjectKeyForDeploymentSelection: Observable<StoreDeploymentProject>;
  createProject: Observable<LoadProjectsAndNavigate | EmptyAction>;
  branchProject: Observable<LoadProjectsAndNavigate | LoadProjects | EmptyAction>;
  connectProjectToVersionControl: Observable<ConnectProjectToVersionControlSuccess | LoadProjects | EmptyAction>;
  navigateToCreatedProject: Observable<SelectProject>;
  /**
   * Calling service to delete project, if error happens error modal will be displayed and no further action will be needed
   * If project is deleted successfully, success modal is displayed, after it's closed delete success will be dispatch and further actions will be taken in related effect
   * @type {Observable<DeleteProjectSuccess | EmptyAction>}
   */
  deleteProject: Observable<DeleteProjectSuccess> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActionTypes.DeleteProject),
      map((action: DeleteProject) => action.payload),
      switchMap((projectKey: string) => {
        const projectName = TenantHelper.cropTenantFromKey(projectKey);
        return this.projectService.deleteProject(projectKey).pipe(
          tap(() => this.modalNotificationService.openSuccessDialog({title: 'Delete project ', description: `${projectName} successfully deleted`}).afterClosed().pipe(take(1)).subscribe()),
          map(() => new DeleteProjectSuccess(projectKey)),
          catchError((error) => {
            console.error(error);
            this.modalNotificationService.openHttpErrorDialog(error, `Error when trying to delete project ${projectName}`);
            return EMPTY;
          }));
      })
    ));
  /**
   * This effect contains flow after deleting a project and takes next steps:
   * 1. List of projects will be loaded again from the backend
   *   1.1. If loading projects fails, the current project list will be used filtering deleted project out from it
   * 2. Navigation will be triggered next
   * IMPORTANT NOTE: navigation is triggered before handling success of loading projects for 2 reasons:
   * not to add extra conditional behavior into effects of that
   * not to interfere with project.component routing subscription, as it would trigger its own navigation if project list changed
   * 2.1. If last project was deleted we navigate to welcome screen
   * 2.2. Otherwise we stay on project settings screen
   * 3. After navigation happened loadProjectSuccess is dispatched to update list of projects in the store
   * @type {Observable<LoadProjectsSuccess>}
   */
  deleteProjectSuccess: Observable<ResetProjectsAfterDeletingLast | LoadProjectsSuccess> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActionTypes.DeleteProjectSuccess),
      map((action: DeleteProjectSuccess) => action.payload),
      withLatestFrom(
        this.store$.pipe(select(allProjectsListView)),
        this.selectedTenantKey$,
      ),
      mergeMap(([projectKey, projects, selectedTenantKey]: [string, Project[], string]) => {
        return this.projectService.getProjectsOfTenant(selectedTenantKey).pipe(
          // if reloading project lists somehow failed use current list and filter out key of deleted project from it
          catchError(() => projects.filter(project => project.projectKey !== projectKey)));
      }),
      mergeMap((projects: Project[]) => {
        let navigationEnd: Observable<boolean>;
        if (_.isEmpty(projects)) {
          navigationEnd = observableFrom(this.navigationService.navigateToWelcome());
          return navigationEnd.pipe(map(() => new ResetProjectsAfterDeletingLast()));
        } else {
          const projectToSelect = projects[0];
          navigationEnd = observableFrom(this.navigationService.navigateToProjectSettings(projectToSelect.projectKey));
          return navigationEnd.pipe(map(() => new LoadProjectsSuccess(projects)));
        }
      })
    ));

  constructor(public store$: Store<AppState>, // NOSONAR because we use constructor of effects for assigning effects, where each of the is independent and therefore don't share complexity
              public jobService: JobService,
              public actions$: Actions<NevisAdminAction<any>>,
              public loadingService: LoadingService,
              private projectService: ProjectService,
              private versionControlService: VersionControlService,
              private modalNotificationService: ModalNotificationService,
              private localChangesModalNotificationService: LocalChangesModalNotificationService,
              private navigationService: NavigationService,
              private toastNotificationService: ToastNotificationService,
              private importProjectDialogService: ImportProjectDialogService,
              private createProjectDialogService: CreateProjectDialogService,
              private revertProjectDialogService: RevertProjectDialogService,
              private branchProjectDialogService: BranchProjectDialogService,
  ) {
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;

    this.fetchProjectData = this.createFetchProjectDataEffect();

    this.checkUpdatesOfProject = createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.CheckUpdatesOfProject))
      .pipe(
        map((action: CheckUpdatesOfProject) => action.payload),
        withLatestFrom(this.store$.pipe(select(allProjectsView))),
        map(([projectKey, projects]: [string, Dictionary<Project>]) => projects[projectKey]),
        withLatestFrom(this.store$.pipe(select(projectMetaView))),
        switchMap(([project, projectMeta]: [Project | undefined, ProjectMeta | null]) => {
          if (_.isNil(project) || _.isNil(projectMeta) || _.isNil(project.repository) || _.isNil(project.branch)) {
            this.modalNotificationService.openErrorDialog({description: 'Cannot check for updates because project data is missing.'});
            return EMPTY;
          }
          return this.versionControlService.getBranchByRepoAndName(project.repository, project.branch).pipe(
            switchMap((branch: Branch | undefined) => {
              const hasRemoteChange = !_.isNil(branch) && branch.commitId !== projectMeta.localHead;
              const hasLocalChange = PublishProjectChangesetHelper.hasLocalChanges(projectMeta);
              const projectName = TenantHelper.cropTenantFromKey(project.projectKey);
              if (hasLocalChange) {
                if (hasRemoteChange) {
                  return of(new RequestUpdatesOfProject(project.projectKey));
                } else {
                  this.localChangesModalNotificationService.openInfoDialog({
                    title: 'No updates found',
                    description: ''
                  }, {
                    confirmButtonText: 'Ok, Return',
                    projectKey: project.projectKey
                  });
                  return EMPTY;
                }
              } else {
                if (hasRemoteChange) {
                  return of(new RequestUpdatesOfProject(project.projectKey));
                } else {
                  this.modalNotificationService.openInfoDialog({title: 'No updates found', description: `There are no updates available for project ${projectName}. The project is up to date.`});
                  return EMPTY;
                }
              }
            }));
        })
      ));

    this.revertProject = createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.RevertProject))
      .pipe(
        map((action: ClearLocalChangesOfProject) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectMetaView))),
        switchMap(([projectKey, projectMeta]: [string, ProjectMeta | null]) => {
          if (_.isNil(projectKey) || _.isNil(projectMeta)) {
            this.modalNotificationService.openErrorDialog({description: 'Cannot clear local changes because project data is missing.'});
            return observableOf(new EmptyAction());
          }
          return this.revertProjectDialogService.openRevertProjectDialog(projectKey).afterClosed().pipe(switchMap((result) => {
              if (!result) return of(new EmptyAction());
              const loadingDialogAfterClosed = this.loadingService.showLoading({title: `The project ${projectKey} is currently being reset.`, description: `This may take a few seconds`});
              return this.projectService.revertToRevision(result.projectKey, result.selectedRevision.commitId).pipe(
                switchMap(() => {
                  this.loadingService.hideLoading();
                  return loadingDialogAfterClosed.pipe(
                    tap(() => {
                      this.modalNotificationService.openSuccessDialog(({title: 'Revert successful', description: 'The project has been reverted successfully.'}));
                      this.navigationService.navigateToProjectSummary(projectKey);
                    }),
                    map(() => new EmptyAction())
                  );
                }),
                catchError((err: HttpErrorResponse) => {
                  this.loadingService.hideLoading();
                  return loadingDialogAfterClosed.pipe(
                    tap(() => {
                      if (err.status === HTTP_STATUS_CONFLICT) {
                        this.modalNotificationService.openErrorDialog({title: 'The project is not up to date', description: 'The project is not up to date . First, update the project from Git and try reverting it again.'});
                      } else {
                        this.modalNotificationService.openErrorDialog({title: '', description: ErrorHelper.getErrorDetail(err)});
                      }
                    }), map(() => new EmptyAction()));
                })
              );
            }),
          );
        })
      ));

    this.clearLocalChangesOfProject = createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.ClearLocalChangesOfProject))
      .pipe(
        map((action: ClearLocalChangesOfProject) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectMetaView))),
        switchMap(([projectKey, projectMeta]: [string, ProjectMeta | null]) => {
          if (_.isNil(projectKey) || _.isNil(projectMeta)) {
            this.modalNotificationService.openErrorDialog({description: 'Cannot clear local changes because project data is missing.'});
            return observableOf(new EmptyAction());
          }
          const hasLocalChange = PublishProjectChangesetHelper.hasLocalChanges(projectMeta);
          const projectName = TenantHelper.cropTenantFromKey(projectKey);
          if (hasLocalChange) {
            return this.modalNotificationService.openConfirmDialog({
              title: 'Local changes will be lost',
              description: `<br/>Your local changes will be lost after you clear them.<br/><br/>Do you want to continue?`
            }, {
              confirmButtonText: 'Continue'
            }).afterClosed().pipe(switchMap((confirmed?: boolean) => {
              if (confirmed === true) {
                const loadingDialogAfterClosed = this.loadingService.showLoading({title: `The project ${projectName} is currently being reset.`, description: `This may take a few seconds`});
                return this.projectService.clearProjectLocalChanges(projectKey).pipe(
                  withLatestFrom(this.store$.pipe(select(selectedPatternInstanceView))),
                  switchMap(([, selectedPattern]: [any, PatternInstance | null]) => {
                    this.loadingService.hideLoading();
                    return loadingDialogAfterClosed.pipe(switchMap(() => this.modalNotificationService.openSuccessDialog({title: 'Reset successful', description: 'The project has been reset successfully.'})
                      .afterClosed().pipe(switchMap(() => {
                        if (_.isNil(selectedPattern)) return of(new SelectProject(projectKey));
                        const isSelectedPatternNewlyAdded = _.isEqual(projectMeta.patterns[selectedPattern.patternId].localStatus, LocalStatus.Added);
                        if (isSelectedPatternNewlyAdded) this.navigationService.navigateToProjectSummary(projectKey);
                        return isSelectedPatternNewlyAdded ? of(new SelectProject(projectKey)) : of(new SelectPatternId(selectedPattern.patternId), new SelectProject(projectKey));
                      }))));
                  }),
                  catchError((error: HttpErrorResponse) => {
                    this.loadingService.hideLoading();
                    console.error(error);
                    const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong with resetting the project.');
                    return loadingDialogAfterClosed.pipe(tap(() => {
                        this.modalNotificationService.openErrorDialog({title: 'Could not reset project ', description: errorMessage});
                      }),
                      map(() => new EmptyAction()));
                  }));
              }
              return of(new EmptyAction());
            }));
          } else {
            this.modalNotificationService.openInfoDialog({title: 'No local changes found', description: `No local changes found in the project ${projectName}.`});
            return of(new EmptyAction());
          }
        })
      ));

    this.requestUpdatesOfProject = createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.RequestUpdatesOfProject))
      .pipe(
        map((action: RequestUpdatesOfProject) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectMetaView))),
        switchMap(([projectKey, projectMeta]: [string, ProjectMeta | null]) => {
          if (_.isNil(projectMeta) || _.isNil(projectMeta.lastModification)) {
            return of(new GetUpdatesOfProject(projectKey));
          }
          return this.localChangesModalNotificationService.openConfirmDialog({
            title: 'Local changes will be lost',
            description: ''
          }, {
            confirmButtonText: 'Cancel',
            cancelButtonText: 'Update project',
            projectKey: projectKey
          }).afterClosed().pipe(
            map((confirmed?: boolean) => confirmed === false ? new GetUpdatesOfProject(projectKey) : new EmptyAction())
          );
        })
      ));

    this.importProject = this.importProjectEffect<Project, LoadProjects | EmptyAction>(
      ProjectActionTypes.ImportProject,
      (project: Project) => this.projectService.importProject(project),
      (error: HttpErrorResponse, project: Project) => {
        if (error.status === HTTP_STATUS_CONFLICT) {
          this.handleImportProjectConflict(error, project);
        } else {
          const projectName = TenantHelper.cropTenantFromKey(project.projectKey);
          console.error(`Something went wrong with importing project: \n%O`, error);
          this.toastNotificationService.showErrorToast(`The project ${projectName} could not be imported.`);
          return of(new LoadProjects());
        }
        return observableOf(new EmptyAction());
      }
    );

    this.importProjectFile = this.importProjectEffect<ProjectFileImportPayload, EmptyAction>(
      ProjectActionTypes.ImportProjectFile,
      (projectFileImportPayload: ProjectFileImportPayload) => this.projectService.importProjectFile(projectFileImportPayload),
      (error: HttpErrorResponse, payload: ProjectFileImportPayload) => {
        const projectName = TenantHelper.cropTenantFromKey(payload.projectKey);
        console.error(`Something went wrong with importing project: \n%O`, error);
        this.toastNotificationService.showErrorToast(`The project ${projectName} could not be imported.`);
        return observableOf(new EmptyAction());
      }
    );

    this.loadIssues = createEffect(() => this.actions$.pipe(
        ofType(ProjectActionTypes.LoadIssues),
        map((action: LoadIssues) => action.payload),
        switchMap((projectKey: string) => this.projectService.getAllIssuesOfProject(projectKey).pipe(
          map((issues: IssueModel[]) => IssueReducingHelper.getUniqueIssues(issues)),
          map((issues: IssueModel[]) => new LoadIssuesSuccess(issues)),
          catchError((error) => {
            console.error(error);
            this.modalNotificationService.openHttpErrorDialog(error, 'Error while loading issues');
            return EMPTY;
          }),
        )),
      ));

    this.loadProjectsIfMissing = createEffect(() => this.actions$.pipe(
        ofType(ProjectActionTypes.LoadProjectsIfMissing),
        switchMap((): Observable<ProjectsNowAvailable> => {
          return this.store$.select(allProjectsView).pipe(
            first(),
            switchMap((allProjects: Dictionary<Project>): Observable<ProjectsNowAvailable> => {
              if (_.isEmpty(allProjects)) {
                this.store$.dispatch(new LoadProjects());
                return this.actions$.pipe(
                  ofType(ProjectActionTypes.LoadProjectsSuccess),
                  first(),
                  mapTo(new ProjectsNowAvailable()),
                );
              } else {
                return of(new ProjectsNowAvailable()).pipe(
                );
              }
            }),
          );
        }),
    ));

    this.loadProjects = createEffect(() => this.actions$
      .pipe(
        ofType(ProjectActionTypes.LoadProjects, ProjectActionTypes.LoadProjectsWithInventoryDeployments),
        map((action: NevisAdminAction): boolean => ProjectActionTypes.LoadProjectsWithInventoryDeployments === action.type),
        switchMap((withDeployments: boolean): Observable<[boolean, string]> => {
          return this.sharedSelectedTenantKey$.pipe(
              first(),
              map((selectedTenantKey: string): [boolean, string] => ([withDeployments, selectedTenantKey])),
          );
        }),
        switchMap(([withInventoryDeployments, selectedTenantKey]: [boolean, string]) => {
          const projects$: Observable<Project[]> = withInventoryDeployments
              ? this.projectService.getProjectsOfTenantWithInventoryDeployments(selectedTenantKey)
              : this.projectService.getProjectsOfTenant(selectedTenantKey);
          return projects$.pipe(
              map((projects: Project[]) => new LoadProjectsSuccess(projects)),
              catchError(err => {
                console.error(err);
                const errorMessage = ErrorHelper.getErrorDetail(err, 'Something went wrong during loading projects');
                this.modalNotificationService.openErrorDialog({title: 'Error while loading projects', description: errorMessage});
                return of(new EmptyAction());
              }),
          );
        }),
      ));

    this.loadProjectsAndNavigate = createEffect(() => this.actions$.pipe(
        ofType(ProjectActionTypes.LoadProjectsAndNavigate),
        map((action: LoadProjectsAndNavigate) => action.payload),
        withLatestFrom(this.selectedTenantKey$),
        switchMap(([projectKey, selectedTenantKey]: [string, string]) => {
          return this.projectService.getProjectsOfTenant(selectedTenantKey)
            .pipe(
              mergeMap((result: Project[]) => {
                return observableOf(
                  new LoadProjectsSuccess(result),
                  new NavigateToCreatedProject(projectKey)
                );
              }),
              catchError((error: HttpErrorResponse) => {
                console.error(error);
                this.modalNotificationService.openHttpErrorDialog(error, 'Error while loading projects.');
                return EMPTY;
              })
            );
        })
      ));

    this.saveSelectedProjectToLocalStorage = createEffect(() => this.actions$.pipe(ofType(ProjectActionTypes.SelectProject))
      .pipe(
        map((action: SelectProject) => action.payload),
        withLatestFrom(this.selectedTenantKey$.pipe(map((tenantKey: string) => LocalStorageHelper.prefixKey(localStorageProjectKey, tenantKey)))),
        tap(([projectKey, projectLocalStorageKey]: [string, string]) => LocalStorageHelper.save(projectLocalStorageKey, projectKey))
      ), {dispatch: false});


    this.getUpdatesOfProject = createEffect(() => this.actions$
      .pipe(
        ofType(ProjectActionTypes.GetUpdatesOfProject),
        map((action: GetUpdatesOfProject) => action.payload),
        tap((projectKey: string) => {
          const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
          this.loadingService.showLoading({title: `The project ${projectName} is currently being updated`, description: 'This may take a few seconds.'});
        }),
        withLatestFrom(this.store$.pipe(select(selectedPatternInstanceView))),
        switchMap(([projectKey, selectedPatternInstance]: [string, PatternInstance | null]) => {
            return this.projectService.triggerUpdatesOfProject(projectKey).pipe(
              switchMap((jobUrl: string) => this.jobService.pollJob(jobUrl)),
              mergeMap(() => {
                this.modalNotificationService.openSuccessDialog({title: 'Update successful', description: 'The project has been updated successfully.'});
                const selectedPatternId = !_.isNil(selectedPatternInstance) && !_.isNil(selectedPatternInstance?.patternId) ? selectedPatternInstance.patternId : null;
                return [new FetchProjectData(), new SelectPatternId(selectedPatternId)];
              }),
              catchError((error) => {
                console.error(error);
                const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
                this.modalNotificationService.openHttpErrorDialog(error, `Failed to update project ${projectName} from Git.`);
                return EMPTY;
              }),
              finalize(() => this.loadingService.hideLoading()));
          }
        )
      ));

    this.storeProjectKeyForDeploymentSelection = createEffect(() => this.actions$
      .pipe(
        ofType(ProjectActionTypes.SelectProject, ProjectActionTypes.StoreProjectFromLocalStorage),
        map((action: StoreProjectFromLocalStorage) => action.payload),
        filter((selectedProjectKey: string) => !_.isNil(selectedProjectKey)),
        map((projectKey: string) => new StoreDeploymentProject(projectKey))
      ));

    this.createProject = createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.CreateProject))
      .pipe(
        map((action: CreateProject) => action.payload),
        switchMap(({project, projectTemplateKey}: {project: Project, projectTemplateKey: string | undefined}) => {
          const projectName = TenantHelper.cropTenantFromKey(project.projectKey);
          return this.projectService.createProject({...project, projectTemplateKey: projectTemplateKey ?? undefined})
            .pipe(
              map(() => {
                this.toastNotificationService.showSuccessToast(`The project ${projectName} has been created successfully.`, 'Successfully created');
                return new LoadProjectsAndNavigate(project.projectKey);
              }),
              catchError(error => {
                if (error.status === HTTP_STATUS_CONFLICT && !_.isEmpty(project.repository)) {
                  this.handleCreateProjectConflict(error, project);
                } else {
                  console.error('Something went wrong with creating project:', error);
                  this.toastNotificationService.showErrorToast(`The project ${projectName} could not be created.`);
                }
                return observableOf(new EmptyAction());
              })
            );
        })
      ));

    this.branchProject = this.branchProjectEffect<Project, LoadProjects | LoadProjectsAndNavigate | EmptyAction>(
      ProjectActionTypes.BranchProject,
      (project: Project) => this.projectService.branchProject(project),
      (error: HttpErrorResponse, project: Project) => {
        if (error.status === HTTP_STATUS_CONFLICT && !_.isEmpty(project.repository)) {
          this.handleBranchProjectConflict(error, project);
        } else {
          const projectName = TenantHelper.cropTenantFromKey(project.projectKey);
          if (_.isNil(project) || _.isNil(project.repository) || _.isNil(project.branch)) {
            console.error(`Something went wrong with branching project: \n%O`, error);
            this.toastNotificationService.showErrorToast(`The project ${projectName} branching is failed.`);
            return of(new LoadProjects());
          }
          // checking if the branch has been created even if the job finished without completed progress because of some exception (ex: no files to imported)
          return this.versionControlService.getBranchByRepoAndName(project.repository, project.branch).pipe(
            map((branch: Branch | undefined) => {
              if (!_.isNil(branch)) {
                this.toastNotificationService.showSuccessToast(`The branch ${project.branch} has been created successfully.`, 'Successfully branch project');
                return new LoadProjectsAndNavigate(project.projectKey);
              } else {
                console.error(`Something went wrong with branching project: \n%O`, error);
                this.toastNotificationService.showErrorToast(`The project ${projectName} branching is failed.`);
                return new LoadProjects();
              }
            })
          );
        }
        return observableOf(new EmptyAction());
      }
    );

    this.connectProjectToVersionControl = createEffect(() => this.actions$
      .pipe(
        ofType(ProjectActionTypes.ConnectProjectToVersionControl),
        map((action: ConnectProjectToVersionControl) => action.payload),
        switchMap((payload: ConnectProjectToVersionControlPayload) => this.projectService.connectProjectToVersionControl(payload.projectKey, payload.projectVersionControlData)
          .pipe(
            mergeMap((project: Project) => {
              const projectName = TenantHelper.cropTenantFromKey(payload.projectKey);
              this.toastNotificationService.showSuccessToast(`${projectName} was successfully connected to Git`, 'Successfully connected');
              return of(
                new ConnectProjectToVersionControlSuccess(project),
                new LoadProjects());
            }),
            catchError((err: HttpErrorResponse) => {
              console.error(`Something went wrong while connecting project to Git:`, err);
              const projectName = TenantHelper.cropTenantFromKey(payload.projectKey);
              if (ErrorHelper.responseErrorHasDetail(err)) {
                this.modalNotificationService.openHttpErrorDialog(err, 'Failed to connect to Git');
              } else {
                this.modalNotificationService.openErrorDialog({description: `The project ${projectName} could not be connected to ${payload.projectVersionControlData.repository}.`});
              }
              return EMPTY;
            })
          )
        )
      ));

    this.navigateToCreatedProject = createEffect(() => this.actions$
      .pipe(
        ofType(ProjectActionTypes.NavigateToCreatedProject),
        map((action: NavigateToCreatedProject) => action.payload),
        map((projectKey: string) => {
          this.navigationService.navigateToProjectSummary(projectKey);
          return new SelectProject(projectKey);
        })
      ));
  }

  private createFetchProjectDataEffect() {
    return createEffect(() => this.actions$
      .pipe(ofType(ProjectActionTypes.FetchProjectData))
      .pipe(
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        filter(([, projectKey]: [FetchProjectData, string | null]) => !_.isNil(projectKey)),
        switchMap(([, projectKey]: [FetchProjectData, string]) => {
          return observableOf(
            new LoadPatternTypes(projectKey),
            new LoadPropertyTypes(projectKey),
            new LoadAllPatternInstances(projectKey),
            new LoadIssues(projectKey),
            new LoadVariables(projectKey),
            new LoadReports(projectKey),
            new LoadProjectMeta(),
            new InvalidatePatternSummaryReports(projectKey)
          );
        })
      ));
  }

  private branchProjectEffect<P extends Project, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>
  ): Observable<LoadProjectsAndNavigate | ErrorAction> {
    const handleProjectImportSuccess = (payload: P) => {
      this.toastNotificationService.showSuccessToast(`The branch ${payload.branch} has been created successfully.`, 'Successfully branch project');
      return new LoadProjectsAndNavigate(payload.projectKey);
    };
    return createEffect(() => this.importEffect<P, LoadProjectsAndNavigate, ErrorAction>(
      importActionType,
      serviceFn,
      handleProjectImportSuccess,
      handleError,
      (payload: P) => payload.projectKey,
      'project',
      'Git'
    ));
  }

  private importProjectEffect<P extends { projectKey: string }, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>
  ): Observable<LoadProjectsAndNavigate | ErrorAction> {
    const isGitImport = importActionType === ProjectActionTypes.ImportProject;
    const importSource = isGitImport ? 'Git' : 'Zip';
    const handleProjectImportSuccess = (payload: P) => {
      const projectName = TenantHelper.cropTenantFromKey(payload.projectKey);
      const toastDescription = `${projectName} was successfully imported from ${importSource}`;
      this.toastNotificationService.showSuccessToast(toastDescription, 'Successfully imported');
      return new LoadProjectsAndNavigate(payload.projectKey);
    };
    return createEffect(() => this.importEffect<P, LoadProjectsAndNavigate, ErrorAction>(
      importActionType,
      serviceFn,
      handleProjectImportSuccess,
      handleError,
      (payload: P) => payload.projectKey,
      'project',
      importSource
    ));
  }

  private handleCreateProjectConflict(errorResponse: HttpErrorResponse, project: Project): void {
    if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.Repository})) {
      this.handleCreateProjectRepositoryError(project);
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.ProjectKey})) {
      this.handleProjectKeyAlreadyExistsError(project, () => this.createProjectDialogService.openCreateProjectDialog(project));
    }
  }

  private handleCreateProjectRepositoryError(project: Project): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Repository folder must be empty',
      description: 'You can import the existing project, manually empty the target folder using Git tools or change the path, branch or repository.'
    }, {
      cancelButtonText: 'Import project',
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed()
      .subscribe((confirmed?: boolean) => {
        if (confirmed === true) {
          this.createProjectDialogService.openCreateProjectDialog(project);
        } else if (confirmed === false) {
          this.importProjectDialogService.openImportProjectDialog(project);
        }
      });
  }

  private handleImportProjectConflict(errorResponse: HttpErrorResponse, project: Project): void {
    if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.Repository})) {
      this.handleImportProjectRepositoryError(project);
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.ProjectKey})) {
      this.handleProjectKeyAlreadyExistsError(project, () => this.importProjectDialogService.openImportProjectDialog(project));
    }
  }

  private handleImportProjectRepositoryError(project: Project): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Repository folder is empty',
      description: 'There is no project to be imported on the repository folder. You can create a new project or change the path, branch or repository to point to an existing project to import.'
    }, {
      cancelButtonText: 'Create project',
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.importProjectDialogService.openImportProjectDialog(project);
      } else if (confirmed === false) {
        this.createProjectDialogService.openCreateProjectDialog(project);
      }
    });
  }

  private handleProjectKeyAlreadyExistsError(project: Project, onConfirmedFn: any): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Project key already exists.',
      description: `There is already a project with the key ${project.projectKey}, please choose another key.<br/>
        Note that you might not have permission to see all projects.`
    }, {
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true && _.isFunction(onConfirmedFn)) {
        onConfirmedFn();
      }
    });
  }

  private handleBranchProjectConflict(errorResponse: HttpErrorResponse, project: Project): void {
    if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.Repository})) {
      this.handleBranchProjectRepositoryError(project);
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.Branch})) {
      this.handleBranchNameAlreadyExistsError(project, () => this.branchProjectDialogService.openBranchProjectDialog(project));
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: ProjectErrorFieldSource.Branch})) {
      this.handleProjectKeyAlreadyExistsError(project, () => this.branchProjectDialogService.openBranchProjectDialog(project));
    }
  }

  private handleBranchNameAlreadyExistsError(project: Project, onConfirmedFn: any): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'The branch name already exists.',
      description: `There is already a project with a branch name ${project.branch}, please choose another branch name.`
    }, {
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true && _.isFunction(onConfirmedFn)) {
        onConfirmedFn();
      }
    });
  }

  private handleBranchProjectRepositoryError(project: Project): void {
    this.modalNotificationService.openWarningDialog({
      title: 'Repository folder is empty',
      description: 'There is no project to be imported on the repository folder. Please change the path, branch or repository to point to an existing project to branch.'
    }).afterClosed().subscribe(() => {
      this.branchProjectDialogService.openBranchProjectDialog(project);
    });
  }
}
