import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { getHighestSeverity } from '../../projects/project-issues/project-issues.helper';
import { VariableModel } from '../variable.model';
import { IssueModel } from '../../common/model/issue.model';
import { ScrollService } from '../../common/services/scroll/scroll.service';

@Component({
    selector: 'adm4-variable-list-element',
    template: `
      <adm4-simple-list-item [link]="['/projects', projectKey, 'variables', variable.variableKey]" [isSelected]='selected'>
        <adm4-validation-indicator
                [isDisplayed]='issues.length > 0'
                [isError]='isErrorIssue'
                [isWarning]='isWarningIssue'>
        </adm4-validation-indicator>
        <span class='text-ellipsis d-flex flex-row color-screen-black' [title]='variable.variableKey'>
            <span [innerHtml]="variable.variableKey | highlight : textToHighLight"></span>
            <span class="ml-2">{{variable.requireOverloading ? '': '●'}}</span>
        </span>
      </adm4-simple-list-item>
    `,
    styleUrls: ['./variable-list-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariableListElementComponent implements OnChanges {
    @Input() variable: VariableModel;
    @Input() issues: IssueModel[];
    @Input() selected: boolean;
    @Input() textToHighLight: string;
    @Input() projectKey: string;
    @Input() scrollArea: any;
    @Input() routerLinkPrefix: string;
    @Output() triggerScroll = new EventEmitter();

    public highestSeverity: IssueSeverityEnum;
    public isErrorIssue: boolean;
    public isWarningIssue: boolean;

  constructor(private elRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['issues']) {
      this.highestSeverity = getHighestSeverity(this.issues);
      this.isErrorIssue = this.highestSeverity === IssueSeverityEnum.ERROR;
      this.isWarningIssue = this.highestSeverity === IssueSeverityEnum.WARNING;
    }
    if (changes['selected'] && changes['selected'].currentValue) {
      if (ScrollService.isElemVisible(this.scrollArea, this.elRef.nativeElement)) {
        this.triggerScroll.emit();
      }
    }
  }

}
