export enum PluginClassEnum {
  Report = 'ch.nevis.admin.v4.core.api.reporting.Report',
}

export enum PatternTypeClassEnum {
  WebApplicationAccess = 'ch.nevis.admin.v4.plugin.nevisproxy.patterns.WebApplicationAccess',
  RESTServiceAccess = 'ch.nevis.admin.v4.plugin.nevisproxy.patterns.RESTServiceAccess',
  NevisAuthRealm = 'ch.nevis.admin.v4.plugin.nevisauth.patterns2.NevisAuthRealm'
}

export const SERVICE_CLASS_NAMES = [
  'ch.nevis.admin.v4.plugin.nevisproxy.patterns.WebApplicationAccess',
  'ch.nevis.admin.v4.plugin.nevisproxy.patterns.RESTServiceAccess',
  'ch.nevis.admin.v4.plugin.nevisproxy.patterns.SOAPServiceAccess',
  'ch.nevis.admin.v4.plugin.nevisidm.patterns.NevisIDMWebApplicationAccess',
  'ch.nevis.admin.v4.plugin.nevisidm.patterns.NevisIDMRESTServiceAccess',
  'ch.nevis.admin.v4.plugin.nevisidm.patterns.NevisIDMSOAPServiceAccess'
];
export const REALM_CLASS_NAMES = [
  'ch.nevis.admin.v4.plugin.nevisauth.patterns2.NevisAuthRealm',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.GenericAuthRealm',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.SamlSpRealm'
];
export const INSTANCE_CLASS_NAMES = [
  'ch.nevis.admin.v4.plugin.nevisproxy.patterns.NevisProxyDeployable',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.NevisAuthDeployable',
  'ch.nevis.admin.v4.plugin.nevisidm.patterns.NevisIDMDeployable',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.NevisLogrendDeployable',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.NevisAuthConnector',
  'ch.nevis.admin.v4.plugin.nevisidm.patterns.NevisIDMConnector',
  'ch.nevis.admin.v4.plugin.nevisauth.patterns.NevisLogrendConnector'
];

export enum PropertyTypeClassEnum {
  TextProperty = 'ch.nevis.admin.v4.plugin.base.generation.property.TextProperty',
  KeyValue = 'ch.nevis.admin.v4.plugin.base.generation.property.KeyValueProperty',
  ModSecurityRuleProperty = 'ch.nevis.admin.v4.plugin.nevisproxy.recommender.ModSecurityRuleRecommender'
}
