import * as _ from 'lodash';

import { GenerationStatusModel } from '@deployment-common/generation-status.model';
import { Status } from '@deployment-common/validating/deployment-status.model';
import { Inventory, InventoryMeta } from '../../inventory/inventory.model';
import { ProjectMeta } from '../../projects/project.model';
import { PatternIssueData } from '../../issues/model/pattern-issue-data.model';
import { ProjectIssuesDisplayHelper } from '../../projects/project-issues-display.helper';
import { IssueModel } from '@common/model/issue.model';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { Dictionary } from '../../model/reducer';
import { PatternType } from '../../plugins/pattern-type.model';
import { PropertyType } from '../../plugins/property-type.model';
import { PublishProjectChangesetHelper } from '../../projects/publish-project/publish-project-changeset.helper';
import { PublishInventoryChangesetHelper } from '../../inventory/publish-inventory/publish-inventory-changeset.helper';

export class ValidateDeploymentHelper {
  static isGenerationInProgress(generationStatus: GenerationStatusModel | undefined): boolean {
    return !_.isNil(generationStatus) && generationStatus.status !== Status.Done;
  }

  static isValidationSuccessful(generationStatus: GenerationStatusModel | undefined): boolean {
    return !_.isNil(generationStatus) && generationStatus.items.every(item => item.status === Status.Done);
  }

  static isGenerationFailed(generationStatus: GenerationStatusModel | undefined): boolean {
    return !_.isNil(generationStatus) && generationStatus.items.some(item => item.status === Status.Failed || item.status === Status.CANCELLED);
  }

  static getPatternIssueGroupsOfSelectedIssues(issues: IssueModel[], patterns: PatternInstance[], patternTypes: Dictionary<PatternType>, propertyTypes: Dictionary<PropertyType>): PatternIssueData[] {
    return _.isEmpty(issues) ? [] : ProjectIssuesDisplayHelper.getPatternIssuesData(patterns, patternTypes, propertyTypes, issues)
      .filter((patternIssueData) => !_.isEmpty(patternIssueData.generalIssues) || !_.isEmpty(patternIssueData.deploymentHostIssues) || patternIssueData.propertiesData.some(propertyData => !_.isEmpty(propertyData.issues)));
  }

  static isPublishRequired(selectedInventory: Inventory | null, inventoryMeta: InventoryMeta | null, projectMeta: ProjectMeta | null): boolean {
    const publishRequired = !_.isNil(selectedInventory) && selectedInventory.publishRequired;
    const projectModified = !_.isNil(projectMeta) && PublishProjectChangesetHelper.hasLocalChanges(projectMeta);
    const inventoryModified = !_.isNil(inventoryMeta) && PublishInventoryChangesetHelper.hasLocalChanges(inventoryMeta);
    return publishRequired && (projectModified || inventoryModified);
  }
}
