import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateVariableOutputData, SetVariableData } from './set-variable-data.model';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import { closeModalOnEscape } from '../modal-dialog/modal-dialog.helper';
import {select, Store} from '@ngrx/store';
import {propertyTypesView} from '../model/views';
import {AppState, Dictionary} from '../model/reducer';
import {PropertyType} from '../plugins/property-type.model';

@Component({
  selector: 'adm4-set-variable-container',
  template: `
    <adm4-create-variable [createVariableData]='data'
                          [header]="header"
                          [variableTypes]="variableTypes$ | async"
                          (variableCreate)='onVariableCreate($event)'
                          (variableSelect)='onVariableSelect($event)'
                          (cancel)='cancelVariableCreation()'></adm4-create-variable>
  `,
  styles:[':host {display: contents;}'],
})
export class SetVariableContainerComponent implements OnInit, OnDestroy {
  header: string;

  public variableTypes$: Observable<Dictionary<PropertyType> | null>;
  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: SetVariableData,
              private readonly dialogRef: MatDialogRef<SetVariableContainerComponent>,
              private readonly store$: Store<AppState>,
  ) {
    dialogRef.addPanelClass('dialog-max-h-full');
  }

  ngOnInit() {
    this.header = this.data.patternTypeProperty ? `Set variable for ${this.data.patternTypeProperty.name}` : 'Set variable';
    closeModalOnEscape(this.dialogRef, this.destroyed$);
    this.variableTypes$ = this.store$.pipe(select(propertyTypesView));

  }

  onVariableCreate(createVariableOutput: CreateVariableOutputData): void {
    this.data.onCreate(createVariableOutput);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onVariableSelect(selectedVariableName: string): void {
    this.data.onSelect(selectedVariableName);
    this.dialogRef.close();
  }

  cancelVariableCreation(): void {
    if (this.data.onCancel && _.isFunction(this.data.onCancel)) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
