import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

import { CreateVariableOutputData, SetVariableData } from './set-variable-data.model';
import { SetVariableHelper } from './set-variable.helper';
import { VariableModel } from '../variables/variable.model';
import { VariablesHelper } from '../variables/variables.helper';
import { VariableValidators } from '../variables/variable.validators';
import { VariableContext } from './variable-context.service';
import { PropertyWidgetContext } from '../property-widgets/property-widget.context';
import {Dictionary} from '../model/reducer';
import {PropertyType} from '../plugins/property-type.model';

@Component({
  selector: 'adm4-create-variable',
  templateUrl: './set-variable.component.html',
  styleUrls: ['./set-variable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: PropertyWidgetContext, useValue: VariableContext}]
})
export class SetVariableComponent implements OnInit, OnChanges {

  @Input() header: string;
  @Input() createVariableData: SetVariableData;
  @Input() variableTypes: Dictionary<PropertyType> | null;

  @Output() variableCreate: EventEmitter<CreateVariableOutputData> = new EventEmitter();
  @Output() variableSelect: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup;
  selectedVariable: VariableModel | null;
  variablesForSelection: VariableModel[] = [];

  public canVariableBeDefault: boolean = false;
  public isVariableDropdownShown: boolean = false;

  readonly variableNameFormControlName = 'variable_name';
  readonly variableValueFormControlName = 'variable_value';
  readonly requireOverloadingFormControlName = 'require_overloading';

  public requireControl: FormControl;
  public isSampleValue: Observable<boolean>;

  constructor(private readonly fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.createForm();
    const variableData = this.createVariableData;
    const variableTypes = this.variableTypes;

    if (_.isNil(variableData.patternTypeProperty) || _.isNil(variableTypes)) {
      return;
    }

    this.variablesForSelection = variableData.existingVariables.filter((variable: VariableModel) => {
      return VariablesHelper.checkUiComponentEquality(variableData, variable, variableTypes);
    });
    this.isVariableDropdownShown = !_.isEmpty(this.variablesForSelection);
    this.form.controls[this.variableNameFormControlName].setValue(SetVariableHelper.createDefaultVariableName(variableData.patternName, variableData.patternTypeProperty, this.variablesForSelection));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.createVariableData) {
      this.canVariableBeDefault = VariablesHelper.canVariableHaveSampleValue(this.createVariableData.variableType) && !VariablesHelper.isSecretVariable(this.createVariableData.patternTypeProperty);
    }
  }

  get createVariableInputDisabled(): boolean | undefined {
    return _.isNil(this.selectedVariable) ? undefined : true;
  }

  shouldShowVariableNameValidationIssue(errorKey: string): boolean {
    const variableNameFormControl = this.form.controls[this.variableNameFormControlName];
    return variableNameFormControl.dirty && !_.isNil(variableNameFormControl.errors) && variableNameFormControl.errors[errorKey];
  }

  createForm(): UntypedFormGroup {
    const form = this.fb.group({});
    const defaultVariableName = SetVariableHelper.combineDefaultVariableName(this.createVariableData.patternName, this.createVariableData.patternTypeProperty);
    const defaultVariableValue = SetVariableHelper.getDefaultVariableValue(this.createVariableData);
    form.addControl(this.variableNameFormControlName, this.fb.control(defaultVariableName, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]*'),
      VariableValidators.VariableKey.variableWithSuchKeyExists(this.createVariableData.existingVariables)
    ]));
    this.requireControl = this.fb.control(true);
    this.isSampleValue = this.requireControl.valueChanges.pipe(map(value => !!value), startWith(true));
    form.addControl(this.requireOverloadingFormControlName, this.requireControl);
    form.addControl(this.variableValueFormControlName, this.fb.control(defaultVariableValue));
    return form;
  }

  selectVariable(variable: VariableModel | null): void {
    this.selectedVariable = variable;
    // if form is errored when we select variable we should hide the error and allow saving
    // when variable is removed we should put errors back
    if (_.isNil(variable)) {
      _.values(this.form.controls).forEach(control => control.updateValueAndValidity());
      this.requireControl.enable();
    } else {
      _.values(this.form.controls).forEach(control => control.setErrors(null));
      this.requireControl.disable();
    }
  }

  save(): void {
    if (_.isNil(this.selectedVariable)) {
      const requireOverloadingFormValue = this.form.value[this.requireOverloadingFormControlName];
      const variableValue = this.form.value[this.variableValueFormControlName];
      this.variableCreate.emit({
        variableName: this.form.value[this.variableNameFormControlName].trim(),
        value: VariablesHelper.getNormalizedVariableValue(variableValue, this.createVariableData.variableType),
        requireOverloading: !!requireOverloadingFormValue,
      });
    } else {
      this.variableSelect.emit(this.selectedVariable.variableKey);
    }
  }

  get variableType() {
    return this.createVariableData.variableType;
  }

  get isSecret() {
    return VariablesHelper.isSecretVariable(this.createVariableData.patternTypeProperty);
  }
}
