import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as _ from 'lodash';

import { OperationKey } from '../permissions/permissions.model';
import {
  allInventoriesView,
  allProjectsView,
  deploymentWizardSelectionInventoryKeyView,
  inventoryKeyView,
  projectKeyView,
  selectedInventoryView,
  selectedProjectView,
  selectedTenantKeyView,
  tenantsView,
} from '../views';
import { PermissionsHelper } from '../../permissions/permissions.helper';
import { Tenant } from '../../tenant/tenant.model';
import { AppState, Dictionary, ProjectKey } from '../reducer';
import { Inventory } from '../../inventory/inventory.model';
import { Project } from '../../projects/project.model';
import { entryWithKeyExists } from '../../common/utils/utils';

export const canCreateProjectView: MemoizedSelector<AppState, boolean> = createSelector(
    tenantsView,
    (tenants: Dictionary<Tenant>) => {
      return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.CREATE_PROJECT));
    },
);

export const canCreateInventoryView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean> = createSelector(
  tenantsView,
  (tenants) => {
    return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.CREATE_INVENTORY));
  },
);

export const hasCreateInventoryPermissionView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean> = createSelector(
  tenantsView,
  (tenants) => _.some(tenants, (t: Tenant) => _.includes(t._userAuthorization, OperationKey.CREATE_INVENTORY)),
);

const hasProjectThisPermission: (permission: OperationKey) => (currentProjectKey: ProjectKey | null, projects: Record<string, Project>) => boolean
  = (permission: OperationKey) => {
    return (currentProjectKey: ProjectKey | null, projects: Record<string, Project>): boolean => {
      if (!_.isEmpty(projects) && !_.isNil(currentProjectKey) && entryWithKeyExists(currentProjectKey, projects)) {
        return _.includes(projects[currentProjectKey]._userAuthorization, permission);
      }
      return false;
    };
  };

export const hasProjectWriteAccessView: MemoizedSelector<AppState, boolean, (res1: ProjectKey | null, res2: Record<string, Project>) => boolean>
  = createSelector(
    projectKeyView,
    allProjectsView,
    hasProjectThisPermission(OperationKey.MODIFY_PROJECT),
  );

export const hasProjectAdminAccessView: MemoizedSelector<AppState, boolean, (res1: ProjectKey | null, res2: Record<string, Project>) => boolean>
  = createSelector(
    projectKeyView,
    allProjectsView,
    hasProjectThisPermission(OperationKey.ADMIN_PROJECT),
  );

export const getProjectsWithModifyPermission: MemoizedSelector<AppState, Project[], (currentProjectKey: ProjectKey | null, allProjects: Dictionary<Project>) => (Project[])>
  = createSelector(
    projectKeyView,
    allProjectsView,
    (currentProjectKey: ProjectKey | null, allProjects: Record<string, Project>): Project[] => {
      if (!_.isEmpty(allProjects) && null !== currentProjectKey) {
        return Object.values(allProjects).filter(
          (project: Project) => project._userAuthorization?.includes(OperationKey.MODIFY_PROJECT)
        );
      }
      return [];
    },
  );

/**
 * Returns true for projects to which current user does not have modify permission, or current project is just a revision of specific commit of project
 * NOTE: this check should not be done for deleting project, because it should be possible to delete revision of project, there hasProjectWriteAccessView has to be checked
 */
export const isSelectedProjectReadonlyView: MemoizedSelector<AppState, boolean, (res1: Project | null) => boolean>
  = createSelector(
    selectedProjectView,
    (currentProject: Project | null): boolean => {
      return !_.isNil(currentProject) && (!_.includes(currentProject._userAuthorization, OperationKey.MODIFY_PROJECT) || !_.isNil(currentProject.originProjectKey));
    },
  );

const hasInventoryThisPermission: (permission: OperationKey) => (currentInventoryKey: string | null, inventories: Record<string, Inventory> | null) => boolean
  = (permission: OperationKey) => {
    return (inventoryKey: string | null, inventories: Record<string, Inventory> | null): boolean => {
      if (!_.isNil(inventories) && !_.isEmpty(inventories) && !_.isNil(inventoryKey) && entryWithKeyExists(inventoryKey, inventories)) {
        return _.includes(inventories[inventoryKey]._userAuthorization, permission);
      }
      return false;
    };
  };

export const hasInventoryWriteAccessView: MemoizedSelector<AppState, boolean, (res1: string | null, res2: Dictionary<Inventory> | null) => boolean>
  = createSelector(
    inventoryKeyView,
    allInventoriesView,
    hasInventoryThisPermission(OperationKey.MODIFY_INVENTORY),
  );

export const hasInventoryAdminAccessView: MemoizedSelector<AppState, boolean, (res1: string | null, res2: Dictionary<Inventory>) => boolean>
  = createSelector(
    inventoryKeyView,
    allInventoriesView,
    hasInventoryThisPermission(OperationKey.ADMIN_INVENTORY),
  );

export const hasInventoryDeployAccessView: MemoizedSelector<AppState, boolean, (res1: string | null, res2: Dictionary<Inventory>) => boolean>
  = createSelector(
    deploymentWizardSelectionInventoryKeyView,
    allInventoriesView,
    hasInventoryThisPermission(OperationKey.DEPLOY_INVENTORY),
  );

/**
 * Returns true for inventories to which current user does not have modify permission, or current inventory is just a revision of specific commit of inventory
 * NOTE: this check should not be done for deleting inventory, because it should be possible to delete revision of inventory, there hasInventoryWriteAccessView has to be checked
 */
export const isSelectedInventoryReadonlyView: MemoizedSelector<AppState, boolean, (res1: Inventory | null) => boolean>
  = createSelector(
    selectedInventoryView,
    (currentInventory: Inventory | null) => {
      return !_.isNil(currentInventory) && (!_.includes(currentInventory._userAuthorization, OperationKey.MODIFY_INVENTORY) || !_.isNil(currentInventory.originInventoryKey));
    },
  );

const calculateHasTenantModificationAccess = (tenants: Dictionary<Tenant>, selectedTenantKey: string | null): boolean => {
  if (!_.isEmpty(tenants) && !_.isNil(selectedTenantKey) && entryWithKeyExists(selectedTenantKey, tenants)) {
    return _.includes(tenants[selectedTenantKey]._userAuthorization, OperationKey.MODIFY_TENANT);
  }
  return false;
};

/**
 * This is the ngrx version of `hasTenantModificationAccessView`. Has the same logic, has memoization too, but created with ngrx, so that it can be mocked.
 */
export const hasTenantModificationAccessView = createSelector(
    tenantsView,
    selectedTenantKeyView,
    calculateHasTenantModificationAccess,
);

const filterInventoriesForThisPermission = (permission: OperationKey) =>
  (allInventories: Record<string, Inventory> | null): string[] => {
    return Object.values(allInventories??{})
      .filter((i: Inventory) => i._userAuthorization?.includes(permission))
      .map(i => i.inventoryKey);
  };

export const getInventoriesWithModifyInventoryAccessView: MemoizedSelector<AppState, string[], (allInventories: Record<string, Inventory>) => string[]>
  = createSelector(
    allInventoriesView,
    filterInventoriesForThisPermission(OperationKey.MODIFY_INVENTORY),
  );

export const getInventoriesWithViewSecretContentAccessView: MemoizedSelector<AppState, string[], (res: Dictionary<Inventory>) => string[]>
  = createSelector(
    allInventoriesView,
    filterInventoriesForThisPermission(OperationKey.VIEW_SECRET_CONTENT_INVENTORY),
  );

export const hasViewSecretContentTenantAccessView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean>
  = createSelector(
    tenantsView,
    (tenants) => {
      return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.VIEW_SECRET_CONTENT_TENANT));
    },
  );
