import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UsageInfo } from './usage-info';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';

@Component({
    selector: 'adm4-usage-info',
    template: `
        <div class='title-container' (click)='onCollapse()'>
          <h1 class='main-title' (click)='onCollapse()'>Usage</h1>
          <mat-icon class="size-16">{{isCollapsed ? 'expand_more' : 'expand_less'}}</mat-icon>
        </div>
        <div class='usage-item not-referenced-pattern' *ngIf='!hasUsageInfo && !isCollapsed'>
          <h2>This {{type}} is not referenced by other patterns.</h2>
        </div>
        <div [class.collapsed-usage-list]='isCollapsed' class='usage-list'>
        <div *ngFor="let usage of arrayUsage" class='usage-item'>
          <h2>{{usage.key}}</h2>
          <p> in >>
            <span *ngFor="let usageInfo of usage.value | slice:0:maxVisibleUsage; let i = index">
                        <a [routerLink]="[basePath, usageInfo.patternId]"
							[fragment]='usageInfo.propertyKey'>{{usageInfo.patternName}}</a>
                        <span *ngIf='i !== usage.value.length - 1'> - </span>
                        <a *ngIf='i === 4 && usage.value.length > 5 && maxVisibleUsage === 5'
                             (click)='maxVisibleUsage = usage.value.length'>
                            {{usage.value.length - maxVisibleUsage}} more patterns
                        </a>
                    </span>
                </p>
            </div>
        </div>
  `,
  styleUrls: ['usage-info.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class UsageInfoComponent implements OnChanges {
    @Input() usageData: UsageInfo[];
    @Input() basePath: string;
    @Input() type: 'Pattern' | 'Variable' = 'Pattern';

    @HostBinding('class.collapsed-usage-container')
    isCollapsed = false;

    public arrayUsage: { key: string, value: UsageInfo[] }[] = [];
    public maxVisibleUsage = 5;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.usageData) {
            const usageDisplay: Dictionary<UsageInfo[]> = this.groupUsage(this.usageData);
            this.arrayUsage = _.map(usageDisplay, (usageInfoList) => {
                return {key: usageInfoList[0].propertyName, value: usageInfoList};
            });
        }
    }

    groupUsage(usages: UsageInfo[]): Dictionary<UsageInfo[]> {
        return _.groupBy(usages, 'propertyName');
    }

  get hasUsageInfo(): boolean {
    return !_.isEmpty(this.usageData);
  }

  onCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
