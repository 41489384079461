import { filter, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { REQUIRE_OVERLOADING_POSTFIX, VariableModel } from '../variable.model';
import { InventoryDisplayData, VariableDetails } from '../variable-details/variable-details.model';
import { PropertyType } from '../../plugins/property-type.model';
import { AppState } from '../../model/reducer';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { multiValueConverter } from '../../property-widgets/dynamic-property-creation.service';
import { VariableEditorHelper } from './variable-editor.helper';
import { DeleteVariable, RenameVariable, UpdateAndRenameVariable, UpdateVariables } from '../../model/variables';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { InventoryColorHelper } from '../../common/helpers/inventory-color.helper';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SaverService } from '../../common/services/saver.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { VariablesHelper } from '../variables.helper';
import { PropertyFormValueConverter } from '../../property-widgets/property-form-value-converter';
import { MetaInfo } from '../../version-control/meta-info.model';
import { UserStateService } from '../../common/services/user/user.state.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { VariableValidators } from '../variable.validators';
import { PropertyWidgetContext } from '../../property-widgets/property-widget.context';
import { VariableContext } from '../../set-variable/variable-context.service';
import { InventorySchemaTypeHelper } from '../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';

@Component({
  selector: 'adm4-variable-editor',
  template: `
    <div class='full-height'>
      <form [formGroup]='variableForm'
            (ngSubmit)='onFormSubmit.next(variableForm)'
            class='full-height'>
        <div class='full-height-flex'>
          <adm4-column-header styleClass="nevis-green-header flex-header-stretched">
            <div class="w-100" (click)="onRenameVariable()">
              <span *ngIf="!this.headerEditMode">{{headerTitle}}</span>
              <adm4-editable-text
                      *ngIf="this.headerEditMode"
                      [fcName]="variableKeyFormControlName"
                      [form]="variableForm"
                      styleClass="headerTitle"
                      (focusLost)="onHeaderBlur()">
              </adm4-editable-text>
            </div>
            <adm4-variable-menu *ngIf="!readOnly"
                                [variable]="variableDetails.selectedVariable.variable"
                                [icon]="headerEditMode ? 'edit' : 'more_vert'"
                                (rename)='onRenameVariable()'
                                (delete)='onDeleteVariable()'></adm4-variable-menu>
            <adm4-toggle-collapse-button *ngIf='isHelpCollapsed'
                                         side='right'
                                         buttonClass='admn4-button-icon-white'
                                         [isCollapsed]='isHelpCollapsed'
                                         (click)='onHelpExpanderClick()'
            ></adm4-toggle-collapse-button>
          </adm4-column-header>
          <div class='remaining-space-flex-content-wrapper variable-details-content overflow-auto'>
            <div class='remaining-space-flex-content'>
              <div class='variable-details-container full-height-flex'>
                <div class='variable-details-section' *ngIf="canVariableBeDefault">
                  <div id="edit-variable-type-label" class='var-details-title'>Variable type</div>
                  <div class='variable-property'>
                      <mat-radio-group aria-labelledby="edit-variable-type-label" class="d-flex flex-column"
                                       [formControlName]="requireOverloadingControlName">
                          <mat-radio-button class="left-align" [value]="true">
                              <span><strong>Sample Value</strong>, has to be overridden in the inventory</span>
                          </mat-radio-button>
                          <adm4-admonition [severity]="'info'" [alignment]="'start'" class="mb-3 adm4-align-with-radio-label"
                                           [messages]="'A sample value is only used for validation. The actual deployed value has to be specified in the inventory.'">
                          </adm4-admonition>
                          <mat-radio-button class="left-align" [value]="false">
                              <span><strong>Default Value</strong>, can be overridden in the inventory</span>
                          </mat-radio-button>
                          <adm4-admonition [severity]="'info'" [alignment]="'start'" class="mb-3 adm4-align-with-radio-label"
                                           [messages]="['The default value will be used for validation and deployment. To override the value, specify it in the inventory.',
                                          'Variables with default values are marked with a ●.']">
                          </adm4-admonition>
                      </mat-radio-group>
                  </div>
                </div>
                <div class='variable-details-section'>
                  <div class='var-details-title'>{{(isSampleValue | async) ? 'Sample value' : 'Default value'}}</div>
                  <div class='variable-property'>
                    <adm4-variable-property [projectKey]="variableDetails.projectKey"
                                            [variableType]='variableDetails.variableType'
                                            [variable]='variableDetails.selectedVariable.variable'
                                            [formGroup]='variableForm'
                                            [issues]='variableDetails.selectedVariable.issues'
                                            [withHeading]='false'
                                            [readOnly]='readOnly'></adm4-variable-property>
                  </div>
                </div>
                <div class='inventory-data-container full-height'>
                  <div class='var-details-title'>Inventory values</div>
                  <div class='inventories-container'>
                    <div *ngIf='displayInventories; else notDefined'>
                      <div class='inventory-list' *ngFor='let inventory of variableDetails.inventories'>
                        <mat-expansion-panel [expanded]='isExpanded(inventory)'
                                             [ngClass]='getInventoryMatExtensionPanelClassName(inventory.color)'
                                             (opened)='onOpenInventory(inventory)'
                                             (closed)='onCloseInventory(inventory)'
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <mat-icon>{{getInventoryIconName(inventory)}}</mat-icon>
                              <span class='inv-key'>{{inventory.inventoryKey | cropTenantFromKey}}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div *ngIf='inventoryHasHostValues(inventory)' class='inventory-value-category full-width'>
                            <div>For hosts:</div>
                            <adm4-inventory-variable *ngFor='let hostVar of inventory.hostVars' [inventoryVariable]='hostVar'></adm4-inventory-variable>
                          </div>
                          <div *ngIf='inventoryHasGroupValues(inventory)' class='inventory-value-category full-width'>
                            <div>For groups:</div>
                            <adm4-inventory-variable *ngFor='let groupVar of inventory.groupVars' [inventoryVariable]='groupVar'></adm4-inventory-variable>
                          </div>
                          <div *ngIf='inventoryHasGlobalValue(inventory)'>
                            <div>Global vars:</div>
                            <adm4-inventory-variable [inventoryVariable]='inventory.vars' class='inventory-value-category'></adm4-inventory-variable>
                          </div>
                        </mat-expansion-panel>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #notDefined>
                  {{VARIABLE_NOT_DEFINED}}
                </ng-template>
              </div>
            </div>
          </div>
          <adm4-button-bar *ngIf='!readOnly'
                           [wrapperClass]='"admn4-buttons-wrapper"'
                           [isSubmitDisabled]="formActionsDisabled"
                           [isCancelDisabled]='formActionsDisabled'
                           [cancelButtonText]='"Reset"'
                           (cancelClicked)='resetForm()'></adm4-button-bar>
        </div>
      </form>
    </div>
  `,
  providers: [{provide: PropertyWidgetContext, useValue: VariableContext}],
  styleUrls: ['variable-editor.component.scss']
})
export class VariableEditorComponent implements OnChanges, OnDestroy {
  @Input() variableDetails: VariableDetails;
  @Input() variables: VariableModel[];
  @Input() variablesMetaInfo: MetaInfo | null;
  @Input() readOnly: boolean;
  @Input() isHelpCollapsed: boolean;
  @Output() expandHelp: EventEmitter<undefined> = new EventEmitter();

  onFormSubmit = new Subject<UntypedFormGroup>();
  destroyed$: Subject<boolean> = new Subject();
  VARIABLE_NOT_DEFINED = 'Variable not defined in any inventory.';

  public readonly isSampleValue: Subject<boolean> = new Subject();
  public sampleSub: Subscription = new Subscription();
  public canVariableBeDefault: boolean = true;

  public variableForm: UntypedFormGroup;
  public variable: VariableModel;
  headerEditMode = false;
  headerTitle: string;
  expandedInventories: string[] = [];

  readonly variableKeyFormControlName = 'variableKey';
  variableKeyFormControl: AbstractControl;
  requireOverloadingControlName: string;
  requireOverloadingControl: AbstractControl;

  readonly getInventoryMatExtensionPanelClassName = (color: string) => InventoryColorHelper.getInventoryMatExtensionPanelClassName(color);

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private saver: SaverService,
    private matDialogService: MatDialog,
    private modalNotificationService: ModalNotificationService,
    private formGuardConnectorService: DirtyFormGuardConnectorService,
    private userService: UserStateService,
    private toastNotificationService: ToastNotificationService
  ) {
    this.saver.onSave.pipe(
      filter(() => !this.formActionsDisabled && !this.matDialogService.openDialogs.length && !this.readOnly),
      takeUntil(this.destroyed$))
      .subscribe(() => this.onFormSubmit.next(this.variableForm));

    this.onFormSubmit.pipe(
      takeUntil(this.destroyed$))
      .subscribe((form: UntypedFormGroup) => this.submitForm(form));
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    const propertyType: PropertyType | undefined = this.variableDetails.variableType;
    const value = multiValueConverter(propertyType, PropertyFormValueConverter.toFormValue(this.variable, propertyType, undefined));
    group.addControl(this.variable.variableKey, this.fb.control(value, null));

    this.sampleSub.unsubscribe();
    this.requireOverloadingControl = this.fb.control(this.variable.requireOverloading, null);
    this.requireOverloadingControlName = this.variable.variableKey + REQUIRE_OVERLOADING_POSTFIX;
    group.addControl(this.requireOverloadingControlName, this.requireOverloadingControl);
    this.sampleSub = this.requireOverloadingControl.valueChanges.subscribe((v: boolean) => this.isSampleValue.next(v));

    this.variableKeyFormControl = this.fb.control(this.variable.variableKey, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]*'),
      VariableValidators.VariableKey.variableWithSuchKeyExists(this.variables, this.variable.variableKey)
    ]);
    group.addControl(this.variableKeyFormControlName, this.variableKeyFormControl);
    if (this.readOnly) {
      group.disable();
    }


    this.formGuardConnectorService.connectForm(group);
    return group;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['variableDetails'] && this.variableDetails) {
      this.handleVariableUpdate();
    }
  }

  ngOnDestroy() {
    this.sampleSub.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.formGuardConnectorService.disconnect();
  }

  handleVariableUpdate(): void {
    this.canVariableBeDefault = VariablesHelper.canVariableHaveSampleValue(this.variableDetails.variableType);
    const hasUnsavedChanges = !_.isNil(this.variableForm) && this.variableForm.dirty;
    this.handleChangeByAnotherUser();
    this.variable = this.variableDetails.selectedVariable.variable;
    if (hasUnsavedChanges) {
      return;
    }
    this.variableForm = this.createFormGroup();
    this.isSampleValue.next(this.variable.requireOverloading);
    this.headerTitle = this.variableDetails.selectedVariable.variable.variableKey;
  }

  handleChangeByAnotherUser(): void {
    const hasUnsavedChanges = !_.isNil(this.variableForm) && this.variableForm.dirty;
    const isSameVariable = !_.isNil(this.variable) && this.variable.variableKey === this.variableDetails.selectedVariable.variable.variableKey;
    const variableChanged = !_.isEqual(this.variable, this.variableDetails.selectedVariable.variable);
    const isModifiedByAnotherUser = !_.isNil(this.userService.username) && !_.isNil(this.variablesMetaInfo) && this.variablesMetaInfo.localAuthor !== this.userService.username;
    if (isSameVariable && variableChanged && isModifiedByAnotherUser) {
      const toastWarning: string = hasUnsavedChanges ? 'Variables were updated by another user. You will not be able to save changes.' : 'Reloaded variables because it was updated by another user.';
      setTimeout(() => this.toastNotificationService.showWarningToast(toastWarning));
    }
  }

  submitForm(form: UntypedFormGroup): void {
    if (this.variableKeyFormControl.invalid) {
      this.modalNotificationService.openErrorDialog({title: 'Variable name is not valid', description: this.variableKeyErrorMessage});
      return;
    }
    // holds a variable with the original key, but with the potentially updated value and requireOverloading flag
    let variableWithUpdatedValue: VariableModel;
    try {
      variableWithUpdatedValue = this.createVariableWithUpdatedValue(form.value);
    } catch (e) {
      this.modalNotificationService.openErrorDialog({title: 'Cannot save variable', description: `Could not parse value of variable ${this.variable.variableKey}`});
      return;
    }
    const newVariableKey = form.value[this.variableKeyFormControlName];
    const originalVariableKey = this.variableDetails.selectedVariable.variable.variableKey;

    const isVariableKeyChanged = this.variableKeyFormControl.dirty && originalVariableKey !== newVariableKey;

    const isVariableValueChanged = form.controls[this.variable.variableKey].dirty || this.requireOverloadingControl.dirty;
    const updatedList = VariableEditorHelper.updateVariableList(this.variables, variableWithUpdatedValue);

    if (isVariableKeyChanged && isVariableValueChanged) {
      this.store$.dispatch(new UpdateAndRenameVariable(
        {projectKey: this.variableDetails.projectKey, variables: updatedList, updatedVariable: variableWithUpdatedValue, newVariableKey},
      ));
    } else if (isVariableValueChanged) {
      this.store$.dispatch(new UpdateVariables({projectKey: this.variableDetails.projectKey, variables: updatedList}));
    } else if (isVariableKeyChanged) {
      this.store$.dispatch(new RenameVariable({variableKey: this.variable.variableKey, newVariableKey: newVariableKey}));
    }
    form.reset(form.value);
  }

  createVariableWithUpdatedValue(formValue: any): VariableModel {
    const variableValue = formValue[this.variable.variableKey];
    return {
      ...this.variableDetails.selectedVariable.variable,
      value: VariablesHelper.getNormalizedVariableValue(variableValue, this.variableDetails.variableType),
      requireOverloading: formValue[this.requireOverloadingControlName],
    };
  }

  resetForm() {
    const recreatedForm = this.createFormGroup();
    this.isSampleValue.next(this.variable.requireOverloading);
    this.variableForm.reset(recreatedForm.value);
    this.headerTitle = recreatedForm.value[this.variableKeyFormControlName];
  }

  onRenameVariable() {
    this.headerEditMode = true;
  }

  onDeleteVariable() {
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.modalNotificationService.openConfirmDialog({
        headerTitle: `Warning`,
        title: `Delete ${this.variable.variableKey}`,
        description: `You are deleting the variable "${this.variable.variableKey}". The deletion is irreversible. It can't be undone.`
      }, {
        confirmButtonText: 'Delete'
      }).afterClosed().subscribe((confirmed?: boolean) => {
        if (confirmed === true) {
          const updatedList = VariableEditorHelper.removeVariableFromList(this.variables, this.variable.variableKey);
          this.store$.dispatch(new DeleteVariable({projectKey: this.variableDetails.projectKey, variables: updatedList}));
        }
      });
    });
  }

  onHeaderBlur() {
    this.headerEditMode = false;
    this.headerTitle = this.variableForm.value[this.variableKeyFormControlName];
  }

  onHelpExpanderClick(): void {
    this.expandHelp.emit();
  }

  isExpanded(inventory: InventoryDisplayData) {
    return _.includes(this.expandedInventories, inventory.inventoryKey);
  }

  onOpenInventory(inventory: InventoryDisplayData) {
    if (!_.includes(this.expandedInventories, inventory.inventoryKey)) {
      this.expandedInventories.push(inventory.inventoryKey);
    }
  }

  onCloseInventory(inventory: InventoryDisplayData) {
    const index = this.expandedInventories.indexOf(inventory.inventoryKey);
    this.expandedInventories.splice(index, 1);
  }

  inventoryHasHostValues(inventoryDisplayData: InventoryDisplayData): boolean {
    return !_.isEmpty(inventoryDisplayData.hostVars);
  }

  inventoryHasGroupValues(inventoryDisplayData: InventoryDisplayData): boolean {
    return !_.isEmpty(inventoryDisplayData.groupVars);
  }

  inventoryHasGlobalValue(inventoryDisplayData: InventoryDisplayData): boolean {
    return !_.isEmpty(inventoryDisplayData.vars.value);
  }

  get variableKeyErrorMessage(): string {
    const variableKeyErrors = this.variableKeyFormControl.errors;
    if (_.isNil(variableKeyErrors)) {
      return '';
    }
    if (variableKeyErrors.required) {
      return 'Variable name is required';
    }
    if (variableKeyErrors.existingVariable) {
      return 'Variable with such name already exists';
    }
    if (variableKeyErrors.pattern) {
      return 'Variable name may only contain: A-Z, a-z, 0-9, _ and -';
    }
    return '';
  }

  get displayInventories(): boolean {
    return !_.isEmpty(this.variableDetails.inventories);
  }

  get formActionsDisabled(): boolean {
    return !this.variableForm.dirty;
  }

  getInventoryIconName(inventory: InventoryDisplayData): string {
    return InventorySchemaTypeHelper.getInventoryIconName(inventory.schemaType);
  }
}
