<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width bundle-management-table' [dataSource]='tableDataSource'>
  <!-- Source Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SourceColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Source</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div class='id-and-actions'>
        <div class='text-ellipsis cursor-default' [ngbTooltip]='resourceIdTooltip' placement='top-left'
             [innerHTML]='element.source | highlight: filterText'></div>
        <mat-icon class="cursor-pointer color-info" [cdkCopyToClipboard]="element.source">content_copy</mat-icon>
        <mat-icon class='global-icon' *ngIf="element?.isTenantScoped" [ngbTooltip]='globalResourceTooltip'
                  placement='top-left'
        >public</mat-icon>
      </div>
      <ng-template #resourceIdTooltip>{{element.source}}</ng-template>
      <ng-template #globalResourceTooltip>Created as global.<br/>Use it in your inventory by inserting its reference
        ID.
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Used in Column -->
  <ng-container [matColumnDef]="resourceTableColumns.UsageColumnName">
    <mat-header-cell *matHeaderCellDef>
      Used in
      <span class='expansion-wrapper' (click)='toggleUsedInExpansion(true)'>{{isExpandedUsage ? 'Collapse all' : 'Expand all'}}
        <i class="fa" [ngClass]="isExpandedUsage ? 'fa-minus-square-o' : 'fa-plus-square-o'" aria-hidden="true"></i>
      </span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>

      <ng-container *ngIf='element?.usedIn; else noUsageTemplate'>
        <ng-container *ngIf='element?.usedIn?.length > 1 && !element.isExpanded; else usageList'>
          <a (click)='toggleUsedInExpansion(false, element)'>{{element?.usedIn.length}} inventories...</a>
        </ng-container>
        <ng-template #usageList>
          <div class='usage-wrapper'>
            <div *ngFor='let usage of element.usedIn' class='usage-item'>
              <a (click)='navigateToInventory(usage)' [innerHTML]='usage | cropTenantFromKey | highlight: filterText'></a></div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #noUsageTemplate>
        <ng-container *ngIf='element?.isTenantScoped; else unlinked'>
          <span>Unused</span>
          <i class="fa fa-info-circle help-icon" aria-hidden="true"
             [ngbTooltip]='unusedPopover' placement='right'></i>
        </ng-container>
      </ng-template>

      <ng-template #unlinked>
        <span>Unlinked</span>
        <i class="fa fa-info-circle help-icon" aria-hidden="true"
           [ngbTooltip]='unLinkedPopover' placement='right'></i>
      </ng-template>

      <ng-template #unusedPopover>The secret has not been used yet.<br/>
        1. Go to your project.<br/>
        2. Create a variable in your pattern.<br/>
        3. Insert the secret reference to your variable in the Inventory.
      </ng-template>
      <ng-template #unLinkedPopover>The file reference has been removed from an inventory and is not used in any
        inventory. To clean up, please remove it.
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Description Column -->
  <ng-container [matColumnDef]="resourceTableColumns.DescriptionColumnName">
    <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)'>
      <div *ngIf='{desc: element.description, showMore: false} as descInfo;' class='description-content-wrapper'>
        {{(descInfo.showMore) ? descInfo.desc : (descInfo.desc | slice:0:25) || '-'}}
        <span *ngIf="!descInfo.showMore && descInfo.desc?.length > 25">...</span><br>
        <a *ngIf="!descInfo.showMore && descInfo.desc?.length > 25" (click)="descInfo.showMore=true">Show More</a>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Expiry date Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ExpiryDateColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Expiry date</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
        <div *ngFor='let singleCertificate of element.certificates' class='single-line-item'>
          <span>{{singleCertificate.expires | customDate}}</span>
          <ng-container
                  *ngIf='{hasWarning: checkExpirationBySeverity(singleCertificate, "warning"), hasError: checkExpirationBySeverity(singleCertificate, "error")} as expirationAlert'>
            <mat-icon *ngIf='expirationAlert.hasWarning || expirationAlert.hasError' class="cert-expiry-icon"
                      [class.warning-icon]='expirationAlert.hasWarning'
                      [class.error-icon]='expirationAlert.hasError'
                      [ngbTooltip]='getExpirationTooltip(singleCertificate)' placement='top-right'>
              {{resolveExpirationIcon(singleCertificate)}}
            </mat-icon>
          </ng-container>
        </div>
    </mat-cell>
  </ng-container>

  <!-- Issuer Column -->
  <ng-container [matColumnDef]="resourceTableColumns.IssuerColumnName">
    <mat-header-cell *matHeaderCellDef> Issuer</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
      <div *ngFor='let singleCertificate of element.certificates' class='single-line-item multi-line-content'>
        <div *ngIf='{issuer: singleCertificate.issuer, showMore: false} as issuerInfo;' class='description-content-wrapper'>
          <span [innerHTML]="(issuerInfo.showMore) ? (issuerInfo.issuer | highlight: filterText) : (issuerInfo.issuer | slice:0:25 | highlight: filterText) || '-'"></span>
          <span *ngIf="!issuerInfo.showMore && issuerInfo.issuer?.length > 25">...</span><br>
          <a *ngIf='issuerInfo.issuer?.length > 25' (click)="issuerInfo.showMore=!issuerInfo.showMore">
            {{issuerInfo.showMore ? 'Show Less' : 'Show More'}}</a>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Subject Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SubjectColumnName">
    <mat-header-cell *matHeaderCellDef> Subject</mat-header-cell>
    <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
        <div *ngFor='let singleCertificate of element.certificates' class='single-line-item multi-line-content'>
          <div *ngIf='{subject: singleCertificate.subject, showMore: false} as subjectInfo;' class='description-content-wrapper'>
            <span [innerHTML]="(subjectInfo.showMore) ? (subjectInfo.subject | highlight: filterText) : (subjectInfo.subject | slice:0:25 | highlight: filterText) || '-'"></span>
            <span *ngIf="!subjectInfo.showMore && subjectInfo.subject?.length > 25">...</span><br>
            <a *ngIf='subjectInfo.subject?.length > 25' (click)="subjectInfo.showMore=!subjectInfo.showMore">
              {{subjectInfo.showMore ? 'Show Less' : 'Show More'}}</a>
          </div>
        </div>
    </mat-cell>
  </ng-container>

  <!-- Serial Column -->
  <ng-container [matColumnDef]="resourceTableColumns.SerialColumnName">
    <mat-header-cell *matHeaderCellDef> Serial</mat-header-cell>
      <mat-cell *matCellDef="let element" [class.disabled-opacity]='!isUsedResource(element)' class='divided-cell'>
        <div *ngFor='let singleCertificate of element.certificates' class='single-line-item serial-wrapper'
             [innerHTML]='singleCertificate.serial.toString() | highlight: filterText'
             [ngbTooltip]='singleCertificate.serial.toString()' placement='top'></div>
      </mat-cell>
  </ng-container>

  <!-- Action Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ActionColumnName">
    <mat-header-cell *matHeaderCellDef> Action</mat-header-cell>
    <mat-cell *matCellDef="let element" class='action-cell'>
      <span [ngbTooltip]='getResourceActionBtnTooltip(hasViewSecretContentPermissionByLevel(element) | async, inventoryResourceActionTypes.View)'
            placement='bottom'>
      <button class='action-btn'
              [disabled]='!(hasViewSecretContentPermissionByLevel(element) | async)'
              (click)='openViewContentDialog(element, true)'>
        <mat-icon>visibility</mat-icon>
        </button></span>

      <span [ngbTooltip]='getResourceActionBtnTooltip(hasModifyPermissionByLevel(element) | async, inventoryResourceActionTypes.Change)'
            placement='bottom'>
        <button class='action-btn'
                [disabled]='!(hasModifyPermissionByLevel(element) | async)'
                (click)='openReplaceContentDialog(element)'>
            <mat-icon>mode_edit</mat-icon>
        </button>
      </span>

      <span [ngbTooltip]='getResourceActionBtnTooltip(hasModifyPermissionByLevel(element) | async, inventoryResourceActionTypes.Delete)'
            placement='bottom'>
      <button class='action-btn' [disabled]='!(hasModifyPermissionByLevel(element) | async)'
              (click)='deleteCertificate(element)'>
        <mat-icon>delete_forever</mat-icon>
      </button>
        </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" class='certificate-item-row'
           [class.error]='isColorHighlighted(row, "error")'
           [class.warning]='isColorHighlighted(row, "warning")'></mat-row>
</mat-table>
<ng-container *ngIf="!hasDisplayableData">
  <adm4-empty-resource><span>There is no certificate found.</span></adm4-empty-resource>
</ng-container>
