import { Component, Input } from '@angular/core';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'adm4-local-changes-inventory-confirmation-dialog',
  template: `
    Please update the inventory before publishing changes.<br/>
    <br/>
    Note that there are local changes. These will be lost if the inventory was modified remotely.<br/>
    <span class='call-to-action'>To create a local backup now, click: <a (click)='exportInventoryToZip()'>Export Inventory to Zip</a></span><br/>
    <br/>
    Do you want to continue?
  `,
  styleUrls: ['./local-changes-confirmation-dialog.component.scss']
})
export class LocalChangesInventoryConfirmationDialogComponent {
  @Input() inventoryKey: string;

  exportInventoryToZip(): void {
    FileDownloader.downloadFileFromUrl(`${environment.baseUrl}/inventories/${this.inventoryKey}/file-export`);
  }
}
