import { SafeHtml } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Project, ProjectInventoryDeployment } from '../../projects/project.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Inventory, InventorySchemaType } from '../../inventory/inventory.model';
import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';
import * as _ from 'lodash';
import { DeploymentSelection } from './deployment-selection.model';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { LoadProjectsWithInventoryDeployments } from '../../model/project';
import { LoadInventories } from '../../model/inventory';
import {
  allInventoriesListView, allProjectsListView,
  deployToClassicInstancePatternsView,
  deployToClassicOptionsView,
} from '../../model/views';
import {
  StoreDeploymentCanaryRoutingOptions,
  StoreDeploymentHost,
  StoreDeploymentInventory,
  StoreDeploymentProject,
} from '../../model/deploy';
import {
  allHosts,
  DeployToKubernetesOptionType,
  DeployToOption,
  primaryDeployOption,
  secondaryDeployOption,
} from './deploy-to-option.model';
import { NavigationService } from '../../navbar/navigation.service';
import { CreateInventoryDialogService } from '../../inventory/create-inventory/create-inventory-dialog.service';
import { DeploymentDialogComponent } from '../deployment-dialog/deployment-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { InventorySchemaTypeHelper } from './inventory-list/inventory-schema-type.helper';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { InventoryService } from '../../inventory/inventory.service';
import { filterEmpty, Maybe, requireNonNull } from '@common/utils/utils';
import {
  DeploymentHistoryItem
} from '@common/model/deployment-history.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@common/helpers/form.helper';
import { KubernetesStatusHelper } from '../../inventory/inventory-kubernetes-status/kubernetes-status.helper';
import { LocalStorageHelper } from '@common/helpers/local-storage.helper';
import { localStorageDeployToInstancePatterns } from '@common/constants/local-storage-keys.constants';
import { CustomSanitizerService } from '@common/services/custom-sanitizer.service';
import { DeployToOptionHelper } from './deploy-to-option.helper';
import {
  DeploymentActivity,
  DeploymentActivityContextService,
  DeploymentActivityIndicator
} from '@common/services/deployment-activity-context.service';
import { TenantHelper } from '@common/helpers/tenant.helper';

interface DeploymentStartControls {
  normalDisabled: boolean;
  normalTooltipShown: boolean;
  normalTooltip?: SafeHtml;
  bgDisabled: boolean;
  bgTooltipShown: boolean;
  bgTooltip?: SafeHtml;
}

const MSG_SELECTION_MISSING = 'Please select a project and an inventory to continue the deployment.';
const MSG_INVENTORY_REQUIRES_PUBLISHED = 'In order to use background deployment please disable the require publishing flag in inventory settings.';

@Component({
  selector: 'adm4-deployment-selection',
  template: `
    <div class="full-height-flex">
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <div class='deployment-selection step-content full-height'>
            <div>
              <adm4-project-list *ngIf='projects$ | async' [items]='projects$ | async'
                                 [preSelectedProjectKey]='deploymentSelection.projectKey'
                                 [isClassicDeployment]='isClassicDeployment(selectedInventorySchemaType$ | async)'
                                 [classicDeployPatterns]='classicDeployInstancePatternsView$ | async'
                                 [deploymentActivity]="deploymentActivity$ | async"
                                 (itemSelected)='onProjectSelected($event)'
                                 (instancePatternSelected)='onInstancePatternClicked($event)'></adm4-project-list>
            </div>
            <div>
              <adm4-inventory-list *ngIf='inventories$ | async' [items]='inventories$ | async'
                                   [selectedProjectInventoryDeployments]="selectedProjectInventoryDeployments$ | async"
                                   [preSelectedInventoryKey]='deploymentSelection.inventoryKey'
                                   [deploymentActivity]="deploymentActivity$ | async"
                                   (itemSelected)='inventoryClicked($event)'
                                   (createInventoryClick)='goToCreateInventory()'></adm4-inventory-list>
            </div>
            <div class='deployment-host-selection'>
              <adm4-host-list *ngIf='isClassicDeployment(selectedInventorySchemaType$ | async)'
                              [items]='classicDeployTargets$ | async'
                              [preSelectedHostExpression]='getHostExpressionPrefix(deploymentSelection.deployTarget)'
                              [boxShadowClass]='boxShadowClass'
                              [inventoryColor]='inventoryColor$ | async'
                              (itemSelected)='onDeployTargetClicked($event)'></adm4-host-list>
              <adm4-k8s-list *ngIf='isKubernetesDeployment(selectedInventorySchemaType$ | async)'
                             [items]='kubernetesDeployTargets$ | async'
                             [preSelectedCanaryRoutingOptions]='deploymentSelection.canaryRouting'
                             [preSelectedProjectKey]='deploymentSelection.projectKey'
                             [inventoryDeploymentHistory]='inventoryDeploymentHistory$ | async'
                             [boxShadowClass]='boxShadowClass'
                             [inventoryColor]='inventoryColor$ | async'
                             [form]='form'
                             (itemSelected)='onDeployK8sTargetClicked($event)'>
              </adm4-k8s-list>
              <form [formGroup]="form">
                <div class='comment-wrapper'>
                  <div class="list-group comment-content" [ngClass]='boxShadowClass'>
                    <div class='comment-title' (click)='onCommentSectionClicked()'>Comment (optional)
                      <mat-icon class='expand-icon'>{{isCommentCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </div>
                    <textarea *ngIf='!isCommentCollapsed'
                              class='form-control admn4-textarea-input'
                              placeholder='Add your deployment comments'
                              [formControlName]='COMMENT_FIELD_FORM_CONTROL_NAME'
                              [(ngModel)]='commentMessage'></textarea>
                    <div *ngIf='!isCommentCollapsed' class="validation-message-container">
                      <adm4-validation-message *ngIf="shouldShowCommentErrorMessage('maxlength')" [isError]='true' [message]='"You have exceeded the maximum character limit of 150."'></adm4-validation-message>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="step-action-bar buttons-right">
        @let bgDeploymentDocLink = DOCS_BACKGROUND_DEPLOYMENT | docLink;
        <a href="{{bgDeploymentDocLink}}" target="_blank" [ngbTooltip]='bgDeploymentTooltip' placement='left' triggers="hover focus">
          <i class="fa fa-info-circle help-icon" aria-hidden="true"></i>
        </a>
        <ng-template #bgDeploymentTooltip>
            <p class="m-0">Visit the Background Deployment section of the
                <a href="{{bgDeploymentDocLink}}" target="_blank">user guide</a> for more details.</p>
        </ng-template>
        <ng-container *ngIf="deploymentStartControls$ | async as controls">
          <span [ngbTooltip]='bgStartTooltip' [disableTooltip]='!controls.bgTooltipShown' placement='top-right'>
            <button class='next-step-button next-step-icon icon-quick'
                    [disabled]="controls.bgDisabled"
                    (click)="onBackgroundDeploymentClicked()">Background deployment</button>
          </span>
          <ng-template #bgStartTooltip>
              <p class="m-0" [innerHTML]="controls.bgTooltip"></p>
          </ng-template>
          <span [ngbTooltip]='normalStartTooltip' [disableTooltip]='!controls.normalTooltipShown' placement='top-right'>
            <button matStepperNext cdkFocusInitial tabindex="0"
                    class='next-step-button next-step-icon icon-play'
                    [disabled]='controls.normalDisabled'
                    (click)='onValidateDeploymentClicked()'>
                Validate deployment
            </button>
          </span>
          <ng-template #normalStartTooltip>
            <p class="m-0" [innerHTML]="controls.normalTooltip"></p>
          </ng-template>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./deployment-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentSelectionComponent implements OnChanges {

  public readonly DOCS_BACKGROUND_DEPLOYMENT = 'nevisadmin4/User-Guide/Deployment-of-the-Configuration/Background-Deployment';

  @Input() deploymentSelection: DeploymentSelection;
  @Input() boxShadowClass: string;
  @Output() closeDialogClicked: EventEmitter<void> = new EventEmitter();
  @Output() validateDeploymentClicked: EventEmitter<DeploymentProcessModel> = new EventEmitter();
  @Output() inventorySelected: EventEmitter<Inventory> = new EventEmitter(true);
  @Output() deploymentCommentChanged: EventEmitter<string> = new EventEmitter();
  @Output() backgroundDeploymentStarted: EventEmitter<DeploymentProcessModel> = new EventEmitter();

  projects$: Observable<Project[]>;
  inventories$: Observable<Inventory[]>;
  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  selectedProjectInventoryDeployments$: Observable<ProjectInventoryDeployment[]>;
  inventoryColor$: Observable<string>;
  classicDeployInstancePatternsView$: Observable<DeployToOption[]>;
  classicDeployTargets$: Observable<DeployToOption[]>;
  inventoryDeploymentHistory$: Observable<DeploymentHistoryItem[]>;
  kubernetesDeployTargets$: Observable<DeployToOption[]>;
  public deploymentActivity$: Observable<DeploymentActivityIndicator>;
  form: UntypedFormGroup;
  readonly COMMENT_FIELD_FORM_CONTROL_NAME = 'comment';
  commentMessage: string;
  isCommentCollapsed: boolean = true;

  deploymentStartControls$: Observable<DeploymentStartControls>;
  isFormValid$: Observable<boolean>;

  private readonly deploymentSelection$: BehaviorSubject<DeploymentSelection> = new BehaviorSubject({});

  constructor(private readonly dialogRef: MatDialogRef<DeploymentDialogComponent>,
              private readonly store$: Store<AppState>,
              private readonly navigationService: NavigationService,
              private readonly createInventoryDialogService: CreateInventoryDialogService,
              private readonly deploymentWizardContext: DeploymentWizardContext,
              private readonly inventoryService: InventoryService,
              private readonly fb: UntypedFormBuilder,
              deploymentActivityContext: DeploymentActivityContextService,
              sanitizer: CustomSanitizerService,
  ) {
    this.store$.dispatch(new LoadProjectsWithInventoryDeployments());
    this.store$.dispatch(new LoadInventories());
    this.form = this.createFormGroup();
    this.projects$ = this.store$.select(allProjectsListView);
    this.inventories$ = this.store$.select(allInventoriesListView);
    this.selectedInventorySchemaType$ = deploymentWizardContext.selectedInventorySchemaType$;
    this.selectedProjectInventoryDeployments$ = deploymentWizardContext.selectedProjectInventoryDeployments$;
    this.inventoryColor$ = this.deploymentWizardContext.inventoryColor$;
    this.classicDeployInstancePatternsView$ = this.store$.pipe(select(deployToClassicInstancePatternsView));
    this.classicDeployTargets$ = this.store$.pipe(select(deployToClassicOptionsView));
    this.inventoryDeploymentHistory$ = this.deploymentWizardContext.selectedInventoryKey$.pipe(
      filter(filterEmpty),
      switchMap((inventoryKey: string) => this.inventoryService.getInventoryDeploymentHistory(requireNonNull(inventoryKey))));
    this.kubernetesDeployTargets$ = combineLatest([
      this.inventoryDeploymentHistory$,
    ]).pipe(
      map(([deploymentHistoryItems]: [DeploymentHistoryItem[]]): DeployToOption[] => {
        const hasPrimaryActive = KubernetesStatusHelper.hasActivePrimaryDeployments(deploymentHistoryItems);
        if (!hasPrimaryActive) {
          this.resetDeploymentSelection();
        }
        return hasPrimaryActive ? [primaryDeployOption, secondaryDeployOption] : [primaryDeployOption];
      }),
    );
    this.deploymentActivity$ = deploymentActivityContext.deploymentActivityIndicator$;

    this.isFormValid$ = this.form.valueChanges.pipe(map((): boolean => this.form.valid), startWith(true));
    this.deploymentStartControls$ = combineLatest([
      this.isFormValid$,
      this.projects$,
      this.inventories$,
      this.deploymentSelection$.pipe(startWith({})),
      this.deploymentActivity$.pipe(startWith({hasActivity: false})),
    ]).pipe(
      distinctUntilChanged(_.isEqual),
      map((
        [formValid, projects, inventories, selection, activity]: [boolean, Project[], Inventory[], DeploymentSelection, DeploymentActivityIndicator]
      ): DeploymentStartControls => {
        if (!formValid) {
          return {normalDisabled: true, normalTooltipShown: false, bgDisabled: true, bgTooltipShown: false};
        }
        if (_.isEmpty(projects) || _.isEmpty(inventories) || _.isNil(selection.projectKey) || _.isNil(selection.inventoryKey)) {
          return {normalDisabled: true, normalTooltip: MSG_SELECTION_MISSING, normalTooltipShown: true,
            bgDisabled: true, bgTooltip: MSG_SELECTION_MISSING, bgTooltipShown: true};
        }
        const projectDeploying: boolean = !!selection.projectKey
          && activity.hasActivity
          && activity.activities.some((a: DeploymentActivity) => a.projectKey === selection.projectKey);
        const inventoryDeploying: boolean = !!selection.inventoryKey
          && activity.hasActivity
          && activity.activities.some((a: DeploymentActivity) => a.inventoryKey === selection.inventoryKey);
        if (projectDeploying && inventoryDeploying) {
          const projectName: Maybe<string> = TenantHelper.cropTenantFromKey(selection.projectKey);
          const inventoryName: Maybe<string> = TenantHelper.cropTenantFromKey(selection.inventoryKey);
          const tooltipText = sanitizer.sanitizeAndTrust(`There is already an ongoing deployment for the <strong>${projectName}</strong> and <strong>${inventoryName}</strong> combination.`);
          return {normalDisabled: true, normalTooltip: tooltipText, normalTooltipShown: true, bgDisabled: true, bgTooltip: tooltipText, bgTooltipShown: true};
        }
        const inventoryRequiresPublished: boolean = inventories.find((inventory: Inventory) => inventory.inventoryKey === selection.inventoryKey)?.publishRequired || false;
        return {normalDisabled: false, normalTooltipShown: false,
          bgDisabled: inventoryRequiresPublished, bgTooltipShown: inventoryRequiresPublished, bgTooltip: inventoryRequiresPublished ? MSG_INVENTORY_REQUIRES_PUBLISHED : undefined};
      }),
      shareReplay(1),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deploymentSelection) {
      this.deploymentSelection$.next(this.deploymentSelection);
    }
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.COMMENT_FIELD_FORM_CONTROL_NAME, this.fb.control(null, Validators.maxLength(150)));
    return group;
  }

  shouldShowCommentErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.COMMENT_FIELD_FORM_CONTROL_NAME], errorKey);
  }

  isClassicDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    return InventorySchemaTypeHelper.isClassicDeployment(inventorySchemaType);
  }

  isKubernetesDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    return InventorySchemaTypeHelper.isKubernetesDeployment(inventorySchemaType);
  }

  onProjectSelected(project: Project): void {
    if (project.projectKey !== this.deploymentSelection.projectKey) {
      this.store$.dispatch(new StoreDeploymentProject(project.projectKey));
    }
  }

  inventoryClicked(inventory: Inventory): void {
    this.inventorySelected.emit(inventory);
    if (inventory.inventoryKey !== this.deploymentSelection.inventoryKey) {
      this.store$.dispatch(new StoreDeploymentInventory(inventory.inventoryKey));
    }
  }

  onDeployTargetClicked(deployToOption: DeployToOption): void {
    if (deployToOption.hostExpression !== this.deploymentSelection.deployTarget) {
      if (_.isNil(this.deploymentSelection.deployTarget)) {
        this.store$.dispatch(new StoreDeploymentHost(deployToOption.hostExpression));
        return;
      }
      const selectedPatternInstance = DeployToOptionHelper.unwrapPatternInstancesFromHostExpression(this.deploymentSelection.deployTarget);
      const finalHostExpression = _.isEmpty(selectedPatternInstance) ? deployToOption.hostExpression : (deployToOption.hostExpression + ';' + selectedPatternInstance);
      this.store$.dispatch(new StoreDeploymentHost(finalHostExpression));
    }
  }

  onInstancePatternClicked(patternInstancesDeployToOption: DeployToOption): void {
    if (_.isNil(this.deploymentSelection.deployTarget)) {
      this.store$.dispatch(new StoreDeploymentHost(allHosts.hostExpression));
      return;
    }
    const hostExpressionPrefix = this.getHostExpressionPrefix(this.deploymentSelection.deployTarget);
    const finalHostExpression = _.isEmpty(patternInstancesDeployToOption.hostExpression) ? hostExpressionPrefix : hostExpressionPrefix + ';' + patternInstancesDeployToOption.hostExpression;
    this.store$.dispatch(new StoreDeploymentHost(finalHostExpression));
  }

  getHostExpressionPrefix(fullHostExpression: string): string {
    return DeployToOptionHelper.getHostExpressionPrefix(fullHostExpression);
  }

  onDeployK8sTargetClicked(deployToOption: DeployToOption): void {
    if (_.isEqual(deployToOption.type, DeployToKubernetesOptionType.Primary) || !_.isEqual(deployToOption.canaryRouting, this.deploymentSelection.canaryRouting)) {
      this.store$.dispatch(new StoreDeploymentCanaryRoutingOptions(deployToOption.canaryRouting));
    }
  }

  onValidateDeploymentClicked(): void {
    const deployment = this.createDeploymentFromDeploymentSelection();
    this.validateDeploymentClicked.emit(deployment);
    this.storeSelectedInstancePatterns(deployment);
  }

  onBackgroundDeploymentClicked(): void {
    const deployment = this.createDeploymentFromDeploymentSelection();
    this.backgroundDeploymentStarted.emit(deployment);
  }

  storeSelectedInstancePatterns(deployment: DeploymentProcessModel): void {
    const retrieve = LocalStorageHelper.retrieve(localStorageDeployToInstancePatterns);
    const selectedProjectWithPatternInstanceSelection = {[deployment.projectKey]: deployment.hostExpression};
    if (_.isNil(retrieve)) {
      LocalStorageHelper.save(localStorageDeployToInstancePatterns, JSON.stringify(selectedProjectWithPatternInstanceSelection));
      return;
    }
    const currentDeployToConfig = JSON.parse(retrieve);
    currentDeployToConfig[deployment.projectKey] = deployment.hostExpression;
    LocalStorageHelper.save(localStorageDeployToInstancePatterns, JSON.stringify(currentDeployToConfig));
  }

  createDeploymentFromDeploymentSelection(): DeploymentProcessModel {
    if (_.isNil(this.deploymentSelection.projectKey) || _.isNil(this.deploymentSelection.inventoryKey)) {
      // while selected project and selected inventory are empty button for the next step should be disabled therefore to prevent misusing of this method such case is handled
      throw new Error('Should not try to proceed to deployment validation without selecting project and inventory');
    }

    const deployment = new DeploymentProcessModel();
    deployment.projectKey = this.deploymentSelection.projectKey;
    deployment.inventoryKey = this.deploymentSelection.inventoryKey;
    if (this.deploymentSelection.deployTarget) {
      deployment.hostExpression = this.deploymentSelection.deployTarget;
    }
    if (this.deploymentSelection.canaryRouting) {
      deployment.canaryRouting = this.deploymentSelection.canaryRouting;
    }
    if (!_.isEmpty(this.commentMessage)) {
      this.deploymentCommentChanged.emit(this.commentMessage);
      deployment.comment = this.commentMessage;
    }
    return deployment;
  }

  goToCreateInventory(): void {
    this.navigationService.navigateAwayFromModalWindow()
      .then(() => this.dialogRef.afterClosed().toPromise())
      .then(() => this.navigationService.navigateToInventories())
      .then(() => this.createInventoryDialogService.openCreateInventoryDialog());
  }

  private resetDeploymentSelection() {
    this.deploymentSelection = {
      projectKey: this.deploymentSelection.projectKey,
      inventoryKey: this.deploymentSelection.inventoryKey,
      comment: this.deploymentSelection.comment
    };
  }

  onCommentSectionClicked(): void {
    this.isCommentCollapsed = !this.isCommentCollapsed;
  }
}
