import { ChangeDetectionStrategy, Component, computed, input, InputSignal, Signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

export type AdmonitionSeverity = 'success' | 'info' | 'warn' | 'error' | 'inactive';

/**
 * Displays one or more messages with the provided severity.<br/><br/>
 * If the messages are not plain text, eg. if they contain html, you can project messages with the `adm4-admonition-msg` attribute.
 * They should be simple paragraphs and should not have any margins.<br/>
 * Also note that projected messages' styling is not controlled by the component, so you should style them according to the severity.
 */
@Component({
  selector: 'adm4-admonition',
  standalone: true,
  imports: [NgClass, MatIcon],
  template: `
      <div class="admonition" [ngClass]="[alignmentClass(), severityClass()]">
          @switch (severity()) {
              @case ('error') {
                  <mat-icon class="color-error size-16">block_outlined</mat-icon>
              }
              @case ('warn') {
                  <mat-icon class="color-warning size-16">report_problem_outlined</mat-icon>
              }
              @case ('success') {
                  <mat-icon class="color-success size-16">done</mat-icon>
              }
              @case ('inactive') {
                  <i class="fa fa-info-circle color-inactive" aria-hidden="true"></i>
              }
              @default {
                  <i class="fa fa-info-circle color-info" aria-hidden="true"></i>
              }
          }
          <div class="admonition-messages">
              @for (msg of _messages(); track msg) {
                  <p>{{ msg }}</p>
              }
              <ng-content select="[adm4-admonition-msg]"></ng-content>
          </div>
      </div>
  `,
  styleUrl: './admonition.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdmonitionComponent {
  /**
   * Use one or more messages to display in the admonition.<br/>
   * Use content projection with the `adm4-admonition-msg` attribute to project messages with html content.
   */
  messages: InputSignal<string | string[] | undefined> = input();
  severity: InputSignal<AdmonitionSeverity> = input.required();

  /**
   * The alignment of the icon and the messages.<br/>
   * Generally use `'start'` so that the icon will be aligned to the top of the admonition.<br/>
   * User `'center'` only if you are sure that the message will only take up a single line
   * and you want the icon to be centered vertically to that line.
   */
  alignment: InputSignal<'start' | 'center'> = input.required();

  _messages: Signal<string[]> = computed((): string[] => {
    const msgs: string | string[] | undefined = this.messages();
    return typeof msgs === 'string' ? [msgs] : msgs??[];
  });

  alignmentClass: Signal<string> = computed((): string => {
    if (this.alignment() === 'center') {
      return 'icon-center';
    } else {
      return '';
    }
  });

  severityClass: Signal<string> = computed((): string => `adm-${(this.severity())}`);
}
