import { GlobalConfig } from 'ngx-toastr';

import { ToastSeverity } from './toast-notification.service';

const CLASS_MAIN = 'nevis-notification';
const CLASS_TITLE = 'nevis-notification-title';
const CLASS_MESSAGE = 'nevis-notification-message';
const CLASS_CURSOR_DEFAULT = 'cursor-default';
const CLASS_CURSOR_POINTER = 'cursor-pointer';

export class ToastOpeningHelper {
  static getBaseClass(className: string): Partial<GlobalConfig> {
    return {
      closeButton: true,
      tapToDismiss: true,
      autoDismiss: true,
      timeOut: 5000,
      extendedTimeOut: 2000,
      enableHtml: true,
      onActivateTick: false,
      toastClass: 'nevis-notification cursor-default ' + className,
      titleClass: 'nevis-notification-title cursor-default ' + className,
      messageClass: 'nevis-notification-message cursor-default ' + className,
    };
  }

  static getPersistentClass(className: string): Partial<GlobalConfig> {
    return {
      closeButton: false,
      tapToDismiss: false,
      autoDismiss: false,
      disableTimeOut: true,
      enableHtml: true,
      onActivateTick: false,
      preventDuplicates: true,
      toastClass: 'nevis-notification cursor-default ' + className,
      titleClass: 'nevis-notification-title cursor-default ' + className,
      messageClass: 'nevis-notification-message cursor-default ' + className
    };
  }

  static createPersistentWithCloseConfig(severity: ToastSeverity, pointerCursor: boolean): Partial<GlobalConfig> {
    const cursorClass: string = pointerCursor ? CLASS_CURSOR_POINTER : CLASS_CURSOR_DEFAULT;
    return {
      closeButton: true,
      tapToDismiss: true,
      autoDismiss: false,
      disableTimeOut: true,
      enableHtml: true,
      onActivateTick: false,
      preventDuplicates: true,
      toastClass: [CLASS_MAIN, cursorClass, severity].join(' '),
      titleClass: [CLASS_TITLE, cursorClass, severity].join(' '),
      messageClass: [CLASS_MESSAGE, cursorClass, severity].join(' '),
    };
  }
}
