import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PublishMainComponent } from '../model/publish-changes/publish-main-component.model';
import { PublishDialogComponent } from '../model/publish-changes/publish-dialog-component.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';

const CONFIRM_UNSAVED_CHANGES = 'Do you want to discard changes?';

@Injectable()
export class PublishCommitMessageGuard  {

  constructor(public modalNotificationService: ModalNotificationService) {}

  canDeactivate(component: PublishMainComponent<PublishDialogComponent>): boolean | Observable<boolean> {
    // sometimes this guard get called when the dialog is not even activated
    if (!component.dialogRef) {
      return true;
    }
    const publishDialog: PublishDialogComponent = component.dialogRef.componentInstance;
    return !publishDialog.isDirty || publishDialog.explicitExit || this.confirmDiscardChanges();
  }

  private confirmDiscardChanges(): Observable<boolean> {
    return this.modalNotificationService.openConfirmDialog(
      {headerTitle: `Warning`, title: 'Unsaved changes', description: CONFIRM_UNSAVED_CHANGES,},
      {confirmButtonText: 'Discard changes'},
    ).afterClosed().pipe(map((confirmed?: boolean) => !!confirmed));
  }
}
