import { Injectable } from '@angular/core';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { deploymentPlanningForcedView, deploymentPlanningIsInProgressView, deploymentPlanningOutputView, deploymentPlanningStatusView } from '../../model/views';
import { map, withLatestFrom } from 'rxjs/operators';
import { DeploymentPreviewHelper } from './deployment-preview.helper';
import { PlanningOutputModel } from './deployment-preview/planning-output.model';

@Injectable()
export class DeploymentPreviewContext {
  planningStatus$: Observable<DeploymentStatusModel | undefined>;
  planningOutput$: Observable<PlanningOutputModel[]>;
  planningInProgress$: Observable<boolean>;
  hostsCount$: Observable<number>;
  redeploymentForced$: Observable<boolean>;
  // Display Logic Streams
  isPlanningFailedEntirely$: Observable<boolean>;
  canRepeatPlanning$: Observable<boolean>;
  canBeAborted$: Observable<boolean>;
  shouldDisplayValidating$: Observable<boolean>;
  noDeploymentNeeded$: Observable<boolean>;
  canDeploy$: Observable<boolean>;
  canGoBack$: Observable<boolean>;
  canShowForceRedeploymentButton$: Observable<boolean>;
  canShowCloseButton$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.planningStatus$ = this.store$.pipe(select(deploymentPlanningStatusView));
    this.planningInProgress$ = this.store$.pipe(select(deploymentPlanningIsInProgressView));
    this.planningOutput$ = this.store$.pipe(select(deploymentPlanningOutputView));
    this.redeploymentForced$ = this.store$.pipe(select(deploymentPlanningForcedView));
    this.hostsCount$ = this.planningOutput$.pipe(map(planningOutput => planningOutput.length));

    this.isPlanningFailedEntirely$ = this.planningInProgress$.pipe(
      withLatestFrom(this.planningStatus$),
      map(([planningInProgress, planningStatus]) => !planningInProgress && DeploymentPreviewHelper.isPlanningEntirelyFailed(planningStatus))
    );
    this.canBeAborted$ = this.planningStatus$.pipe(map((planningStatus: DeploymentStatusModel | undefined) => DeploymentPreviewHelper.isPlanningInProgress(planningStatus)));

    // Display Logic Streams
    this.shouldDisplayValidating$ = combineLatest([this.planningOutput$, this.planningInProgress$, this.redeploymentForced$])
      .pipe(map(([planOutput, isPlanningInProgress, redeploymentForced]) => DeploymentPreviewHelper.shouldShowValidating(planOutput, isPlanningInProgress, redeploymentForced)));
    this.canRepeatPlanning$ = combineLatest([this.planningOutput$, this.planningInProgress$, this.redeploymentForced$])
      .pipe(map(([planOutput, isPlanningInProgress, redeploymentForced]: [PlanningOutputModel[], boolean, boolean]) => DeploymentPreviewHelper.canRepeatPlanning(planOutput, isPlanningInProgress, redeploymentForced)));
    this.noDeploymentNeeded$ = this.planningOutput$.pipe(map((planOutput: PlanningOutputModel[]) => DeploymentPreviewHelper.shouldShowNoDeploymentNeeded(planOutput)));
    this.canDeploy$ = combineLatest([this.planningOutput$, this.redeploymentForced$])
      .pipe(map(([planOutput, redeploymentForced]: [PlanningOutputModel[], boolean]) => DeploymentPreviewHelper.canDeploy(planOutput, redeploymentForced)));
    this.canGoBack$ = combineLatest([this.planningOutput$, this.redeploymentForced$])
      .pipe(map(([planOutput, redeploymentForced]: [PlanningOutputModel[], boolean]) => DeploymentPreviewHelper.canGoBack(planOutput, redeploymentForced)));
    this.canShowForceRedeploymentButton$ = combineLatest([this.planningOutput$, this.planningInProgress$, this.redeploymentForced$])
      .pipe(map(([planOutput, isInProgress, redeploymentForced]: [PlanningOutputModel[], boolean, boolean]) => DeploymentPreviewHelper.canShowForceRedeploymentButton(planOutput, isInProgress, redeploymentForced)));
    this.canShowCloseButton$ = combineLatest([this.planningInProgress$, this.noDeploymentNeeded$, this.redeploymentForced$])
      .pipe(map(([inProgress, noDeploymentNeeded, redeploymentForced]: [boolean, boolean, boolean]) => !inProgress && noDeploymentNeeded && !redeploymentForced));
  }
}
