import { NgModule } from '@angular/core';
import { BatchActionComponent } from './batch-action.component';
import { BatchActionDialogContainerComponent } from './batch-action-dialog-container/batch-action-dialog-container.component';
import { CommonModules } from '../../common/common.module';
import { ModalDialogModule } from '../../modal-dialog/modal-dialog.module';
import { SelectedPatternListElementComponent } from './selected-pattern-list-element.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AdmonitionComponent } from '../../common/components/admonition/admonition.component';

@NgModule({
  imports: [
    CommonModules,
    ModalDialogModule,
    ScrollingModule,

    // standalone components
    AdmonitionComponent,
  ],
  declarations: [
    BatchActionComponent,
    BatchActionDialogContainerComponent,
    SelectedPatternListElementComponent
  ]
})
export class BatchActionModule {
}
