import { createAction, props } from '@ngrx/store';

import { ParsedBundle } from '../bundle-management/bundle.model';

export const loadBundles = createAction('[BundleManagement] Load Bundles');

export const deleteBundle = createAction(
  '[BundleManagement] Delete Bundle',
  props<{ bundle: ParsedBundle }>(),
);

export const loadBundlesSuccess = createAction(
  '[BundleManagement] Load Bundles Success',
  props<{ bundles: ParsedBundle[] }>(),
);

export const uploadBundles = createAction(
  '[BundleManagement] Upload Bundles',
  props<{ files: File[] }>(),
);
