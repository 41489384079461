import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Mixin } from '../../decorators/mixin.decorator';
import { IMatMenuFocusFixMixin, MatMenuFocusFixMixin } from '../../mixins/mat-menu-focus-fix.mixin';

@Component({
  selector: 'adm4-split-button',
  template: `
    <div class='split-button'>
      <button type='button'
              class='primary-action-button admn4-button-ellipse-yellow'
              [disabled]="isDisabled"
              (click)='triggerPrimaryAction($event)'>
        {{primaryButtonText}}
      </button>
      <button type='button' #matMenuTrigger
              mat-icon-button
              class='secondary-action-dropdown-trigger'
              aria-label='menu'
              [disabled]='isDisabled'
              [matMenuTriggerFor]='menu'
              [disableRipple]='isDisabled'
              (click)='handleMenuOpen()'>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu='matMenu'
                class='mat-elevation-z8 split-button-menu'
                [overlapTrigger]='false'
                (closed)='handleMenuClose(matMenuTrigger)'
      >
        <ng-content select='[mat-menu-item]'></ng-content>
      </mat-menu>
    </div>
  `,
  styleUrls: ['./split-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([MatMenuFocusFixMixin])
export class SplitButtonComponent implements IMatMenuFocusFixMixin {
  @Input() primaryButtonText: string;
  @Input() isDisabled: boolean;
  @Output() primaryClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() splitMenuToggled: EventEmitter<boolean> = new EventEmitter();

  onMenuClosed(_matMenuTrigger: any): void {/* Implemented by MenuFocusFixMixin */}

  handleMenuOpen(): void {
    this.splitMenuToggled.emit(true);
  }

  handleMenuClose(matMenuTrigger: any): void {
    this.onMenuClosed(matMenuTrigger);
    this.splitMenuToggled.emit(false);
  }

  triggerPrimaryAction(event: MouseEvent): void {
    this.primaryClick.emit(event);
  }

}
