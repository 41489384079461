<div class='variables-diff-view-container full-height-flex'>
  <div class='diff-view-header'>
    <div class='remote'>
      Remote project <span *ngIf='currentBranch$ | async'>({{currentBranch$ | async}})</span>
    </div>
    <div class='local'>
      Local project
      <mat-slide-toggle class="secondary-slide-toggle"
              labelPosition='before'
              [ngModel]='showOnlyDifferences$ | async' (ngModelChange)='rememberShowDifferencesView($event)'>
        <span class="diff-switch-label">Show differences only</span>
      </mat-slide-toggle>
    </div>
  </div>
  <div class='remaining-space-flex-content-wrapper'>
    <div class='remaining-space-flex-content'>
      <div class='full-height-flex'>
        <adm4-variable-diff *ngFor='let variableDiff of variablesDiffData$ | async'
                            [variableDiff]='variableDiff'
                            [projectKey]='projectKey$ | async'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences$ | async'
        ></adm4-variable-diff>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content dummy-space-taker'>
            <div class='remote full-height'></div>
            <div class='local full-height'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
