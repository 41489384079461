import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adm4-modal-dialog-title',
  template: `
    <div class="modal-dialog-title-container" [cdkTrapFocus] [cdkTrapFocusAutoCapture]="true"
         [class.full-height-flex]='isFullHeightContent'>
      <mat-toolbar mat-dialog-title>
        <span class="mat-toolbar-item mat-headline-5">{{header}}</span>
        <button type="button" mat-icon-button *ngIf="showClose" aria-label="close" (click)='closeClicked.emit()'>
          <mat-icon>clear</mat-icon>
        </button>
      </mat-toolbar>
      <div [class.remaining-space-flex-content-wrapper]='isFullHeightContent'>
        <div [class.remaining-space-flex-content]='isFullHeightContent' class="modal-dialog-title-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>`,
  styleUrls: ['modal-dialog-title.component.scss']
})
export class ModalDialogTitleComponent {

  @Input() header: string;
  @Input() showClose: boolean;
  @Input() isFullHeightContent: boolean;
  @Output() closeClicked = new EventEmitter<any>();

}
