import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { GlobalConstantWithUsage, GlobalConstantBase } from '../../../inventory/inventory.model';
import { FormHelper } from '../../../common/helpers/form.helper';
import { DirtyFormGuardConnectorService } from '../../../common/services/dirty-form-guard-connector.service';

const NAME = 'name';
const VALUE = 'value';
const DESCRIPTION = 'description';

export interface EditGlobalConstantDialogPayload {
  /**
   * Can be omitted, that means that the dialog will create a new GC.
   */
  globalConstant: GlobalConstantWithUsage | undefined;
  saveCallback: (globalConstant: GlobalConstantBase) => Observable<void>;
}

@Component({
  selector: 'adm4-edit-global-constant-dialog',
  template: `
    <form [formGroup]="form" class="d-contents">
    <adm4-dialog-title mat-dialog-title [title]="header" [showClose]='true' (close)="closeWithoutSaving()"/>
    <div mat-dialog-content class="adm4-dialog-content bg-screen-white" cdkFocusInitial>
        <div class="form-group" [class.has-error]="shouldShowNameInvalidState()">
          <label for="gc-name" class="input-label">Constant name* (may only contain: A-Z, a-z, 0-9, _ and -)</label>
          <input id="gc-name" type="text" class="form-control admn4-text-input" placeholder="Define constant name"
                 required [maxlength]="MAX_LENGTH" tabindex="1001" formControlName="name">
          <div class="validation-message-container">
            <adm4-validation-message *ngIf="shouldShowNameValidationMessage(VALIDATION_REQUIRED)"
                                     [isError]='true'
                                     [message]="MSG_REQUIRED"></adm4-validation-message>
            <adm4-validation-message *ngIf="shouldShowNameValidationMessage(VALIDATION_MAX_LENGTH)"
                                     [isError]='true'
                                     [message]="MSG_MAX_LENGTH"></adm4-validation-message>
            <adm4-validation-message *ngIf="shouldShowNameValidationMessage(VALIDATION_PATTERN)"
                                     [isError]='true'
                                     [message]="MSG_PATTERN"></adm4-validation-message>
          </div>
        </div>
        <div class="form-group" [class.has-error]="shouldShowValueInvalidState()">
          <label for="gc-value" class="input-label">Constant value*</label>
          <adm4-monaco-editor-form-control
            id="gc-value" class="form-control admn4-text-input" tabindex="1002"
            formControlName="value"
          ></adm4-monaco-editor-form-control>
          <div class="validation-message-container">
            <adm4-validation-message *ngIf="shouldShowValueValidationMessage(VALIDATION_REQUIRED)"
                                     [isError]='true'
                                     [message]="MSG_REQUIRED"></adm4-validation-message>
            <adm4-validation-message *ngIf="shouldShowValueValidationMessage(VALIDATION_MAX_LENGTH)"
                                     [isError]='true'
                                     [message]="MSG_MAX_LENGTH"></adm4-validation-message>
            <adm4-validation-message *ngIf="shouldShowValueValidationMessage(VALIDATION_CONST_REF)"
                                     [isError]='true'
                                     [message]="MSG_CONST_REF"></adm4-validation-message>
          </div>
        </div>
        <div class="form-group" [class.has-error]="shouldShowDescriptionInvalidState()">
          <label for="gc-desc" class="input-label">Description</label>
          <textarea id="gc-desc" type="text" class="form-control admn4-textarea-input" placeholder="Add your description (max 150 characters)"
                    [maxlength]="DESC_MAX_LENGTH" tabindex="1003" formControlName="description"></textarea>
          <div class="validation-message-container">
            <adm4-validation-message *ngIf="shouldShowDescriptionValidationMessage(VALIDATION_DESC_MAX_LENGTH)"
                                     [isError]='true'
                                     [message]="MSG_DESC_MAX_LENGTH"></adm4-validation-message>
          </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="adm4-dialog-actions">
        <button type="button" class='admn4-button-text' (click)="closeWithoutSaving()">Cancel</button>
        <button type="button" class='admn4-button-ellipse-blue'
                [disabled]="form.invalid || !form.dirty" (click)="save()"
        >{{isExisting ? 'Save' : 'Create'}}</button>
    </mat-dialog-actions>
    </form>
  `,
  styleUrls: ['./edit-global-constant-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
})
export class EditGlobalConstantDialogComponent implements OnDestroy, OnInit {

  public readonly MSG_REQUIRED = 'This field is required.';
  public readonly VALIDATION_REQUIRED = 'required';

  public readonly MAX_LENGTH = 4000;
  public readonly MSG_MAX_LENGTH = 'This field can be at most 4000 characters long.';
  public readonly VALIDATION_MAX_LENGTH = 'maxlength';

  public readonly DESC_MAX_LENGTH = 151;
  public readonly MSG_DESC_MAX_LENGTH = 'This field can be at most 150 characters long.';
  public readonly VALIDATION_DESC_MAX_LENGTH = 'maxlength';

  public readonly MSG_PATTERN = 'Value does not match [A-Za-z0-9_-]*';
  public readonly VALIDATION_PATTERN = 'pattern';

  public readonly MSG_CONST_REF = 'The value cannot contain ${const:// or ${g-const:// pattern.';
  public readonly VALIDATION_CONST_REF = 'constRef';

  private readonly saveCallback: (globalConstant: GlobalConstantBase) => Observable<void>;
  public form: UntypedFormGroup;

  public readonly header: string = '';
  public readonly isExisting: boolean = false;

  public readonly nameControl: AbstractControl;
  public readonly valueControl: AbstractControl;
  public readonly descriptionControl: AbstractControl;

  constructor(
      @Inject(MAT_DIALOG_DATA) public payload: EditGlobalConstantDialogPayload,
      private dialogRef: MatDialogRef<EditGlobalConstantDialogComponent>,
      private dirtyGuard: DirtyFormGuardConnectorService,
      private cdRef: ChangeDetectorRef,
      private destroyer: DestroyRef,
      fb: UntypedFormBuilder,
  ) {
    const maxLength4k = Validators.maxLength(this.MAX_LENGTH);
    const maxLength150 = Validators.maxLength(this.DESC_MAX_LENGTH - 1);
    this.form = fb.group({
      [NAME]: [null, {
        validators: [Validators.required, maxLength4k, Validators.pattern(FormHelper.VALIDATOR_PATTERN_STANDARD_CHARSET)],
        updateOn: 'change'
      }],
      [VALUE]: [null, {validators: [Validators.required, maxLength4k, this.validateForConstRef], updateOn: 'change'}],
      [DESCRIPTION]: [null, {validators: [maxLength150], updateOn: 'change'}],
    });
    this.nameControl = this.form.controls[NAME];
    this.valueControl = this.form.controls[VALUE];
    this.descriptionControl = this.form.controls[DESCRIPTION];

    this.dirtyGuard.connectForm(this.form);

    if (payload.globalConstant) {
      this.header = 'Edit global constant';
      this.isExisting = true;

      const gc = payload.globalConstant;
      this.form.setValue({name: gc.name, value: gc.value, description: gc.description});
      this.form.get(NAME)?.disable();
    } else {
      this.header = 'Create global constant';
    }
    this.saveCallback = payload.saveCallback;
  }

  ngOnInit(): void {
    this.valueControl.valueChanges.pipe(takeUntilDestroyed(this.destroyer)).subscribe(() => this.cdRef.detectChanges());
  }

  ngOnDestroy(): void {
    this.dirtyGuard.disconnect();
  }

  public save() {
    const updated: GlobalConstantBase = {
      name: this.form.get(NAME)?.value || '',
      value: this.form.get(VALUE)?.value || '',
      description: this.form.get(DESCRIPTION)?.value || '',
    };
    this.saveCallback(updated).pipe(first()).subscribe();
  }

  public closeWithoutSaving() {
    this.dialogRef.close();
  }

  shouldShowNameValidationMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.nameControl, errorKey);
  }

  shouldShowNameInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.nameControl);
  }

  shouldShowValueValidationMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.valueControl, errorKey);
  }

  shouldShowValueInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.valueControl);
  }

  shouldShowDescriptionValidationMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.descriptionControl, errorKey);
  }

  shouldShowDescriptionInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.descriptionControl);
  }

  private validateForConstRef(control: AbstractControl): ValidationErrors | null {
    const value: unknown = control.value;
    if (typeof value !== 'string') {
      return null;
    }
    if (value.includes('${const://') || value.includes('${g-const://')) {
      return {constRef: true};
    }
    return null;
  }

}
