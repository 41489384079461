import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';

@Component({
  selector: 'adm4-inventory-has-errors',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="step-content">
            <h2 class='step-content-header'>Selected inventory has errors</h2>
            <p>
              Deployment cannot continue due to the errors on the selected inventory.
              Please <a (click)='navigateToInventory()'>go to the inventory</a>, fix it and try to deploy again.
            </p>
            <div *ngIf='inventoryValidationStatus'>
              <p>Error details:</p>
              <adm4-inventory-validation-tooltip-content
                      [inventoryValidationStatus]='inventoryValidationStatus'></adm4-inventory-validation-tooltip-content>
            </div>
          </div>
        </div>
      </div>
      <div class='step-action-bar'>
        <button matStepperPrevious class='previous-step-button'
                (click)='onBackFromInventoryHasErrors()'>
          Change selection
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryHasErrorsComponent {
  @Input() inventoryValidationStatus: ValidationStatus<InventoryValidationIssue>;
  @Input() inventoryKey: string;
  @Output() backFromInventoryHasErrors: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToInventoryClick: EventEmitter<string> = new EventEmitter();

  onBackFromInventoryHasErrors(): void {
    this.backFromInventoryHasErrors.emit();
  }

  navigateToInventory(): void {
    this.navigateToInventoryClick.emit(this.inventoryKey);
  }
}
