import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Project, ProjectMeta } from '../../projects/project.model';
import { ProjectHelper } from '../../projects/project.helper';
import { ConnectProjectVersionControlDialogService } from '../../projects/connect-project-version-control/connect-project-version-control-dialog.service';
import * as _ from 'lodash';
import { PublishProjectChangesetHelper } from '../../projects/publish-project/publish-project-changeset.helper';

@Component({
  selector: 'adm4-project-information',
  template: `
    <div class="section-title">
      <span>Project information</span>
      <span ngbTooltip="You don't have permission to delete this project." [disableTooltip]="hasProjectModifyAccess" placement="left">
        <button type="button" class="admn4-button-ellipse-red" [class.cursor-disabled]="!hasProjectModifyAccess"
                [disabled]="!hasProjectModifyAccess" (click)="deleteProject.emit()"
        >Delete project</button>
      </span>
    </div>
    <div class='project-information'>
      <table class='settings-info-table'>
        <tr>
          <td id="project-info-key">
            <div class="key">Project key</div>
            <div><a [routerLink]="['/projects', project.projectKey]">{{project.projectKey}}</a></div>
          </td>
          <td id="project-info-repo" *ngIf="project.repository">
            <div class="key">Repository</div>
            <span>{{ project.repository }}</span>
          </td>
          <td id="project-info-path" *ngIf='project.path'>
            <div class="key">Directory in repository</div>
            <span>{{ project.path }}</span>
          </td>
        </tr>
        <tr>
          <td id="project-info-tenant">
            <div class="key">Tenant key</div>
            <span>{{ project.tenantKey }}</span>
          </td>
          <td id="project-info-branch" *ngIf="project.branch">
            <div class="key">Branch</div>
            <span>{{ project.branch }}</span>
          </td>
          <td id="project-info-commit-id" *ngIf='commitId'>
            <div class='key'>Commit</div>
            <span [ngbTooltip]='projectPublishStatus' [disableTooltip]="!projectPublishStatus" class='commit-info'>
              <span>{{ commitId }}</span>
              <em *ngIf='projectHasChanges' class="fa fa-info-circle help-icon" aria-hidden="true"></em>
            </span>
          </td>
        </tr>
      </table>
      <div class='project-actions' *ngIf='!isVersionedProject'>
        <span ngbTooltip="You don't have permission to change Git settings on this project." [disableTooltip]="hasProjectModifyAccess" placement="left">
          <button type="button" class="admn4-button-ellipse-blue" [class.cursor-disabled]="!hasProjectModifyAccess"
                  [disabled]="!hasProjectModifyAccess" (click)="openConnectProjectToVersionControlDialog()">
            <span class="menu-item-text">Connect to Git</span>
          </button>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/settings-info-table.scss', './project-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectInformationComponent {

  @Input() project: Project;
  @Input() projectMeta: ProjectMeta | null;
  @Input() hasProjectModifyAccess: boolean = false;

  @Output() deleteProject: EventEmitter<void> = new EventEmitter();

  constructor(private connectProjectToVersionControlDialogService: ConnectProjectVersionControlDialogService) {}


  openConnectProjectToVersionControlDialog(): void {
    this.connectProjectToVersionControlDialogService.openConnectProjectToVersionControlDialog(this.project);
  }

  get isVersionedProject(): boolean {
    return ProjectHelper.isVersionedProject(this.project);
  }

  get projectHasChanges(): boolean {
    return !_.isNil(this.projectMeta) && PublishProjectChangesetHelper.hasLocalChanges(this.projectMeta);
  }

  get projectPublishStatus(): string {
    if (_.isNil(this.projectMeta)) {
      return '';
    } else if (this.projectHasChanges) {
      return 'There are local changes pending to be published on this project';
    } else {
      return 'There are no local changes pending to be published on this project';
    }
  }

  get commitId(): string | undefined {
    return _.isNil(this.projectMeta) ? this.project.commitId : this.projectMeta.localHead;
  }
}
