import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HierarchyTreeNode } from '../pattern-hierarchy-tree.model';

@Component({
  selector: 'adm4-pattern-hierarchy-tree-item-detail',
  template: `
  <div class='pattern-hierarchy-tree-item-detail'>
      <div class="node-property-type text-ellipsis">{{node.pattern.parentPropertyKey}}</div>
      <div class='text-ellipsis node-property-name'>
          <a (click)='navigateToNode()'>{{node.pattern.pattern.name}}</a>
          <mat-icon svgIcon='loop' class='mat-icon' *ngIf='node.pattern.loop' [ngbTooltip]='loopInfo' placement='left'></mat-icon>
          <mat-icon svgIcon='shared_pattern' class='mat-icon' *ngIf='node.isSharedPattern' [ngbTooltip]='usageInfo' placement='left'></mat-icon>
          <mat-icon class='mat-icon warning' *ngIf='node.pattern.aborted' [ngbTooltip]='abortedInfo' placement='top-right'>warning</mat-icon>
      </div>
      <ng-template #abortedInfo>Incomplete view<div>The further steps of the flow cannot be displayed due to reached limit.</div>
          <span>You may open <a class='fullscreen-link' [href]='node.graphViewLink' target='_blank'>
            <span>Full graph</span>
          </a></span>
      </ng-template>
      <ng-template #usageInfo>Also used in:
          <ul>
              <li *ngFor='let pattern of node.sharedPatternReferenceList'>
                  <a (click)='navigateToPatternById.emit(pattern.pattern.patternId)'>{{pattern.pattern.name}}</a>
              </li>
          </ul>
      </ng-template>
  </div>
  <ng-template #loopInfo>There is a loop in the flow starting from this step.</ng-template>
  `,
  styleUrls: ['./pattern-hierarchy-tree-item-detail.component.scss'],
})
export class PatternHierarchyTreeItemDetailComponent {

  @Input()
  public node: HierarchyTreeNode;

  @Output()
  public readonly navigateToPatternById: EventEmitter<string> = new EventEmitter();

  @Output()
  public readonly navigateTreeNode: EventEmitter<HierarchyTreeNode> = new EventEmitter();

  public navigateToNode() {
    this.navigateTreeNode.emit(this.node);
  }
}
