import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { loadMarketPlaceItems, marketplaceItemsLoaded } from './marketplace.actions';
import { MarketplaceApiService } from './marketplace-api.service';
import { AppState } from '../../model/reducer';
import { selectedTenantKeyView } from '../../model/views';
import { filterEmpty } from '../../common/utils/utils';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class MarketplaceEffects {

  loadMarketPlaceItems$ = createEffect(() =>
      this.actions$.pipe(
          ofType(loadMarketPlaceItems),
          switchMap(() => this.store$.select(selectedTenantKeyView).pipe(filter(filterEmpty), take(1))),
          switchMap((selectedTenantKey: string) => this.marketplaceApi.getTenantScopedMarketplaceItems(selectedTenantKey)),
          catchError((error) => {
            console.error(`MarketplaceEffects#loadMarketPlaceItems$, error when loading the project templates`);
            this.modals.openHttpErrorDialog(error, 'Unexpected error when loading the project templates');
            return EMPTY;
          }),
          map((items) => marketplaceItemsLoaded({items})),
      )
  );

  constructor(
      private readonly actions$: Actions,
      private readonly marketplaceApi: MarketplaceApiService,
      private readonly store$: Store<AppState>,
      private readonly modals: ModalNotificationService,
  ) {}
}
