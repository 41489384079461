import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { first, map, mapTo, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import * as _ from 'lodash';

import { IssueModel } from '../../common/model/issue.model';
import { AppState, Dictionary, ProjectKey } from '../../model/reducer';
import { PatternType, Property } from '../../plugins/pattern-type.model';
import { Pattern } from '../../patterns/pattern.model';
import { PatternService } from '../../patterns/pattern.service';
import {
  allProjectsView,
  issuesView,
  patternsDiffView,
  patternsView,
  patternTypesView,
  projectKeyView,
  projectMetaView,
  variableListView,
} from '../../model/views';
import { Project, ProjectMeta, PublishProjectInput } from '../project.model';
import { ProjectService } from '../project.service';
import { VariableModel } from '../../variables/variable.model';
import {
  PatternDiffData,
  ViewAttachmentFileDiffContent,
  ViewAttachmentFileDiffEvent,
} from './pattern-diff-view/pattern-diff-data.model';
import { LoadDiffData, RequestPublishProject } from '../../model/publish-project/publish-project.actions';
import { NavigationService } from '../../navbar/navigation.service';
import { IssueSeverityCount } from '../project-issues-display.model';
import { ProjectIssuesDisplayHelper } from '../project-issues-display.helper';
import { filterForPatternIssues, getGeneralIssues } from '../project-issues/project-issues.helper';
import { extractSyntaxFromFileName } from '../../file-utils';
import { LocalStatus } from '../../version-control/meta-info.model';
import { PublishProjectPayload } from './publish-project-payload.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { NotificationMessage } from '../../notification/notification.model';

/**
 * Collects snapshot data relevant for the publishing, snapshot at the moment of the opening of the publish dialog.<br/>
 * Only uses the store as the source.
 */
@Injectable()
export class PublishProjectContext implements OnDestroy {
  /**
   * Snapshots are collecting already preloaded data at the moment of opening publish, which means that data displayed in publish project would not be affected by background updates
   * It is needed so to have publish project modal only contain data which was recent only at the moment of opening modal window and when another user does a change in the meantime
   * it would not refresh parts of publish changes modal
   */

  private _projectKeySnapshot$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _currentProjectSnapshot$: BehaviorSubject<Project | null> = new BehaviorSubject(null);
  private _projectMetaSnapshot$: BehaviorSubject<ProjectMeta | null> = new BehaviorSubject(null);
  private _patternsSnapshot$: BehaviorSubject<Map<string, Pattern>> = new BehaviorSubject(new Map());
  private _patternTypesSnapshot$: BehaviorSubject<Dictionary<PatternType> | null> = new BehaviorSubject(null);
  private _issuesSnapshot$: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);
  private _variablesSnapshot$: BehaviorSubject<VariableModel[]> = new BehaviorSubject([]);
  private _diffAttachmentResource$: BehaviorSubject<ViewAttachmentFileDiffEvent | null> = new BehaviorSubject(null);

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  projectKey$: Observable<ProjectKey | null> = this._projectKeySnapshot$.asObservable();
  currentProject$: Observable<Project | null> = this._currentProjectSnapshot$.asObservable();
  projectMeta$: Observable<ProjectMeta | null> = this._projectMetaSnapshot$.asObservable();
  patterns$: Observable<Map<string, Pattern>> = this._patternsSnapshot$.asObservable();
  patternTypes$: Observable<Dictionary<PatternType> | null> = this._patternTypesSnapshot$.asObservable();
  issues$: Observable<IssueModel[]> = this._issuesSnapshot$.asObservable();
  variables$: Observable<VariableModel[]> = this._variablesSnapshot$.asObservable();
  currentBranch$: Observable<string | null>;

  readonly isDiffMode$: Observable<boolean> = this._diffAttachmentResource$.asObservable().pipe(
    map((res: ViewAttachmentFileDiffEvent | null) => !!res),
  );
  readonly diffContent$: Observable<ViewAttachmentFileDiffContent | null>;

  patternsDiff$: Observable<PatternDiffData[]>;

  generalIssues$: Observable<IssueModel[]>;
  patternIssueNumberBySeverity$: Observable<IssueSeverityCount>;

  constructor(
      private store$: Store<AppState>,
      private navigation: NavigationService,
      private patternApi: PatternService,
      private projectApi: ProjectService,
      private modalNotificationService: ModalNotificationService,
  ) {
    this.store$.pipe(select(projectKeyView), first((projectKey: string | null) => !_.isNil(projectKey)), takeUntil(this.destroyed$))
      .subscribe((projectKey: string) => this._projectKeySnapshot$.next(projectKey));

    forkJoin([this.store$.pipe(select(allProjectsView), first()), this.projectKey$.pipe(first())]).pipe(
      map(([projects, projectKey]: [Dictionary<Project>, string]) => projects[projectKey] || null),
      takeUntil(this.destroyed$)
    ).subscribe((project: Project | null) => this._currentProjectSnapshot$.next(project));

    this.store$.pipe(select(projectMetaView), first((projectMeta: ProjectMeta | null) => !_.isNil(projectMeta)), takeUntil(this.destroyed$))
      .subscribe((projectMeta: ProjectMeta) => this._projectMetaSnapshot$.next(projectMeta));

    this.store$.pipe(select(patternsView), first(), takeUntil(this.destroyed$))
      .subscribe((patterns: Map<string, Pattern>) => this._patternsSnapshot$.next(patterns));

    this.store$.pipe(select(patternTypesView), first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes)))
      .subscribe((patternTypes: Dictionary<PatternType>) => this._patternTypesSnapshot$.next(patternTypes));

    this.store$.pipe(select(issuesView), first())
      .subscribe((issues: IssueModel[]) => this._issuesSnapshot$.next(issues));

    this.store$.pipe(select(variableListView), first())
      .subscribe((variables: VariableModel[]) => this._variablesSnapshot$.next(variables));

    this.currentBranch$ = this.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.branch || null));

    this.patternsDiff$ = this.store$.pipe(select(patternsDiffView));

    this.generalIssues$ = this.issues$.pipe(
        map((allIssues: IssueModel[]): IssueModel[] => getGeneralIssues(allIssues)),
    );
    this.patternIssueNumberBySeverity$ = this.issues$.pipe(
        map((issues: IssueModel[]) => filterForPatternIssues(issues)),
        map(ProjectIssuesDisplayHelper.issuesToCountBySeverity),
    );
    this.diffContent$ = combineLatest([
      this._diffAttachmentResource$.asObservable(),
      this.currentProject$.pipe(map((p: Project | null) => p ? p.projectKey : null)),
      this.store$.select(patternsView),
      this.patternTypes$,
    ]).pipe(
      switchMap(this.createDiffContent.bind(this)),
    );
  }

  private createDiffContent(
    [diffViewEvent, projectKey, patterns, patternTypes]:
      [ViewAttachmentFileDiffEvent | null, string | null, Map<string, Pattern>, Record<string, PatternType>],
  ): Observable<ViewAttachmentFileDiffContent | null>  {
    if (!diffViewEvent || projectKey === null || patterns.size < 1) {
      return of(null);
    }

    const pattern: Pattern | undefined = patterns.get(diffViewEvent.patternId);
    if (!pattern) {
      console.trace(`PublishProjectContext#createDiffContent, pattern NOT FOUND`, diffViewEvent.patternId);
      return of(null);
    }
    const patternLabel = pattern.name;

    const patternType: PatternType | undefined = patternTypes[pattern.className];
    if (!patternType) {
      console.trace(`PublishProjectContext#createDiffContent, patternType NOT FOUND`, pattern.className);
      return of(null);
    }
    const property: Property | undefined = patternType.properties?.find((p: Property) => p.propertyKey === diffViewEvent.propertyKey);
    if (!property) {
      console.trace(`PublishProjectContext#createDiffContent, property NOT FOUND`, diffViewEvent.propertyKey);
      return of(null);
    }
    const propertyLabel = property.name;

    const fileName = diffViewEvent.fileResource.resourceName;
    const contentSyntax = extractSyntaxFromFileName(fileName);

    const loadLocalContent: Observable<string> = (diffViewEvent.localStatus === LocalStatus.Added || diffViewEvent.localStatus === LocalStatus.Modified)
      ? this.patternApi.getResourceContent(projectKey, diffViewEvent.patternId, diffViewEvent.propertyKey, diffViewEvent.fileResource.resourceName).pipe(
        first(),
        mergeMap((blob: Blob) => blob.text()),
      )
      : of('');
    const loadRemoteContent: Observable<string> = (diffViewEvent.localStatus === LocalStatus.Deleted || diffViewEvent.localStatus === LocalStatus.Modified)
      ? this.patternApi.getHeadResourceContent(projectKey, diffViewEvent.patternId, diffViewEvent.propertyKey, diffViewEvent.fileResource.resourceName).pipe(
          first(),
          mergeMap((blob: Blob) => blob.text()),
        )
      : of('');
    return combineLatest([
      loadLocalContent,
      loadRemoteContent,
    ]).pipe(
      map(([localContent, remoteContent]: [string, string]): ViewAttachmentFileDiffContent => {
        return {localContent, remoteContent, contentSyntax, fileName, patternLabel, propertyLabel};
      }),
    );
}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  preloadDiffs(): void {
    this.store$.dispatch(new LoadDiffData());
  }

  /** Checks if the new tags already exist in the project,
   *  and asks the user to confirm if they want to overwrite them */
  private checkAndConfirmExistingTags(newTags: string[]): Observable<boolean> {
    if (newTags.length > 0) {
      return this.projectKey$.pipe(
        switchMap((projectKey: string): Observable<string[]> => this.projectApi.getAllTagsOfProject(projectKey)),
        switchMap((allTags: string[]): Observable<boolean> => {
          const conflictingTags: string[] = newTags.filter(newTag => allTags.includes(newTag));
          if (conflictingTags.length > 0) {
            let notification: NotificationMessage;
            if (conflictingTags.length === 1) {
              notification = {
                title: 'Tag already exists',
                description: `The tag <strong>${conflictingTags[0]}</strong> already exists. Do you want to overwrite it?`
              };
            } else {
              const tagsLabel: string = conflictingTags.map(tag => `<strong>${tag}</strong>`).join(', ');
              notification = {
                title: 'Tags already exist',
                description: `The tags ${tagsLabel} already exist. Do you want to overwrite them?`
              };
            }
            return this.modalNotificationService.openConfirmDialog(notification, {
              confirmButtonText: 'Overwrite tags',
              cancelButtonText: 'Cancel',
            }).afterClosed().pipe(map((confirmed?: boolean) => {
              return !!confirmed;
            }));
          } else {
            return of(true);
          }
        })
      );
    }
    return of(true);
  }

  private publishProjectChanges(publishProjectInput: PublishProjectInput): Observable<void> {
    return this.projectMeta$.pipe(
      first((projectMeta: ProjectMeta | null) => !_.isNil(projectMeta)),
      withLatestFrom(this.projectKey$, this.store$.pipe(select(allProjectsView))),
      takeUntil(this.destroyed$),
      tap(([projectMeta, projectKey, projects]: [ProjectMeta, string | null, Dictionary<Project>]): void => {
        const projectToPublish: Project | undefined = _.isNil(projectKey) ? undefined : projects[projectKey];
        if (_.isNil(projectToPublish)) {
          return;
        }
        const publishRequest: PublishProjectPayload = {
          projectMeta,
          commitMessage: publishProjectInput.commitMessage,
          project: projectToPublish,
          tags: publishProjectInput.tags,
        } as any;
        this.store$.dispatch(new RequestPublishProject(publishRequest));
      }),
      mapTo(undefined),
    );
  }

  /**
   * Requests the publishing of the project changes,
   * after checking whether there will be data overwritten and confirming that with the user.
   */
  requestPublishProjectChanges(publishProjectInput: PublishProjectInput): Observable<boolean> {
    return this.checkAndConfirmExistingTags(publishProjectInput.tags).pipe(
      switchMap((confirmed: boolean): Observable<boolean> => {
        if (confirmed) {
          return this.publishProjectChanges(publishProjectInput).pipe(mapTo(true));
        } else {
          return of(false);
        }
      }),
    );
  }

  public navigateToProjectIssues() {
    this.projectKey$.pipe(first()).subscribe((projectKey: string) => this.navigation.navigateToIssues(projectKey));
  }

  public toggleDiffAttachmentResource(item: ViewAttachmentFileDiffEvent | null): void {
    this._diffAttachmentResource$.next(item);
  }
}
