import { Component, EventEmitter, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DeploymentFileViewService } from '@deployment-common/deployment-file-view/deployment-file-view.service';
import { FileService } from '@common/services/file/file.service';
import { CommandModel, FileModel, OutputType } from '../../planning-output.model';
import { OutputHelper } from '../../../../output.helper';
import { PathHelper } from '@common/helpers/path.helper';
import { FileDownloader } from '@common/helpers/file-downloader';
import { ItemDetailsComponent } from '../item-details.component';
import { DeploymentFileInfo } from '@deployment-common/deployment-file-view/deployment-file-urls.model';

@Component({
  selector: 'adm4-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['../../item-details.scss']
})
export class FileDetailsComponent extends ItemDetailsComponent<FileModel> implements OnChanges {
  @Output() changeSelectedDetail: EventEmitter<string> = new EventEmitter<string>();
  readonly OutputType = OutputType;
  viewingFileView: DeploymentFileInfo;
  selectedIndex = 0;

  constructor(
    private fileViewer: DeploymentFileViewService,
    private fileService: FileService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.node) {
      this.viewingFileView = {
        path: this.node.details.path,
        localFileUrl: <string>(this.node.details.fileContent || this.node.details.localFile),
        remoteFileUrl: <string>this.node.details.remoteFile,
        remoteRequired: this.outputType === OutputType.PLAN
      };
    }
  }

  // NOTE: if these accessors are used in template their code is executed on every change detection cycle
  // though for OnPush components it should be fine
  get onChangeCommands(): CommandModel[] {
    return this.node.details.onChange ?
      OutputHelper.getCommandsByIds(this.node.output, this.node.details.onChange) : [];
  }

  navigateToCommand(commandId: string): void {
    this.changeSelectedDetail.emit(commandId);
  }

  viewFile(): void {
    this.fileViewer.viewFile({
      path: this.node.details.path,
      localFileUrl: <string>(this.node.details.fileContent || this.node.details.localFile),
      remoteFileUrl: <string>this.node.details.remoteFile,
      remoteRequired: this.outputType === OutputType.PLAN
    });
  }

  downloadFile(): void {
    this.fileService.loadFile(<string>(this.node.details.fileContent || this.node.details.localFile))
      .subscribe((blob: Blob) => {
        const fileName = PathHelper.getArtifactNameFromPath(this.node.details.path);
        FileDownloader.downloadFile(blob, fileName);
      });
  }

}
