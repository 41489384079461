import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable()
export class CustomSvgIconService {
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
  }

  registerIcons(): void {
    this.matIconRegistry.addSvgIcon('create_project', this.toSafeResourceUrl('assets/createProject.svg'));
    this.matIconRegistry.addSvgIcon('create_project-inv', this.toSafeResourceUrl('assets/createProjectInv.svg'));
    this.matIconRegistry.addSvgIcon('create_inventory', this.toSafeResourceUrl('assets/createInventory.svg'));
    this.matIconRegistry.addSvgIcon('create_inventory-inv', this.toSafeResourceUrl('assets/createInventoryInv.svg'));
    this.matIconRegistry.addSvgIcon('create_pattern', this.toSafeResourceUrl('assets/heroButtonExport64.svg'));
    this.matIconRegistry.addSvgIcon('import_from_zip_blk', this.toSafeResourceUrl('assets/importFromZipBlk.svg'));
    this.matIconRegistry.addSvgIcon('import_from_zip_inv', this.toSafeResourceUrl('assets/importFromZipInv.svg'));
    this.matIconRegistry.addSvgIcon('project_settings_blk', this.toSafeResourceUrl('assets/projectSettingsBlk.svg'));
    this.matIconRegistry.addSvgIcon('project_settings_green', this.toSafeResourceUrl('assets/projectSettingsGreen.svg'));
    this.matIconRegistry.addSvgIcon('project_settings_inverse', this.toSafeResourceUrl('assets/projectSettingsInverse.svg'));
    this.matIconRegistry.addSvgIcon('inventory_settings', this.toSafeResourceUrl('assets/inventorySettings.svg'));
    this.matIconRegistry.addSvgIcon('export_to_zip', this.toSafeResourceUrl('assets/exportToZipBlk.svg'));
    this.matIconRegistry.addSvgIcon('export_to_zip-inv', this.toSafeResourceUrl('assets/exportToZipInv.svg'));
    this.matIconRegistry.addSvgIcon('secondary_route', this.toSafeResourceUrl('assets/alt_route.svg'));
    this.matIconRegistry.addSvgIcon('canary_active', this.toSafeResourceUrl('assets/canary_active.svg'));
    this.matIconRegistry.addSvgIcon('canary_rolled_back', this.toSafeResourceUrl('assets/canary_rolled_back.svg'));
    this.matIconRegistry.addSvgIcon('canary_outdated', this.toSafeResourceUrl('assets/canary_outdated.svg'));
    this.matIconRegistry.addSvgIcon('canary_promoted', this.toSafeResourceUrl('assets/canary_promoted.svg'));
    // google fonts, material symbols, `delete`, rounded, fill: 0, weight: 700, grade: 0, optical size: 20px
    this.matIconRegistry.addSvgIcon('delete', this.toSafeResourceUrl('assets/delete.svg'));
    this.matIconRegistry.addSvgIcon('delete_forever', this.toSafeResourceUrl('assets/delete_forever.svg'));
    this.matIconRegistry.addSvgIcon('shared_pattern', this.toSafeResourceUrl('assets/shared-pattern.svg'));
    this.matIconRegistry.addSvgIcon('loop', this.toSafeResourceUrl('assets/loop.svg'));
    this.matIconRegistry.addSvgIcon('manage_history', this.toSafeResourceUrl('assets/manage_history_black_24dp.svg'));

    // google fonts, material symbols, data object, rounded, fill: 0, weight: 700, grade: 0, optical size: 20px
    this.matIconRegistry.addSvgIcon('global_constants', this.toSafeResourceUrl('assets/global_constants.svg'));

    // google fonts, material symbols, data object, rounded, fill: 0, weight: 700, grade: 0, optical size: 48px
    this.matIconRegistry.addSvgIcon('widgets', this.toSafeResourceUrl('assets/widgets.svg'));

    // google fonts, material symbols, Note Add, outlined, Fill: 0, Weight: 400, Grade: 0, Optical Size: 48
    this.matIconRegistry.addSvgIcon('note_add', this.toSafeResourceUrl('assets/note_add.svg'));
    // google fonts, material symbols, Analytics, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('analytics_inline', this.toSafeResourceUrl('assets/analytics_inline.svg'));
    // google fonts, material symbols, Logout, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('logout_inline', this.toSafeResourceUrl('assets/logout_inline.svg'));
    // google fonts, material symbols, Key, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('key_inline', this.toSafeResourceUrl('assets/key_inline.svg'));
    // google fonts, material symbols, Person, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('person-outline_inline', this.toSafeResourceUrl('assets/person-outline_inline.svg'));
    // google fonts, material symbols, Group, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('group-outline_inline', this.toSafeResourceUrl('assets/group-outline_inline.svg'));
    // google fonts, material symbols, Description, 16dp, Weight: 300, Grade: -25, Optical Size: 20
    this.matIconRegistry.addSvgIcon('description-outline_inline', this.toSafeResourceUrl('assets/description-outline_inline.svg'));

    // https://boxicons.com/ `cloud-upload`
    this.matIconRegistry.addSvgIcon('cloud_up', this.toSafeResourceUrl('assets/cloud_up.svg'));
    // https://boxicons.com/ `add-to-queue`, 48px, modified with fill="currentColor"
    this.matIconRegistry.addSvgIcon('add-to-queue', this.toSafeResourceUrl('assets/add-to-queue.svg'));

    this.matIconRegistry.addSvgIcon('file-lock', this.toSafeResourceUrl('assets/file-lock.svg'));
  }

  private toSafeResourceUrl(url: string): SafeResourceUrl {
    // using for local resource URLs only
    // eslint-disable-next-line ban/ban
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
