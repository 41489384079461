import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from '../actions';
import { VariableService } from '../../variables/variable.service';
import { CreateVariable, CreateVariableSuccess, DeleteVariable, DeleteVariableSuccess, LoadVariables, LoadVariablesSuccess, RenameVariable, UpdateAndRenameVariable, UpdateVariables, UpdateVariablesSuccess, VariableActionTypes } from './variable.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducer';
import { requireNonNull } from '../../common/utils/utils';
import { VariableModel } from '../../variables/variable.model';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { NavigationService } from '../../navbar/navigation.service';
import * as _ from 'lodash';
import { projectKeyView } from '../views';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { EffectWithErrorHandlingMixin, IEffectWithErrorHandlingMixin } from '../effect-with-error-handling.mixin';
import { NotificationMessage } from '../../notification/notification.model';
import { HttpErrorResponse } from '@angular/common/http';
import { RenameVariablePayload, UpdateAndRenameVariablePayload, UpdateVariablesPayload } from './update-variables-payload.model';

@Injectable()
@Mixin([EffectWithErrorHandlingMixin])
export class VariableEffects implements IEffectWithErrorHandlingMixin {
   loadVariables: Observable<LoadVariablesSuccess | EmptyAction>;
   updatedVariables: Observable<UpdateVariablesSuccess | EmptyAction>;
   renameVariable: Observable<LoadVariables | EmptyAction>;
   createVariable: Observable<CreateVariableSuccess | EmptyAction>;
   updateAndRenameVariable: Observable<LoadVariables | UpdateVariablesSuccess | EmptyAction>;
   deleteVariable: Observable<DeleteVariableSuccess | EmptyAction>;
   deleteVariableSuccess: Observable<any>;

  private readonly UPDATE_SUCCESS_TOAST_TITLE = 'Successfully updated';

  /**
   * Implemented by EffectWithErrorHandlingMixin
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;

  constructor(private variablesService: VariableService,
              private actions$: Actions<NevisAdminAction<any>>,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              private toastNotificationService: ToastNotificationService,
              public modalNotificationService: ModalNotificationService) {
    this.loadVariables = createEffect(() => this.actions$
      .pipe(
        ofType(VariableActionTypes.LoadVariables),
        map((action: LoadVariables) => action.payload),
        switchMap((projectKey: string) => {
          return this.variablesService.getAllVariablesOfProject(projectKey).pipe(
            map((variables: VariableModel[]) => new LoadVariablesSuccess(variables)),
            catchError((error) => {
              const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
              return of(this.handleErrorAction<EmptyAction>(error, {title: 'Failed to load variables', description: `Error while loading variables for project ${projectName}`}));
            }));
        })
      ));

    this.updatedVariables = createEffect(() => this.actions$
      .pipe(ofType(VariableActionTypes.UpdateVariables))
      .pipe(
        map((action: UpdateVariables) => action.payload),
        switchMap((payload: UpdateVariablesPayload) => {
          return this.variablesService.upsertVariables(payload.projectKey, payload.variables)
            .pipe(
              map((vars: VariableModel[]) => {
                this.toastNotificationService.showSuccessToast(`Variables have been successfully updated`, this.UPDATE_SUCCESS_TOAST_TITLE);
                return new UpdateVariablesSuccess(vars);
              }),
              catchError((error) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong during updating variables'})))
            );
        })
      ));

    this.deleteVariable = createEffect(() => this.actions$
      .pipe(ofType(VariableActionTypes.DeleteVariable))
      .pipe(
        map((action: DeleteVariable) => action.payload),
        switchMap((payload: UpdateVariablesPayload) => {
          return this.variablesService.upsertVariables(payload.projectKey, payload.variables)
            .pipe(
              map((vars: VariableModel[]) => {
                this.toastNotificationService.showSuccessToast(`Variable has been successfully deleted`, 'Successfully deleted');
                return new DeleteVariableSuccess(vars);
              }),
              catchError((error) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong during deleting variable'})))
            );
        })
      ));

    this.deleteVariableSuccess = createEffect(() => this.actions$
      .pipe(
        ofType(VariableActionTypes.DeleteVariableSuccess),
        map((action: DeleteVariableSuccess) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        tap(([variables, projectKey]: [any, string]) => {
          if (!_.isEmpty(variables)) {
            this.navigationService.navigateToVariable(projectKey, variables[0].variableKey);
          } else {
            this.navigationService.navigateToProjectSummary(projectKey);
          }
        }),
        mapTo(undefined),
      ), {dispatch: false});


    this.renameVariable = createEffect(() => this.actions$
      .pipe(ofType(VariableActionTypes.RenameVariable))
      .pipe(
        map((action: RenameVariable) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        switchMap(([payload, projectKey]: [RenameVariablePayload, string]) => {
          return this.variablesService.renameVariable(requireNonNull(projectKey), payload.variableKey, payload.newVariableKey).pipe(
            map((variable: VariableModel) => {
              this.toastNotificationService.showSuccessToast(`Variable <b>${variable.variableKey}</b> has been successfully updated`, this.UPDATE_SUCCESS_TOAST_TITLE);
              // check if it has to be handled in the success method
              this.navigationService.navigateToVariable(projectKey, variable.variableKey);
              return new LoadVariables(requireNonNull(projectKey));
            }),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong when renaming variable'})))
          );
        })
      ));

    this.createVariable = createEffect(() => this.actions$
      .pipe(ofType(VariableActionTypes.CreateVariable))
      .pipe(
        map((action: CreateVariable) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        switchMap(([variables, projectKey]) => {
          return this.variablesService.upsertVariables(requireNonNull(projectKey), variables).pipe(
            map((resultVariables: VariableModel[]) => {
              this.toastNotificationService.showSuccessToast(`Variable has been successfully created`, 'Successfully created');
              return new CreateVariableSuccess(resultVariables);
            }),
            catchError((error) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong when creating variable'})))
          );
        })
      ));

    this.updateAndRenameVariable = createEffect(() => this.actions$.pipe(ofType(VariableActionTypes.UpdateAndRenameVariable))
      .pipe(
        map((action: UpdateAndRenameVariable) => action.payload),
        switchMap((payload: UpdateAndRenameVariablePayload) => {
          return this.variablesService.upsertVariables(payload.projectKey, payload.variables).pipe(
            mergeMap((vars: VariableModel[]) => {
              return this.variablesService.renameVariable(requireNonNull(payload.projectKey), payload.updatedVariable.variableKey, payload.newVariableKey)
                .pipe(
                  switchMap((variable: VariableModel) => {
                    this.toastNotificationService.showSuccessToast(`Variables have been successfully updated`, this.UPDATE_SUCCESS_TOAST_TITLE);
                    this.navigationService.navigateToVariable(payload.projectKey, variable.variableKey);
                    return of(
                      new LoadVariables(requireNonNull(payload.projectKey)),
                      new UpdateVariablesSuccess(vars)
                    );
                  }),
                  catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong when renaming variable'})))
                );
            }),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong when updating the variable'})))
          );
        })
      ));
  }
}
