import { createReducer, on } from '@ngrx/store';

import { AppState } from '../model/reducer';
import { NevisAdminAction } from '../model/actions';
import {
  deleteBackgroundDeploymentSuccessful,
  loadBackgroundDeploymentStatusDetailsSuccess,
  loadBackgroundDeploymentSuccess,
  selectBackgroundDeployment,
} from './background-deployment.actions';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { DeploymentProcessState } from '@deployment-common/deployment-process.model';

export const BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY = 'backgroundDeployment';

interface BackgroundDeploymentStateBase {
  deploymentStatus: DeploymentStatusModel | null;
}

interface EmptyBackgroundDeploymentState extends BackgroundDeploymentStateBase {
  selectedDeploymentID: null;
}

interface SelectedBackgroundDeploymentState extends BackgroundDeploymentStateBase {
  selectedDeploymentID: string;
}

export interface LoadedBackgroundDeploymentState extends BackgroundDeploymentStateBase {
  selectedDeploymentID: string;
  state: DeploymentProcessState;
  projectKey: string;
  inventoryKey: string;
  hostExpression: string;
}

export type BackgroundDeploymentState = EmptyBackgroundDeploymentState | SelectedBackgroundDeploymentState | LoadedBackgroundDeploymentState;

export const isBackgroundDeploymentStateLoaded = (state: BackgroundDeploymentState): state is LoadedBackgroundDeploymentState => !!(state as LoadedBackgroundDeploymentState).state;

export const initialState: EmptyBackgroundDeploymentState = {
  selectedDeploymentID: null,
  deploymentStatus: null,
};

export interface BackgroundDeploymentAppState extends AppState {
  [BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY]: BackgroundDeploymentState;
}

export const backgroundDeploymentReducer = createReducer<BackgroundDeploymentState, NevisAdminAction>(
  initialState,
  on(selectBackgroundDeployment, (_state: BackgroundDeploymentState, action): SelectedBackgroundDeploymentState => {
    // the selection resets the state
    return {selectedDeploymentID: action.deploymentID, deploymentStatus: null};
  }),
  on(loadBackgroundDeploymentSuccess, (_state: BackgroundDeploymentState , action): LoadedBackgroundDeploymentState => {
    // the loaded data overrides the state
    const loaded = action.deployment;
    return {
      selectedDeploymentID: loaded.deploymentId,
      state: loaded.state,
      projectKey: loaded.projectKey,
      inventoryKey: loaded.inventoryKey,
      hostExpression: loaded.hostExpression,
      deploymentStatus: null,
    };
  }),
  on(loadBackgroundDeploymentStatusDetailsSuccess, (state, action): BackgroundDeploymentState => {
    if (isBackgroundDeploymentStateLoaded(state)) {
      return {
        ...state,
        deploymentStatus: action.deploymentStatus,
      };
    } else {
      console.error(`backgroundDeploymentReducer: unexpected loadBackgroundDeploymentStatusDetailsSuccess action when the deployment is not loaded yet`, {state, action});
      return state;
    }
  }),
  on(deleteBackgroundDeploymentSuccessful, (): EmptyBackgroundDeploymentState => ({...initialState})),
);
