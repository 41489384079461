import * as _ from 'lodash';

import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { Status } from '@deployment-common/validating/deployment-status.model';
import { CommandModel, DirectoryModel, FileModel, PlanningOutputModel } from './deployment-preview/planning-output.model';
import { InventorySchemaType } from '../../inventory/inventory.model';
import { InventorySchemaTypeHelper } from '../deployment-selection/inventory-list/inventory-schema-type.helper';

export class DeploymentPreviewHelper {
  static isPlanningInProgress(planningStatus: DeploymentStatusModel | undefined): boolean {
    return !_.isNil(planningStatus) && planningStatus.status !== Status.Done;
  }

  static isPlanningEntirelyFailed(planningStatus: DeploymentStatusModel | undefined): boolean {
    return !_.isNil(planningStatus) && planningStatus.items.every(item => item.status === Status.Failed);
  }

  /**
   * Checks if the planning output has tasks in any of its property (commands, directories, files)
   * @param {PlanningOutputModel[]} planningOutputs
   * @returns {boolean}
   */
  static hasPlanningOutputTasks(planningOutputs: PlanningOutputModel[]): boolean {
    return _.reduce(planningOutputs, (result: boolean, planningOutput: PlanningOutputModel) => {
      return result ||
        this.hasPropertiesTasks(planningOutput.commands) ||
        this.hasPropertiesTasks(planningOutput.directories) ||
        this.hasPropertiesTasks(planningOutput.files);
    }, false);
  }

  static allPlanningOutputItemsHaveError(planningOuput: PlanningOutputModel[]): boolean {
    return planningOuput.every((output: PlanningOutputModel) => !_.isNil(output.error));
  }

  /**
   * It checks whether any planning output in the list has error
   * @param {PlanningOutputModel[]} planningOutputs
   * @returns {boolean}
   */
  static hasPlanningOutputError(planningOutputs: PlanningOutputModel[]): boolean {
    return !!_.find(planningOutputs, (output: PlanningOutputModel) => !!output.error);
  }

  /**
   * It checks whether any of the properties have tasks
   * @param {T[]} properties
   * @returns {boolean}
   */
  private static hasPropertiesTasks<T extends CommandModel | DirectoryModel | FileModel>(properties: T[]): boolean {
    const propertyWithTasks = _.find(properties, (property: T) => property.tasks.length > 0);
    return !!propertyWithTasks;
  }

  static canRepeatPlanning(planOutput: PlanningOutputModel[], isPlanningInProgress: boolean, redeploymentForced: boolean): boolean {
    return (!isPlanningInProgress && !_.isEmpty(planOutput) && this.hasPlanningOutputTasks(planOutput)) || redeploymentForced;
  }

  static shouldShowValidating(planOutput: PlanningOutputModel[], isPlanningInProgress: boolean, redeploymentForced: boolean): boolean {
    const allItemsFailed = this.allPlanningOutputItemsHaveError(planOutput);
    const hasTasks = this.hasPlanningOutputTasks(planOutput);
    return isPlanningInProgress || allItemsFailed || (!hasTasks && !redeploymentForced);
  }

  static shouldShowNoDeploymentNeeded(planOutput: PlanningOutputModel[]): boolean {
    const hasTasks = this.hasPlanningOutputTasks(planOutput);
    return !_.isEmpty(planOutput) && !hasTasks;
  }

  static canDeploy(planOutput: PlanningOutputModel[], redeploymentForced: boolean): boolean {
    const allItemsFailed = this.allPlanningOutputItemsHaveError(planOutput);
    const hasTasks = this.hasPlanningOutputTasks(planOutput);
    return !_.isEmpty(planOutput) && !allItemsFailed && (hasTasks || redeploymentForced);
  }

  static canGoBack(planOutput: PlanningOutputModel[], redeploymentForced: boolean): boolean {
    const hasErrors = this.hasPlanningOutputError(planOutput);
    const hasTasks = this.hasPlanningOutputTasks(planOutput);
    return hasTasks || hasErrors || redeploymentForced;
  }

  static canShowForceRedeploymentButton(planOutput: PlanningOutputModel[], isInProgress: boolean, redeploymentForced: boolean): boolean {
    const hasTasks = this.hasPlanningOutputTasks(planOutput);
    return !isInProgress && hasTasks && !redeploymentForced;
  }

  /**
   * Returns the given kubernetesText if the inventorySchemaType is Kubernetes. Otherwise returns the given classicalText.
   *
   * @param inventorySchemaType The schemaType of the inventory used for deployment.
   * @param kubernetesText The text to return if the inventorySchemaType is Kubernetes.
   * @param classicalText The text to return if the inventorySchemaType is classical.
   */
  static getTextBasedOnSchemaType(inventorySchemaType: InventorySchemaType, kubernetesText: string, classicalText: string): string {
    if (InventorySchemaTypeHelper.isKubernetesDeployment(inventorySchemaType)) {
      return kubernetesText;
    } else {
      return classicalText;
    }
  }
}
