import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { deleteBundle, loadBundles, loadBundlesSuccess, uploadBundles } from './bundle-management.actions';
import { BundleService } from '../bundle-management/bundle.service';
import { ParsedBundle } from '../bundle-management/bundle.model';
import { FileUploadResultHelper } from '../../common/helpers/file-upload-result.helper';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { FileUploadResultsDialogService } from '../../common/components/file-upload-results-dialog/file-upload-results-dialog.service';
import { FileUploadResult, FileUploadStatus } from '../../common/model/file-upload-result.model';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { loadMarketPlaceItems } from '../marketplace/marketplace.actions';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Injectable()
export class BundleManagementEffects {
    loadBundles = createEffect(() => this.actions$.pipe(
      ofType(loadBundles),
      switchMap(() => this.bundleService.getAllBundles().pipe(
        map((bundles) => loadBundlesSuccess({bundles})),
        catchError((err) => {
          console.error(err);
          this.modals.openHttpErrorDialog(err, 'Could not load libraries');
          return EMPTY;
        })
      ))
    ));

    deleteBundles = createEffect(() => this.actions$.pipe(
      ofType(deleteBundle),
      switchMap((action) => {
        const bundle: ParsedBundle = action.bundle;
        return this.bundleService.deleteBundle(bundle).pipe(
          tap(() => this.toastNotificationService.showSuccessToast(`Library <strong>${bundle.symbolicName}</strong> (version <strong>${bundle.version}</strong>) has been successfully deleted.`)),
          switchMap(() => of(loadBundles())),
          catchError(error => {
            this.modals.openHttpErrorDialog(error, 'Could not delete library');
            return EMPTY;
          }),
        );
      }),
    ));

   uploadBundles = createEffect(() => this.actions$.pipe(
    ofType(uploadBundles),
    map((action) => action.files),
    switchMap((files: File[]) => {
      const combineProgressText = (loadedBundles: number) => `${loadedBundles} of ${files.length} libraries have been uploaded`;
      const progressText = new Subject<string>();
      let loadedBundlesAmount = 0;
      this.loadingService.showLoading({title: 'Uploading new libraries', description: '', progressText: progressText.asObservable()});
      return forkJoin(files.map(file => this.bundleService.uploadBundle(file).pipe(
        tap(() => {
          loadedBundlesAmount++;
          progressText.next(combineProgressText(loadedBundlesAmount));
        }),
        map(() => FileUploadResultHelper.createSuccessFileUploadResult(file)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(FileUploadResultHelper.createFailFileUploadResult(file, err, ''));
        })
      ))).pipe(
        finalize(() => this.loadingService.hideLoading()),
        tap((fileUploadResults: FileUploadResult[]) => {
          const failedResults = fileUploadResults.filter(res => res.status === FileUploadStatus.Fail);
          if (_.isEmpty(failedResults)) {
            this.toastNotificationService.showSuccessToast('All bundles have been successfully uploaded');
          } else {
            this.fileUploadResultsDialogService.openFileUploadResultsDialog(fileUploadResults, {
              headerText: 'Failure during library upload',
              failureGroupText: 'The following libraries could not be uploaded',
              successGroupText: 'Successfully uploaded libraries'
            });
          }
        }),
        concatMap(() => of(loadBundles(), loadMarketPlaceItems())),
      );
    }),
  ));

  constructor(private actions$: Actions,
              private bundleService: BundleService,
              private loadingService: LoadingService,
              private fileUploadResultsDialogService: FileUploadResultsDialogService,
              private toastNotificationService: ToastNotificationService,
              private modals: ModalNotificationService,
  ) {}
}
