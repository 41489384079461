import * as _ from 'lodash';
import {
  inventoryResourceReferencePrefix, inventorySecretResourceReferencePrefix,
  k8sSecretFileReferencePrefix, k8sSecretReferencePrefix, resourceReferencePrefix,
  secretReferencePrefix,
} from '../../../common/constants/reference-prefix.constants';
import { requireNonNull } from '../../../common/utils/utils';
import { InventoryResource, ResourceWrapper, SecretResourceWrapper, SecretWrapper, TenantResourceFlag } from '../../inventory.model';
import { InventoryResourceTypeHelper } from '../../../resources/secret-management/inventory-resource-type.helper';

import { editor, IDisposable, IMarkdownString, IRange, languages, Position, Range } from 'monaco-editor';
import ITextModel = editor.ITextModel;
import Hover = languages.Hover;
import TrackedRangeStickiness = editor.TrackedRangeStickiness;
import IIdentifiedSingleEditOperation = editor.IIdentifiedSingleEditOperation;
import HoverProvider = languages.HoverProvider;

// eslint-disable-next-line max-len
export const secretURIRegExp = new RegExp(`${secretReferencePrefix}[a-fA-F0-9]{24}|${inventorySecretResourceReferencePrefix}[a-fA-F0-9]{24}|${inventoryResourceReferencePrefix}[a-fA-F0-9]{32}|${resourceReferencePrefix}[a-fA-F0-9]{32}|${k8sSecretReferencePrefix}[a-zA-Z0-9-.]+:[a-zA-Z0-9-.]+/|${k8sSecretFileReferencePrefix}[a-zA-Z0-9-.]+:[a-zA-Z0-9-.]+/`);
export const secretNameSeparator = '#';

export class SecretCreationHelper {

  static getVariableFromCursorLine(lineContent: string): string[] {
    const regexpKeyValue = /\s*(.*)\s*:\s+(\S*)\s*/;
    const keyValueArray = lineContent.match(regexpKeyValue);
    return keyValueArray || [];
  }

  /**
   * returns the secret form the line with the resourceName (available only for files and secret files)
   * @param lineContent One line from codeMirror
   * @returns {string | undefined}
   */
  private static getSecretOfLine(lineContent: string): string | undefined {
    const match: RegExpMatchArray | null = lineContent.match(secretURIRegExp);
    return _.isNil(match) ? undefined : match[0];
  }

  private static cutOutSecretIdFromSecretLine(secretLine: string): string {
    const sliceIndex = _.indexOf(secretLine, secretNameSeparator);
    return _.isEqual(sliceIndex, -1) ? secretLine : secretLine.slice(0, sliceIndex);
  }

  /**
   * Returns a Range for each secret found in the text
   */
  private static getRangeArrayOfSecretUris(text: string): Range[] {
    return _.split(text, '\n').reduce((acc: Range[], lineContent: string, lineIndex: number) => {
      const foundSecret = SecretCreationHelper.getSecretOfLine(lineContent);
      if (foundSecret) {
        const secretRange = this.getRangeOfstring(lineContent, foundSecret, lineIndex);
        return _.concat(acc, secretRange);
      }
      return acc;
    }, []);
  }

  private static getRangeOfstring(lineContent: string, foundSecret: string, lineIndex: number) {
    const startColumn = lineContent.indexOf(foundSecret);
    const endColumn = startColumn + foundSecret.length;
    return new Range(
        lineIndex + 1,
        startColumn + 1,
        lineIndex + 1,
        endColumn + 1
    );
  }

  public static highLightSecretURIs(editorForHighlight: editor.ICodeEditor) {
    const model = requireNonNull<ITextModel>(editorForHighlight.getModel(), 'editor.getModel()');
    const rangeArrayOfSecretUris: Range[] = SecretCreationHelper.getRangeArrayOfSecretUris(model.getValue());

    editorForHighlight.deltaDecorations([], rangeArrayOfSecretUris.map(rangeOfSecretUri => {
      return {range: rangeOfSecretUri, options: {inlineClassName: 'secretHighlightColor', stickiness: TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges}};
    }));
  }

  public static registerTooltips(inventoryResources: (InventoryResource & TenantResourceFlag)[]): IDisposable {
    // `languages.registerHoverProvider` didn't work with the typing, hence the `any`
    // `languages` might be `undefined` if monaco was not used yet
    if (!((window as any).monaco?.languages)) {
      console.trace('SecretCreationHelper#registerTooltips: monaco not found');
    }
    return (window as any).monaco?.languages?.registerHoverProvider('yaml', SecretCreationHelper.createTooltipProvider(inventoryResources));
  }

  public static createTooltipProvider = (inventoryResources: (InventoryResource & TenantResourceFlag)[]): HoverProvider => {
    return {
      provideHover: (model: ITextModel, position: Position): languages.ProviderResult<languages.Hover> => {
        if (_.isNil(model.getValue())) {
          return;
        }
        const editorContentLines: string[] = model.getValue().split('\n');
        if (position.lineNumber > editorContentLines.length) {
          return;
        }
        const line = editorContentLines[position.lineNumber - 1];
        const foundInventoryResourceLine = SecretCreationHelper.getSecretOfLine(line);

        if (_.isNil(foundInventoryResourceLine)) {
          return;
        }

        const foundInventoryResourceObj = inventoryResources.find(value => {
          const idFromWrapper = InventoryResourceTypeHelper.getTypeSpecificId(value as SecretWrapper | SecretResourceWrapper | ResourceWrapper);
          return idFromWrapper === SecretCreationHelper.cutOutSecretIdFromSecretLine(foundInventoryResourceLine);
        });

        const range: IRange = new Range(position.lineNumber, position.column + 3, position.lineNumber, position.column + 10);
        if (!foundInventoryResourceObj) {
          return {
            range: range,
            contents: [
              {value: `${foundInventoryResourceLine}`},
              {value: `This resource cannot be found. It either has a typo, or have been removed from the Global settings page`}
            ]
          };
        }

        const description: string = foundInventoryResourceObj.description ? foundInventoryResourceObj.description : '&lt;no description&gt;';
        const contents: IMarkdownString[] = [
          // 2 line breaks before the separator, otherwise it becomes an underline, which makes the first line a header in GH markdown
          {value: `${foundInventoryResourceLine}\n\n----\n${description}`},
        ];
        return <Hover>{range, contents};
      }
    };
  };

  /**
   * Operation  what to insert the secretUri to a line
   * @param rangeToInsertSecret
   * @param secret
   * @returns {monaco.editor.IIdentifiedSingleEditOperation}
   */
  public static getEditOperation(rangeToInsertSecret: Range, secret: string): IIdentifiedSingleEditOperation {
    return {
      range: rangeToInsertSecret,
      text: secret,
      forceMoveMarkers: true
    };
  }

}
