import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../model/reducer';
import { ResourcesState } from './index';

const resourcesFeatureSelector: MemoizedSelector<AppState, ResourcesState> = createFeatureSelector('resources');

export const bundlesView = createSelector(
  resourcesFeatureSelector,
  (state: ResourcesState) => state.bundleManagement.bundles,
);

