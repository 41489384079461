import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { NavigationService } from '../../navbar/navigation.service';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { PublishProjectContext } from './publish-project.context';
import { PropertyWidgetContext } from '../../property-widgets/property-widget.context';
import { ProjectChangesetItem } from './project-changeset-item.model';
import { PublishProjectInput } from '../project.model';
import { MetaInfo } from '../../version-control/meta-info.model';
import { PublishProjectChangesetContext } from './publish-project-changeset.context';
import { PublishProjectPropertyContext } from './publish-project-property.context';
import { PublishDialogComponent } from '../../common/model/publish-changes/publish-dialog-component.model';
import { ViewAttachmentFileDiffContent } from './pattern-diff-view/pattern-diff-data.model';

@Component({
  selector: 'adm4-publish-project-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title full-height-flex'
                             [header]='publishModalName'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <adm4-publish-project class='full-height'
                            [projectKey]='projectKey'
                            [changesetItems]='changesetItems$ | async'
                            [initialCommitMessage]='initialCommitMessage$ | async'
                            (publish)='triggerPublishProject($event)'
                            (cancel)='closeDialog()'
                            (isDirty)='onDirtyChanged($event)'></adm4-publish-project>
      <adm4-publish-file-diff class="full-height" *ngIf="diffMode$ | async"
          [diffContent]="diffContent$ | async"
          (close)="closeFileDiff()"
      ></adm4-publish-file-diff>
    </adm4-modal-dialog-title>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PublishProjectContext,
    PublishProjectChangesetContext,
    {provide: PropertyWidgetContext, useClass: PublishProjectPropertyContext}
  ]
})
export class PublishProjectDialogComponent implements PublishDialogComponent, OnDestroy {

  publishModalName: string;
  isDirty = false;
  /** Needed for deactivation guard to find out that dialog can be closed regardless of dirty state */
  explicitExit = false;

  changesetItems$: Observable<ProjectChangesetItem<MetaInfo, any>[]>;
  initialCommitMessage$: Observable<string>;

  public diffContent$: Observable<ViewAttachmentFileDiffContent | null>;
  public diffMode$: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public projectKey: string,
              private navigationService: NavigationService,
              private publishProjectChangesetContext: PublishProjectChangesetContext,
              private publishProjectContext: PublishProjectContext,
  ) {
    this.publishModalName = 'Publish changes of project ' + TenantHelper.cropTenantFromKey(this.projectKey);
    this.changesetItems$ = this.publishProjectChangesetContext.changesetItems$;
    this.initialCommitMessage$ = this.publishProjectChangesetContext.initialCommitMessage$;
    this.diffContent$ = this.publishProjectContext.diffContent$;
    this.diffMode$ = this.publishProjectContext.isDiffMode$;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onDirtyChanged(messageChanged: boolean): void {
    this.isDirty = messageChanged;
  }

  triggerPublishProject(event: PublishProjectInput): void {
    this.publishProjectContext.requestPublishProjectChanges(event).pipe(first()).subscribe(confirmed => {
      if (confirmed) {
        this.closeDialogAfterPublishing();
      }
    });
  }

  public closeFileDiff() {
    this.publishProjectContext.toggleDiffAttachmentResource(null);
  }

  closeDialogAfterPublishing(): void {
    /** Signalling that we want to exit the dialog, so the guard won't ask for confirmation */
    this.explicitExit = true;
    this.navigationService.navigateAwayFromModalWindow();
  }

  closeDialog(): void {
    this.diffMode$.pipe(first()).subscribe((diffMode: boolean) => {
      if (diffMode) {
        this.closeFileDiff();
      } else {
        this.navigationService.navigateAwayFromModalWindow();
      }
    });
  }
}
