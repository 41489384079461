import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { ResourcesRoutingModule } from './resources-routing.module';
import { InventoryContext } from '../inventory/inventory.context';
import { ResourcesComponent } from './resources.component';
import { CommonModules } from '../common/common.module';
import { DialogTitleComponent } from '../common/dialog/dialog-title/dialog-title.component';
import { BundleManagementModule } from './bundle-management/bundle-management.module';
import * as fromBundleManagementState from './reducers';
import { BundleManagementEffects } from './reducers/bundle-management.effects';
import { SecretManagementModule } from './secret-management/secret-management.module';
import { CertificateManagementModule } from './certificates/certificate-management.module';
import { KubernetesCertificateManagementModule } from './kubernetes-certificates/kubernetes-certificate-management.module';
import { GlobalConstantsComponent } from './global-constants/global-constants.component';
import { GlobalConstantsTableComponent } from './global-constants/global-constants-table/global-constants-table.component';
import { EditGlobalConstantDialogComponent } from './global-constants/edit-global-constant-dialog/edit-global-constant-dialog.component';
import { ConstantUsageListComponent } from './global-constants/constant-usage-list/constant-usage-list.component';
import { ResourcesMenuComponent } from './resources-menu/resources-menu.component';

@NgModule({
  declarations: [
    ResourcesComponent,
    GlobalConstantsComponent,
    GlobalConstantsTableComponent,
    EditGlobalConstantDialogComponent,
    ConstantUsageListComponent,
    ResourcesMenuComponent,
  ],
  imports: [
    CommonModules,
    StoreModule.forFeature('resources', fromBundleManagementState.reducers),
    EffectsModule.forFeature([BundleManagementEffects]),
    ResourcesRoutingModule,
    BundleManagementModule,
    SecretManagementModule,
    CertificateManagementModule,
    KubernetesCertificateManagementModule,
    MatTableModule,
    MatSortModule,
    ClipboardModule,

    // standalone components,
    DialogTitleComponent,
  ],
  providers: [
    InventoryContext
  ]
})
export class ResourcesModule { }
