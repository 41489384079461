import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ImportProjectDialogService } from '../../projects/import-project/import-project-dialog.service';
import { CreateProjectDialogService } from '../../projects/create-project/create-project-dialog.service';
import { Project } from '../../projects/project.model';
import { FileBasedImportProjectHelper } from '../../projects/file-based-import-project/file-based-import-project.helper';
import { FileBasedImportProjectDialogService } from '../../projects/file-based-import-project/file-based-import-project-dialog.service';
import { NavigationService } from '../../navbar/navigation.service';

@Component({
  selector: 'adm4-welcome-content',
  template: `
    <div class='welcome-content-wrapper'>

      <div class='header-wrapper'>
        <h1>Welcome to nevisAdmin 4!</h1>
        <p class='sub-heading'>To get started, please create your first project.</p>
      </div>

      <div class='explanation'>
        <div>
          <p>Fire up your NEVIS environment by defining projects and inventories:</p>
          <ul>
            <li>In a project, you configure the NEVIS products and features that you want to use.</li>
            <li>Later on, in an inventory, you specify stage-specific host names and settings.</li>
          </ul>
        </div>
      </div>

      <div class='call-to-action'>
        <h3 class='header'>To start your first project, please choose:</h3>
        <div class='option-wrapper'>
          <div class='option-row'>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateProject' placement='top'>
              <mat-icon role='button' svgIcon='create_project' (click)='createProject()' [class.icon-disabled]='!canCreateProject'></mat-icon>
            </div>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateProject' placement='top'>
              <mat-icon role='link' svgIcon='widgets' (click)='gotoMarketPlace()' [class.icon-disabled]='!canCreateProject'></mat-icon>
            </div>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateProject' placement='top'>
              <mat-icon role='button' (click)='importProject()' [class.icon-disabled]='!canCreateProject'>save_alt_outline</mat-icon>
            </div>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canImportFromZip' placement='top'>
              <mat-icon role='button' svgIcon='import_from_zip_blk' (click)='importProjectFromZip()' [class.icon-disabled]='!canImportFromZip'></mat-icon>
            </div>
          </div>
          <div class='option-row'>
            <p>Create a new project</p>
            <p>View all project templates</p>
            <p>Import a project from a Git repository</p>
            <p>Import a project from a Zip file</p>
          </div>
          <div class='option-row' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateProject' placement='top'>
            <button class='admn4-button-ellipse-blue'
                    id='createProject'
                    (click)='createProject()'
                    type='button'
                    [disabled]='!canCreateProject'>
              Create project
            </button>
            <button class='admn4-button-ellipse-blue'
                    id='gotoMarketPlace'
                    (click)='gotoMarketPlace()' role='link'
                    type='button'
                    [disabled]='!canCreateProject'>
              Project templates
            </button>
            <button class='admn4-button-ellipse-blue'
                    id='importProject'
                    (click)='importProject()'
                    type='button'
                    [disabled]='!canCreateProject'>
              Import project from Git
            </button>
            <button class='admn4-button-ellipse-blue'
                    id='importProjectFromZip'
                    (click)='importProjectFromZip()'
                    type='button'
                    [disabled]='!canImportFromZip'>
              Import project from Zip
            </button>
          </div>
        </div>
      </div>

    </div>
  `,
  styleUrls: ['./welcome-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeContentComponent implements OnChanges {
  @Input() canCreateProject: boolean;
  @Input() projects: Project[];

  public disabledTooltipText = 'You don\'t have permission to create or import projects.';
  public canImportFromZip: boolean = false;

  constructor(private importProjectDialogService: ImportProjectDialogService,
              private createProjectDialogService: CreateProjectDialogService,
              private fileBasedImportProjectDialogService: FileBasedImportProjectDialogService,
              private nav: NavigationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canCreateProject || changes.projects) {
      this.canImportFromZip = FileBasedImportProjectHelper.canImportFromZip(this.canCreateProject, this.projects);
    }
  }

  importProject(): void {
    this.importProjectDialogService.openImportProjectDialog();
  }

  importProjectFromZip(): void {
    this.fileBasedImportProjectDialogService.openFileBasedImportProjectDialog();
  }

  createProject(): void {
    this.createProjectDialogService.openCreateProjectDialog();
  }

  gotoMarketPlace() {
    this.nav.navigateToMarketPlace();
  }
}
