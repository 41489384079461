import { createAction, props } from '@ngrx/store';

import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';

export enum BackgroundDeploymentActionTypes {
  SELECT = '[Background Deployment] Select Deployment',
  LOAD_SUCCESS = '[Background Deployment] Load Background Deployment Success',
  LOAD_STATUS_DETAILS_SUCCESS = '[Background Deployment] Load Background Deployment Status Details Success',
  EXIT = '[Background Deployment] Exit Deployment',
  DELETED = '[Background Deployment] Deleted',
}

export const selectBackgroundDeployment = createAction(
  BackgroundDeploymentActionTypes.SELECT,
  props<{ deploymentID: string }>(),
);

export const loadBackgroundDeploymentSuccess = createAction(
  BackgroundDeploymentActionTypes.LOAD_SUCCESS,
  props<{ deployment: DeploymentProcessModel }>(),
);

export const loadBackgroundDeploymentStatusDetailsSuccess = createAction(
  BackgroundDeploymentActionTypes.LOAD_STATUS_DETAILS_SUCCESS,
  props<{ deploymentStatus: DeploymentStatusModel }>(),
);

export const exitBackgroundDeployment = createAction(
  BackgroundDeploymentActionTypes.EXIT,
);

export const deleteBackgroundDeploymentSuccessful = createAction(
  BackgroundDeploymentActionTypes.DELETED,
);
