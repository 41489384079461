import { BehaviorSubject, interval, Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { ProjectService } from './project.service';
import { AppState } from '../model/reducer';
import { Action, select, Store } from '@ngrx/store';
import { projectIssueStateView, projectKeyView, projectMetaStateView } from '../model/views';
import { UpdateProjectIssueTimestamp, ProjectMetaTimestampChangedInBackground } from '../model/project';
import * as _ from 'lodash';

@Injectable()
export class ProjectSyncService {

  private readonly POLLING_INTERVAL_INITIAL = 2000;
  private pollMetaSubscription: Subscription;
  private pollIssueSubscription: Subscription;
  private projectKey$: Observable<string> = this.store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));

  constructor(public projectService: ProjectService,
              private store$: Store<AppState>,
              private zone: NgZone) {}

  startSync() {
    this.zone.runOutsideAngular(() => {
      this.pollMetaSubscription = this.pollService(
        (projectKey) => this.projectService.getProjectTimeStamp(projectKey),
        this.store$.pipe(select(projectMetaStateView)),
        (local: string, remote: string) => new ProjectMetaTimestampChangedInBackground({local, remote}),
        'project meta service',
      );
      this.pollIssueSubscription = this.pollService(
        (projectKey) => this.projectService.getIssueTimeStamp(projectKey),
        this.store$.pipe(select(projectIssueStateView)),
        (local: string, remote: string) => new UpdateProjectIssueTimestamp({local, remote}),
        'issues service',
      );
    });
  }

  stopSync() {
    if (this.pollMetaSubscription) {
      this.pollMetaSubscription.unsubscribe();
    }
    if (this.pollIssueSubscription) {
      this.pollIssueSubscription.unsubscribe();
    }
  }

  isSyncOn() {
    return this.pollMetaSubscription && this.pollIssueSubscription && !this.pollMetaSubscription.closed && !this.pollIssueSubscription.closed;
  }

  private pollService(
              functionToPoll: (projectKey: string) => Observable<{ timestamp: string }>,
              currentTimestamps: Observable<{ local: string, remote: string }>,
              actionToDispatch: (remoteTimestamp: string, localTimestamp: string) => Action,
              serviceName: string,
  ): Subscription {
    const timerSubject = new BehaviorSubject(this.POLLING_INTERVAL_INITIAL);
    let pollingInterval = this.POLLING_INTERVAL_INITIAL;
    return timerSubject.pipe(
      switchMap(i => interval(i)),
      withLatestFrom(this.projectKey$),
      filter(([_poll, projectKey]) => !!projectKey),
      mergeMap(([_poll, projectKey]) => {
        return functionToPoll(projectKey).pipe(
          catchError(e => {
            console.warn(`An error occurred when polling ${serviceName}`, e);
            this.stopSync();
            return observableOf({timestamp: ''});
          }));
      }),
      distinctUntilChanged(),
      withLatestFrom(currentTimestamps),
    ).subscribe(([serverTimestamp, currentTimestamp]) => {
      if (this.shouldUpdate(currentTimestamp, serverTimestamp)) {
        pollingInterval = pollingInterval * 2 ;
        this.zone.run(() => this.store$.dispatch(actionToDispatch(currentTimestamp.local, serverTimestamp.timestamp)));
      } else {
        pollingInterval = this.POLLING_INTERVAL_INITIAL;
      }
      timerSubject.next(pollingInterval);
    });
  }

  private shouldUpdate(currentTimestamp: { local: string; remote: string }, serverTimestamp: { timestamp: string }) {
    const isServerDataNewer = this.isServerDataNewer(currentTimestamp, serverTimestamp);
    return !currentTimestamp.local || isServerDataNewer;
  }

  private isServerDataNewer(currentTimestamp: { local: string; remote: string }, serverTimestamp: { timestamp: string }) {
    const serverDataTime = serverTimestamp ? new Date(serverTimestamp.timestamp) : new Date();
    return !!currentTimestamp.local && serverDataTime > new Date(currentTimestamp.local);
  }
}
