import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DeploymentActivity } from '../../../common/services/deployment-activity-context.service';

@Component({
  selector: 'adm4-tabs-deployment-activity',
  template: `
      <p class="local-activity" *ngFor="let deployment of localJobActivities">
          <strong>You</strong> have started a background deployment
          of <strong>{{deployment.projectKey | cropTenantFromKey}}</strong>
          to <strong>{{deployment.inventoryKey | cropTenantFromKey}}</strong>.
      </p>
      @if (showSeparator) {
          <hr/>
      }
      <p class="history-activity" *ngFor="let deployment of historyActivities">
          User <strong>{{deployment.userKey}}</strong> has started a deployment of
          <ng-container *ngIf="!deployment.isProjectRedacted; else projectRedacted">
              <strong>{{deployment.projectKey  | cropTenantFromKey}}</strong>
          </ng-container>
          <ng-template #projectRedacted>
              an undisclosed project
          </ng-template>
          to
          <ng-container *ngIf="!deployment.isInventoryRedacted; else inventoryRedacted">
              <strong>{{deployment.inventoryKey | cropTenantFromKey}}</strong>
          </ng-container>
          <ng-template #inventoryRedacted>
              an undisclosed inventory
          </ng-template>
          .
      </p>
  `,
})
export class TabsDeploymentActivityComponent implements OnChanges {

  @Input() activities: DeploymentActivity[] | undefined;

  public localJobActivities: DeploymentActivity[] = [];
  public historyActivities: DeploymentActivity[] = [];
  public showSeparator: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activities) {
      this.localJobActivities = (this.activities??[]).filter((activity: DeploymentActivity): boolean => activity.localJob);
      this.historyActivities = (this.activities??[]).filter((activity: DeploymentActivity): boolean => !activity.localJob);
      this.showSeparator = this.localJobActivities.length > 0 && this.historyActivities.length > 0;
    }
  }

}
