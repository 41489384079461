import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AppState } from './model/reducer';
import { InitApplication } from './model/actions';
import { CustomSvgIconService } from './common/services/custom-svg-icon.service';
import { ApplicationTitleService } from './common/services/application-title/application-title.service';
import { UserService } from './user/login/user.service';
import { StartupChecksService } from './common/services/startup-checks.service';

@Component({
  selector: 'adm4-root',
  template: '<adm4-root-layout></adm4-root-layout>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private POLLING_INTERVAL = 60000;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private customSvgIconService: CustomSvgIconService,
    private appTitleService: ApplicationTitleService,
    private userService: UserService,

    // injecting the service to initialize it, as it is not directly used anywhere
    private _startupChecks: StartupChecksService,
  ) {
  }

  ngOnInit(): void {
    this.customSvgIconService.registerIcons();
    this.store$.dispatch(new InitApplication());
    this.appTitleService.startService(this.router);
    this.globalPolling();
  }

  private globalPolling(): void {
    timer(1, this.POLLING_INTERVAL).pipe(
      takeUntil(this.destroyed$),
      switchMap(() => this.userService.getUserData()))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
