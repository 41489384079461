import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { singularPlural } from '../project-summary.helper';
import { VariableModel } from '../../../variables/variable.model';
import { NavigationService } from '../../../navbar/navigation.service';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-project-summary-variables',
  template: `
    <adm4-project-summary-section [title]='"Variables"'
                                  [summary]='variableSummary'>
      <a *ngIf="displayVariablesLink" (click)="navigateToVariables()">Go to Variables...</a>
    </adm4-project-summary-section>
  `,
  styleUrls: ['../project-summary-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryVariablesComponent {
  @Input() currentProjectKey: string;
  @Input() variables: VariableModel[];

  constructor(private navigationService: NavigationService) { }

  navigateToVariables(): void {
    this.navigationService.navigateToVariables(this.currentProjectKey);
  }

  get variableSummary(): string {
    const variableCount = !!this.variables ? this.variables.length : 0;
    const what = singularPlural(variableCount, 'variable', 'variables');
    return `This project contains ${variableCount} ${what}.`;
  }

  get displayVariablesLink(): boolean {
    return !_.isEmpty(this.variables);
  }
}
