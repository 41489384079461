import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProjectBundle } from '../../../projects/project.model';
import * as _ from 'lodash';
import { STANDARD_BUNDLE_INFO, StandardBundleInfo, StandardBundleNames } from '../../../resources/bundle-management/bundle.model';
import { ProjectDependenciesHelper } from '../project-dependencies.helper';

@Component({
  selector: 'adm4-project-common-dependencies',
  template: `
    <div>
      <div *ngIf='isEditMode' class='standard-libraries-header'>
        <span class='standard-libraries-title'>Standard libraries</span>
        <mat-slide-toggle [ngModel]='isSimpleMode'
                          [disabled]='isSimpleModeDisabled'
                          [title]='simpleModeDisabledMessage'
                          [labelPosition]='"before"'
                          (ngModelChange)='toggleSimpleMode($event)'
        >
          Group common libraries
        </mat-slide-toggle>
      </div>
      <adm4-project-dependencies-table [isEditMode]='isEditMode'
                                       [projectBundles]='displayProjectBundles'
                                       [currentProjectBaseVersion]='currentProjectBaseVersion'
                                       (projectBundlesChange)='triggerProjectBundleChange($event)'
      ></adm4-project-dependencies-table>
      <div *ngIf='isEditMode' class='standard-libraries-footer'>
      <button type='button' class='admn4-button-ellipse-blue' (click)='updateAllToLatest()'>Update to latest</button>
        <span>
          To upload a library first go to <a (click)='navigateToPatternLibraryManagement.emit()'>Pattern library management</a>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./project-common-dependencies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCommonDependenciesComponent implements OnChanges {
  @Input() isEditMode: boolean;
  @Input() projectBundles: ProjectBundle[];
  @Input() currentProjectBaseVersion: string | undefined;
  @Output() projectBundlesChange: EventEmitter<ProjectBundle[]> = new EventEmitter();
  /**
   * Emitted when user intends to navigate to the Pattern Library Management.
   */
  @Output() navigateToPatternLibraryManagement: EventEmitter<ProjectBundle[]> = new EventEmitter();

  /**
   * May contain grouped fake bundle and tracks all changes
   */
  displayProjectBundles: ProjectBundle[] = [];
  /**
   * Contains only the real bundles and tracks all changes
   */
  updatedProjectBundles: ProjectBundle[] = [];
  isSimpleModeDisabled = true;
  isSimpleMode = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectBundles) {
      this.defineAutomaticSimpleMode();
      this.updatedProjectBundles = this.projectBundles;
      this.displayProjectBundles = this.getDisplayProjectBundlesBasedOnMode(this.projectBundles);
    }
  }

  private getCommonVersionsOfBundles(projectBundles: ProjectBundle[]): string[] {
    return _.isEmpty(projectBundles) ? [] : projectBundles[0].allVersions.filter(version => projectBundles.every(projectBundle => _.includes(projectBundle.allVersions, version)));
  }

  private getPossibleProjectCommonBundles(): (ProjectBundle | undefined)[] {
    return STANDARD_BUNDLE_INFO
      .filter(standardBundleInfo => standardBundleInfo.belongsToCommon)
      .map(standardBundleInfo => this.projectBundles.find(projectBundle => projectBundle.symbolicName === standardBundleInfo.symbolicName))
      .filter(value => value);
  }

  private defineAutomaticSimpleMode(): void {
    const projectCommonBundles: (ProjectBundle | undefined)[] = this.getPossibleProjectCommonBundles();
    const allCommonVersions: string[] = this.getCommonVersionsOfBundles(<ProjectBundle[]>projectCommonBundles);
    this.isSimpleModeDisabled = _.isEmpty(allCommonVersions);
    this.isSimpleMode = !this.isSimpleModeDisabled
      && ProjectDependenciesHelper.areAllBundlesActiveForProject(<ProjectBundle[]>projectCommonBundles)
      && ProjectDependenciesHelper.areAllBundlesOnSameVersion(<ProjectBundle[]>projectCommonBundles);
  }

  private getDisplayProjectBundlesBasedOnMode(projectBundles: ProjectBundle[]): ProjectBundle[] {
    const displayProjectBundles = this.isSimpleMode ? this.groupCommonBundles(projectBundles, STANDARD_BUNDLE_INFO) : projectBundles;
    return _.sortBy(displayProjectBundles, (projectBundle: ProjectBundle) => {
      const standardProjectBundleInfo: StandardBundleInfo | undefined = STANDARD_BUNDLE_INFO.find((standardBundleInfo => standardBundleInfo.symbolicName === projectBundle.symbolicName));
      return _.isNil(standardProjectBundleInfo) ? 0 : standardProjectBundleInfo.weight;
    });
  }

  /**
   * Call this method when simple mode is enabled
   * It returns list of project bundles after grouping bundles that belong to common into 1
   * @param projectBundles
   * @param standardBundleInfos
   */
  private groupCommonBundles(projectBundles: ProjectBundle[], standardBundleInfos: StandardBundleInfo[]): ProjectBundle[] {
    const commonBundles: ProjectBundle[] = this.getBundlesBelongingToCommon(projectBundles, standardBundleInfos);
    const commonVersions: string[] = this.getCommonVersionsOfBundles(commonBundles);
    const restOfStandardBundles = _.difference(projectBundles, commonBundles);
    const areCommonBundlesOnSameVersion = ProjectDependenciesHelper.areAllBundlesOnSameVersion(commonBundles);
    const commonBundle: ProjectBundle = {
      symbolicName: StandardBundleNames.Common,
      selectedVersion: areCommonBundlesOnSameVersion ? commonBundles[0].selectedVersion : commonVersions[0],
      latestVersion: commonVersions[0],
      allVersions: commonVersions,
      isMandatory: true,
      isActiveForProject: true,
      hint: `Contains the following libraries: ${commonBundles.map(bundle => bundle.symbolicName).join(', ')}`
    };
    return _.concat([], commonBundle, restOfStandardBundles);
  }

  private getBundlesBelongingToCommon(projectBundles: ProjectBundle[], standardBundleInfos: StandardBundleInfo[]): ProjectBundle[] {
    return projectBundles.filter(projectBundle => {
      return standardBundleInfos.filter(standardBundleInfo => standardBundleInfo.belongsToCommon).some(standardBundleInfo => standardBundleInfo.symbolicName === projectBundle.symbolicName);
    });
  }

  toggleSimpleMode(isSimpleMode: boolean): void {
    if (this.isSimpleModeDisabled) {
      return;
    }
    this.isSimpleMode = isSimpleMode;
    this.displayProjectBundles = this.getDisplayProjectBundlesBasedOnMode(this.updatedProjectBundles);
    this.triggerProjectBundleChange(this.displayProjectBundles);
  }

  updateAllToLatest(): void {
    const displayBundlesOnLatestVersions: ProjectBundle[] = ProjectDependenciesHelper.getAllBundlesToLatestVersion(this.displayProjectBundles);
    this.triggerProjectBundleChange(displayBundlesOnLatestVersions);
  }

  triggerProjectBundleChange(updatedProjectBundles: ProjectBundle[]): void {
    const commonBundle: ProjectBundle | undefined = updatedProjectBundles.find(projectBundle => projectBundle.symbolicName === StandardBundleNames.Common);
    if (_.isNil(commonBundle)) {
      this.updatedProjectBundles = updatedProjectBundles;
    } else {
      const commonBundles: ProjectBundle[] = this.getBundlesBelongingToCommon(this.updatedProjectBundles, STANDARD_BUNDLE_INFO);
      const updatedCommonBundles = commonBundles.map(projectBundle => {
        return {
          ...projectBundle,
          selectedVersion: commonBundle.selectedVersion,
          isActiveForProject: commonBundle.isActiveForProject
        };
      });
      const restOfBundles = updatedProjectBundles.filter(projectBundle => projectBundle.symbolicName !== StandardBundleNames.Common);
      this.updatedProjectBundles = _.concat(updatedCommonBundles, restOfBundles);
    }
    this.displayProjectBundles = this.getDisplayProjectBundlesBasedOnMode(this.updatedProjectBundles);
    this.projectBundlesChange.emit(this.updatedProjectBundles);
  }

  get simpleModeDisabledMessage(): string {
    return this.isSimpleModeDisabled ? 'Current common libraries cannot be grouped' : '';
  }

}
