import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';

import { debounceTime, distinctUntilChanged, first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { editor, IDisposable, Range, Selection } from 'monaco-editor';

import { InventoryService } from '../../inventory.service';
import { SecretCreationHelper } from './secret-creation.helper';
import { InsertSecretsModalComponent } from './insert-secrets-modal.component';
import { InsertSecretsModalPayload } from './insert-secret.payload';
import * as _ from 'lodash';
import { Maybe, requireNonNull } from '../../../common/utils/utils';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { HTTP_STATUS_INTERNAL_SERVER_ERROR } from '../../../shared/http-status-codes.constants';
import { ErrorHelper } from '../../../common/helpers/error.helper';
import { LoadInventoryResources, LoadTenantConstants } from '../../../model/inventory';
import { AppState } from '../../../model/reducer';
import {
  InsertGlobalConstantDialogComponent
} from '../insert-global-constant-dialog/insert-global-constant-dialog.component';
import { InventoryResource, TenantResourceFlag, wrapGlobalConstantRef } from '../../inventory.model';
import {
  diffInventoryKeyView,
  inventoryValidationStatusView,
  resourcesView,
  secretResourcesView,
  secretsView,
  selectedDiffInventoryContentView,
} from '../../../model/views';
import { FilePosition, parseFilePosition } from '../../../common/model/file-info.model';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { InventoryValidationIssue, ValidationStatus } from '../../../common/model/validation-status.model';
import { validationStatusToMonacoMarkers } from '../../../common/utils/monaco-utils';

const markerToKey = (marker: editor.IMarkerData): string => {
  return `${marker.code}-${marker.message}`;
};

const DEFAULT_ISSUE_KEY: string = 'issue.code.inventory.syntax.violation-Unable to parse YAML inventory.';

@Injectable()
export class InventoryEditorContextService implements OnDestroy {

  private destroyed$: Subject<boolean> = new Subject();
  private tooltipDisposable: IDisposable;
  private tooltipRefreshSub: Subscription;

  public diffInventoryKey$: Observable<Maybe<string>>;
  public diffInventoryContent$: Observable<Maybe<string>>;
  public editorPosition$: Observable<Maybe<FilePosition>>;

  public editorIssueMarkers$: Observable<editor.IMarkerData[]>;

  constructor(
      private inventoryService: InventoryService,
      private dialog: MatDialog,
      private modalNotificationService: ModalNotificationService,
      private store$: Store<AppState>,
      private activatedRoute: ActivatedRoute,
  ) {
    this.diffInventoryKey$ = store$.select(diffInventoryKeyView);
    this.diffInventoryContent$ = store$.select(selectedDiffInventoryContentView);
    this.editorPosition$ = this.activatedRoute.queryParams.pipe(
        map((queryParams: Params): Maybe<FilePosition> => {
          const positionParam: unknown = queryParams[NavigationConstants.PARAM_POSITION];
          if (!(positionParam && typeof positionParam === 'string')) {
            return undefined;
          }
          return parseFilePosition(positionParam);
        }),
        distinctUntilChanged(),
    );
    this.editorIssueMarkers$ = this.store$.select(inventoryValidationStatusView).pipe(
      map((validationStatus:  ValidationStatus<InventoryValidationIssue> | null): editor.IMarkerData[] => {
        if (!validationStatus) {
          return [];
        }
        const validationMarkers = validationStatusToMonacoMarkers(validationStatus);
        const markersByKey: Map<string, editor.IMarkerData> = new Map();
        validationMarkers.forEach(marker => markersByKey.set(markerToKey(marker), marker));
        if (markersByKey.size > 1) {
          markersByKey.delete(DEFAULT_ISSUE_KEY);
        }
        return Array.from(markersByKey.values());
      }),
      shareReplay(),
    );
    this.initTooltipRefresh();
  }

  ngOnDestroy(): void {
    this.tooltipDisposable?.dispose();
    this.tooltipRefreshSub?.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  private initTooltipRefresh() {
    this.tooltipRefreshSub = combineLatest([
          this.store$.select(secretsView),
          this.store$.select(secretResourcesView),
          this.store$.select(resourcesView),
        ]
    ).pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
        map(([a, b, c]: [(InventoryResource & TenantResourceFlag)[], (InventoryResource & TenantResourceFlag)[], (InventoryResource & TenantResourceFlag)[]]) => {
          return _.concat(a, b, c) as (InventoryResource & TenantResourceFlag)[];
        })
    ).subscribe(inventoryResourceViews => this.refreshTooltips(inventoryResourceViews));
  }

  private refreshTooltips(inventoryResourceViews: (InventoryResource & TenantResourceFlag)[]) {
    if (!_.isNil(this.tooltipDisposable)) {
      this.tooltipDisposable.dispose();
    }
    this.tooltipDisposable = SecretCreationHelper.registerTooltips(inventoryResourceViews);
  }

  /**
   * Opens a variable dialog, and calls its callback with the secret uri.<br/>
   * The `variableName` will be displayed in the dialog, to signal the user that the secret will be inserted to a specific variable
   * @param {string} variableName
   * @param onSaveCallback
   * @returns {string}
   */
  openInsertSecretDialog(
      variableName: string,
      onSaveCallback: (shouldCreateSecret: boolean, secretValue?: string, selectedSecretKey?: string, description?: string) => void,
  ): void {
    this.dialog.open<InsertSecretsModalComponent, InsertSecretsModalPayload>(InsertSecretsModalComponent, {
      data: {
        variableName: variableName,
        onSaveCallback: onSaveCallback
      },
      maxHeight: '50%',
      width: '30%',
      autoFocus: true
    });
  }

  public insertSecretToEditor(editorForSecret: editor.ICodeEditor, inventoryKey: string): void {
    const selection = editorForSecret.getSelection();
    const model = requireNonNull<editor.ITextModel>(editorForSecret.getModel(), 'editor.getModel()');
    if (_.isNil(selection)) {
      return;
    }
    const variable = SecretCreationHelper.getVariableFromCursorLine(model.getLineContent(selection.startLineNumber));
    const key = variable[1];
    this.openInsertSecretDialog(key, (shouldCreateSecret, valueToSecret, selectedSecretKey, description) => {
      if (!_.isNil(valueToSecret) && shouldCreateSecret) {
        this.inventoryService.createInventorySecret(inventoryKey, valueToSecret, description).subscribe(createdSecret => {
            this.loadInventoryResources(inventoryKey);
            this.insertTextToEditor(selection, model, editorForSecret, createdSecret);
          },
          (error: HttpErrorResponse) => {
            if (error.status === HTTP_STATUS_INTERNAL_SERVER_ERROR && ErrorHelper.responseErrorHasDetail(error)) {
              this.modalNotificationService.openErrorDialog({title: 'Unable to encrypt/decrypt your secrets', description: ErrorHelper.getErrorDetail(error, '')});
            }
          });
      } else {
        if (_.isNil(selectedSecretKey)) {
          return;
        }
        this.insertTextToEditor(selection, model, editorForSecret, selectedSecretKey);
      }
    });
  }

  public insertGlobalConstantToEditor(editorForGC: editor.ICodeEditor): void {
    const selection = editorForGC.getSelection();
    const model = requireNonNull<editor.ITextModel>(editorForGC.getModel(), 'editor.getModel()');
    if (_.isNil(selection)) {
      return;
    }
    const dialogRef = this.dialog.open<InsertGlobalConstantDialogComponent, void, string | undefined>(
        InsertGlobalConstantDialogComponent,
        {autoFocus: 'adm4-insert-global-constant-dialog mat-select'},
    );
    dialogRef.afterClosed().pipe(first()).subscribe((gcName: string | undefined) => {
      if (typeof gcName === 'string') {
        this.insertTextToEditor(selection, model, editorForGC, wrapGlobalConstantRef(gcName));
      }
    });
  }

  /* actualizes secret, secretFile, file and global constants in the store*/
  public loadInventoryResources(inventoryKey: string) {
    this.store$.dispatch(new LoadInventoryResources(inventoryKey));
    this.store$.dispatch(new LoadTenantConstants({usedIn: false}));
  }

  private insertTextToEditor(selection: Selection, model: editor.ITextModel, targetEditor: editor.ICodeEditor, textToInsert: string) {
    const currentLineNr = selection.startLineNumber;
    const selectionStartCol: number = selection.startColumn;
    // the target's start and end coordinates are the same, so the insertion does not override anything
    const targetPosition = new Range(currentLineNr, selectionStartCol, currentLineNr, selectionStartCol);
    targetEditor.executeEdits(model.getValue(), [SecretCreationHelper.getEditOperation(targetPosition, textToInsert)]);
  }
}
