import { editor, MarkerSeverity } from 'monaco-editor';
import { ValidationIssue, ValidationStatus } from '../model/validation-status.model';
import { Maybe } from './utils';
import ITextModel = editor.ITextModel;
import IEditorOptions = editor.IEditorOptions;

const baseTheme: editor.BuiltinTheme = 'vs';

interface Adm4MonacoEditor {
  defineTheme: typeof editor.defineTheme;
  setTheme: typeof editor.setTheme;
}

const getWindowMonaco = (): Adm4MonacoEditor => {
  return (window as any).monaco.editor as Adm4MonacoEditor;
};

/** Creates a reverse diff theme for the monaco editor, which overrides the default one,
    because for us the local-remote sides are reversed. */
const createMonacoReverseDiffTheme = () => {
  return {
    base: baseTheme,
    inherit: true,
    rules: [],
    colors: {
      'diffEditor.removedLineBackground': '#d4f6d4',
      'diffEditor.removedTextBackground': '#b2efb2',
      'diffEditor.insertedLineBackground': '#dfdfdf',
      'diffEditor.insertedTextBackground': '#c5c5c5',
      /* originals below, as captured from the DOM. They pairs are the same color actually, but use opacity,
      so placed above each other they achieve a stronger color. The hard coded values above achieve the same colors. */
      // 'diffEditor.removedLineBackground': 'rgba(40, 210, 40, 0.2)',
      // 'diffEditor.removedTextBackground': 'rgba(40, 210, 40, 0.2)',
      // 'diffEditor.insertedLineBackground': 'rgba(96, 96, 96, 0.2)',
      // 'diffEditor.insertedTextBackground': 'rgba(96, 96, 96, 0.2)',
    }
  };
};

/**
 * Applies a theme to the monaco editor, which overrides the default one in which the left is the remote side.
 */
export const applyMonacoReverseDiffTheme = () => {
  const globalEditor: Adm4MonacoEditor = getWindowMonaco();
  globalEditor.defineTheme('customDiffTheme', createMonacoReverseDiffTheme());
  globalEditor.setTheme('customDiffTheme');
};

const createMonacoDefaultTheme = () => {
  return {
    base: baseTheme,
    inherit: true,
    rules: [],
    colors: {
      'editor.lineHighlightBackground': '#deeef0',  // $nevis-green-ligtest-c4
    }
  };
};

/**
 * Applies the nevis accented default theme to the monaco editor.
 */
export const applyMonacoDefaultTheme = () => {
  const globalEditor: Adm4MonacoEditor = getWindowMonaco();
  globalEditor.defineTheme('nevisDefaultTheme', createMonacoDefaultTheme());
  globalEditor.setTheme('nevisDefaultTheme');
};

export const setMonacoMarkers = (model: ITextModel, owner: string, markers: editor.IMarkerData[]): void => {
  (window as any).monaco.editor.setModelMarkers(model, owner, markers);
};

const SOURCE_KEY_LINE_NR = 'LINE_NUMBER';
const SOURCE_KEY_COL_NR = 'COLUMN_NUMBER';
// this is a line length big enough to make the marker cover the whole line
const END_COL_WHOLE_LINE = 999;

const hasIssueLineNumber = (issue: ValidationIssue): boolean => {
  const firstSource: Record<string, string> | undefined = (issue?.sources ?? [])[0];
  if (!firstSource) {
    return false;
  }
  const rawLineNumber = firstSource[SOURCE_KEY_LINE_NR];
  return !isNaN(Number.parseInt(rawLineNumber));

};

const validationIssueToMonacoMarker = (issue: ValidationIssue, severity: MarkerSeverity): editor.IMarkerData => {
  if (hasIssueLineNumber(issue)) {
    const sources: Record<string, string>[] = issue.sources ?? [];
    const startLineNumber: number = Number.parseInt(sources[0][SOURCE_KEY_LINE_NR]);
    let startColumn: number = Number.parseInt(sources[0][SOURCE_KEY_COL_NR]);
    let endColumn: number;
    startColumn = isNaN(startColumn) ? 0 : startColumn;
    if (startColumn === 0) {
      endColumn = END_COL_WHOLE_LINE;
    } else {
      endColumn = startColumn + 1;
    }
    return {
      severity,
      message: issue.detail,
      code: issue.title,
      startLineNumber: startLineNumber + 1,
      endLineNumber: startLineNumber + 1,
      startColumn: startColumn + 1,
      endColumn: endColumn + 1,
    };
  }
  return {
    severity,
    message: issue.detail,
    code: issue.title,
    startLineNumber: 1,
    endLineNumber: 1,
    startColumn: 1,
    endColumn: END_COL_WHOLE_LINE,
  };
};

export const validationStatusToMonacoMarkers = (validationStatus: Maybe<ValidationStatus<ValidationIssue>>): editor.IMarkerData[] => {
  const errors: editor.IMarkerData[] = (validationStatus?._errors??[]).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Error));
  const warnings: editor.IMarkerData[] = (validationStatus?._warnings??[]).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Warning));
  const infos: editor.IMarkerData[] = (validationStatus?._infos??[]).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Info));
  return errors.concat(warnings, infos);
};

export const logViewerMonacoConfig: Readonly<IEditorOptions> = {
  readOnly: true,
  domReadOnly: true,
  minimap: {enabled: false},
  lineNumbers: 'on',
  scrollBeyondLastLine: false,
  smoothScrolling: true,
  automaticLayout: true,
  wordWrap: 'off',
};
