import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { InventorySettingsContext } from '../inventory-settings.context';
import { Inventory, InventoryMeta, InventorySchemaType } from '../../inventory.model';
import { TenantHelper } from '../../../common/helpers/tenant.helper';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { filter, first } from 'rxjs/operators';
import { InventoryContext } from '../../inventory.context';

@Component({
  selector: 'adm4-inventory-settings-details',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='details-container remaining-space-flex-content'>
          <adm4-inventory-information-container *ngIf='currentInventory$ | async'
                                                [inventory]='currentInventory$ | async'
                                                [inventoryContent]='currentInventoryContent$ | async'
                                                [inventoryMeta]='inventoryMeta$ | async'
                                                [hasInventoryModifyPermission]='hasInventoryModifyPermission$ | async'
                                                [inventorySchemaType]='currentInventorySchemaType$ | async'
                                                (delete)="deleteInventory()"
          ></adm4-inventory-information-container>
          <adm4-inventory-permissions></adm4-inventory-permissions>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../../common/styles/component-specific/settings-details.scss']
})
export class InventorySettingsDetailsComponent {
  inventoryKey$: Observable<string | null>;
  currentInventory$: Observable<Inventory | undefined>;
  currentInventoryContent$: Observable<string | null>;
  inventoryMeta$: Observable<InventoryMeta | null>;
  currentInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  inventories$: Observable<Inventory[]>;
  hasInventoryModifyPermission$: Observable<boolean>;

  constructor(
      private inventorySettingsContext: InventorySettingsContext,
      private inventoryContext: InventoryContext,
      private modalNotificationService: ModalNotificationService,
  ) {
    this.inventoryKey$ = this.inventorySettingsContext.inventoryKey$;
    this.currentInventory$ = this.inventorySettingsContext.currentInventory$;
    this.currentInventoryContent$ = this.inventorySettingsContext.currentInventoryContent$;
    this.inventoryMeta$ = this.inventorySettingsContext.inventoryMeta$;
    this.inventories$ = this.inventorySettingsContext.inventories$;
    this.currentInventorySchemaType$ = this.inventorySettingsContext.currentInventorySchemaType$;
    this.hasInventoryModifyPermission$ = this.inventorySettingsContext.hasInventoryModifyPermission$;
  }

  deleteInventory() {
    this.inventoryKey$.pipe(
        filter((inventoryKey: string | null) => !!inventoryKey),
        first()
    ).subscribe((inventoryKey: string) => {
      const inventoryName = TenantHelper.cropTenantFromKey(inventoryKey);
      this.modalNotificationService.openConfirmDialog({
        headerTitle: `Warning`,
        title: `Delete ${inventoryName}`,
        description: `You are deleting the inventory <strong>${inventoryName}</strong>. The deletion is irreversible. It can't be undone.`
      }, {
        confirmButtonText: 'Delete'
      }).afterClosed().subscribe((confirmed?: boolean) => {
        if (confirmed === true) {
          this.inventoryContext.deleteInventory(inventoryKey);
        }
      });
    });
  }

}
