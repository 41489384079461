import { CanaryRoutingOption } from '@deployment-common/deployment-process.model';

export interface PromoteRollbackDialogPayload {
  inventoryKey: string;
  canaryDialogType: KubernetesDialogTypeEnum;
  canaryConfig: CanaryRoutingOption;
}

export enum KubernetesDialogTypeEnum {
  ROLLBACK = 'Rollback',
  PROMOTE = 'Promote'
}
