import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, Input, Output,
  OnDestroy, OnInit,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

import { take } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { ProjectBundle } from '../../projects/project.model';
import { ProjectService } from '../../projects/project.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ProjectDependenciesHelper } from './project-dependencies.helper';
import { NavigationService } from '../../navbar/navigation.service';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { UpgradeNotesModel, UpgradesConfirmation } from './project-dependencies.model';
import { ProjectDependenciesDialogService } from './project-dependencies-dialog.service';
import { StandardBundleNames, UpgradeNote } from '../../resources/bundle-management/bundle.model';
import { BundleService } from '../../resources/bundle-management/bundle.service';
import { requireNonNull } from '../../common/utils/utils';
import { UpgradeNotesComponent } from './upgrade-notes.component';
import { VersionCompareHelper } from '../../common/helpers/version-compare.helper';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { indicate } from '../../rx.utils';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Component({
  selector: 'adm4-select-dependencies-dialog',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="dependencies-dialog-container full-height content-container">
            <adm4-spinner-overlay *ngIf="isLoading$ | async"></adm4-spinner-overlay>
            <adm4-project-common-dependencies *ngIf='hasCommonBundles; else noCommonBundles' [projectBundles]='commonProjectBundles'
                                              [isEditMode]='true'
                                              (projectBundlesChange)='updateSelectedCommonOptions($event)'
                                              [currentProjectBaseVersion]='currentProjectBaseVersion'
                                              (navigateToPatternLibraryManagement)='navigateToPatternLibraryManagement()'
            ></adm4-project-common-dependencies>
            <ng-template #noCommonBundles>
              <div class='dependencies-header'>
                Standard libraries
              </div>
              <div>No common libraries found in the system. To upload a library first go to <a (click)='navigateToPatternLibraryManagement()'>Pattern library management</a></div>
            </ng-template>
            <div *ngIf='hasAdditionalBundles'>
              <div class='dependencies-header'>
                Additional libraries
              </div>
              <adm4-project-dependencies-table
                      [isEditMode]="true"
                      [projectBundles]="additionalProjectBundles"
                      (projectBundlesChange)="updateSelectedAdditionalOptions($event)"
              ></adm4-project-dependencies-table>
            </div>
          </div>
        </div>
      </div>
      @let isLoading = isLoading$ | async;
      <div mat-dialog-actions [class.cursor-wait]="isLoading || !notesInited">
        <button class='admn4-button-text' (click)='onCancel()' [disabled]="isLoading" [class.cursor-wait]="isLoading">Cancel</button>
        <button (click)="onConfirmSelection()" class='admn4-button-ellipse-blue' [class.cursor-wait]="isLoading || !notesInited"
                [disabled]='!canSave || isLoading || !notesInited'
                ngbTooltip='Please read the upgrade notes for the currently selected common library' [disableTooltip]="isLoading">
          Save changes
        </button>
      </div>
    </div>
  `,
  styleUrls: [
    'select-dependencies-dialog.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDependenciesDialogComponent implements OnInit, OnDestroy {
  @Input() commonProjectBundles: ProjectBundle[];
  @Input() additionalProjectBundles: ProjectBundle[];
  @Input() projectKey: string;

  @Output() bundlesSaved = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  /**
   * Contains all project bundles that will be used after changes, including unmodified bundles
   */
  updatedCommonProjectBundles: ProjectBundle[] = [];
  updatedAdditionalProjectBundles: ProjectBundle[] = [];
  currentProjectBaseVersion: string | undefined;
  notes: UpgradeNote[] = [];
  notesInited: boolean = false;
  filteredUpgradeNotes: UpgradeNote[] = [];
  private upgradeNotesDialog: MatDialogRef<UpgradeNotesComponent, UpgradesConfirmation>;
  private upgradeNotesSub: Subscription = new Subscription();

  /** Indicates if there are any unsaved changes. */
  canSave = false;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(private readonly projectService: ProjectService,
              private readonly toastNotificationService: ToastNotificationService,
              private readonly navigationService: NavigationService,
              private readonly dialogRef: MatDialogRef<SelectDependenciesDialogComponent>,
              private readonly bundleService: BundleService,
              private readonly projectDependenciesDialogService: ProjectDependenciesDialogService,
              private readonly modals: ModalNotificationService,
  ) {}

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);

    this.updatedCommonProjectBundles = this.commonProjectBundles;
    this.updatedAdditionalProjectBundles = this.additionalProjectBundles;
    this.currentProjectBaseVersion = this.pluginBaseBundle ? this.pluginBaseBundle.selectedVersion : undefined;
    this.getInitialUpgradeNotes();
  }

  getInitialUpgradeNotes() {
    const initialProjectBaseBundle = this.pluginBaseBundle;
    if (initialProjectBaseBundle) {
      this.bundleService.getBundleUpgradeNotes(requireNonNull(this.pluginBaseBundle)).subscribe(
        (notes: UpgradeNote[]) => {
          this.notes = notes;
          this.notesInited = true;
        },
        (error) => {
          console.error('SelectDependenciesDialogComponent#getInitialUpgradeNotes, error when trying to load upgrade notes', error);
          this.modals.openHttpErrorDialog(error, 'Unexpected error when loading upgrade notes');
          this.notes = [];
        });
    }
  }

  get pluginBaseBundle(): ProjectBundle | undefined {
    return this.updatedCommonProjectBundles.find(bundle => bundle.symbolicName === StandardBundleNames.BaseGeneration);
  }

  ngOnDestroy(): void {
    this.upgradeNotesSub.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onConfirmSelection() {
    if (!this.isLoading$.getValue()) {
      if (this.shouldShowUpgradeNotes()) {
        this.openUpgradeNotesDialog();
      } else {
        // no upgrade if no upgrade notes
        this.saveChanges(false);
      }
    }
  }

  private saveChanges(shouldMigrate: boolean): void {
    const bundlesToUpdate: string[] = this.updatedCommonProjectBundles.concat(this.updatedAdditionalProjectBundles)
      .filter(projectBundle => projectBundle.isActiveForProject)
      .map(projectBundle => ProjectDependenciesHelper.createBundleKeyFromProjectBundle(projectBundle));
    this.projectService.updateBundlesForProject(bundlesToUpdate, this.projectKey, shouldMigrate)
      .pipe(
        indicate(this.isLoading$),
      ).subscribe(() => {
        this.toastNotificationService.showSuccessToast('Dependencies of the project were successfully saved.', 'Changes saved');
        this.bundlesSaved.next();
        this.closeUpgradeNotesDialogs();
      },
      (error: HttpErrorResponse) => {
        const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong during the save.');
        this.toastNotificationService.showErrorToast(errorMessage, 'Changes could not be saved');
        this.dialogRef.close();
      });
  }

  shouldShowUpgradeNotes(): boolean {
    if (_.isEmpty(this.filteredUpgradeNotes)) {
      return false;
    }
    return _.isNil(this.upgradeNotesDialog) ? true : this.upgradeNotesDialog.getState() === MatDialogState.CLOSED;
  }

  openUpgradeNotesDialog() {
    this.upgradeNotesSub.unsubscribe();
    const notesModel: UpgradeNotesModel = {notes: this.filteredUpgradeNotes, projectKey: this.projectKey};
    this.upgradeNotesDialog = this.projectDependenciesDialogService.openUpgradeNotesDialog(notesModel);
    this.upgradeNotesSub = this.upgradeNotesDialog.afterClosed().pipe(take(1)).subscribe((result: UpgradesConfirmation | undefined): void => {
      if (result !== undefined) {
        this.saveChanges(result.shouldMigrate);
      }
    });
  }

  private closeUpgradeNotesDialogs() {
    if (this.upgradeNotesDialog) {
      this.upgradeNotesDialog.close();
    }
  }

  navigateToPatternLibraryManagement(): void {
    this.dialogRef.close();
    this.navigationService.navigateToLibraryManagement();
  }

  updateSelectedCommonOptions(projectBundles: ProjectBundle[]): void {
    this.updatedCommonProjectBundles = projectBundles;
    this.filteredUpgradeNotes = this.getUpgradeNotesForBundle();

    this.canSave = this.areBundlesChanged();
  }

  getUpgradeNotesForBundle(): UpgradeNote[] {
    if (_.isEmpty(this.notes)) {
      return [];
    }
    const projectBaseBundle: ProjectBundle | undefined = this.pluginBaseBundle;
    const currentVersion: string | undefined = this.currentProjectBaseVersion;
    if (_.isNil(projectBaseBundle) || _.isNil(currentVersion)) {
      return [];
    }
    return this.notes.filter((note: UpgradeNote) => {
      if (_.isEmpty(note.html)) {
        return false;
      }
      const isNewerThanCurrentVersion = VersionCompareHelper.compare(note.version, currentVersion) > 0;
      const isNotNewerThanSelectedVersion = VersionCompareHelper.compare(note.version, projectBaseBundle.selectedVersion) <= 0;
      return isNewerThanCurrentVersion && isNotNewerThanSelectedVersion;
    });
  }

  updateSelectedAdditionalOptions(projectBundles: ProjectBundle[]): void {
    this.updatedAdditionalProjectBundles = projectBundles;

    this.canSave = this.areBundlesChanged();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get hasCommonBundles(): boolean {
    return !_.isEmpty(this.commonProjectBundles);
  }

  get hasAdditionalBundles(): boolean {
    return !_.isEmpty(this.additionalProjectBundles);
  }

  private areBundlesChanged(): boolean {
    const commonBundlesChanged = !ProjectDependenciesHelper.areProjectBundleListsIdentical(this.commonProjectBundles, this.updatedCommonProjectBundles);
    const additionalBundlesChanged = !ProjectDependenciesHelper.areProjectBundleListsIdentical(this.additionalProjectBundles, this.updatedAdditionalProjectBundles);
    return commonBundlesChanged || additionalBundlesChanged;
  }
}
