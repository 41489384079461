import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Project, ProjectDescription, ProjectFromTemplate } from './project.model';
import { Observable } from 'rxjs';
import { IssueModel } from '../common/model/issue.model';
import { ProjectFileImportPayload } from './file-based-import-project/project-file-import-payload.model';
import { ParsedBundle } from '../resources/bundle-management/bundle.model';
import { map } from 'rxjs/operators';
import { BundleHelper } from '../resources/bundle-management/bundle.helper';
import { PublishPayload } from '../common/model/publish-changes/publish-payload.model';
import { VersionControlData } from '../common/model/version-control-data.model';
import { BundlesVersionedInfo } from '../version-control/bundles-meta-info.model';
import { ProjectSummaryReportGenerationModel, ProjectSummaryReportModel } from './project-summary/project-summary-report/project-summary-report.model';
import { RecommenderInput, RecommenderOutput } from '../common/components/text-editor-dialog/recommender.model';
import { Revision } from '../version-control/revision/revision.model';

/**
 * This service provides all the methods needed for getting data about project
 */
@Injectable()
export class ProjectService extends BaseService {

  getProjectsOfTenant(tenantKey: string): Observable<Project[]> {
    const url = '/projects';
    const params: HttpParams = new HttpParams().set('tenantKey', tenantKey);
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getProjectsOfTenantWithInventoryDeployments(tenantKey: string): Observable<Project[]> {
    const url = '/projects';
    const params: HttpParams = new HttpParams().set('tenantKey', tenantKey).set('deployedInventories', true);
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getProject(projectKey: string): Observable<Project> {
    const url = `/projects/${projectKey}`;
    return this.httpGet(url);
  }

  triggerUpdatesOfProject(projectKey: string): Observable<string> {
    const url = `/projects/${projectKey}/revision-update`;
    return this.httpPut(url, undefined, undefined, undefined, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  createProject(project: ProjectFromTemplate): Observable<Project> {
    const url = '/projects';
    return this.httpPost(url, project);
  }

  /**
   * Starts job for importing project
   * @param {Project} project
   * @returns {Observable<string>} URL for job, which is running while importing project, polling which we can know status of the progress
   */
  importProject(project: Project): Observable<string> {
    const url = '/projects/revision-import';
    return this.httpPost(url, project, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  importProjectForRevision(projectKey: string, commitId: string): Observable<string> {
    const url = `/projects/${projectKey}/revision-import/${commitId}`;
    return this.httpPost(url, undefined, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  importProjectFile(projectFileImportPayload: ProjectFileImportPayload): Observable<string> {
    const url = '/projects/file-import';
    const formData = new FormData();
    formData.append('project', projectFileImportPayload.projectFile);
    const params: HttpParams = new HttpParams().append('projectKey', projectFileImportPayload.projectKey);
    return this.httpPostFormData(url, formData, undefined, params, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  connectProjectToVersionControl(projectKey: string, projectVersionControlData: VersionControlData): Observable<Project> {
    const url = `/projects/${projectKey}`;
    return this.httpPatch<VersionControlData, Project, Project>(url, projectVersionControlData);
  }

  /**
   *  Update/modify a project
   *  PUT /nevisadmin/rest/v1/projects/<projectKey>
   */
  updateProject(project: Project, id: string): Observable<HttpResponse<Project>> {
    const url = `/projects/${id}`;
    return this.httpPut(url, project);
  }

  deleteProject(id: string): Observable<void> {
    const url = `/projects/${id}`;
    return this.httpDelete(url);
  }

  getPluginBundleForProject(projectKey: string): Observable<ParsedBundle[]> {
    const url = `/projects/${projectKey}/bundles`;
    return this.httpGetUnwrapped(url).pipe(map((bundleKeys: string[]) => BundleHelper.parseBundlesFromBundleKeys(bundleKeys)));
  }

  getProjectBundlesMeta(projectKey: string): Observable<BundlesVersionedInfo> {
    const url = `/projects/${projectKey}/bundles`;
    const params: HttpParams = new HttpParams().set('meta', true.toString());
    return this.httpGet(url, undefined, params);
  }

  updateBundlesForProject(updatedBundles: string[], projectKey: string, shouldMigrate: boolean): Observable<string[]> {
    const url = `/projects/${projectKey}/bundles`;
    return this.httpPut(url, updatedBundles, undefined, new HttpParams().set('migrate', shouldMigrate.toString()));
  }

  getAllIssuesOfProject(projectKey: string): Observable<IssueModel[]> {
    const url = `/projects/${projectKey}/issues`;
    return this.httpGetUnwrapped(url);
  }

  getPatternSummaryReport(projectKey: string, generationModel: ProjectSummaryReportGenerationModel): Observable<ProjectSummaryReportModel> {
    const url = `/projects/${projectKey}/reports`;
    return this.httpPost(url, generationModel);
  }

  publishProject(projectKey: string, publishContent: PublishPayload): Observable<any> {
    const url = `/projects/${projectKey}/revisions`;
    return this.httpPost(url, publishContent);
  }

  clearProjectLocalChanges(projectKey: string): Observable<any> {
    const url = `/projects/${projectKey}/meta`;
    return  this.httpDelete(url);
  }

  branchProject(project: Project): Observable<string> {
    const url = '/projects/branch-import';
    return this.httpPost(url, project, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  getProjectTimeStamp(projectKey: string): Observable<{ timestamp: string }> {
    const url = `/projects/${projectKey}/timestamp`;
    return this.httpGet(url);
  }

  getIssueTimeStamp(projectKey: string): Observable<{ timestamp: string }> {
    const url = `/projects/${projectKey}/issues/timestamp`;
    return this.httpGet(url);
  }

  getRecommender(projectKey: string, patternId: string, propertyKey: string, recommenderInput: RecommenderInput): Observable<RecommenderOutput> {
    const url = `/projects/${projectKey}/patterns/${patternId}/properties/${propertyKey}/recommender`;
    return this.httpPost(url, recommenderInput);
  }

  getProjectRevisionList(projectKey: string, revisionsToSelect = 100): Observable<Revision[]> {
    const url = `/projects/${projectKey}/revisions?size=${revisionsToSelect}`;
    return this.httpGetUnwrapped(url);
  }

  revertToRevision(projectKey: string, commitId: string) {
    const url = `/projects/${projectKey}/revision-revert/${commitId}`;
    return this.httpPost(url, {}, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  getAllTagsOfProject(projectKey: string): Observable<string[]> {
    const url = `/projects/${projectKey}/tags`;
    return this.httpGetUnwrapped(url);
  }

  getProjectDescription(projectKey: string, includeMeta?: boolean): Observable<ProjectDescription> {
    const url = `/projects/${projectKey}/description`;
    return this.httpGet(url, undefined, includeMeta ? new HttpParams().append('meta', true) : undefined);
  }

  hasProjectDescription(projectKey: string): Observable<boolean> {
    return this.getProjectDescription(projectKey).pipe(
        map((pd: ProjectDescription): boolean => !!pd.description),
    );
  }

  updateProjectDescription(projectKey: string, projectDescription: ProjectDescription): Observable<void> {
    const url = `/projects/${projectKey}/description`;
    return this.httpPut(url, projectDescription);
  }
}
