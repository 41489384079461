import { forkJoin, MonoTypeOperatorFunction, Observable, of, of as observableOf } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeAll, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from '../actions';
import { DeploymentService } from '../../deployment-wizard/deployment-dialog/deployment.service';
import {
  AbortDeploymentPlanningSuccess,
  AbortGenerationSuccess,
  DeleteDeploymentProcess,
  DeleteDeploymentProcessSuccess,
  DeployActionTypes,
  DeploymentPlanningDone,
  FetchDeploymentPlanningOutput,
  FetchDeploymentPlanningOutputSuccess,
  FetchGenerationIssues,
  FetchGenerationIssuesSuccess,
  FetchGenerationOutputSuccess,
  GenerationDone,
  GetDeploymentProcess,
  GetDeploymentProcessSuccess,
  LoadDeployablePatternInstancesSuccess,
  LoadGroupsSuccess,
  LoadHostsSuccess,
  PollDeploymentPlanningStatus,
  PollDeploymentPlanningStatusSuccess,
  PollDeploymentStatus,
  PollDeploymentStatusSuccess,
  PollGenerationStatus,
  PollGenerationStatusSuccess,
  ScheduleDeploymentPlanningDone,
  ScheduleGenerationDone,
  StartDeploymentProcessSuccess,
  StartGeneration,
  StartGenerationFailed,
  StoreDeploymentInventory,
  StoreDeploymentProject
} from './deploy.actions';
import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';
import { AppState, Dictionary } from '../reducer';
import { Project } from '../../projects/project.model';
import { select, Store } from '@ngrx/store';
import { InventoryService } from '../../inventory/inventory.service';
import { PatternService } from '../../patterns/pattern.service';
import { DeployToOption } from '../../deployment-wizard/deployment-selection/deploy-to-option.model';
import { DeployToOptionHelper } from '../../deployment-wizard/deployment-selection/deploy-to-option.helper';
import * as fromProject from '../project/project.actions';
import * as fromInventory from '../inventory/inventory.actions';
import * as _ from 'lodash';
import { Inventory, InventorySchemaType } from '../../inventory/inventory.model';
import { allInventoriesView, allProjectsView, deploymentWizardSelectionInventoryKeyView, deploymentWizardSelectionProjectKeyView, deployProcessView, isDeploymentWizardOpenedView } from '../views';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { Pattern } from '../../patterns/pattern.model';
import { DeploymentStartModel, DeploymentStatusModel, GenerationStatusModel } from '@deployment-common/generation-status.model';
import { Status } from '@deployment-common/validating/deployment-status.model';
import { IssueModel } from '@common/model/issue.model';
import { POLL_INTERVAL } from '@common/constants/app.constants';
import { GenerationOutputModel, PlanningOutputModel } from '../../deployment-wizard/deploy/deployment-preview/planning-output.model';
import { Mixin } from '@common/decorators/mixin.decorator';
import { EffectWithErrorHandlingMixin, IEffectWithErrorHandlingMixin } from '../effect-with-error-handling.mixin';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationMessage } from '../../notification/notification.model';
import { ErrorHelper } from '@common/helpers/error.helper';
import { InventoryErrorFieldSource } from '../../inventory/inventory.constants';
import { ValidationIssue, ValidationStatus } from '@common/model/validation-status.model';
import { HTTP_STATUS_BAD_REQUEST } from '../../shared/http-status-codes.constants';
import { ValidationStatusHelper } from '@common/helpers/validation-status.helper';


@Injectable()
@Mixin([EffectWithErrorHandlingMixin])
export class DeployEffects implements IEffectWithErrorHandlingMixin {
   loadDeployablePatternsOnDeploymentProjectChange: Observable<LoadDeployablePatternInstancesSuccess>;
   loadDeployTargetsOnDeploymentInventoryChange: Observable<LoadHostsSuccess | LoadGroupsSuccess>;
  /**
   * After loading projects it validates the stored deployment project key whether such project still exists
   * if it exists action to store it again is dispatched (helps updating other data e.g. deployable patterns)
   * if it does not exist the stored deployment project key is deleted
   */
   invalidateDeploymentProjectKeyOnProjectListLoad: Observable<StoreDeploymentProject>;
  /**
   * After loading inventories it validates the stored deployment inventory key whether such inventory still exists
   * if it exists action to store it again is dispatched (helps updating other data e.g. hosts, groups)
   * if it does not exist the stored deployment inventory key is deleted
   */
   invalidateDeploymentInventoryKeyOnInventoryListLoad: Observable<StoreDeploymentInventory>;
   startDeploymentProcess$: Observable<StartDeploymentProcessSuccess | EmptyAction>;
   startDeploymentProcessSuccess$: Observable<StartGeneration>;
   startGeneration$: Observable<GetDeploymentProcess | PollGenerationStatus | StartGenerationFailed | EmptyAction>;
   startGenerationFailed$: Observable<GenerationDone | DeleteDeploymentProcess>;
   pollGeneration$: Observable<PollGenerationStatusSuccess | ScheduleGenerationDone>;
  /**
   * Requirement is to show generation process for at least 1 second so it doesn't flash, therefore this effect handles that
   */
   scheduleGenerationDone: Observable<GenerationDone | GetDeploymentProcess | FetchGenerationIssues>;
   fetchGenerationIssues$: Observable<FetchGenerationIssuesSuccess | EmptyAction>;
   fetchGenerationOutput$: Observable<FetchGenerationOutputSuccess | EmptyAction>;
   abortGeneration$: Observable<AbortGenerationSuccess | GetDeploymentProcess | EmptyAction>;
   startDeploymentPlanning$: Observable<GetDeploymentProcess | PollDeploymentPlanningStatus | EmptyAction>;
   pollDeploymentPlanning$: Observable<PollDeploymentPlanningStatusSuccess | GetDeploymentProcess | FetchDeploymentPlanningOutput>;
  /**
   * Requirement is to show planning process for at least 1 second so it doesn't flash, therefore this effect handles that
   */
   scheduleDeploymentPlanningDone: Observable<DeploymentPlanningDone>;
   fetchDeploymentPlanningOutput$: Observable<FetchDeploymentPlanningOutputSuccess | ScheduleDeploymentPlanningDone | EmptyAction>;
  /**
   * Basically same as start planning but with a different parameter to a service call, I didn't want to change start planning effect to include some checks there, even though it would reduce code duplication
   */
   forceRedeployment: Observable<PollDeploymentPlanningStatus | GetDeploymentProcess | EmptyAction>;
   abortDeploymentPlanning$: Observable<AbortDeploymentPlanningSuccess | GetDeploymentProcess | EmptyAction>;
   startDeploy$: Observable<GetDeploymentProcess | PollDeploymentStatus | EmptyAction>;
   pollDeploymentStatus: Observable<PollDeploymentStatusSuccess | GetDeploymentProcess>;
   getDeploymentProcess$: Observable<GetDeploymentProcessSuccess | EmptyAction>;
   deleteDeploymentProcess$: Observable<DeleteDeploymentProcessSuccess | EmptyAction>;

  /**
   * Implemented by EffectWithErrorHandlingMixin
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;

  constructor(private deploymentService: DeploymentService,
              private inventoryService: InventoryService,
              private patternService: PatternService,
              private action$: Actions<NevisAdminAction>,
              private store$: Store<AppState>,
              public modalNotificationService: ModalNotificationService) {

    this.createLoadDeployablePatternsOnDeploymentProjectChangeEffect();
    this.createLoadDeployTargetsOnDeploymentInventoryChangeEffect();
    this.createInvalidateDeploymentProjectKeyOnProjectListLoadEffect();
    this.createInvalidateDeploymentInventoryKeyOnInventoryListLoadEffect();
    this.createStartDeploymentProcessEffect();
    this.createStartDeploymentProcessSuccessEffect();
    this.createStartGenerationEffect();
    this.createStartGenerationFailedEffect();
    this.createPollGenerationEffect();
    this.createScheduleGenerationDoneEffect();
    this.createFetchGenerationIssuesEffect();
    this.createFetchGenerationOutputEffect();
    this.createAbortGenerationEffect();
    this.createStartDeploymentPlanningEffect();
    this.createPollDeploymentPlanningEffect();
    this.createScheduleDeploymentPlanningDoneEffect();
    this.createFetchDeploymentPlanningOutputEffect();
    this.createForceRedeploymentEffect();
    this.createAbortDeploymentPlanningEffect();
    this.createStartDeployEffect();
    this.createPollDeploymentStatusEffect();
    this.createGetDeploymentProcessEffect();
    this.createDeleteDeploymentProcessEffect();
  }

  private createLoadDeployablePatternsOnDeploymentProjectChangeEffect() {
    this.loadDeployablePatternsOnDeploymentProjectChange = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.StoreDeploymentProject),
        map((action: NevisAdminAction<string | undefined>) => action.payload),
        withLatestFrom(this.store$.pipe(select(isDeploymentWizardOpenedView))),
        filter(([, isWizardOpen]: [string | undefined, boolean]) => isWizardOpen),
        map(([projectKey]: [string | undefined, boolean]) => projectKey),
        switchMap((projectKey: string | undefined) => {
          return this.store$.pipe(select(allProjectsView))
            .pipe(switchMap((projects: Dictionary<Project>) => {
              if (_.isNil(projectKey) || _.isEmpty(projects) || _.isNil(projects[projectKey])) {
                return observableOf(new LoadDeployablePatternInstancesSuccess([]));
              }
              return this.patternService.getAllPatterns(projectKey).pipe(
                map((patternInstances: Pattern[]) => {
                  const deployablePatterns: DeployToOption[] = patternInstances
                    .filter(pattern => !_.isEmpty(pattern.deploymentHosts))
                    .map(pattern => DeployToOptionHelper.createDeployToOptionFromPattern(pattern));
                  return new LoadDeployablePatternInstancesSuccess(deployablePatterns);
                }));
            }));
        })
      ));
  }

  private createLoadDeployTargetsOnDeploymentInventoryChangeEffect() {
    this.loadDeployTargetsOnDeploymentInventoryChange = createEffect(() => this.action$.pipe(
      ofType(DeployActionTypes.StoreDeploymentInventory),
      map((action: NevisAdminAction<string | undefined>) => action.payload),
      withLatestFrom(this.store$.pipe(select(isDeploymentWizardOpenedView))),
      filter(([, isWizardOpen]: [string | undefined, boolean]) => isWizardOpen),
      map(([inventoryKey]: [string | undefined, boolean]) => inventoryKey),
      switchMap((inventoryKey: string | undefined) => {
        return this.store$.pipe(select(allInventoriesView)).pipe(
          switchMap((inventories: Dictionary<Inventory>) => {
            if (_.isNil(inventoryKey) || _.isEmpty(inventories) || _.isNil(inventories[inventoryKey])) {
              return observableOf(new LoadHostsSuccess([]), new LoadGroupsSuccess([]));
            }
            const inventory = inventories[inventoryKey];
            switch (inventory.schemaType) {
              case InventorySchemaType.CLASSIC:
                return this.loadDeployTargetsAndGroups(inventoryKey, DeployToOptionHelper.createDeployToOptionFromHost);
              case InventorySchemaType.KUBERNETES:
                return this.loadDeployTargetsAndGroups(inventoryKey, DeployToOptionHelper.createDeployToOptionFromService);
            }
          })
        );
      })
    ));
  }

  private createInvalidateDeploymentProjectKeyOnProjectListLoadEffect() {
    this.invalidateDeploymentProjectKeyOnProjectListLoad = createEffect(() => this.action$
      .pipe(
        ofType(fromProject.ProjectActionTypes.LoadProjectsSuccess),
        map((action: NevisAdminAction<Project[]>) => action.payload),
        withLatestFrom(this.store$.pipe(select(deploymentWizardSelectionProjectKeyView))),
        map(([projects, deploymentProjectKey]: [Project[], string | undefined]) => {
          const resultingProjectKey: string | undefined = _.isEmpty(projects) || projects.every(project => project.projectKey !== deploymentProjectKey) ? undefined : deploymentProjectKey;
          return new StoreDeploymentProject(resultingProjectKey);
        })
      ));
  }

  private createInvalidateDeploymentInventoryKeyOnInventoryListLoadEffect() {
    this.invalidateDeploymentInventoryKeyOnInventoryListLoad = createEffect(() => this.action$
      .pipe(
        ofType(fromInventory.InventoryActionTypes.LoadInventoriesSuccess),
        map((action: NevisAdminAction<Inventory[]>) => action.payload),
        withLatestFrom(this.store$.pipe(select(deploymentWizardSelectionInventoryKeyView))),
        map(([inventories, deploymentInventoryKey]: [Inventory[], string | undefined]) => {
          const resultingInventoryKey: string | undefined = _.isEmpty(inventories) || inventories.every(inventory => inventory.inventoryKey !== deploymentInventoryKey) ? undefined : deploymentInventoryKey;
          return new StoreDeploymentInventory(resultingInventoryKey);
        })
      ));
  }

  private createStartDeploymentProcessEffect() {
    this.startDeploymentProcess$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartDeploymentProcess))
      .pipe(
        map((action: NevisAdminAction) => action.payload),
        switchMap((deploymentData: DeploymentProcessModel) => {
          return this.deploymentService.createDeploymentProcess(deploymentData).pipe(
            map((result: DeploymentProcessModel) => new StartDeploymentProcessSuccess(result)),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while starting deployment'})))
          );
        })
      ));
  }

  private createStartDeploymentProcessSuccessEffect() {
    this.startDeploymentProcessSuccess$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartDeploymentProcessSuccess))
      .pipe(
        map((action: NevisAdminAction) => action.payload),
        map((deploymentData: DeploymentProcessModel) => new StartGeneration(deploymentData.deploymentId))
      ));
  }

  private createStartGenerationEffect() {
    this.startGeneration$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartGeneration))
      .pipe(
        map((action: NevisAdminAction) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.startGeneration(deploymentId).pipe(
            mergeMap(() => of(new GetDeploymentProcess(deploymentId), new PollGenerationStatus(deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleStartGenerationFailed(error)))
          );
        })
      ));
  }

  private createStartGenerationFailedEffect() {
    this.startGenerationFailed$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartGenerationFailed))
      .pipe(
        mergeMap(() => of(new GenerationDone(), new DeleteDeploymentProcess()))
      ));
  }

  private createPollGenerationEffect() {
    this.pollGeneration$ = createEffect(() => this.action$.pipe(
      ofType(DeployActionTypes.PollGenerationStatus),
      map((action: NevisAdminAction<string>) => action.payload),
      switchMap((deploymentId: string) => this.deploymentService.pollGenerationStatus(deploymentId)
        .pipe(
          takeUntil(this.action$.pipe(ofType(DeployActionTypes.AbortGenerationSuccess))),
          takeUntil(this.action$.pipe(ofType(DeployActionTypes.DeleteDeploymentProcess))),
          this.takeUntilWizardClosed(),
          mergeMap((generationStatus: GenerationStatusModel) => {
            const actionsToDispatch: (PollGenerationStatusSuccess | ScheduleGenerationDone)[] = [new PollGenerationStatusSuccess(generationStatus)];
            // when generation is finished we need to update deployment process and fetch generation issues
            if (generationStatus.status === Status.Done) {
              actionsToDispatch.push(new ScheduleGenerationDone(deploymentId));
            }
            return of(...actionsToDispatch);
          })
        )
      )
    ));
  }

  private createScheduleGenerationDoneEffect() {
    this.scheduleGenerationDone = createEffect(() => this.action$.pipe(
      ofType(DeployActionTypes.ScheduleGenerationDone),
      map((action: NevisAdminAction<string>) => action.payload),
      debounceTime(POLL_INTERVAL),
      withLatestFrom(this.store$.pipe(select(state => state.deploy.isWizardOpened))),
      filter(([, isWizardOpened]: [string, boolean]) => isWizardOpened),
      switchMap(([deploymentId]: [string, boolean]) => of(new GenerationDone(), new GetDeploymentProcess(deploymentId), new FetchGenerationIssues(deploymentId)))
    ));
  }

  private createFetchGenerationIssuesEffect() {
    this.fetchGenerationIssues$ = createEffect(() => this.action$.pipe(
      ofType(DeployActionTypes.FetchGenerationIssues),
      map((action: NevisAdminAction<string>) => action.payload),
      switchMap((deploymentId: string) => this.deploymentService.getGenerationIssues(deploymentId)
        .pipe(
          this.takeUntilWizardClosed(),
          map((issues: IssueModel[]) => new FetchGenerationIssuesSuccess(issues)),
          catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {description: 'Failed to load generation issues.'})))
        )
      )
    ));
  }

  private createFetchGenerationOutputEffect() {
    this.fetchGenerationOutput$ = createEffect(() => this.action$.pipe(
      ofType(DeployActionTypes.FetchGenerationOutput),
      map((action: NevisAdminAction<string>) => action.payload),
      switchMap((deploymentId: string) => this.deploymentService.getGenerationOutput(deploymentId)
        .pipe(
          this.takeUntilWizardClosed(),
          map((output: GenerationOutputModel[]) => new FetchGenerationOutputSuccess(output)),
          catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {description: 'Failed to load generation output, cannot build tree.'})))
        )
      )
    ));
  }

  private createAbortGenerationEffect() {
    this.abortGeneration$ = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.AbortGeneration),
        map((action: NevisAdminAction) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.abortGeneration(deploymentId).pipe(
            mergeMap(() => of(new AbortGenerationSuccess(), new GetDeploymentProcess(deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while deleting generation'})))
          );
        })
      ));
  }

  private createStartDeploymentPlanningEffect() {
    this.startDeploymentPlanning$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartDeploymentPlanning))
      .pipe(
        map((action: NevisAdminAction<string>) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.startDeploymentPlanning(deploymentId).pipe(
            mergeMap(() => of(new GetDeploymentProcess(deploymentId), new PollDeploymentPlanningStatus(deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Unfortunately, an unexpected error happened', description: ErrorHelper.getErrorDetail(error)})))
          );
        })
      ));
  }

  private createPollDeploymentPlanningEffect() {
    this.pollDeploymentPlanning$ = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.PollDeploymentPlanningStatus),
        map((action: NevisAdminAction<string>) => action.payload),
        switchMap((deploymentId: string) => this.deploymentService.pollDeploymentPlanningStatus(deploymentId)
          .pipe(
            takeUntil(this.action$.pipe(ofType(DeployActionTypes.AbortDeploymentPlanningSuccess))),
            takeUntil(this.action$.pipe(ofType(DeployActionTypes.DeleteDeploymentProcess))),
            this.takeUntilWizardClosed(),
            mergeMap((deploymentPlanningStatus: DeploymentStatusModel) => {
              const actionsToDispatch: (PollDeploymentPlanningStatusSuccess | GetDeploymentProcess | FetchDeploymentPlanningOutput)[] = [new PollDeploymentPlanningStatusSuccess(deploymentPlanningStatus)];
              // when planning is finished we need to update deployment process and fetch planning output
              if (deploymentPlanningStatus.status === Status.Done) {
                actionsToDispatch.push(new GetDeploymentProcess(deploymentId), new FetchDeploymentPlanningOutput(deploymentId));
              }
              return of(...actionsToDispatch);
            })
          )
        )
      ));
  }

  private createScheduleDeploymentPlanningDoneEffect() {
    this.scheduleDeploymentPlanningDone = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.ScheduleDeploymentPlanningDone),
        map((action: NevisAdminAction<string>) => action.payload),
        debounceTime(POLL_INTERVAL),
        withLatestFrom(this.store$.pipe(select(state => state.deploy.isWizardOpened))),
        filter(([, isWizardOpened]: [string, boolean]) => isWizardOpened),
        map(() => new DeploymentPlanningDone())
      ));
  }

  private createFetchDeploymentPlanningOutputEffect() {
    this.fetchDeploymentPlanningOutput$ = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.FetchDeploymentPlanningOutput),
        map((action: NevisAdminAction<string>) => action.payload),
        switchMap((deploymentId: string) => this.deploymentService.getPlanningResult(deploymentId)
          .pipe(
            this.takeUntilWizardClosed(),
            mergeMap((planningOutput: PlanningOutputModel[]) => of(new FetchDeploymentPlanningOutputSuccess(planningOutput), new ScheduleDeploymentPlanningDone(deploymentId))),
            catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {description: 'Failed to load planning output. Cannot build the tree.'})))
          )
        )
      ));
  }

  private createForceRedeploymentEffect() {
    this.forceRedeployment = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.ForceRedeployment))
      .pipe(
        map((action: NevisAdminAction<string>) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.startForceDeploymentPlanning(deploymentId).pipe(
            mergeMap(() => of(new PollDeploymentPlanningStatus(deploymentId), new GetDeploymentProcess(deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while starting deployment planning'})))
          );
        })
      ));
  }

  private createAbortDeploymentPlanningEffect() {
    this.abortDeploymentPlanning$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.AbortDeploymentPlanning))
      .pipe(
        map((action: NevisAdminAction) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.abortDeploymentPlanning(deploymentId).pipe(
            mergeMap(() => of(new AbortDeploymentPlanningSuccess(), new GetDeploymentProcess(deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while aborting deployment planning'})))
          );
        })
      ));
  }

  private createStartDeployEffect() {
    this.startDeploy$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.StartDeploy))
      .pipe(
        map((action: NevisAdminAction<DeploymentStartModel>) => action.payload),
        switchMap((deploymentStart: DeploymentStartModel) => {
          return this.deploymentService.startDeployment(deploymentStart.deploymentId, deploymentStart.deploymentComment).pipe(
            mergeMap(() => of(new GetDeploymentProcess(deploymentStart.deploymentId), new PollDeploymentStatus(deploymentStart.deploymentId))),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while starting deployment'})))
          );
        })
      ));
  }

  private createPollDeploymentStatusEffect() {
    this.pollDeploymentStatus = createEffect(() => this.action$
      .pipe(
        ofType(DeployActionTypes.PollDeploymentStatus),
        map((action: NevisAdminAction<string>) => action.payload),
        switchMap((deploymentId: string) => this.deploymentService.pollDeploymentStatus(deploymentId)
          .pipe(
            // TODO (soroka 17/September/2018) add takeUntil(ActionTypes.ABORT_DEPLOYMENT) when abort deployment is implemented
            takeUntil(this.action$.pipe(ofType(DeployActionTypes.DeleteDeploymentProcess))),
            this.takeUntilWizardClosed(),
            mergeMap((deploymentStatus: DeploymentStatusModel) => {
              const actionsToDispatch: (PollDeploymentStatusSuccess | GetDeploymentProcess)[] = [new PollDeploymentStatusSuccess(deploymentStatus)];
              // when deployment is finished we need to update deployment process
              if (deploymentStatus.status === Status.Done) {
                actionsToDispatch.push(new GetDeploymentProcess(deploymentId));
              }
              return of(...actionsToDispatch);
            })
          )
        )
      ));
  }

  private createGetDeploymentProcessEffect() {
    this.getDeploymentProcess$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.GetDeploymentProcess))
      .pipe(
        map((action: NevisAdminAction) => action.payload),
        switchMap((deploymentId: string) => {
          return this.deploymentService.getDeploymentProcess(deploymentId).pipe(
            map((deploymentProcess: DeploymentProcessModel) => new GetDeploymentProcessSuccess(deploymentProcess)),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while loading deployment process'})))
          );
        })
      ));
  }

  private createDeleteDeploymentProcessEffect() {
    this.deleteDeploymentProcess$ = createEffect(() => this.action$
      .pipe(ofType(DeployActionTypes.DeleteDeploymentProcess))
      .pipe(
        withLatestFrom(this.store$.pipe(select(deployProcessView))),
        map(([, deploymentProcess]: [NevisAdminAction<undefined>, DeploymentProcessModel | null]) => deploymentProcess),
        filter((deploymentProcess: DeploymentProcessModel) => !!deploymentProcess),
        map((deploymentProcess: DeploymentProcessModel) => deploymentProcess.deploymentId),
        switchMap((deploymentId: string) => {
          return this.deploymentService.deleteDeploymentProcess(deploymentId).pipe(
            map(() => new DeleteDeploymentProcessSuccess()),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Error while deleting deployment'})))
          );
        })
      ));
  }

  private loadDeployTargetsAndGroups(inventoryKey: string, createDeployToOptionFunction: (string) => DeployToOption) {
    return forkJoin([
      this.inventoryService.getHosts(inventoryKey).pipe(
        map((services: string[]) => new LoadHostsSuccess(services.map(service => {
          return createDeployToOptionFunction(service);
        })))
      ),
      this.loadInventoryGroups(inventoryKey)
    ]).pipe(
      mergeAll()
    );
  }

  private loadInventoryGroups(inventoryKey: string) {
    return this.inventoryService.getGroups(inventoryKey).pipe(
      map((groups: string[]) => new LoadGroupsSuccess(groups.map(group => {
        return DeployToOptionHelper.createDeployToOptionFromGroup(group);
      })))
    );
  }

  private handleStartGenerationFailed(errorResponse: HttpErrorResponse): StartGenerationFailed | EmptyAction {
    const isInventoryInvalid = errorResponse.status === HTTP_STATUS_BAD_REQUEST && ErrorHelper.hasSource(errorResponse, {FIELD: InventoryErrorFieldSource.InventoryKey});
    if (isInventoryInvalid) {
      let inventoryValidationStatus: ValidationStatus<ValidationIssue> | undefined;
      if (errorResponse.error && errorResponse.error._status) {
        // TODO (2019.04.30/paksyd): This is a hack. Here we change all warning issues to error as a quick solution in FE. Remove this hack ASAP!
        inventoryValidationStatus = ValidationStatusHelper.changeAllIssuesToError(errorResponse.error._status);
      }
      return new StartGenerationFailed(inventoryValidationStatus);
    } else {
      return this.handleErrorAction<EmptyAction>(errorResponse, {title: 'Unfortunately, an unexpected error happened', description: ErrorHelper.getErrorDetail(errorResponse, 'Error while starting generation')});
    }
  }

  takeUntilWizardClosed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.action$
      .pipe(
        ofType(DeployActionTypes.StoreDeploymentWizardWindowState),
        filter((action: NevisAdminAction<boolean>) => !action.payload)
      )
    );
  }
}
