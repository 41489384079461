import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DiffEditorModel } from 'ngx-monaco-editor-v2/lib/types';
import { editor } from 'monaco-editor';

import { ViewAttachmentFileDiffContent } from '../pattern-diff-view/pattern-diff-data.model';

@Component({
  selector: 'adm4-publish-file-diff',
  template: `
      <div class="diff-file-content">
        <h1 class="file-diff-header flex-shrink-0 flex-grow-0 cursor-default">
            Changes in <strong>{{patternLabel}}</strong> / <strong>{{propertyLabel}}</strong> / <strong>{{fileName}}</strong>
        </h1>
        <div class="diff-file-border default-inventory-box-shadow">
          <ngx-monaco-diff-editor [modifiedModel]='modifiedModel' class="monaco-height-fix flex-grow-1 flex-shrink-1"
                                [originalModel]='originalContent'
                                [options]='editorOptions'
                                (onInit)='initializeEditor($event)'></ngx-monaco-diff-editor>
        </div>
      </div>
      <mat-dialog-actions class="flex-shrink-0 flex-grow-0">
          <button type="button" class="admn4-button-ellipse-blue" (click)="close.emit()">Close viewer</button>
      </mat-dialog-actions>
`,
  styleUrl: './publish-file-diff.component.scss',
})
export class PublishFileDiffComponent implements OnChanges {

  readonly editorOptions: editor.IDiffEditorOptions = {
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    },
    enableSplitViewResizing: false,
    automaticLayout: true,
    renderSideBySide: true,
    useInlineViewWhenSpaceIsLimited: false,
  };

  @Input() diffContent: ViewAttachmentFileDiffContent | null = null;
  @Output() close: EventEmitter<void> = new EventEmitter();

  public modifiedModel: DiffEditorModel;
  public originalContent: DiffEditorModel;

  public patternLabel: string = '';
  public propertyLabel: string = '';
  public fileName: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diffContent) {
      const newDiff = this.diffContent;
      if (newDiff) {
        this.modifiedModel = {code: newDiff.localContent, language: newDiff.contentSyntax};
        this.originalContent = {code: newDiff.remoteContent, language: newDiff.contentSyntax};
        this.patternLabel = newDiff.patternLabel;
        this.propertyLabel = newDiff.propertyLabel;
        this.fileName = newDiff.fileName;
      } else {
        this.modifiedModel = {code: '', language: 'text'};
        this.originalContent = {code: '', language: 'text'};
      }
    }
  }

  public initializeEditor(newEditor: editor.IEditor): void {
    newEditor.layout({} as editor.IDimension);
  }
}
