import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeploymentSelectionListComponent } from '../deployment-selection-list/deployment-selection-list.component';
import * as _ from 'lodash';
import { DeployToClassicOptionType, DeployToKubernetesOptionType, DeployToOption, SECONDARY_HOST_EXPRESSION_NAME } from '../deploy-to-option.model';
import { DeployToOptionHelper } from '../deploy-to-option.helper';
import { UntypedFormGroup } from '@angular/forms';
import { CanaryRoutingOption } from '@deployment-common/deployment-process.model';
import { DeploymentHistoryItem } from '@common/model/deployment-history.model';
import { InventoryColorHelper } from '@common/helpers/inventory-color.helper';

/**
 * Third column of deployment wizard
 */
@Component({
  selector: 'adm4-k8s-list',
  template: `
    <div class='full-height-flex'>
      <h2 class='step-content-header'>Deploy as</h2>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="list-group" [ngClass]='boxShadowClass'>
            <ng-container *ngFor='let host of items; let i = index'>
              <div class="menu-item-wrapper indictable-host" [class.selected]='activeItemIndex === i' [ngClass]='getInventorySelectionItemClassName(inventoryColor)'>
                <button mat-button #selectionList class="border-radius-4"
                        (keydown)='keyDown($event, i)'
                        (click)='selectItem(host, i)'>
                  <div class="host-content">
                    <ng-container *ngIf='isSecondaryHost(host); else primaryDeployOption'>
                      <mat-icon svgIcon='secondary_route' class='shouldRotate mat-icon' [title]='getDeployToOptionTooltipByType(host.type)'></mat-icon>
                    </ng-container>
                    <ng-template #primaryDeployOption>
                      <mat-icon [title]='getDeployToOptionTooltipByType(host.type)'>{{getDeployToOptionIconByType(host.type)}}</mat-icon>
                    </ng-template>
                    <span class="color-screen-black">{{host.name}}</span>
                    <span *ngIf='isSecondaryHost(host)' [ngbTooltip]='secondaryInfo' placement='top-right' class='info-icon-container'>
                      <i class="fa fa-info-circle help-icon" aria-hidden="true" title=''></i>
                    </span>
                    <ng-template #secondaryInfo>
                      Deploy your new version alongside the primary version. Split the traffic between them and test your new version while the primary version is still accessible. You can promote or rollback the secondary version. Find further details at
                      <adm4-external-link [linkUrl]="'nevisadmin4/User-Guide/Deployment-of-the-Configuration/Kubernetes-Deployment/Side-by-side-Deployment/'| docLink"
                                          [linkLabel]='"Side-by-side Deployment"'
                                          [displayStyle]="'inline'"
                                          [openInNewTab]='true'></adm4-external-link>
                    </ng-template>
                  </div>
                </button>
              </div>
            </ng-container>
            <adm4-secondary-deployment-settings *ngIf='isSecondarySelected'
                                                [selectedItem]='selectedItem'
                                                [preSelectedProjectKey]='preSelectedProjectKey'
                                                [inventoryDeploymentHistory]='inventoryDeploymentHistory'
                                                [form]='form'
                                                (canaryRoutingChanged)='updateRoutingOptions($event)'></adm4-secondary-deployment-settings>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./deploy-target-k8s-list.component.scss', '../deployment-selection-list/deployment-selection-list.component.scss', '../../../common/styles/component-specific/modal-window.scss']
})
export class DeployTargetK8sListComponent extends DeploymentSelectionListComponent<DeployToOption> implements OnChanges {
  @Input() preSelectedCanaryRoutingOptions: CanaryRoutingOption | null;
  @Input() preSelectedProjectKey: string | undefined;
  @Input() boxShadowClass: string;
  @Input() form: UntypedFormGroup;
  @Input() inventoryDeploymentHistory: DeploymentHistoryItem[];
  @Input() inventoryColor: string;

  readonly getInventorySelectionItemClassName = (color: string) => InventoryColorHelper.getInventorySelectionItemClassName(color);

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.preSelectedCanaryRoutingOptions || changes.items) && !_.isNil(this.items)) {
      this.activeItemIndex = _.isNil(this.preSelectedCanaryRoutingOptions) ? 0 : 1;
      this.itemSelected.emit(this.items[this.activeItemIndex]);
    }
  }

  getDeployToOptionIconByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string {
    return DeployToOptionHelper.getDeployToOptionIconByType(deployToType);
  }

  getDeployToOptionTooltipByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string | undefined {
    return DeployToOptionHelper.getDeployToOptionTooltipByType(deployToType);
  }

  get isSecondarySelected(): boolean {
    return this.activeItemIndex === 1;
  }

  isSecondaryHost(host: DeployToOption): boolean {
    return host.name === SECONDARY_HOST_EXPRESSION_NAME;
  }

  get selectedItem(): DeployToOption {
    return this.items[this.activeItemIndex];
  }

  updateRoutingOptions(updatedDeployToOption: DeployToOption): void {
    this.updateSelectedItem(updatedDeployToOption, this.activeItemIndex);
    this.selectItem(updatedDeployToOption, this.activeItemIndex);
  }
}
