import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { ClearLocalChangesOfProject } from '../../model/project';

@Component({
  selector: 'adm4-local-changes-project-info-dialog',
  template: `
    <br/>There are no updates available for project {{projectKey | cropTenantFromKey}}. The project is up to date. <br/>
    <br/>Local changes found in the project.
    <span class='call-to-action'>If you want to clear the local changes, click: <a (click)='triggerClearLocalChanges()'>Clear local changes.</a></span>
  `,
  styleUrls: ['./local-changes-confirmation-dialog.component.scss']
})
export class LocalChangesProjectInfoDialogComponent {
  @Input() projectKey: string;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  constructor(private store$: Store<AppState>) {
  }

  triggerClearLocalChanges(): void {
    this.store$.dispatch(new ClearLocalChangesOfProject(this.projectKey));
    this.closeDialog.next();
  }
}
