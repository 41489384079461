import { first } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeploymentService } from '../deployment-dialog/deployment.service';
import { Observable } from 'rxjs';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { DeploymentContext } from './deployment.context';
import { InventorySchemaType } from '../../inventory/inventory.model';

@Component({
  selector: 'adm4-deployment',
  template: `
    <div class="full-height-flex">
      <div class="remaining-space-flex-content-wrapper">
        <div class='remaining-space-flex-content'>
          <div class='step-content full-height'>
            <adm4-validating [validatingText]='"Deploying..."'
                             [validationDoneText]='"Deployment report"'
                             [status]='displayableDeploymentStatus$ | async'>
              <p class='success'>
                <span *ngIf='hasDoneItems$ | async'>{{doneItemsCount$ | async}} instances deployed successfully</span>
                <span *ngIf='hasFailedItems$ | async'> {{failedItemsCount$ | async}}
                  <ng-container *ngIf='hasDoneItems$ | async; else failed'> instances failed to deploy</ng-container>
                  <ng-template #failed> failed</ng-template>
                </span>
              </p>
            </adm4-validating>
          </div>
        </div>
      </div>
      <div class="step-action-bar">
        <button *ngIf='isFinished$ | async' class='admn4-button-ellipse-blue close-button'
                (click)='closeWizard.emit()'>Close wizard
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./deployment.component.scss'],
  providers: [DeploymentContext]
})
export class DeploymentComponent implements OnInit {
  @Input() deploymentId: string;
  @Input() selectedInventorySchemaType?: InventorySchemaType;
  @Output() closeWizard: EventEmitter<void> = new EventEmitter();
  @Output() finish: EventEmitter<void> = new EventEmitter(true);

  displayableDeploymentStatus$: Observable<DeploymentStatusModel>;
  doneItemsCount$: Observable<number>;
  failedItemsCount$: Observable<number>;
  hasDoneItems$: Observable<boolean>;
  hasFailedItems$: Observable<boolean>;
  isFinished$: Observable<boolean>;

  constructor(private deploymentService: DeploymentService, private deploymentContext: DeploymentContext) {
    this.displayableDeploymentStatus$ = this.deploymentContext.displayableDeploymentStatus$;
    this.doneItemsCount$ = this.deploymentContext.doneItemsCount$;
    this.failedItemsCount$ = this.deploymentContext.failedItemsCount$;
    this.hasDoneItems$ = this.deploymentContext.hasDoneItems$;
    this.hasFailedItems$ = this.deploymentContext.hasFailedItems$;
    this.isFinished$ = this.deploymentContext.isFinished$;
  }

  ngOnInit(): void {
    this.isFinished$.pipe(first(isFinished => isFinished)).subscribe(() => this.finish.emit());
  }
}
