import { Injectable } from '@angular/core';
import { Inventory } from './inventory.model';
import { AppState, Dictionary } from '../model/reducer';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import {
  allInventoriesView,
  diffInventoryKeyView,
  inventoryKeyView,
  inventoryValidationStatusView,
  selectedInventoryView,
  selectedTenantKeyView
} from '../model/views';
import { hasCreateInventoryPermissionView, hasInventoryAdminAccessView, hasInventoryDeployAccessView, hasInventoryWriteAccessView, isSelectedInventoryReadonlyView } from '../model/views/permission.views';
import { ChangePublishRequiredFlag, DeleteInventory, LoadInventories, ResetInventories, SelectInventory } from '../model/inventory';
import { InventoryValidationIssue, ValidationStatus } from '../common/model/validation-status.model';

// NOTE: don't extend this context if want to add more functionality for inventory settings, instead do it in another one
// this context can be used by different parts of inventory screens (since a component provider each screen would get a new instance)
@Injectable()
export class InventoryContext {
  inventories$: Observable<Dictionary<Inventory> | null>;
  inventoryKey$: Observable<string | null>;
  diffInventoryKey$: Observable<string | null>;
  currentInventory$: Observable<Inventory | undefined>;
  selectedTenantKey$: Observable<string>;
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;
  hasWriteAccess$: Observable<boolean>;
  hasAdminAccess$: Observable<boolean>;
  hasDeployPermission$: Observable<boolean>;
  canCreateInventory$: Observable<boolean>;
  /** True if the user does NOT have `MODIFY_INVENTORY` permission for the currently selected inventory. */
  isCurrentInventoryReadonly$: Observable<boolean>;

  constructor(private readonly store$: Store<AppState>) {
    this.inventories$ = this.store$.pipe(select(allInventoriesView));
    this.inventoryKey$ = this.store$.pipe(select(inventoryKeyView));
    this.diffInventoryKey$ = this.store$.pipe(select(diffInventoryKeyView));
    this.currentInventory$ = this.store$.pipe(select(selectedInventoryView));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.inventoryValidationStatus$ = this.store$.pipe(select(inventoryValidationStatusView));
    this.hasWriteAccess$ = this.store$.pipe(select(hasInventoryWriteAccessView));
    this.hasAdminAccess$ = this.store$.pipe(select(hasInventoryAdminAccessView));
    this.hasDeployPermission$ = this.store$.pipe(select(hasInventoryDeployAccessView));
    this.canCreateInventory$ = this.store$.pipe(select(hasCreateInventoryPermissionView));
    this.isCurrentInventoryReadonly$ = this.store$.pipe(select(isSelectedInventoryReadonlyView));
  }

  loadInventories(): void {
    this.store$.dispatch(new LoadInventories());
  }

  resetInventories(): void {
    this.store$.dispatch(new ResetInventories());
  }

  selectInventory(inventoryKey: string): void {
    this.store$.dispatch(new SelectInventory(inventoryKey));
  }

  changePublishRequiredFlag(inventoryKey: string, publishRequired: boolean): void {
    this.store$.dispatch(new ChangePublishRequiredFlag({inventoryKey, publishRequired}));
  }

  deleteInventory(inventoryKey: string): void {
    this.store$.dispatch(new DeleteInventory(inventoryKey));
  }
}
