<div class='pattern-diff-view-container full-height-flex'>
  <div class='diff-view-header'>
    <div class='remote'>
      Remote project <span *ngIf='currentBranch$ | async'>({{currentBranch$ | async}})</span>
    </div>
    <div class='local'>
      Local project
      <mat-slide-toggle class="secondary-slide-toggle"
              labelPosition='before'
              [ngModel]='showOnlyDifferences$ | async' (ngModelChange)='rememberShowDifferencesView($event)'>
        <span class="diff-switch-label">Show differences only</span>
      </mat-slide-toggle>
    </div>
  </div>
  <div class='remaining-space-flex-content-wrapper'>
    <div class='remaining-space-flex-content'>
      <div class='pattern-diff'>
        <adm4-pattern-name-diff *ngIf='patternNameDiff$ | async'
                                [patternNameDiff]='patternNameDiff$ | async'
                                [showOnlyDifferences]='showOnlyDifferences$ | async'
                                [currentUsername]='currentUsername'
        ></adm4-pattern-name-diff>
        <adm4-pattern-label-diff *ngIf='patternLabelDiff$ | async'
                                [patternLabelDiff]='patternLabelDiff$ | async'
                                [showOnlyDifferences]='showOnlyDifferences$ | async'
                                [currentUsername]='currentUsername'
        ></adm4-pattern-label-diff>
        <adm4-pattern-link-diff *ngIf='patternLinkDiff$ | async'
                                [patternLinkDiff]='patternLinkDiff$ | async'
                                [showOnlyDifferences]='showOnlyDifferences$ | async'
                                [currentUsername]='currentUsername'
        ></adm4-pattern-link-diff>
        <adm4-simple-pattern-field-diff *ngFor='let simplePatternDiff of simplePatternDiffData$ | async'
                                        [simplePatternFieldDiff]='simplePatternDiff'
                                        [showOnlyDifferences]='showOnlyDifferences$ | async'
                                        [currentUsername]='currentUsername'
        ></adm4-simple-pattern-field-diff>
        <adm4-deployment-host-diff *ngIf='deploymentHostDiffData$ | async'
                                   [deploymentHostDiff]='deploymentHostDiffData$ | async'
                                   [showOnlyDifferences]='showOnlyDifferences$ | async'
                                   [currentUsername]='currentUsername'
        ></adm4-deployment-host-diff>
        <adm4-property-diff *ngFor='let propertyDiff of propertyDiffs$ | async'
                            [propertyDiff]='propertyDiff' [variables]='variables$ | async'
                            [projectKey]='projectKey$ | async'
                            [showOnlyDifferences]='showOnlyDifferences$ | async'
                            [currentUsername]='currentUsername'
        ></adm4-property-diff>
        <adm4-pattern-notes-diff *ngIf='patternNotesDiffData$ | async'
                                 [patternNotesDiff]='patternNotesDiffData$ | async'
                                 [showOnlyDifferences]='showOnlyDifferences$ | async'
                                 [currentUsername]='currentUsername'
        ></adm4-pattern-notes-diff>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content dummy-space-taker'>
            <div class='remote full-height'></div>
            <div class='local full-height'></div>
          </div>
        </div>
        <!-- when pattern is deleted the local part of diff view is covered with this block -->
        <div *ngIf='isDeletedPattern' class='deleted-pattern-local-info'>
          <mat-icon class='deleted-pattern-icon'>delete_forever</mat-icon>
          <div>
            <span class='deleted-pattern-name'>{{changesetItem.name}}</span>
            is deleted
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
