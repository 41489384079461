import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { DeploymentFileContents, DeploymentFileInfo } from './deployment-file-urls.model';
import { ValidatingHelper } from '@deployment-common/validating/validating.helper';
import { PatternLogHelper } from '@deployment-common/validating/validating-items/pattern-log.helper';
import { DeploymentItemStatusModel } from '@deployment-common/validating/deployment-status.model';
import { FileService } from '@common/services/file/file.service';
import { ErrorHelper } from '@common/helpers/error.helper';
import { HTTP_STATUS_NOT_FOUND } from '../../shared/http-status-codes.constants';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { indicate } from '../../rx.utils';

@Injectable()
export class DeploymentFileViewService {

  private readonly _viewingFileInfo: BehaviorSubject<DeploymentFileInfo | null> = new BehaviorSubject(null);
  private _loading = new Subject<boolean>();

  /**
   * Emits value to either view file or close viewing, providing file id should show file, providing null should close it
   * @type {BehaviorSubject<DeploymentFileInfo | null>}
   */
  public readonly viewingFileInfo: Observable<DeploymentFileInfo | null> = this._viewingFileInfo.asObservable();
  public readonly viewingFileContent: Observable<DeploymentFileContents | null>;
  public readonly loading$: Observable<boolean> = this._loading.asObservable();

  constructor(
    private fileService: FileService,
    private modalNotificationService: ModalNotificationService,
  ) {
    this.viewingFileContent = this.viewingFileInfo.pipe(
      switchMap((fileInfo: DeploymentFileInfo | null): Observable<DeploymentFileContents> => {
        if (_.isNil(fileInfo)) {
          return of({localFileContent: '', remoteFileContent: '', path: ''});
        }
        return forkJoin([
          this.downloadLocalFile(fileInfo),
          this.downloadRemoteFile(fileInfo),
        ]).pipe(
          indicate(this._loading),
          map((fileContents: string[]): [string, string] => {
            const local = fileContents[0];
            const remote = fileContents[1];
            const transformedLocal = _.isFunction(fileInfo.fileContentMapper) ? fileInfo.fileContentMapper(local) : local;
            return [transformedLocal, remote];
          }),
          map(([local, remote]: [string, string]): DeploymentFileContents => {
            return {localFileContent: local, remoteFileContent: remote, remoteRequired: fileInfo.remoteRequired, path: fileInfo.path};
          }),
        );
      }),
      shareReplay(1),
    );
  }

  public viewFile(fileInfo: DeploymentFileInfo | null): void {
    this._viewingFileInfo.next(fileInfo);
  }

  public closeViewer(): void {
    this._viewingFileInfo.next(null);
  }

  public viewDeploymentStatusModel(item: DeploymentItemStatusModel): void {
    this.viewFile({
      path: ValidatingHelper.combineItemLogName(item),
      localFileUrl: <string>item.log,
      // log from the backend always comes from same source containing all info
      // until it's change on BE, we grep that log per deployment item from same log
      fileContentMapper: (content: string) => PatternLogHelper.grepPatternLog(content, item)
    });
  }

  private downloadLocalFile(fileUrls: DeploymentFileInfo): Observable<string> {
    return this.fileService.loadFileContent(fileUrls.localFileUrl);
  }

  private downloadRemoteFile(fileUrls: DeploymentFileInfo): Observable<string> {
    if (!_.isNil(fileUrls.remoteFileUrl)) {
      return this.fileService.loadFileContent(fileUrls.remoteFileUrl).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error downloading remote file for', fileUrls);
          console.error(error);
          if (error.status === HTTP_STATUS_NOT_FOUND) {
            return of('');
          }
          const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong with downloading the remote file.');
          this.modalNotificationService.openErrorDialog({title: 'Could not download remote file', description: errorMessage});
          return of('');
        }));
    } else if (fileUrls.remoteRequired) {
      return of('');
    } else {
      return of('');
    }
  }
}
