<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width bundle-management-table' [dataSource]='tableDataSource'>
  <!-- Name Column -->
  <ng-container [matColumnDef]="bundleNameColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon class='expand-icon' [class.expanded]='isGroupExpanded(element)'>chevron_right</mat-icon>
      {{element.latestBundle.symbolicName}}
    </mat-cell>
  </ng-container>

  <!-- Version Column -->
  <ng-container [matColumnDef]="versionColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class='d-inline-flex align-items-center'>
        <span>{{element.latestBundle.version}}</span>
      </span>
    </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container [matColumnDef]="actionsColumnName">
    <mat-header-cell *matHeaderCellDef><span class="ml-3"> Action </span></mat-header-cell>
    <mat-cell *matCellDef="let bundleGroup" class='d-inline-flex align-items-center'>
        <a mat-icon-button download class="flex-center-xy"
          [href]='bundleGroup.latestBundle.bundleKey | bundleDownloadUrl' [adm4StopEventPropagation]='["click"]'
          [ngbTooltip]='downloadTooltip'
        ><mat-icon class="m-0 color-green-c1">file_download</mat-icon></a>
        <ng-template #downloadTooltip>
          <span>Download the library <strong>{{bundleGroup.latestBundle.bundleKey}}</strong></span>
        </ng-template>
        <span [ngbTooltip]="noPermissionTooltip" [disableTooltip]="hasSuperAdmin">
            <button mat-icon-button class="flex-center-xy" [disabled]="!hasSuperAdmin" (click)='onDeleteBundleGroup($event, bundleGroup)'
                    [ngbTooltip]="deleteTooltip">
              <mat-icon class="m-0" [class.color-green-c1]="hasSuperAdmin">delete_forever</mat-icon>
            </button>
        </span>
        <ng-template #deleteTooltip>
            <span>Delete the library <strong>{{bundleGroup.bundleKey}}</strong></span>
        </ng-template>
        <ng-template #noPermissionTooltip>
            <span>You don't have permission to delete plugin libraries.</span>
        </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The previous version bundles row is made up of this one column that spans across all columns -->
  <ng-container [matColumnDef]="previousBundleVersionsColumnName">
    <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div [@previousVersionBundlesExpand] *ngIf='isGroupExpanded(element)' class='expanded-previous-versions full-width'>
        <adm4-bundle-table-component *ngIf='groupHasPreviousVersionBundles(element); else noPreviousVersions'
                                     [bundles]='element.previousVersionBundles'
                                     [hasSuperAdmin]="hasSuperAdmin"
                                     (deleteBundle)="deleteBundle.emit($event)"
        ></adm4-bundle-table-component>
        <ng-template #noPreviousVersions>
            <adm4-empty-resource class="no-previous-version-bundles-message" [leftAligned]="true">
                <span>There are no previous versions uploaded for this pattern library.</span>
            </adm4-empty-resource>
        </ng-template>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)='toggleBundleGroup(row)'></mat-row>
  <mat-row *matRowDef="let row; columns: expandableColumns;" class='expanded-previous-versions-row' [class.expanded]='isGroupExpanded(row)'></mat-row>
</mat-table>
