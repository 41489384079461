<section>
	<h2>About the Kubernetes Inventory File</h2>
    <p>An inventory file is the place where the deployment environment is defined.
    It is divided into different sections: <code>services</code>, <code>vars</code>, <code>consts</code>, <code>deployment-repository</code> and <code>kubernetes-cluster</code>.</p>
    <ul>
        <li>The <code>services</code> section defines all Nevis components that are deployed. Each service will define one Nevis component.</li>
        <li>The <code>vars</code> section contains variables which are defined for the whole inventory. Variables can also be defined at <code>service</code> level.</li>
        <li>The <code>consts</code> section allows you to create constants that can be reused in multiple variables.</li>
        <li>The <code>deployment-repository</code> section contains the configuration to connect to the Git deployment repository.
            Generated service configurations are transferred to Kubernetes via this repository.</li>
        <li>The <code>kubernetes-cluster</code> section contains the configuration to connect to the Kubernetes cluster for the deployment.</li>
    </ul>
    <p>The <code>deployment-repository</code> and <code>kubernetes-cluster</code> sections are mandatory, whereas <code>services</code>, <code>vars</code>, <code>consts</code> are optional.</p>

    <p>It is common practice to have more than one inventory file: namely one for each stage.
    For example: one inventory file describes the services used in your test stage and a second inventory file describes the services used in your production stage.</p>
</section>

<section>
	<h2>File Format</h2>
    <p>The inventory file uses YAML syntax. To get familiar with the YAML syntax, use one of the many online tutorials.
    In YAML, indentation is a crucial part of the syntax and is done with spaces. If tab stops are used, that leads to syntax errors.</p>

    <p>The integrated editor converts a tab stop automatically into two spaces, but be careful when copy-pasting from external editors.
    The syntax is automatically validated while you are editing the inventory file.</p>

    <p>The green traffic light in the top right corner switches to red to indicate syntax violation.
    Hovering over the traffic light reveals details about the syntax violation.</p>

    <p>A Kubernetes inventory file must contain the <code>service</code> and <code>schemaVersion</code>:</p>

    <ul>
        <li>The <code>schemaType</code> defines the deployment type. Set the value to <code>Kubernetes</code> to indicate that it is a Kubernetes inventory.
            If <code>schemaType</code> is not set, it will be considered a <code>Classic</code> inventory.</li>
        <li>The <code>schemaVersion</code> defines the version of the inventory, which ensures that future format changes can be handled.</li>
    </ul>

    <p>The <code>manifestTarget</code> defines the target of the generated Kubernetes manifests.
    With <code>Cluster</code> the manifests will be directly upserted to the Kubernetes cluster, while with the <code>Git</code> option, the manifests will be only pushed to the deployment repository.
    The later one allows the usage of GitOps tools such as ArgoCD to handle the actual deployment of the resources.</p>

    <p>Optionally, you can define a color for the inventory.
    This helps to distinguish between inventories at a glance, For example you can assign different colors for different stages.
	The available values for <em>color</em> are:
		<span class='pill red'>Red</span>,
		<span class='pill yellow'>Yellow</span>,
		<span class='pill green'>Green</span>,
		<span class='pill purple'>Purple</span>,
		<span class='pill brown'>Brown</span> and
		<span class='pill blue'>Blue</span>.
	</p>
	<p>The main part of the inventory file consists of the following five sections: <em>services</em>, <em>vars</em>, <em>deployment-repository</em> and <em>kubernetes-cluster</em>.</p>
<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
schemaType: Kubernetes
schemaVersion: 1

color: Red

services:
  &lt;items&gt;
groups:
  &lt;items&gt;
vars:
  &lt;items&gt;
deployment-repository:
  &lt;items&gt;
kubernetes-cluster:
  &lt;items&gt;
</pre>
</section>

<section>
  <h2>Services</h2>
    <p>The services section defines the Kubernetes services to be deployed. You can think about services as a list of Nevis components you want to deploy.
    Each service defines a logical set of Pods running a Nevis component. To get familiar with the Kubernetes concepts, use one of the many online tutorials.</p>

    <p>Configurations options:</p>
    <ul>
    <li><code>version</code>: Defines the version of the component's Docker image to be deployed. By default it will take the latest version that has been tested with the pattern library you're using.
        You can configure the pattern library in your project settings.</li>
        <li><code>git-init</code>:
        <ul>
        <li><code>version</code>: Defines the version of the Docker image to be used for the git-init container.</li>
        <li><code>mirror</code>:
        <ul>
            <li><code>claim-name</code>: Name of the persistent volume claim to use as fallback for your pods to read the configuration from, in case git becomes unavailable.
                Unless <code>storage-class-name</code>is specified, an existing claim has to be used. The claim has to support <code><code></code></code> access mode.</li>
            <li><code>storage-class-name</code>: Name of the StorageClass to be used by the claim. If specified nevisOperator will attempt to create the claim if it does not exist.
                The StorageClass has to support <code><code></code></code> access mode.</li>
            <li><code>size</code>: Size of the created persistent volume claim. Default: 20Gi</li>
        </ul>
        </ul>
        </li>
    <li><code>replicas</code>: Defines the number of Pods where the component will be replicated. By default it will create one replica.
    <li><code>requiredDBVersion</code>: Defines the minimum version of the database schema required by the component. Warning: this is an advanced setting. We recommend that you do not change this setting. It means that the latest tested and supported version will be used.
    <li><code>database</code>:
        <ul>
            <li><code>version</code>: Defines the actual version of the database schema pushed to the database server. Caution: this is an advanced setting.
            We recommend that you do not change this setting. It means that the latest tested and supported version is used.</li>
        </ul>
    </li>
    <li><code>resources</code>:  Defines how much memory and CPU a component Pod can use. The defaults for your version of the nevisAdmin 4 pattern library can be seen in the generated NevisComponent resources in the deployment preview.
    <li><code>time-zone</code>: Defines the time zone of the created Kubernetes pods. It expects a time zone string such as <code>America/Los_Angeles</code>
    <li><code>autoscaler</code>:
        <ul>
            <li><code>min-replicas</code>: Defines the minimum number of replicas that the horizontal autoscaler uses.</li>
            <li><code>max-replicas</code>: Defines the maximum number of replicas the horizontal autoscaler uses.</li>
            <li>(Optional) <code>cpu-average-value-target</code>: Defines the average CPU usage across the component Pods that the horizontal autoscaler tries to maintain,
                by downscaling or upscaling the deployment. By default, this value is set to 70% of the component CPU limit.</li>
            <li>(Optional) <code>memory-average-value-target</code>: Defines the average memory usage across the component Pods that the horizontal autoscaler tries to maintain,
                by downscaling or upscaling the deployment. For this to work properly, the memory has to decrease proportionally to the load in a reasonable timeframe.</li>
        </ul>
    </li>
    <li><code>affinity</code>: Defines the affinity settings to be used for the component in Kubernetes format. A special placeholder <code>{{'postfix'}}</code> can be used to refer to the current side-by-side postfix.
    <li><code>custom-volumes</code>: Can be used to define custom volumes for the deployment. A list of <code>volumeMount</code>-<code>volume</code> pairs, see yaml example below.
    <ul>
        <li><code>volumeMount</code>: VolumeMount definition, see <adm4-external-link [linkUrl]="'https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.28/#volumemount-v1-core'"
            [linkLabel]='"the official Kubernetes documentation"'
            [displayStyle]="'inline'"
            [matIconName]="'open_in_new'"
            [openInNewTab]='true'></adm4-external-link>
        </li>
        <li><code>volume</code>: Volume definition, see <adm4-external-link [linkUrl]="'https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.28/#volume-v1-core'"
            [linkLabel]='"the official Kubernetes documentation"'
            [displayStyle]="'inline'"
            [matIconName]="'open_in_new'"
            [openInNewTab]='true'></adm4-external-link>
        </li>
    </ul>
    </li>

    <li><code>pod-disruption-budget</code>:
        <ul>
            <li><code>min-available</code>: The number of pods that has to be available. It can be either an absolute number or a percentage.</li>
            <li><code>max-unavailable</code>: The number of pods that can be unavailable. It can be either an absolute number or a percentage.
                By default it is set to 50%, which means that with at least 2 replicas, one is always available.</li>
            <li><code>enabled</code>: Set to <code>false</code> to disable the generation of the PDB.</li>
            <p class="admonition-warning"><strong>CAUTION</strong><br/>
                It's possible to set up the PDB in a way, that all pods have to be available at all times, which means that the operations that effect nodes, such as node drain, cluster upgrade etc. fail.<br/>
                Make sure to read the official Kubernetes documentation about <adm4-external-link [linkUrl]="'https://kubernetes.io/docs/concepts/workloads/pods/disruptions/#pod-disruption-budgets/'"
                                      [linkLabel]='"pod disruption budgets"'
                                      [displayStyle]="'inline'"
                                      [matIconName]="'open_in_new'"
                                      [openInNewTab]='true'></adm4-external-link>
                and  <adm4-external-link [linkUrl]="'https://kubernetes.io/docs/tasks/run-application/configure-pdb/'"
                                [linkLabel]='"configuring PDBs"'
                                [displayStyle]="'inline'"
                                [matIconName]="'open_in_new'"
                                [openInNewTab]='true'></adm4-external-link>,
                paying a special attention to how the rounding is handled, before specifying a PDB.<br/>
                After the PDB is created, it can be further verified by checking that the <em>allowed disruptions</em> is at least one in the created resource.
            </p>
        </ul>
    </li>

    <li><code>topology-spread-constraints</code>: Defines the topology spread constraints to be used for the deployment.
        For more information see the official Kubernetes documentation
        about <adm4-external-link [linkUrl]="'https://kubernetes.io/docs/concepts/scheduling-eviction/topology-spread-constraints/'"
                                 [linkLabel]='"topology spread constraints"'
                                 [displayStyle]="'inline'"
                                 [matIconName]="'open_in_new'"
                                 [openInNewTab]='true'></adm4-external-link>
    <li><code>pod-security</code>:
        <ul>
            <li><code>policy</code>: Pod Security Standard policy to comply with. Supported values: <code>restricted</code>, <code>baseline</code></li>
            <li><code>automountServiceAccountToken</code>: If enabled, the service account token will be mounted to the running Pods.</li>
            <li><code>fsGroup</code>: Defines the <code>fsGroup</code> for the Deployment.</li>
            <li><code>runAsUser</code>: Defines the <code>runAsUser</code> for the Deployment.</li>
            <li><code>seccomp</code>:
            <ul>
                <li><code>localhost-profile</code>: Use a custom seccomp profile with type <code>LocalHost</code> instead of the default <code>RuntimeDefault</code></li>
            </ul>
        </ul>
    </li>

    <li><code>labels</code>: Additional custom labels for the Deployment.
    </ul>
  <!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
  <pre>
services:
- ebanking-proxy:
    kubernetes:
      version: "3.14.0.1-32"
      git-init:
        version: "1.2"
        mirror:
          claim-name: giteamirror
          storage-class-name: azurefile
          size: 30Gi
      replicas: 2
      requiredDBVersion: "0.1"
      database:
        version: "0.1"
      resources:
        limits:
          cpu: 1000m
          memory: 1000Mi
        requests:
          cpu: 20m
          memory: 100Mi
      time-zone: America/Los_Angeles
      autoscaler:
        min-replicas: 2
        max-replicas: 4
        cpu-average-value-target: 600m
        memory-average-value-target: 1000Mi
      custom-volumes:
      - volumeMount:
          name: tz-istanbul
          mountPath: /etc/localtime
        volume:
          name: tz-istanbul
          hostPath:
            path: /usr/share/zoneinfo/Europe/Istanbul
      pod-disruption-budget:
        max-unavailable: 50%
      labels:
        myKey: myValue
      pod-security:
        policy: restricted
        localhost-profile: profiles/audit.json
      affinity:
        podAntiAffinity:
          preferredDuringSchedulingIgnoredDuringExecution:
          - weight: 100
            podAffinityTerm:
              labelSelector:
                matchExpressions:
                - key: deploymentTarget
                  operator: In
                  values:
                  - ebanking-proxy{{'postfix'}}
      topologyKey: kubernetes.io/hostname
      topology-spread-constraints:
      - maxSkew: 1
        topologyKey: zone
        whenUnsatisfiable: DoNotSchedule
        labelSelector:
          matchLabels:
            deploymentTarget: ebanking-proxy{{'postfix'}}
      - ebanking-auth
        policy: restricted
</pre>

    <p>If you want all your services to have the same <code><code></code></code> and <code>replicas</code>, you can define these attributes as global inventory attributes:</p>

    <pre>
services:
- ebanking-proxy
- ebanking-auth

kubernetes:
  version: "3.14.0.1-32"
  replicas: 2
</pre>

    <p>For the full set of options see <adm4-external-link [linkUrl]="'nevisadmin4/Appendixes/File-Formats-Reference/Kubernetes-Infrastructure-Inventory-YAML-file-format' | docLink"
                                            [linkLabel]='"Kubernetes Infrastructure Inventory YAML file format"'
                                            [displayStyle]="'inline'"
                                            [matIconName]="'open_in_new'"
                                            [openInNewTab]='true'></adm4-external-link> chapter the nevisAdmin 4 documentation.</p>
</section>

<section>
	<h2>Vars (Variables)</h2>
    <p>The vars section defines variables that are valid for the entire inventory.
    This means that they are defined for every service in the services section. It is common practice to define variables at this level.</p>

    <p>The names of the variables are defined in the project. In the inventory the values are assigned to these variables.</p>

    <p>In case some services need different values for the same variable, they can be configured at the corresponding service level.
    Keep in mind that variables defined on service level override variables defined on inventory level.
    Try to keep the confusion at a minimum by defining the variables at the appropriate level, rather than using precedence.</p>

    <p>Variable names consist only of letters, numbers, underscores and dashes.</p>

    <p>Variables do not only support simple key/value pairs, but also more complicated structures, such as lists, dictionaries and even nested structures.</p>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
vars:
  proxy_bind_address: https://www.siven.ch/

  proxy_alias:
    - https://www.nevis.ch/
    - https://www.nevis-security.ch/
    - https://www.nevis-security.de/

  # property with multiple value
  session:
    ttl_max_sec: 43200
    inactive_interval_sec: 1800

  # variable example for key-value property
  log-level:
    - EsAuthStart: INFO
    - org.apache.catalina.startup.HostConfig: Error
</pre>
</section>

<section>
  <h2>Consts (Constants)</h2>
  <p>Constants allows you to create one constant and use it in multiple variables.
		What you only need to do is to link your constant to variables by inserting a reference in the following formats:</p>
  <ul>
		<li>For local constants: <code>{{'${const://constantVariableName}'}}</code></li>
		<li>For global constants: <code>{{'${g-const://constantVariableName}'}}</code></li>
	</ul>
	<p>This will also give you a possibility to manage your variables efficiently. If you need to update the value of
        your variable later on, you will no longer need to update it in multiple places where it is used, but simply you
        update the constant and this will apply to all variables which are referencing to the given constant.</p>
  <p>Constants cannot be referenced in another constants.</p>

	<h3>Local constants</h3>
	<p>Local constants can be created and used only in the given inventory.
        To create and use local constants, define consts section in the inventory file and list the constants in that section.
        To use a constant in your variable, insert its reference as a variable value.</p>

  <!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
  <pre>
consts:
  c1: nevis.net
  c2: nevis
  c3: 8080
  c4: true
  c5: secret://cd445cb0e611e26f8f6480c2

vars:
  var1: {{'${const://c1}'}}                         # resolved: nevis.net
  var2: {{'http://${const://c1}:${const://c3}'}}    # resolved: http://nevis.net:8080
  var3: {{'https://${const://notfound}'}}           # resolved: {{'https://${const://notfound}'}}
  var4: {{'${const://c3}'}}                         # resolved: 8080 (note: 8080 as a number, not as a string)
  var5: {{'${const://c4}'}}                         # resolved: true (note: true as a boolean, not as a string)
  var6:
  varKey: {{'${const://c2}'}}                       # resolved: nevis
  var7:
    - {{'${const://c1}'}}                           # resolved: [ nevis.net, portal.nevis.com ]
    - portal.nevis.com
  var8: {{'${const://c5}'}}                         # resolved: secret://cd445cb0e611e26f8f6480c2
  </pre>

  <h3>Global constants</h3>
  <p>Unlike local constants, the global constants can be created in Global Constants screen and used in multiple inventories.
      To use a global constant in your variable, insert its reference as a variable value. You can insert the reference in three ways:</p>
  <ul>
    <li>By using the <strong>Insert global constant</strong>button</li>
    <li>By copy-pasting it (copy it from the <strong>Global Constants screen</strong>)</li>
    <li>By manually typing it in the format of <code>{{'${g-const://constantVariableName}'}}</code></li>
  </ul>
</section>

<section>
	<h2>Deployment repository</h2>
	<p>In the deployment-repository section, the configuration of the version control repository used during the deployment is defined.</p>
    <p>During deployment, the generated Nevis configuration files will be pushed to this repository.
        Subsequently, the configuration will be fetched from within the Kubernetes cluster, to initialize the pods.</p>
    <p>The version control repository <code>url</code> and the <code>branch</code> must be defined.</p>
<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
deployment-repository:
  url: ssh://git&#64;host.com/siven/ebanking.git
  branch: acceptancetest
</pre>
</section>

<section>
	<h2>Kubernetes cluster</h2>
	<p>In the kubernetes-cluster section, the configuration required to connect to the Kubernetes cluster is defined.</p>
	<p>You need to define the <code>url</code> of a running Kubernetes cluster, the <code>namespace</code> within the cluster and the <code>token</code> required to authenticate to the cluster.</p>
	<p>For security reasons, it is highly recommended adding the token as a secret.</p>
	<p>If nevisAdmin 4 runs on Kubernetes, or the <code>manifestTarget</code> is set to <code>Git</code>, then the <code>token</code> and <code>url</code> are not required.</p>
<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
kubernetes-cluster:
  url: https://siven.kubernetes.cluster:443
  namespace: siven-nva4
  token: secret://0af63020f0dd51194ae100df
</pre>
</section>

<section>
    <h2>Product Analytics</h2>
    <p>For this feature, the setup described in the <adm4-external-link [linkUrl]="'nevisadmin4/Installation/Software-Installation/Product-Analytics' | docLink"
                                                                [linkLabel]='"Product Analytics"'
                                                                [displayStyle]="'inline'"
                                                                [matIconName]="'open_in_new'"
                                                                [openInNewTab]='true'></adm4-external-link>
        chapter the nevisAdmin 4 documentation must be in place.</p>
    <p>To enable a default Open Telemetry configuration in the project, inventory variable must be configured with the
        correct otel collector endpoints under the <code>__otel</code> variable.</p>
    <pre>
vars:
  __otel:
    metricsEndpoint: http://otel-otel-collector.observability:4318/v1/metrics
    logsEndpoint: http://otel-otel-collector.observability:4318/v1/logs
    tracesEndpoint: http://otel-otel-collector.observability:4318/v1/traces
  </pre>
</section>

<section>
	<h2>Disable Patterns</h2>
	<p class="admonition-info">Instance patterns can not be disabled in Kubernetes deployments.</p>
    <p>If you want to exclude some patterns from the deployment, you can disable them.
        For example, this allows you to apply a pattern in one stage while skipping it in another.
        The following predefined variables can be used:</p>

	<ul>
		<li>
			<code>__disabled_patterns</code>: There are two ways to disable a pattern: by its name or by referencing its ID. It is possible to combine both options on the same list.
            Pattern name supports wildcard character asterisk <code>*</code> which matches zero or any number of characters. It can be used multiple times in pattern name.
		</li>
		<li>
			<code>__disabled_pattern_types</code>: Disables all the patterns of a certain type. Pattern types are defined by their fully-qualified class name.
		</li>
	</ul>

	<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
	<pre>
vars:
  __disabled_patterns:
    - "SSO Realm"
    - "*Step*"
    - "PEM*"
    - "*Dev"
    - "Generic*Settings"
    - "pattern://d3f51b1fcbd3eaf433588645"

  __disabled_pattern_types:
    - "ch.nevis.admin.v4.plugin.nevisauth.patterns2.LdapLogin"
</pre>

</section>
