import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IssueModel } from '../../../common/model/issue.model';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { IssueSeverityEnum } from '../../../common/model/issue-severity.enum';
import { getHighestSeverity } from '../../project-issues/project-issues.helper';
import * as _ from 'lodash';
import { MetaInfo } from '../../../version-control/meta-info.model';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { IOutputData } from 'angular-split/lib/interface';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';
import { IssueSeverityCount } from '../../project-issues-display.model';
import { Observable } from 'rxjs';
import { PublishProjectContext } from '../publish-project.context';
import { PublishProjectInput } from '../../project.model';

@Component({
  selector: 'adm4-publish-project-changeset',
  templateUrl: './publish-project-changeset.component.html',
  styleUrls: ['./publish-project-changeset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([SplitMixin])
export class PublishProjectChangesetComponent implements ISplitMixin, OnChanges {
  @Input() changesetItems: ProjectChangesetItem<MetaInfo, any>[];
  @Input() projectKey: string;
  @Input() allProjectIssues: IssueModel[];
  @Input() initialCommitMessage: string;
  @Input() currentUserName: string | null;
  @Output() userInputChanged: EventEmitter<PublishProjectInput> = new EventEmitter();
  @Output() changesetItemSelected: EventEmitter<ProjectChangesetItem<MetaInfo, any>> = new EventEmitter();

  generalIssues$: Observable<IssueModel[]>;
  patternIssueNumberBySeverity$: Observable<IssueSeverityCount>;

  highestIssueSeverity: IssueSeverityEnum;
  isValid: boolean;
  isError: boolean;
  isWarning: boolean;

  selectedChangesetItem?: ProjectChangesetItem<MetaInfo, any>;

  readonly form: FormGroup<{ commitMsg: FormControl<string | null>; tag: FormControl<string | null>; }>;

  readonly changesetSplitAreaKey = 'changeset';
  readonly commitMessageSplitAreaKey = 'commit-message';
  readonly splitPaneConfigLocalStorageKey = 'publish-project-changeset-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.changesetSplitAreaKey]: {order: 0, size: 75},
    [this.commitMessageSplitAreaKey]: {order: 1, size: 25}
  };

  /** Implemented by SplitMixin */
  onResize: (event: IOutputData) => void;

  constructor(
      private publishContext: PublishProjectContext,
      fb: FormBuilder,
  ) {
    this.form = fb.group({
      commitMsg: fb.control('', {nonNullable: false}),
      tag: fb.control('', {nonNullable: false}),
    });
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(formValue => {
      const tag: string | undefined = formValue.tag?.trim();
      const tags: string[] = (tag !== undefined && tag.length > 0) ? [tag] : [];
      this.userInputChanged.emit({commitMessage: formValue.commitMsg??'', tags});
    });

    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.generalIssues$ = this.publishContext.generalIssues$;
    this.patternIssueNumberBySeverity$ = this.publishContext.patternIssueNumberBySeverity$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialCommitMessage) {
      this.form.patchValue({commitMsg: this.initialCommitMessage});
    }
    if (changes.changesetItems && !_.isEmpty(this.changesetItems)) {
      this.setInitialSelection();
    }
    if (changes.allProjectIssues) {
      this.highestIssueSeverity = getHighestSeverity(this.allProjectIssues);
      this.isValid =  this.highestIssueSeverity === IssueSeverityEnum.NO_ISSUE || this.highestIssueSeverity === IssueSeverityEnum.INFO;
      this.isError = this.highestIssueSeverity === IssueSeverityEnum.ERROR;
      this.isWarning = this.highestIssueSeverity === IssueSeverityEnum.WARNING;
    }
  }

  isSelectedChangesetItem(changesetItem: ProjectChangesetItem<MetaInfo, any>): boolean {
    return !_.isNil(this.selectedChangesetItem) && this.selectedChangesetItem.key === changesetItem.key;
  }

  selectChangesetItem(selectChangesetItem: ProjectChangesetItem<MetaInfo, any>): void {
    if (this.isSelectedChangesetItem(selectChangesetItem)) {
      return;
    }
    this.selectedChangesetItem = selectChangesetItem;
    this.changesetItemSelected.emit(selectChangesetItem);
  }

  public navigateToIssues(): void {
    this.publishContext.navigateToProjectIssues();
  }

  setInitialSelection(): void {
    setTimeout(() => {
      const initialSelection = this.changesetItems[0];
      this.selectChangesetItem(initialSelection);
    });
  }
}
