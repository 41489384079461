import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';

import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError, filter,
  finalize,
  first,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { ActiveToast } from 'ngx-toastr';
import { DeploymentActivity } from './deployment-activity-context.service';
import { DeploymentService } from '../../deployment-wizard/deployment-dialog/deployment.service';
import { JobService } from '../../shared/job.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { AppState } from '../../model/reducer';
import { selectedTenantKeyView, userKeyView } from '../../model/views';
import {
  DeploymentProcessModel,
  DeploymentProcessState,
  BackgroundDeploymentResponse
} from '@deployment-common/deployment-process.model';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { DeploymentItemStatusModel, Status } from '@deployment-common/validating/deployment-status.model';
import {
  BackgroundDeploymentDialogComponent,
  BackgroundDeploymentDialogData
} from '../../deployment-background/background-deployment-dialog/background-deployment-dialog.component';
import { exitBackgroundDeployment, selectBackgroundDeployment } from '../../deployment-background/background-deployment.actions';
import { Maybe } from '@common/utils/utils';
import { TenantHelper } from '@common/helpers/tenant.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';

const createDeploymentKey = (projectKey: string, inventoryKey: string): string => `${projectKey}-${inventoryKey}`;

@Injectable()
export class BackgroundDeploymentContextService {

  private readonly startBackgroundDeployment$: Subject<DeploymentProcessModel> = new Subject();
  private readonly deploymentActivities: Map<string, DeploymentActivity> = new Map();
  private readonly refresh$: Subject<void> = new Subject();

  public readonly backgroundDeploymentActivity: Observable<DeploymentActivity[]>;

  constructor(
    private readonly deployApi: DeploymentService,
    private readonly jobs: JobService,
    private readonly toasts: ToastNotificationService,
    private readonly store: Store<AppState>,
    private readonly matDialogs: MatDialog,
    private readonly modals: ModalNotificationService,
  ) {
    this.setupBackgroundDeploymentActivity().pipe(takeUntilDestroyed()).subscribe();

    this.backgroundDeploymentActivity = this.refresh$.pipe(
      map((): DeploymentActivity[] => {
        return Array.from(this.deploymentActivities.values());
      }),
      startWith([]),
      shareReplay(1),
    );
  }

  public startBackgroundDeployment(deployment: DeploymentProcessModel) {
    this.startBackgroundDeployment$.next(deployment);
  }

  private setupBackgroundDeploymentActivity(): Observable<void> {
    return this.startBackgroundDeployment$.pipe(
      switchMap((deployment: DeploymentProcessModel): Observable<{deployment: DeploymentProcessModel, userKey: string, originalTenantKey: string}> => {
        return combineLatest([
          this.store.select(userKeyView).pipe(first()),
          this.store.select(selectedTenantKeyView).pipe(first()),
        ]).pipe(
          map(([userKey, originalTenantKey]: [string, string]) => ({deployment, userKey, originalTenantKey})),
        );
      }),
      mergeMap(({deployment, userKey, originalTenantKey}): Observable<void> => {
        const newDeploymentActivity: DeploymentActivity
          = {projectKey: deployment.projectKey, inventoryKey: deployment.inventoryKey, userKey, isProjectRedacted: false, isInventoryRedacted: false, localJob: true, tenantKey: originalTenantKey};
        const deploymentKey = createDeploymentKey(deployment.projectKey, deployment.inventoryKey);
        this.deploymentActivities.set(deploymentKey, newDeploymentActivity);
        this.refresh$.next();
        return this.startBackgroundAndPollAndGetDeployment(deployment).pipe(
          first(),
          withLatestFrom(this.store.select(selectedTenantKeyView)),
          first(),
          filter(([_deploymentProcess, currentTenantKey]: [DeploymentProcessModel, string]): boolean => currentTenantKey === originalTenantKey),
          tap(([deploymentProcess, _currentTenantKey]: [DeploymentProcessModel, string]) => {
            const status = deploymentProcess.state;
            switch (status) {
              case DeploymentProcessState.Generating:
              case DeploymentProcessState.Planning:
                console.warn(`BackgroundDeploymentContextService#setupBackgroundDeploymentActivity, unexpected deployment process status when BG deployment job finished`,
                  {deploymentProcess});
                break;
              case DeploymentProcessState.Initialized:
              case DeploymentProcessState.Generated:
                this.notifyEarlyFailure(deploymentProcess);
                break;
              case DeploymentProcessState.Planned:
                this.handlePlanned(deploymentProcess);
                break;
              case DeploymentProcessState.Deploying:
              case DeploymentProcessState.Deployed:
                this.notifyDeployed(deploymentProcess);
                break;
            }
          }),
          mapTo(undefined),
          finalize(() => {
            this.deploymentActivities.delete(deploymentKey);
            this.refresh$.next();
          }),
          catchError((error): Observable<void> => {
            console.error(`BackgroundDeploymentContextService#createBackgroundDeployment, error during BG deployment`, deployment);
            console.error(error);
            return EMPTY;
          }),
        );
      }),
    );
  }

  /**
   * Starts a BG deployment, polls the deployment job until it is finished, and then loads the deployment process.
   * @param request
   * @private
   */
  private startBackgroundAndPollAndGetDeployment(request: DeploymentProcessModel): Observable<DeploymentProcessModel> {
    return this.deployApi.createBackgroundDeployment(request).pipe(
      first(),
      switchMap((bgDeployment: BackgroundDeploymentResponse): Observable<string> =>
        this.jobs.pollJobWithQuickFinish(bgDeployment.jobUrl).pipe(
          first(),
          map(() => bgDeployment.deploymentId),
        )
      ),
      switchMap((deploymentId: string) => {
        return this.deployApi.getDeploymentProcess(deploymentId).pipe(first());
      }),
      catchError(error => {
        console.error(`BackgroundDeploymentContextService#startBackgroundAndPollAndGetDeployment, error creating and starting deployment`, request);
        console.error(error);
        this.modals.openHttpErrorDialog(error, 'Unexpected error when starting background deployment');
        return EMPTY;
      }),
    );
  }

  /**
   * Notifies the user about early failure, ie. if the deployment failed before the actual deployment could have been started.
   */
  private notifyEarlyFailure(deploymentProcess: DeploymentProcessModel): void {
    const inventoryLabel: string = TenantHelper.cropTenantFromKey(deploymentProcess.inventoryKey);
    const projectLabel: string = TenantHelper.cropTenantFromKey(deploymentProcess.projectKey);
    this.toasts.showPersistedToastWithClose(
      'error',
      `Background deployment of <strong>${projectLabel}</strong> to <strong>${inventoryLabel}</strong> has <strong>failed</strong>.`
      + '<br/>For details run the deployment inside the wizard.',
      false, null,
    );
  }

  private handlePlanned(deploymentProcess: DeploymentProcessModel): void {
    this.deployApi.getStatusOfDeploymentPlanning(deploymentProcess.deploymentId).pipe(first()).subscribe((deploymentStatus: DeploymentStatusModel) => {
      const {itemsCount, someFailed, projectLabel, inventoryLabel, status} = this.extractStatusFromDeploymentModel(deploymentStatus, deploymentProcess);
      if (
        (status === Status.Done && itemsCount === 0)
        || (status === Status.Done && itemsCount > 0 && !someFailed)
      ) {
        // successful deployment, but no deployment happened -> blue info toast, no dialog
        this.showNoopInfoToast(projectLabel, inventoryLabel);
      } else {
        this.notifyEarlyFailure(deploymentProcess);
      }
    });
  }

  /**
   * Notifies the user about the deployment's status, for the cases where the actual deployment was started.
   * @param deploymentProcess
   * @private
   */
  private notifyDeployed(deploymentProcess: DeploymentProcessModel): void {
    this.deployApi.getStatusOfDeploy(deploymentProcess.deploymentId).pipe(first()).subscribe((deploymentStatus: DeploymentStatusModel) => {
      const {itemsCount, someFailed, projectLabel, inventoryLabel, status, deploymentId} = this.extractStatusFromDeploymentModel(deploymentStatus, deploymentProcess);
      let toast: Maybe<ActiveToast<void>>;
      if (status === Status.Done && itemsCount === 0) {
        // successful deployment, but no deployment happened -> blue info toast, no dialog
        this.showNoopInfoToast(projectLabel, inventoryLabel);
      } else if (status === Status.Done && itemsCount > 0 && !someFailed) {
        // successful deployment, with items, none of them failed -> green success toast, showing the dialog
        toast = this.toasts.showPersistedToastWithClose(
          'success',
          `Background deployment of <strong>${projectLabel}</strong> to <strong>${inventoryLabel}</strong> was successful.`,
          false, 'Show report',
        );
        this.handleToastActivation(deploymentId, toast);
      } else {
        // failed deployment -> red error toast, dialog
        toast = this.toasts.showPersistedToastWithClose(
          'error', `Background deployment of <strong>${projectLabel}</strong> to <strong>${inventoryLabel}</strong> has failed.`,
          false, 'Show report');
        this.handleToastActivation(deploymentId, toast);
      }
    });
  }

  private showNoopInfoToast(projectLabel: string, inventoryLabel: string): void {
    this.toasts.showPersistedToastWithClose(
      'info',
      `Background deployment of <strong>${projectLabel}</strong> to <strong>${inventoryLabel}</strong> was skipped due to an existing identical deployment.`,
      false, null,
    );
  }

  private handleToastActivation(deploymentID: string, toast: ActiveToast<void> | null): void {
    if (!toast) {
      console.warn(`BackgroundDeploymentContextService#handleToastActivation, toast missing for deploymentID`, deploymentID);
      return;
    }
    toast.onTap.pipe(
      take(1),
    ).subscribe(
      () => this.openBackgroundDeployment(deploymentID),
    );
  }

  public openBackgroundDeployment(deploymentId: string): void {
    this.store.dispatch(selectBackgroundDeployment({deploymentID: deploymentId}));
    const matDialogRef = this.matDialogs.open<BackgroundDeploymentDialogComponent, BackgroundDeploymentDialogData, void>(BackgroundDeploymentDialogComponent, {
      data: {deploymentId},
      panelClass: ['dialog-w-900', 'dialog-h-700'],
      disableClose: true,
    });
    matDialogRef.afterClosed().pipe(
      take(1),
    ).subscribe(
      () => {
        this.store.dispatch(exitBackgroundDeployment());
      },
    );
  }

  private extractStatusFromDeploymentModel(
    deploymentStatus: DeploymentStatusModel, deploymentProcess: DeploymentProcessModel,
    ): {itemsCount: number, someFailed: boolean, projectLabel: string, inventoryLabel: string, deploymentId: string, status: Status} {
    const items: DeploymentItemStatusModel[] = deploymentStatus.items;
    const someFailed: boolean = items.some(item => Status.Failed === item.status);
    const status: Status = deploymentStatus.status;
    const {projectKey, inventoryKey, deploymentId} = {...deploymentProcess};
    const inventoryLabel: string = TenantHelper.cropTenantFromKey(inventoryKey);
    const projectLabel: string = TenantHelper.cropTenantFromKey(projectKey);
    return {itemsCount: items.length, someFailed, projectLabel, inventoryLabel, deploymentId, status};
  }
}
