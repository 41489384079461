import { Injectable } from '@angular/core';
import { PropertyWidgetContext } from '../../property-widgets/property-widget.context';
import { Dictionary } from '../../model/reducer';
import { Observable } from 'rxjs';
import { Pattern } from '../../patterns/pattern.model';
import { PatternType } from '../../plugins/pattern-type.model';
import { PublishProjectContext } from './publish-project.context';
import { map } from 'rxjs/operators';
import { PatternDiffData, ViewAttachmentFileDiffEvent } from './pattern-diff-view/pattern-diff-data.model';
import * as _ from 'lodash';
import { Project } from '../project.model';
import { ResourceList } from '../../patterns/pattern-attachment.model';
import { PropertyWidgetContextType } from '../../property-widgets/property-widget-context-type.enum';

@Injectable()
export class PublishProjectPropertyContext implements PropertyWidgetContext {
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  patterns$: Observable<Map<string, Pattern>>;
  currentProject$: Observable<Project | null>;
  type = PropertyWidgetContextType.PUBLISH;
  allPatternResources$: Observable<Dictionary<Dictionary<ResourceList>>>;

  constructor(private publishProjectContext: PublishProjectContext) {
    this.currentProject$ = this.publishProjectContext.currentProject$;
    this.patterns$ = this.publishProjectContext.patterns$;
    this.patternTypes$ = this.publishProjectContext.patternTypes$;
    this.allPatternResources$ = this.publishProjectContext.patternsDiff$.pipe(
      map((patternDiffs: PatternDiffData[]) => {
        return patternDiffs.reduce((allPatternResources: Dictionary<Dictionary<ResourceList>>, patternDiff: PatternDiffData) => {
          if (_.isEmpty(patternDiff.patternResources)) {
            return allPatternResources;
          }
          return {...allPatternResources, [patternDiff.patternId]: patternDiff.patternResources};
        }, {});
      })
    );
  }

  public toggleDiffAttachmentResource(item: ViewAttachmentFileDiffEvent): void {
    this.publishProjectContext.toggleDiffAttachmentResource(item);
  }
}
