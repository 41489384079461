import { NgModule } from '@angular/core';
import { NavigationComponent } from './navigation.component';
import { NavigationService } from './navigation.service';
import { UserMenuModule } from '../username-menu/user-menu.module';
import { CommonModules } from '../common/common.module';
import { TabsComponent } from './tabs/tabs.component';
import { TabItemComponent } from './tabs/tab.item.component';
import { TabsDeploymentActivityComponent } from './tabs/tabs-deployment-activity/tabs-deployment-activity.component';

@NgModule({
  imports: [
    CommonModules,
    UserMenuModule
  ],
  declarations: [
    NavigationComponent,
    TabsComponent,
    TabItemComponent,
    TabsDeploymentActivityComponent,
  ],
  providers: [
    NavigationService
  ],
  exports: [
    NavigationComponent
  ]
})
export class NavigationModule {
}
