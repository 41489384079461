<div class='publish-project-container full-height-flex'>
  <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
    <as-split-area [order]='splitPaneConfig[listSplitAreaKey].order' [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='20'>
      <div class='changeset-container full-height'>
        <adm4-publish-project-changeset [changesetItems]='changesetItems'
                                        [projectKey]="projectKey"
                                        [allProjectIssues]='issues$ | async'
                                        [initialCommitMessage]="initialCommitMessage"
                                        [currentUserName]='currentUsername'
                                        (changesetItemSelected)='onChangesetItemSelected($event)'
                                        (userInputChanged)='onUserInputChanged($event)'
        ></adm4-publish-project-changeset>
      </div>
    </as-split-area>
    <as-split-area [order]='splitPaneConfig[diffViewSplitAreaKey].order' [size]='splitPaneConfig[diffViewSplitAreaKey].size' [minSize]='40'>
      <div class="full-height-flex">
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content diff-view-container'>
            <ng-template #diffViewContainer></ng-template>
          </div>
        </div>
        <div mat-dialog-actions>
          <button class='admn4-button-text'
                  (click)='triggerCancel()'>Cancel
          </button>
          <button class='admn4-button-ellipse-blue'
                  (click)='triggerPublish()'>
            Publish
          </button>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
