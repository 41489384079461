<mat-card class="overflow-hidden"><mat-card-content class="padding-small deployment-card">
<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width deployment-history' [dataSource]='tableDataSource'>
  <!-- Status Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.StatusColumnName">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <adm4-inventory-deployment-state-icons [historyItem]="element"></adm4-inventory-deployment-state-icons>
    </mat-cell>
  </ng-container>

  <!-- Project Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.ProjectColumnName">
    <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
    <mat-cell *matCellDef="let element">

      <ng-template #projectName>
        <span>{{element.projectKey | cropTenantFromKey}}</span>
      </ng-template>
      <ng-template #projectNameDeleted>
        <span>{{element.projectKey | cropTenantFromKey}} (DELETED)</span>
      </ng-template>

      <div class='project-key-text' [ngbTooltip]='element.projectDeleted ? projectNameDeleted : projectName'
           placement='top-left'>
        <ng-container *ngTemplateOutlet="element.projectDeleted ? projectNameDeleted : projectName"></ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Deployed To Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.DeployedToColumnName">
    <mat-header-cell *matHeaderCellDef> Deployed to </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span>{{getDeployToOfDeploymentHistoryItem(element)}}</span>
    </mat-cell>
  </ng-container>

  <!-- Last action user Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.DeployedByColumnName">
    <mat-header-cell *matHeaderCellDef> Triggered by </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span>{{element.userKey}}</span>
    </mat-cell>
  </ng-container>

  <!-- Start date Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.StartDateColumnName">
    <mat-header-cell *matHeaderCellDef>{{displayedStartDateColumnName}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span [ngbTooltip]='displayedDateTooltip(element.timestamp | date:"medium", element.deploymentState, element.userKey)'
            placement='top-left'>{{element.timestamp| customDate}}</span>
    </mat-cell>
  </ng-container>

  <!-- Deploy Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.DeployColumnName">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class='deploy-button-cell'>
      <button *ngIf='canRedeployDeployment(element)' type='button' class='admn4-button-ellipse-blue'
              [adm4StopEventPropagation]='["click"]'
              (click)='triggerRedeployDeployment(element)'
      >
        Deploy
      </button>
    </mat-cell>
  </ng-container>

  <!-- Expand Icon Column -->
  <ng-container [matColumnDef]="deploymentHistoryTableColumns.ExpandIconColumn">
    <mat-header-cell *matHeaderCellDef class='expand-icon-cell'></mat-header-cell>
    <mat-cell *matCellDef="let element" class='expand-icon-cell'>
      <mat-icon>{{this.isDeploymentExpanded(element) ? 'expand_less' : 'expand_more'}}</mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The details of deployment row is made up of this one column that spans across all columns -->
  <ng-container [matColumnDef]="deploymentDetailsColumnName">
    <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <adm4-inventory-deployment-details
        [historyItem]="element"
        [deployedServices]="deployedServices"
        [expanded]="isDeploymentExpanded(element)"
        [isKubernetesInventory]="isKubernetesInventory"
        [isKubernetesStatusScreen]="isKubernetesStatusScreen"
        [isSecondary]="isSecondary"
        (openPodLogsDialog)="openPodLogsDialog($event)"
      ></adm4-inventory-deployment-details>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)='toggleDeploymentDetails(row)' class='deployment-history-row cursor-pointer'
           [class.deleted-prj]="row.projectDeleted"
  ></mat-row>
  <mat-row *matRowDef="let row; columns: expandableColumns;" class='expanded-deployment-details' [class.expanded]='isDeploymentExpanded(row)'></mat-row>
</mat-table>
</mat-card-content></mat-card>
