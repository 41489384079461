<adm4-modal-dialog-title [header]='header'
                         [showClose]='true'
                         (closeClicked)="closeDialog()">
  <div class='full-height-flex'>
    <div class='rules-dialog-container content-container remaining-space-flex-content-wrapper bg-screen-white'>
      <adm4-admonition class="mb-3" severity='info' alignment='start'>
        <p class="m-0" adm4-admonition-msg>Copy log snippets with <em>IW4ModsecF</em> from the nevisProxy navajo.log. <br>
          <strong>Insert one log per line</strong> in the first box and convert to allowlist rules.
        </p>
      </adm4-admonition>
      <div class='whitelist-editor-container' #editorContainer>
        <ngx-monaco-editor class="h-100 default-inventory-box-shadow overflow-hidden" [(ngModel)]='editedContent'
                           [options]='editorOptions'
                           (ngModelChange)='inputModelChanged()'
                           (onInit)='setInitialFocus($event)'>
        </ngx-monaco-editor>
      </div>
      <div class="mt-2 validation-message-container" *ngIf='hasInputWarning$ | async'>
          <adm4-validation-message *ngFor='let warning of inputWarnings$ | async' [isWarning]='true' [message]='warning.detail'></adm4-validation-message>
      </div>
      <div class='convert-button-container'>
        <button class='admn4-button-ellipse-blue' type='button' [disabled]="isConvertDisabled"
                (click)='convertToRule()'>Convert to rule
        </button>
      </div>
      <div class='whitelist-editor-container output-rules-container' #outputRuleContainer>
        <ngx-monaco-editor class="h-100 default-inventory-box-shadow overflow-hidden" [(ngModel)]='convertedResult'
                           [options]='outputOptions'
                           (ngModelChange)='outputModelChanged()'>
        </ngx-monaco-editor>
      </div>
      <div class="mt-2 mb-0 validation-message-container" *ngIf='hasOutputWarning$ | async'>
        <adm4-validation-message *ngFor='let warning of outputWarnings$ | async' [isWarning]='true' [message]='warning.detail'></adm4-validation-message>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class='admn4-button-text' (click)='closeDialog()'>Cancel</button>
      <button class='admn4-button-ellipse-blue' [disabled]='!canConfirmInsert' (click)="applyEdit()">
        Insert
      </button>
    </div>
  </div>
</adm4-modal-dialog-title>
