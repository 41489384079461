import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { DeploymentItemStatusModel, Status } from '@deployment-common/validating/deployment-status.model';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { deploymentStatusView } from '../../model/views';
import { filter, map, scan, share } from 'rxjs/operators';
import { DeploymentPollingHelper } from './deployment-polling.helper';
import * as _ from 'lodash';

@Injectable()
export class DeploymentContext {
  /**
   * Raw deployment status that comes from backend, needed to track changes compared to next responses
   */
  deploymentStatus$: Observable<DeploymentStatusModel | undefined>;

  /**
   * Deployment status adjusted and prepared for displaying
   */
  displayableDeploymentStatus$: Observable<DeploymentStatusModel>;
  doneItemsCount$: Observable<number>;
  failedItemsCount$: Observable<number>;
  hasDoneItems$: Observable<boolean>;
  hasFailedItems$: Observable<boolean>;
  isFinished$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.deploymentStatus$ = this.store$.pipe(select(deploymentStatusView));
    this.displayableDeploymentStatus$ = this.deploymentStatus$.pipe(
      scan(([_oldValue, previousStatus]: [DeploymentStatusModel | undefined, DeploymentStatusModel | undefined], currentStatus: DeploymentStatusModel) => [previousStatus, currentStatus], []),
      filter(([_previousStatus, newStatus]: [DeploymentStatusModel | undefined, DeploymentStatusModel | undefined]) => !_.isNil(newStatus)),
      map(([previousStatus, newStatus]: [DeploymentStatusModel | undefined, DeploymentStatusModel]) => DeploymentPollingHelper.updatePolledDeploymentStatus(newStatus, previousStatus)),
      share()
    );
    this.doneItemsCount$ = this.deploymentStatus$.pipe(
      filter((deploymentStatus: DeploymentStatusModel | undefined) => !_.isNil(deploymentStatus)),
      map((deploymentStatus: DeploymentStatusModel) => (deploymentStatus.items??[]).filter(item => item.status === Status.Done)),
      map((doneItems: DeploymentItemStatusModel[]) => doneItems.length)
    );
    this.failedItemsCount$ = this.deploymentStatus$.pipe(
      filter((deploymentStatus: DeploymentStatusModel | undefined) => !_.isNil(deploymentStatus)),
      map((deploymentStatus: DeploymentStatusModel) => (deploymentStatus.items??[]).filter(item => item.status === Status.Failed)),
      map((failedItems: DeploymentItemStatusModel[]) => failedItems.length)
    );
    this.hasDoneItems$ = this.doneItemsCount$.pipe(map((doneItemsCount: number) => doneItemsCount > 0));
    this.hasFailedItems$ = this.failedItemsCount$.pipe(map((failedItemsCount: number) => failedItemsCount > 0));
    this.isFinished$ = this.deploymentStatus$.pipe(
      filter((deploymentStatus: DeploymentStatusModel | undefined) => !_.isNil(deploymentStatus)),
      map((deploymentStatus: DeploymentStatusModel) => deploymentStatus.status === Status.Done || deploymentStatus.status === Status.Failed)
    );
  }
}
