import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { AppState, Dictionary } from '../../model/reducer';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { BUNDLE_OLD_VERSION_ISSUE_CODE, IssueModel } from '../../common/model/issue.model';
import { projectValidatingView } from '../../model/views';
import { Project, ProjectMetaChangeIndicator } from '../project.model';
import { NavigationService } from '../../navbar/navigation.service';
import { ProjectDependenciesDialogService } from '../../project-settings/project-dependencies/project-dependencies-dialog.service';
import { ProjectIssuesDisplayContext } from '../project-issues-display.context';
import { IssueSeverityCount } from '../project-issues-display.model';
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-project-header',
  template: `
    <ng-template #libraryUpdateTooltip>
      <p>There is a newer version of the libraries for the project.<br/> Click to update to the latest.</p>
    </ng-template>
    <ng-template #issuesTooltip>
      <adm4-project-issues-tooltip
        [generalIssues]="(generalIssues$ | async) || []"
        [patternIssueSeverityCount]="patternIssueNumberBySeverity$ | async"
        (navigateToIssues)="navigateToProjectIssues()"
      ></adm4-project-issues-tooltip>
    </ng-template>
    <div class="header-container">
      <div *ngIf='projectKey' class='menu-key-wrapper'>
        <div class="left-project">
          <span class="project-validation-indicator">
            <adm4-validation-indicator
                    [ngbTooltip]='issuesTooltip' placement='bottom-left' closeDelay="4000" [disableTooltip]="shouldHideIssuesTooltip"
                    [isDisplayed]="!(validating$ | async)"
                    [isValid]='isValid'
                    [isError]='isError'
                    [isWarning]='isWarning'
                    [isNeutral]='isNeutral'
                    [validating]='(validating$|async) || false'></adm4-validation-indicator>
          </span>
          <adm4-project-dropdown [projectKey]='projectKey'
                                 [projects]='projects'></adm4-project-dropdown>
        </div>
        <div class="right-icons">
          <mat-icon *ngIf='shouldDisplayLibraryUpdateIndicator' class='update-icon help-icon-with-action'
                    [ngbTooltip]='libraryUpdateTooltip' placement='left'
                    (click)='navigateToStandardLibraries()' role="link"
          >update</mat-icon>
          <ng-container *ngIf="showProjectChange">
            <ng-template #modifiedTooltip>
                <p>Another user, <em>{{lastModifiedByUser}}</em> has updated the project at {{lastModifiedTime | customDate}}</p>
            </ng-template>
            <div class="flex-center-xy prj-change-indicator" [ngbTooltip]="modifiedTooltip" placement="left">
              <mat-icon class="color-green-c1 position-absolute" [class.animate-ping]="showRealTimeChange">person</mat-icon>
              <mat-icon class="color-green-c1">person</mat-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/header.scss', './project-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectHeaderComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() projects: Dictionary<Project>;
  @Input() projectIssues: IssueModel[];
  @Input() highestSeverity: IssueSeverityEnum;
  @Input() projectMetaIndicator: Maybe<ProjectMetaChangeIndicator>;

  public showProjectChange: boolean = false;
  public lastModifiedTime: Maybe<Date>;
  public lastModifiedByUser: Maybe<string>;
  public showRealTimeChange: boolean = false;

  public shouldHideIssuesTooltip: boolean = true;
  public shouldDisplayLibraryUpdateIndicator: boolean = false;
  // for showing neutral status until something is loaded
  public isNeutral: boolean = true;
  public isValid: boolean = false;
  public isError: boolean = false;
  public isWarning: boolean = false;

  validating$: Observable<boolean>;
  public readonly generalIssues$: Observable<IssueModel[]>;
  public readonly patternIssueNumberBySeverity$: Observable<IssueSeverityCount>;

  constructor(private store$: Store<AppState>,
              private navigationService: NavigationService,
              private projectDependenciesDialogService: ProjectDependenciesDialogService,
              issuesContext: ProjectIssuesDisplayContext,
  ) {
    this.validating$ = this.store$.pipe(select(projectValidatingView));

    this.generalIssues$ = issuesContext.generalIssues$;
    this.patternIssueNumberBySeverity$ = issuesContext.patternIssueNumberBySeverity$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectIssues) {
      this.shouldDisplayLibraryUpdateIndicator = this.projectIssues.some((issue) => _.isEqual(issue.code, BUNDLE_OLD_VERSION_ISSUE_CODE));
      this.shouldHideIssuesTooltip = !this.projectIssues || this.projectIssues.length === 0;
    }
    if (changes.highestSeverity) {
      this.isNeutral = false;
      this.isValid = this.highestSeverity === IssueSeverityEnum.NO_ISSUE || this.highestSeverity === IssueSeverityEnum.INFO;
      this.isError = this.highestSeverity === IssueSeverityEnum.ERROR;
      this.isWarning = this.highestSeverity === IssueSeverityEnum.WARNING;
    }
    if (changes.projectMetaIndicator) {
      const newMetaIndicator: Maybe<ProjectMetaChangeIndicator> = this.projectMetaIndicator;
      if (newMetaIndicator?.isModifiedByOtherUser) {
        this.showProjectChange = true;
        this.lastModifiedTime = newMetaIndicator.lastModifiedTime;
        this.lastModifiedByUser = newMetaIndicator.lastModifiedByUser;
        this.showRealTimeChange = newMetaIndicator.showRealTimeChange;
      } else {
        this.showProjectChange = false;
        this.lastModifiedTime = null;
        this.lastModifiedByUser = null;
      }
    }
  }

  navigateToStandardLibraries(): void {
    this.navigationService.navigateToProjectSettings(this.projectKey).then(() => {
      this.projectDependenciesDialogService.triggerDependencyDialogOpen();
    });
  }

  navigateToProjectIssues(): void {
    this.navigationService.navigateToIssues(this.projectKey);
  }
}
