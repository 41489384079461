import { ProjectDependenciesHelper } from './project-dependencies.helper';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProjectBundle } from '../../projects/project.model';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'adm4-project-dependencies-table',
  template: `
    <ng-container>
      <mat-table [dataSource]="projectBundlesSource" class="adm4-mat-table no-shadow">
        <ng-container matColumnDef="select">
          <mat-header-cell mat-header-cell *matHeaderCellDef class="mat-column-checkbox">Enable</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-column-checkbox">
            <mat-checkbox *ngIf='!element.isMandatory'
                    (change)="onRowSelectionChange(element)"
                    [checked]="selection.isSelected(element.symbolicName)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell mat-header-cell *matHeaderCellDef class='dependency-name-header'> Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class='column-content'>
              <div class="name-container">
                <adm4-validation-indicator [isDisplayed]='isInvalidBundle(element)' [isError]='true' [diameter]='8'></adm4-validation-indicator>
                {{element.symbolicName}}
              </div>
              <span class='info-icon-container'>
                <em *ngIf='element.hint' class="fa fa-question-circle help-icon" [title]='element.hint' aria-hidden="true"></em>
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
          <mat-header-cell mat-header-cell *matHeaderCellDef class='version-column-header'>
            <span>Version</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index;">
            <span class='column-content full-width'>
              <mat-form-field *ngIf="isEditMode; else staticText" class='version-select-field'>
                <mat-select [ngModel]='element.selectedVersion'
                            [disableOptionCentering]='true'
                            (ngModelChange)="onVersionSelectionChange(element, $event)">
                  <mat-select-trigger>
                    <span [innerHTML]='showVersion(element.selectedVersion,element)'></span>
                  </mat-select-trigger>
                  <mat-option *ngFor='let version of element.allVersions' [value]='version' [innerHTML]='showVersion(version,element)'>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ng-template #staticText>
                <span>{{element.selectedVersion}}</span>
              </ng-template>
              <span class='info-icon-container'>
                <em *ngIf='!isLatestVersionSelected(element)' class="fa fa-info-circle help-icon" aria-hidden="true"
                    ngbTooltip="There is a newer version of this library available" placement="bottom-right"></em>
              </span>
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="isEditMode ? displayedColumnsEdit : displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: isEditMode ? displayedColumnsEdit : displayedColumns;"></mat-row>
      </mat-table>
    </ng-container>
  `,
  styleUrls: ['project-dependencies-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDependenciesTableComponent implements OnInit, OnChanges {
  @Input() isEditMode: boolean;
  @Input() projectBundles: ProjectBundle[];
  @Input() currentProjectBaseVersion: string | undefined;
  @Output() projectBundlesChange: EventEmitter<ProjectBundle[]> = new EventEmitter();

  initialBundles: ProjectBundle[];
  displayedColumns = ['name', 'version'];
  displayedColumnsEdit = ['select', 'name', 'version'];
  projectBundlesSource: MatTableDataSource<ProjectBundle> = new MatTableDataSource([]);
  selection: SelectionModel<string>; // contains selection information per bundle symbolicName

  ngOnInit() {
    this.initialBundles = this.projectBundles;
    this.initialBundles.forEach(bundle => bundle.currentProjectVersion = bundle.selectedVersion);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectBundles) {
      this.projectBundlesSource.data = this.projectBundles;
      this.selection = this.updateSelection(this.projectBundles);
    }
  }

  isInvalidBundle(projectBundle: ProjectBundle): boolean {
    return !_.includes(projectBundle.allVersions, projectBundle.selectedVersion);
  }

  updateSelection(projectBundles): SelectionModel<string> {
    const selectedProjectBundleSymbolicNames: string[] = projectBundles.filter(projectBundle => projectBundle.isActiveForProject).map((projectBundle: ProjectBundle) => projectBundle.symbolicName);
    return new SelectionModel<string>(true, selectedProjectBundleSymbolicNames);
  }

  isLatestVersionSelected(element: ProjectBundle): boolean {
    return element.selectedVersion === element.latestVersion;
  }

  onVersionSelectionChange(projectBundle: ProjectBundle, selectedVersion: string): void {
    const updatedProjectBundles: ProjectBundle[] = ProjectDependenciesHelper.updateProjectBundlesByBundleVersionChange(this.projectBundlesSource.data, projectBundle.symbolicName, selectedVersion);
    this.projectBundlesSource.data = updatedProjectBundles;
    this.projectBundlesChange.emit(updatedProjectBundles);
  }

  onRowSelectionChange(projectBundle: ProjectBundle): void {
    this.selection.toggle(projectBundle.symbolicName);
    const updatedProjectBundles: ProjectBundle[] = ProjectDependenciesHelper.updateProjectBundlesBySelectionChange(this.projectBundlesSource.data, this.selection);
    this.projectBundlesSource.data = updatedProjectBundles;
    this.projectBundlesChange.emit(updatedProjectBundles);
  }

  showVersion(version: string, element: ProjectBundle): string {
    return ProjectDependenciesHelper.displayedVersion(version, element, this.currentProjectBaseVersion);
  }
}
