import { Injectable } from '@angular/core';
import { AppState } from '../model/reducer';
import { Store } from '@ngrx/store';
import { AbortDeploymentPlanning, AbortGeneration, DeleteDeploymentProcess, ForceRedeployment, StartDeploy, StartDeploymentPlanning, StartDeploymentProcess, StartGeneration } from '../model/deploy';
import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';

@Injectable()
export class DeploymentProcessService {

  constructor(private store$: Store<AppState>) {}

  startDeploymentProcess(deployment: DeploymentProcessModel): void {
    this.store$.dispatch(new StartDeploymentProcess(deployment));
  }

  clearDeployProcess(): void {
    this.store$.dispatch(new DeleteDeploymentProcess());
  }

  startGeneration(deploymentId: string): void {
    this.store$.dispatch(new StartGeneration(deploymentId));
  }

  startDeploymentPlanning(deploymentId: string): void {
    this.store$.dispatch(new StartDeploymentPlanning(deploymentId));
  }

  startDeployment(deploymentId: string, comment: string): void {
    this.store$.dispatch(new StartDeploy({deploymentId: deploymentId, deploymentComment: comment}));
  }

  forceRedeployment(deploymentId: string): void {
    this.store$.dispatch(new ForceRedeployment(deploymentId));
  }

  abortGeneration(deploymentId: string): void {
    this.store$.dispatch(new AbortGeneration(deploymentId));
  }

  abortDeploymentPlanning(deploymentId: string): void {
    this.store$.dispatch(new AbortDeploymentPlanning(deploymentId));
  }
}
