import { ChangeDetectionStrategy, Component, Input, output, TrackByFunction } from '@angular/core';
import { ParsedBundle } from '../bundle.model';

@Component({
  selector: 'adm4-bundle-table-component',
  template: `
    <mat-table class='adm4-mat-table full-width bundle-table' [dataSource]='bundles' [trackBy]="trackBundles">
      <ng-container [matColumnDef]="bundleNameColumnName">
        <mat-cell *matCellDef="let bundle"> {{bundle.symbolicName}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="versionColumnName">
        <mat-cell *matCellDef="let bundle">
        <span class='d-inline-flex align-items-center'>
          <span>{{bundle.version}}</span>
        </span>
        </mat-cell>
      </ng-container>
        
      <ng-container [matColumnDef]="actionsColumnName">
        <mat-cell *matCellDef="let bundle">
        <span class='d-inline-flex align-items-center'>
          <a download mat-icon-button class="flex-center-xy" [href]='bundle.bundleKey | bundleDownloadUrl' [ngbTooltip]="downloadTooltip">
              <mat-icon class="m-0 color-green-c1">file_download</mat-icon>
          </a>
          <ng-template #downloadTooltip>
            <span>Download the library <strong>{{bundle.bundleKey}}</strong></span>
          </ng-template>
          <span [ngbTooltip]="noPermissionTooltip" [disableTooltip]="hasSuperAdmin">
            <button mat-icon-button class="flex-center-xy" [disabled]="!hasSuperAdmin" (click)='deleteBundle.emit(bundle)'
                    [ngbTooltip]="deleteTooltip">
              <mat-icon class="m-0" [class.color-green-c1]="hasSuperAdmin">delete_forever</mat-icon>
            </button>
          </span>
          <ng-template #deleteTooltip>
            <span>Delete the library <strong>{{bundle.bundleKey}}</strong></span>
          </ng-template>
          <ng-template #noPermissionTooltip>
            <span>You don't have permission to delete plugin libraries.</span>
          </ng-template>
        </span>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleTableComponent {
  @Input() bundles: ParsedBundle[];
  @Input() hasSuperAdmin: boolean;
  deleteBundle = output<ParsedBundle>();

  readonly bundleNameColumnName = 'bundleName';
  readonly versionColumnName = 'version';
  readonly actionsColumnName = 'actions';

  displayedColumns = [this.bundleNameColumnName, this.versionColumnName, this.actionsColumnName];

  public trackBundles: TrackByFunction<ParsedBundle> = (_index, item) => item.bundleKey;
}
