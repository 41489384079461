import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromProject from './project/project.reducer';
import * as fromPattern from './pattern/pattern.reducer';
import * as fromVariables from './variables/variable.reducer';
import * as fromVersionCtrl from './version-control/version-control.reducer';
import * as fromDeploy from './deploy/deploy.reducer';
import * as fromInventory from './inventory/inventory.reducer';
import * as fromUser from './user';
import * as fromRequest from './request/request.reducer';
import * as fromReport from './report/report.reducer';
import * as fromPublishProject from './publish-project/publish-project.reducer';
import * as fromPublishInventory from './publish-inventory/publish-inventory.reducer';
import * as fromResources from '../resources/reducers/index';
import * as fromShared from './shared/shared.reducer';
import * as fromBackgroundDeployment from '../deployment-background/background-deployment.reducer';
import { BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY } from '../deployment-background/background-deployment.reducer';
import { cacheReducer } from './cache';
import * as fromActions from './actions';
import { MarketplaceState } from '../resources/marketplace/marketplace.reducer';

export type ProjectKey = string;

export type Dictionary<T> = Record<string, T>;


// -- Cache state
export class EntityCache {
  readonly pluginDocumentations: Dictionary<string | null> = {};
}

// -- Root state
export class CoreAppState {
  readonly shared: fromShared.SharedState = fromShared.initState;
  readonly entities: EntityCache = new EntityCache();
  readonly project: fromProject.ProjectState = fromProject.initState;
  readonly pattern: fromPattern.PatternState = fromPattern.initState;
  readonly variable: fromVariables.VariableState = fromVariables.initState;
  readonly versionControl: fromVersionCtrl.VersionControlState = fromVersionCtrl.initState;
  readonly deploy: fromDeploy.DeployState = fromDeploy.initState;
  readonly inventory: fromInventory.InventoryState = fromInventory.initState;
  readonly userState: fromUser.UserState = fromUser.initState;
  readonly requestState: fromRequest.RequestState = fromRequest.initState;
  readonly report: fromReport.ReportState = fromReport.initState;
  readonly publishProject: fromPublishProject.PublishProjectState = fromPublishProject.initialState;
  readonly publishInventory: fromPublishInventory.PublishInventoryState = fromPublishInventory.initialState;
  readonly [BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY]: fromBackgroundDeployment.BackgroundDeploymentState = fromBackgroundDeployment.initialState;
}

export interface FeatureStates {
  readonly resources: fromResources.ResourcesState;
  readonly marketplace: MarketplaceState;
}

export type AppState = CoreAppState & FeatureStates;

export function initialState(): CoreAppState {
  return new CoreAppState();
}

export const reducers: ActionReducerMap<CoreAppState> = {
  shared: fromShared.sharedReducer,
  entities: cacheReducer,
  project: fromProject.reducer,
  pattern: fromPattern.reducer,
  variable: fromVariables.reducer,
  versionControl: fromVersionCtrl.reducer,
  deploy: fromDeploy.reducer,
  inventory: fromInventory.reducer,
  userState: fromUser.reducer,
  requestState: fromRequest.reducer,
  report: fromReport.reducer,
  publishProject: fromPublishProject.reducer,
  publishInventory: fromPublishInventory.reducer,
  [BACKGROUND_DEPLOYMENT_STORE_FEATURE_KEY]: fromBackgroundDeployment.backgroundDeploymentReducer,
};

export const metaReducers: MetaReducer<CoreAppState>[] = [
  clearStoreOnLogout,
];

export function clearStoreOnLogout(reducer: ActionReducer<CoreAppState, fromActions.NevisAdminAction>): ActionReducer<CoreAppState, fromActions.NevisAdminAction> {
  return (state: CoreAppState, action: fromActions.NevisAdminAction) => {
    let newState: CoreAppState;
    switch (action.type) {
      case fromUser.UserActionTypes.SignOut:
        newState = initialState();
        break;
      default:
        newState = state;
        break;
    }
    return reducer(newState, action);
  };
}
