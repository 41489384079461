import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { PatternListData } from './pattern-list-data.model';
import { ScrollService } from '../common/services/scroll/scroll.service';
import * as _ from 'lodash';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { LocalStatus } from '../version-control/meta-info.model';
import { getHighestSeverity, isUnusedPattern } from '../projects/project-issues/project-issues.helper';
import { NavigationService } from '../navbar/navigation.service';
import { DirtyFormGuardConnectorService } from '../common/services/dirty-form-guard-connector.service';
import { BatchSelectionContext } from './batch-actions/batch-selection.context';
import { PatternListScrollViewportConstants } from './pattern-list/pattern-list-scroll-viewport.constants';
import { IssueSeverity } from '../common/model/issue.model';

/**
 * This component represents a pattern instance.
 */
@Component({
  selector: 'adm4-pattern-list-element',
  template: `
    <ng-template #popContentPatternErrors>
      <adm4-pattern-error-list-hover
              [issues]='patternDetail.issues'
              [projectKey]='projectKey'></adm4-pattern-error-list-hover>
    </ng-template>
    <ng-template #popupLocalChanges>
      <adm4-local-changes-popup [metaInfo]="patternMetaInfo" (publishClick)='openPublishProjectDialog()'>
      </adm4-local-changes-popup>
    </ng-template>
    <mat-checkbox class='pattern-element-checkbox'
                  [style.opacity]="checkBoxOpacity"
                  [(ngModel)]='isChecked'
                  (change)='onCheckBoxClick()'
                  (mouseover)='hideCheckBox(true)'
                  (mouseout)='hideCheckBox(false)'></mat-checkbox>
    <adm4-simple-list-item [isSelected]='active' [isBatchSelection]='isChecked' [itemSize]='scrollConstants.ITEM_SIZE'
                           [link]="[routerLinkPrefix, patternDetail.pattern.patternId]" (mouseover)='hideCheckBox(true)' (mouseout)='hideCheckBox(false)'>
      <div class='list-element'>
        <adm4-validation-indicator class='validation-indicator'
                [ngbTooltip]='popContentPatternErrors' placement='bottom-left'
                [disableTooltip]="!shouldDisplayIssue"
                [isDisplayed]='shouldDisplayIssue'
                [isError]='isErrorIssue'
                [isWarning]='isWarningIssue'
                [isNeutral]='isUnusedIssue'
                [isInfo]='isInfoIssue'
                [diameter]='10'>
        </adm4-validation-indicator>
        <div class='list-element-content'>
          <div class='list-element-row'>
            <div class='list-element-title'
                 [innerHtml]="patternDetail.pattern.name | highlight : textToHighLight">
            </div>
            <div class='list-element-icon-container'>
              <mat-icon *ngIf='isCopied' class='expand-icon list-element-copy-button material-icons size-16 cursor-default'
                        [ngbTooltip]='copiedFromDialog' placement='right'>content_copy</mat-icon>
            </div>
            <ng-template #copiedFromDialog>Copied from another project</ng-template>
            <div class='category-container'>
              <button *ngFor='let category of patternCategories'
                      class='admn4-button-filter-pattern-icon'
                      [class.selected]='selectedCategories[category]'
                      [title]='category'>
                {{category | abbreviate: 2}}
              </button>
            </div>
          </div>
          <div class='list-element-row list-element-info'>
            <div class='list-element-type' [innerHtml]='patternDetail.type?.name | highlight : textToHighLight'></div>
            <div [ngClass]='patternMetaInfo?.localStatus === UNMODIFIED_STATUS ? "date-no-local-change" : "date-local-change"'
                 [class.greyed-out]="textToHighLight"
                 [ngbTooltip]='popupLocalChanges' [disableTooltip]='disableLocalChangesPopup'
                 placement='right' tooltipClass="adm4-tooltip behind-dialog">{{lastModificationDate| customDate}}
            </div>
          </div>
        </div>
      </div>
    </adm4-simple-list-item>
  `,
  styleUrls: ['pattern-list-element.scss', '../common/styles/component-specific/extended-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternListElementComponent implements OnChanges {
  @Input() patternDetail: PatternListData;
  @Input() active: boolean;
  @Input() textToHighLight: string;
  @Input() projectKey: string;
  @Input() scrollArea: any;
  @Input() selectedCategories: Record<string, boolean>;
  @Input() statusFiltersSelected: Map<string, boolean>;
  @Input() patternMetaInfo: PatternVersionInfo;
  @Input() selectedPattern: string;
  @Input() versioned: boolean;
  @Input() isChecked: boolean;
  @Output() triggerScroll = new EventEmitter<string>();
  @Output() checkboxSelect = new EventEmitter();
  shouldShowCheckBox = false;

  // calculated from `patternDetail`
  public highestSeverity: IssueSeverityEnum;
  public isErrorIssue: boolean;
  public isWarningIssue: boolean;
  public isUnusedIssue: boolean;
  public isInfoIssue: boolean;
  public patternCategories: string[] = [];

  // other calculated fields
  public routerLinkPrefix: string;
  public checkBoxOpacity: number;
  public shouldDisplayIssue: boolean;
  public isCopied: boolean;
  public disableLocalChangesPopup: boolean;

  public UNMODIFIED_STATUS = LocalStatus.Unmodified;
  readonly scrollConstants = PatternListScrollViewportConstants;

  public lastModificationDate: string | undefined;

  constructor(private readonly navigationService: NavigationService,
              private readonly formGuardConnectorService: DirtyFormGuardConnectorService,
              private readonly elRef: ElementRef,
              private readonly batchSelectionContext: BatchSelectionContext,
  ) {}

  hideCheckBox(shouldShow: boolean): void {
    this.shouldShowCheckBox = shouldShow;
    this.calculateCheckboxOpacity();
  }

  onCheckBoxClick(): void {
    this.checkboxSelect.emit(this.isChecked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((!_.isNil(changes.patternMetaInfo) || !_.isNil(changes.active)) && this.active) {
      if (ScrollService.isElemVisible(this.scrollArea, this.elRef.nativeElement)) {
        this.triggerScroll.emit(this.selectedPattern);
      }
    }

    if (changes['patternDetail']) {
      // If some bundles related to the pattern are deleted, patternDetail.type might be undefined,
      // but we should still indicate the issues of the pattern
      if (this.patternDetail.type) {
        this.patternCategories = _.sortBy(this.patternDetail.type.categories);
      }
      this.highestSeverity = getHighestSeverity(this.patternDetail.issues);
      this.isErrorIssue = this.highestSeverity === IssueSeverityEnum.ERROR;
      this.isInfoIssue = this.highestSeverity === IssueSeverityEnum.INFO && !this.hasUnusedIssue();
      this.isWarningIssue = this.highestSeverity === IssueSeverityEnum.WARNING;
      this.isUnusedIssue = this.highestSeverity === IssueSeverityEnum.INFO && this.hasUnusedIssue();
      const patternNameChanged = changes['patternDetail'] && changes['patternDetail'].currentValue && changes['patternDetail'].previousValue ? changes['patternDetail'].currentValue.pattern.name !== changes['patternDetail'].previousValue.pattern.name : false;
      if (patternNameChanged) {
        setTimeout(() => this.batchSelectionContext.updateBatchSelectionItem(this.patternDetail));
      }
      this.shouldDisplayIssue = !!(this.patternDetail.issues.length > 0 && this.isErrorIssue || this.isWarningIssue || this.isUnusedIssue || (this.isInfoIssue && this.statusFiltersSelected.get(IssueSeverity.INTO.valueOf())));
      this.isCopied = !_.isNil(this.patternDetail.pattern.link);
    }
    if (changes['patternMetaInfo'] && this.patternMetaInfo) {
      this.lastModificationDate = this.getLastModificationDate(this.patternMetaInfo);
    }
    if (changes.selectedPattern) {
      this.routerLinkPrefix = _.isEmpty(this.selectedPattern) ? '../patterns/' : '../';
    }
    if (changes.isChecked) {
      this.calculateCheckboxOpacity();
    }
    if (changes.versioned || changes.patternMetaInfo) {
      this.disableLocalChangesPopup = !this.versioned || !this.patternMetaInfo;
    }
  }

  private calculateCheckboxOpacity() {
    this.checkBoxOpacity = this.isChecked || this.shouldShowCheckBox ? 100 : 0;
  }

  private hasUnusedIssue() {
    return this.patternDetail.issues.some(issue => isUnusedPattern(issue));
  }

  /**
   * Returns local or remote modification date when it's possible to retrieve it
   * @param {PatternVersionInfo} patternMetaInfo
   * @returns {string}
   */
  getLastModificationDate(patternMetaInfo: PatternVersionInfo): string | undefined {
    if (patternMetaInfo.localStatus !== LocalStatus.Unmodified && _.isString(patternMetaInfo.localDate)) {
      return patternMetaInfo.localDate;
    } else if (_.isString(this.patternMetaInfo.remoteDate)) {
      return <string>patternMetaInfo.remoteDate;
    }
    return undefined;
  }

  openPublishProjectDialog(): void {
    this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToPublishProjectWindow());
  }
}
