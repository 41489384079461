<div class="deployment-step-content full-height-flex">
  <div class="remaining-space-flex-content-wrapper">
    <div class='remaining-space-flex-content'>
      <div class='step-content full-height-flex'>
        <div class="remaining-space-flex-content-wrapper">
          <div class="remaining-space-flex-content">
            <ng-template #validation>
              <ng-template #validating>
                <adm4-deployment-validating class='full-height'
                                            [generationStatus]='generationStatus$ | async'
                                            [showGenerationButtonVisible]='shouldShowGenerationResultsButton$ | async'
                                            (showGenerationResults)='displayGenerationOutput()'
                ></adm4-deployment-validating>
              </ng-template>
              <adm4-validation-results *ngIf='shouldShowValidationResults$ | async; else validating'
                                       class='full-height'
                                       [patterns]='patterns$ | async'
                                       [validationIssues]='generationIssues$ | async'
                                       [generalIssues]='generalIssues$ | async'
                                       [shouldDisplayGeneralIssues]='shouldDisplayGeneralIssues$ | async'
                                       [patternIssueGroups]='patternIssueGroups$ | async'
                                       [showGenerationButtonVisible]='shouldShowGenerationResultsButton$ | async'
                                       [boxShadowClass]='boxShadowClass'
                                       [projectKey]='projectKey$ | async'
                                       [variables]='variables$ | async'
                                       [selectedInventorySchemaType]='selectedInventorySchemaType$ | async'
                                       (showGenerationResults)='displayGenerationOutput()'
                                       (nodeSelected)='onIssueGroupSelect($event)'
              ></adm4-validation-results>
            </ng-template>
            <adm4-generation-output *ngIf='isGenerationResultVisible; else validation'
                                    class='full-height'
                                    [generationOutputArray]='generationOutput$ | async'
                                    [patterns]='patterns$ | async'
                                    [boxShadowClass]='boxShadowClass'
                                    [selectedInventorySchemaType]='selectedInventorySchemaType$ | async'
                                    (downloadGenerationResult)='onDownloadGenerationResultClick()'
            ></adm4-generation-output>
          </div>
        </div>
        <div class="step-content-action-bar">
          <button *ngIf='!(isValidationInProgress$ | async)'
                  class="step-content-action-button"
                  (click)="onRepeatValidation()">
            <span class="material-icons">replay</span>
            <span>Repeat validation</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="step-action-bar">
    <button *ngIf='isValidationInProgress$ | async; else stepButtons' (click)='onAbortGeneration()'
            class='previous-step-button'>Abort generation
    </button>
    <ng-template #stepButtons>
      <button class='previous-step-button'
              matStepperPrevious>Change selection
      </button>
      <div class='button-wrapper'
           [ngbTooltip]='nextStepDisabledTooltipTemplate$ | async' [disableTooltip]='!(disablePreviewDeployment$ | async)'
           placement='top' tooltipClass='adm4-tooltip small-tooltip'>
        <button class='next-step-button next-step-icon icon-play'
                [disabled]='disablePreviewDeployment$ | async'
                (click)='onPreviewDeploymentClick()'>
          Preview deployment
        </button>
      </div>
    </ng-template>
    <ng-template #noPermissionToProceedTooltip>
      <span>
        You don't have permission to deploy to this inventory.
      </span>
    </ng-template>
    <ng-template #generationFullyFailedTooltip>
      <span>Errors are preventing the deployment. Please resolve them and <a (click)='onRepeatValidation()'>repeat the validation</a>.</span>
    </ng-template>
    <ng-template #publishRequiredTooltip>
      <span>Please, make sure the project and the inventory are connected to Git and published.</span>
    </ng-template>
  </div>
</div>
