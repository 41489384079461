	<form [formGroup]='form' class="d-contents adm4-mat-dialog">
    <adm4-dialog-title mat-dialog-title [title]="header" [showClose]='true' (close)="cancel.emit()" class="mb-0"/>
		<div mat-dialog-content class='adm4-dialog-content scroll-shadows'>
			<h4 class='modal-local-heading'>Create new variable</h4>
			<div class='form-group mb-0 mt-2' [class.has-error]='form.controls[variableNameFormControlName].invalid'>
				<label class='input-label' for='new-variable-name-input'>Variable name* (may only contain: A-Z, a-z, 0-9, _ and -)</label>
				<input id='new-variable-name-input' type='text' class='admn4-text-input form-control'
							 [readonly]='createVariableInputDisabled'
							 [formControlName]="variableNameFormControlName">
				<div class='validation-message-container'>
					<adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('required')"
                                             [isError]='true' message='Variable name should not be empty'></adm4-validation-message>
                    <adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('existingVariable')"
                                             [isError]='true' message='Variable with such name already exists'></adm4-validation-message>
                    <adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('pattern')"
                                             [isError]='true' message='Variable name does not match [A-Za-z0-9_-]*'></adm4-validation-message>
				</div>
			</div>
			<div class='form-group mb-0 mb-4' *ngIf="canVariableBeDefault">
                <label id="set-variable-type-label" class='input-label d-block'>Variable type</label>
                <mat-radio-group aria-labelledby="set-variable-type-label" class="d-flex flex-column"
                                 [formControlName]="requireOverloadingFormControlName">
                    <mat-radio-button class="left-align" [value]="true">
                        <span><strong>Sample Value</strong>, has to be overridden in the inventory</span>
                    </mat-radio-button>
                    <adm4-admonition [severity]="requireControl.disabled ? 'inactive' : 'info'"
                                     [alignment]="'start'" class="mb-3 adm4-align-with-radio-label"
                                     [messages]="'A sample value is only used for validation. The actual deployed value has to be specified in the inventory.'">
                    </adm4-admonition>
                    <mat-radio-button class="left-align" [value]="false">
                        <span><strong>Default Value</strong>, can be overridden in the inventory</span>
                    </mat-radio-button>
                    <adm4-admonition [severity]="requireControl.disabled ? 'inactive' : 'info'"
                                     [alignment]="'start'" class="mb-3 adm4-align-with-radio-label"
                                     [messages]="['The default value will be used for validation and deployment. To override the value, specify it in the inventory.',
                                          'Variables with default values are marked with a ●.']">
                    </adm4-admonition>
                </mat-radio-group>
      </div>
			<div class='form-group mb-0 mb-4'>
                <label class='input-label'>{{(isSampleValue | async) ? 'Sample value' : 'Default value'}}</label>
                <ng-container *ngIf='canVariableBeDefault; else noSampleValueVariable'>
                  <ng-template adm4DynamicVariable
                               [readOnly]='createVariableInputDisabled'
                               [formGroup]='form'
                               [variableName]='variableValueFormControlName'
                               [variableValue]='form.value[variableValueFormControlName]'
                               [variableType]='createVariableData.variableType'
                               [variableParameters]='createVariableData.patternTypeProperty?.parameters'></ng-template>
                </ng-container>
                <ng-template #noSampleValueVariable>
                  <div class='validation-message-container'>
                      <adm4-no-sample-value-variable [variableType]='variableType' [secret]='isSecret'></adm4-no-sample-value-variable>
                  </div>
                </ng-template>
			</div>
      <div *ngIf='isVariableDropdownShown' class="mb-3">
              <h4 class='modal-local-heading'>Or select an existing variable</h4>
              <adm4-variable-selection [variables]='variablesForSelection'
                                       [projectKey]='createVariableData.projectKey'
                                       (variableSelected)='selectVariable($event)'
                                       (removeSelectedVariable)='selectVariable(null)'>
              </adm4-variable-selection>
      </div>
		</div>
    <mat-dialog-actions align="end" class="adm4-dialog-actions">
      <button type="button" class='admn4-button-text' (click)='cancel.emit()'>Cancel</button>
      <button type="button" class='admn4-button-ellipse-blue'
              [disabled]="form.invalid" (click)='save()'
      >Set variable</button>
    </mat-dialog-actions>
	</form>
