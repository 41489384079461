import { CanaryRoutingOption } from '@deployment-common/deployment-process.model';

export enum DeployToClassicOptionType {
  AllHosts = 'AllHosts',
  Host = 'Host',
  Group = 'Group',
  Pattern = 'Pattern'
}

/** Option types for the Cloud (Kubernetes) deployment */
export enum DeployToKubernetesOptionType {
  AllServices = 'AllServices',
  Service = 'Service',
  Group = 'ServiceGroup',
  Primary = 'Primary',
  Secondary = 'Secondary (side-by-side)'
}

export interface DeployToOptions {
  deployTargets: DeployToOption[];
  groups: DeployToOption[];
  patterns: DeployToOption[];
}

export interface DeployToOption {
  type: DeployToClassicOptionType | DeployToKubernetesOptionType;
  name: string;
  /** host expression is value that is sent to backend when we selected it */
  hostExpression?: string;
  comment?: string;
  canaryRouting?: CanaryRoutingOption;
}

export const ALL_HOSTS_HOST_EXPRESSION_NAME = 'All hosts';
export const ALL_SERVICES_HOST_EXPRESSION_NAME = 'All Services';
export const ALL_INSTANCE_PATTERNS_EXPRESSION_NAME = 'All instance patterns';
export const LAST_SELECTED_INSTANCE_PATTERNS_EXPRESSION_NAME = 'Last selected';
export const PRIMARY_HOST_EXPRESSION_NAME = 'Primary';
export const SECONDARY_HOST_EXPRESSION_NAME = 'Secondary (Canary, A/B)';

export const ALL_HOSTS_HOST_EXPRESSION_VALUE = '*';

export const allHosts: DeployToOption = {
  type: DeployToClassicOptionType.AllHosts,
  name: ALL_HOSTS_HOST_EXPRESSION_NAME,
  hostExpression: ALL_HOSTS_HOST_EXPRESSION_VALUE,
};

export const allServices: DeployToOption = {
  type: DeployToKubernetesOptionType.AllServices,
  name: ALL_SERVICES_HOST_EXPRESSION_NAME
};

export const primaryDeployOption: DeployToOption = {
  type: DeployToKubernetesOptionType.Primary,
  name: PRIMARY_HOST_EXPRESSION_NAME
};

export const DEFAULT_CANARY_ROUTING_OPTION: CanaryRoutingOption = {
  header: 'canary',
  cookie: 'canary'
};

export const secondaryDeployOption: DeployToOption = {
  type: DeployToKubernetesOptionType.Secondary,
  name: SECONDARY_HOST_EXPRESSION_NAME,
  canaryRouting: DEFAULT_CANARY_ROUTING_OPTION
};
