<adm4-diff-view-element [remoteCommitInfo]='variableDiff.remote.commitInfo'
                        [localChangeInfo]='variableDiff.local.changeInfo'
                        [localFallbackLabel]='variableDiff.local.fallbackLabel'
                        [currentUsername]='currentUsername'
                        [showOnlyDifferences]='showOnlyDifferences'
>
  <div remote class='variable-diff-side'>
    <adm4-variable-property [projectKey]="projectKey"
                            [variableType]='variableDiff.remote.variableType'
                            [variable]='variableDiff.remote.variable'
                            [formGroup]='remoteForm'
                            [issues]='variableDiff.remote.issues'
                            [withHeading]='true'
                            [showVariableType]="true"
                            [readOnly]='true'></adm4-variable-property>
  </div>
  <div local class='variable-diff-side'>
    <adm4-variable-property [projectKey]="projectKey"
                            [variableType]='variableDiff.local.variableType'
                            [variable]='variableDiff.local.variable'
                            [formGroup]='localForm'
                            [issues]='variableDiff.local.issues'
                            [withHeading]='true'
                            [showVariableType]="true"
                            [readOnly]='true'></adm4-variable-property>
  </div>
</adm4-diff-view-element>
