import { last, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { JobStatus, JobStatusProgress } from '../common/model/job-status.model';
import { POLL_INTERVAL } from '../common/constants/app.constants';
import { poll } from '../rx.utils';
import * as _ from 'lodash';


@Injectable()
export class JobService extends BaseService {

  /**
   * Getting current status of a job for specified url
   * @param {string} url
   * @returns {Observable<JobStatus>}
   */
  getJobStatus(url: string): Observable<JobStatus> {
    return this.httpGet(url);
  }

  /**
   * Polls the job specified by the relative URL until the job is finished, signalled by the `endTime` being present.<br/>
   * Throws an error if the job finishes with a non-completed `progress`.
   */
  pollJob(url: string): Observable<JobStatus> {
    return poll(POLL_INTERVAL,
      () => this.getJobStatus(url),
      (jobStatus: JobStatus) => _.isNil(jobStatus.endTime))
      .pipe(
        last(),
        tap(jobStatus => {
          if (!_.isNil(jobStatus.endTime) && jobStatus.progress < JobStatusProgress.Done) {
            // Job failed if progress less than 1.0 and it has endTime
            throw new Error('Job was finished before completing progress');
          }
        })
      );
  }

  /**
   * Polls the job specified by the relative URL until the job is finished, signalled by the `endTime` being present.<br/>
   * As opposed to `pollJob`, this does not throw an error if the job finishes with a non-complete `progress`.
   */
  pollJobWithQuickFinish(url: string): Observable<JobStatus> {
    return poll(POLL_INTERVAL,
      () => this.getJobStatus(url),
      (jobStatus: JobStatus) => _.isNil(jobStatus.endTime),
    ).pipe(last());
  }
}
