import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

/**
 * Displays a spinner overlay.<br/>
 * Note that it fills the whole parent and covers all it's siblings.
 */
@Component({
  selector: 'adm4-spinner-overlay',
  standalone: true,
  imports: [MatProgressSpinner],
  template: `<mat-spinner></mat-spinner>`,
  styles: [`
    :host {
        position: absolute;
        inset: 0;
        z-index: 1;
  
        cursor: wait;
        background-color: rgba(255, 255, 255, 0.5);
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
  `],
})
export class SpinnerOverlayComponent { }
