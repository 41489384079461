import { Component, input, output, InputSignal, OutputEmitterRef, ChangeDetectionStrategy } from '@angular/core';
import { NgIf } from '@angular/common';

import { MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
  selector: 'adm4-dialog-title',
  standalone: true,
  imports: [MatDialogTitle, MatIcon, MatIconButton, MatToolbar, NgIf],
  template:
`
  <span class="dialog-title">{{title()}}</span>
  <button type="button" mat-icon-button *ngIf="showClose()" aria-label="close" (click)='close.emit()'>
    <mat-icon>clear</mat-icon>
  </button>
`,
  styleUrl: './dialog-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTitleComponent {

  public readonly title: InputSignal<string> = input.required<string>();
  public readonly showClose: InputSignal<boolean | undefined> = input<boolean>();

  public readonly close: OutputEmitterRef<void> = output();
}
