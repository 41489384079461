import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { ParsedBundle } from '../bundle-management/bundle.model';
import { loadBundlesSuccess } from './bundle-management.actions';

export interface BundleManagementState {
  bundles: ParsedBundle[];
}

export const initialState: BundleManagementState = {
  bundles: []
};

export const bundlesReducer: ActionReducer<BundleManagementState, Action> = createReducer(
  initialState,
  on(loadBundlesSuccess, (state: BundleManagementState, action) => {
    return {
      ...state,
      bundles: action.bundles
    };
  }),
);
