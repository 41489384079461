import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type InventoryEditorUserActions = 'RESET' | 'INSERT-GLOBAL-CONSTANT' |
    'INSERT-SECRET-URI' | 'ATTACH-SECRET-FILE' | 'ATTACH-FILE' | 'ATTACH-CERT' | 'INSERT-PRJ-VARS';

export enum InventoryEditorTextActions {
  FOLD_ALL = 'adm4.inventory.editor.text.foldAll',
  UNFOLD_ALL = 'adm4.inventory.editor.text.unfoldAll',
  NEXT_PEEK = 'adm4.inventory.editor.text.nextPeek',
}

@Component({
  selector: 'adm4-inventory-editor-actions',
  template: `
  <mat-menu #editorActionsMenu="matMenu" class="mat-elevation-z8">
      <button mat-menu-item (click)='onNextError()' [disabled]="!nextMarkerActionEnabled"
              [ngbTooltip]="errorTooltip" placement="right" [disableTooltip]="!nextMarkerActionEnabled">
          <mat-icon>{{nextMarkerActionEnabled? 'arrow_downward' : 'check'}}</mat-icon>
          <span>{{nextMarkerActionEnabled ? 'Next validation error' : 'No validation issues found' }}</span>
      </button>
      <ng-template #errorTooltip>Open or select the details of the next inventory validation issue.</ng-template>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="onFoldAll()" [disabled]="diffView" [ngbTooltip]="foldAllTooltip" placement="right">
          <mat-icon>expand_less</mat-icon>
          <span>Fold All</span>
      </button>
      <ng-template #foldAllTooltip>Collapse all sections of the inventory yml file.<br/>You can also use the built-in controls of the editor near the line numbers.</ng-template>
      <button mat-menu-item (click)="onUnFoldAll()" [disabled]="diffView" [ngbTooltip]="expandAllTooltip" placement="right">
          <mat-icon>expand_more</mat-icon>
          <span>Unfold All</span>
      </button>
      <ng-template #expandAllTooltip>Expand all sections of the inventory yml file.
          <br/>You can also use the built-in controls of the editor near the line numbers.</ng-template>
  </mat-menu>
  <div class='actions'>
    <div class="row-1">
      <button type="button" mat-mini-fab [color]="'primary'" class="editor-actions-btn touch-target-contain"
              [matMenuTriggerFor]="editorActionsMenu">
          <mat-icon class="size-20">more_vert</mat-icon>
      </button>
      <span class="editor-actions-separator"></span>
      <ng-template #disabledTooltip>
        <ng-container *ngIf="readOnly; else hasPermissionButDisabled">
          <span>You do not have permission to modify this inventory.</span></ng-container>
        <ng-template #hasPermissionButDisabled><span>Please click inside the inventory file before you insert a secret,
          import variables or attach files.</span></ng-template>
        
      </ng-template>
      <div class="resource-actions">
        @let actionButtonsDisabled = actionsDisabled || readOnly;
        <div class="disabled-overlay" *ngIf="actionButtonsDisabled" [ngbTooltip]='disabledTooltip'></div>
        <button mat-button type="button" class="touch-target-contain icon-flex" [disabled]="actionButtonsDisabled" 
                [ngbTooltip]="'Insert global constant'" (click)="inventoryAction.emit('INSERT-GLOBAL-CONSTANT')">
            <mat-icon [svgIcon]="'global_constants'"></mat-icon>
        </button>
        <button mat-button type="button" class="touch-target-contain" [disabled]="actionButtonsDisabled"
                [ngbTooltip]="'Insert secret'" (click)="inventoryAction.emit('INSERT-SECRET-URI')">
            <mat-icon>lock</mat-icon>
        </button>
        <button mat-button type="button" class="touch-target-contain icon-flex" [disabled]="actionButtonsDisabled"
                [ngbTooltip]="'Attach secret file'" (click)="inventoryAction.emit('ATTACH-SECRET-FILE')">
            <mat-icon [svgIcon]="'file-lock'"></mat-icon>
        </button>
        <button mat-button type="button" class="touch-target-contain" [disabled]="actionButtonsDisabled"
                [ngbTooltip]="'Attach certificate'" (click)="inventoryAction.emit('ATTACH-CERT')">
            <mat-icon>verified</mat-icon>
        </button>
        <button mat-button type="button" class="touch-target-contain" [disabled]="actionButtonsDisabled"
                [ngbTooltip]="'Attach file'" (click)="inventoryAction.emit('ATTACH-FILE')">
            <mat-icon>description</mat-icon>
        </button>
        <button mat-button type="button" class="touch-target-contain" [disabled]="actionButtonsDisabled"
                [ngbTooltip]="'Import variables'" (click)="inventoryAction.emit('INSERT-PRJ-VARS')">
            <mat-icon>save_alt</mat-icon>
        </button>
      </div>
    </div>
    <div class="save-actions-spacer"></div>
    <div class="row-2">
      <button type="button" class="admn4-button-text mr-3" [disabled]="saveDisabled"
              (click)="inventoryAction.emit('RESET')">Reset
      </button>
      <button type="submit" class="admn4-button-ellipse-blue" [disabled]="saveDisabled">
          Save changes
      </button>
    </div>
  </div>
  `,
  styleUrls: ['./inventory-editor-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEditorActionsComponent {

  @Input()
  public saveDisabled: boolean = true;

  @Input()
  public actionsDisabled: boolean = true;

  /** True if the user does NOT have `MODIFY_INVENTORY` permission for the currently selected inventory. */
  @Input()
  public readOnly: boolean = true;

  @Input()
  public nextMarkerActionEnabled: boolean = false;

  @Input()
  public diffView: boolean = false;

  @Output()
  public inventoryAction: EventEmitter<InventoryEditorUserActions> = new EventEmitter();

  @Output()
  public editorAction: EventEmitter<InventoryEditorTextActions> = new EventEmitter();

  public onNextError() {
    this.editorAction.emit(InventoryEditorTextActions.NEXT_PEEK);
  }

  public onUnFoldAll() {
    this.editorAction.emit(InventoryEditorTextActions.UNFOLD_ALL);
  }

  public onFoldAll() {
    this.editorAction.emit(InventoryEditorTextActions.FOLD_ALL);
  }
}
