import { NgModule } from '@angular/core';
import { VariableListComponent } from './variable-list/variable-list.component';
import { VariablesComponent } from './variables.component';
import { CommonModules } from '../common/common.module';
import { DynamicPropertyCreationService } from '../property-widgets/dynamic-property-creation.service';
import { VariablePropertyComponent } from './variable-property.component';
import { ModalComponent } from './modal.component';
import { VariablesMainComponent } from './variables-main.component';
import { VariableHelpComponent } from './variable-help/variable-help.component';
import { DynamicVariableDirective } from './dynamic-variable.directive';
import { VariableService } from './variable.service';
import { SaverService } from '../common/services/saver.service';
import { AdmonitionComponent } from '@common/components/admonition/admonition.component';
import { ProjectHeaderModule } from '../projects/project-header/project-header.module';
import { DropdownVarListComponent } from './variable-selection/dropdown-var-list.component';
import { VariableSelectionComponent } from './variable-selection/variable-selection.component';
import { VariableListElementComponent } from './variable-list/variable-list-element.component';
import { VariableEditorComponent } from './variable-editor/variable-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { VariableMenuComponent } from './variable-details/variable-menu.component';
import { VariableDetailsComponent } from './variable-details/variable-details.component';
import { InventoryVariableComponent } from './inventory-variable/inventory-variable.component';
import { NoSampleValueVariableComponent } from './no-sample-value-variable.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModules,
    ProjectHeaderModule,
    MatExpansionModule,
    ScrollingModule,
    AdmonitionComponent,
  ],
  declarations: [
    VariableListComponent,
    VariableListElementComponent,
    VariablesComponent,
    VariablePropertyComponent,
    ModalComponent,
    VariablesMainComponent,
    VariableHelpComponent,
    DynamicVariableDirective,
    DropdownVarListComponent,
    VariableSelectionComponent,
    VariableEditorComponent,
    VariableDetailsComponent,
    VariableMenuComponent,
    InventoryVariableComponent,
    NoSampleValueVariableComponent
  ],
  providers: [
    VariableService,
    DynamicPropertyCreationService,
    SaverService
  ],
  exports: [
    VariablePropertyComponent,
    DynamicVariableDirective,
    VariableSelectionComponent,
    NoSampleValueVariableComponent
  ]
})
export class VariableModule {
}
