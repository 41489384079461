import { SecretCreationHelper } from './insert-secrets-modal/secret-creation.helper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { delay, distinctUntilChanged, filter, map, mapTo, take, takeUntil, tap, } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { DiffEditorModel } from 'ngx-monaco-editor-v2/lib/types';
import { editor, IDisposable, languages, Position } from 'monaco-editor';

import {
  applyMonacoDefaultTheme,
  applyMonacoReverseDiffTheme,
  setMonacoMarkers,
} from '../../common/utils/monaco-utils';
import { fromMonacoEditorEvent } from '../../rx.utils';
import { Maybe } from '../../common/utils/utils';

import { SaverService } from '../../common/services/saver.service';
import { createColorCompletionItemProvider } from './color-completion-item-provider';
import { InventoryColorHelper } from '../../common/helpers/inventory-color.helper';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { InventoryEditorContextService } from './insert-secrets-modal/inventory-editor-context.service';
import {
  InsertProjectVariablesDialogService
} from '../insert-project-variables-dialog/insert-project-variables-dialog.service';
import { EditorInsertingHelper } from './editor-inserting.helper';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { yamlBasicIndentation } from './yaml-code-editor.constants';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import {
  InsertInventoryAttachmentsDialogService
} from './insert-inventory-attachments-dialog/insert-inventory-attachments-dialog.service';
import {
  InventoryAttachmentResourceMode
} from './insert-inventory-attachments-dialog/inventory-attachment-payload.model';
import {
  InventoryEditorTextActions,
  InventoryEditorUserActions,
} from './inventory-editor-actions/inventory-editor-actions.component';
import { FilePosition } from '../../common/model/file-info.model';
import ICodeEditor = editor.ICodeEditor;
import ITextModel = editor.ITextModel;
import IEditorOptions = editor.IEditorOptions;
import ScrollType = editor.ScrollType;

const codeToDiffModel = (code: string): DiffEditorModel => ({code, language: 'yaml'});

const OWNER_EDITOR_MODEL = 'adm4-diff-editor';
const MONACO_ACTION_HIDE_MARKERS_NAVIGATION = 'closeMarkersNavigation';
const MONACO_ACTION_NEXT_MARKER = 'editor.action.marker.next';
const MONACO_ACTION_FOLD_ALL = 'editor.foldAll';
const MONACO_ACTION_UNFOLD_ALL = 'editor.unfoldAll';

interface Adm4MonacoOptions extends IEditorOptions {
  originalEditable: boolean;
  theme: string;
  language: string;
  wordBasedSuggestions: boolean;
  diffAlgorithm: string;
  renderSideBySide?: boolean;
  useInlineViewWhenSpaceIsLimited?: boolean;
  renderSideBySideInlineBreakpoint?: number;
}

@Component({
  selector: 'adm4-inventory-editor',
  template: `
    <form class='full-height' (ngSubmit)='save()'>
      <div class='full-height-flex'>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class='editor-wrapper full-height'>
              <ngx-monaco-editor *ngIf="!diffMode"
                [ngClass]='inventoryBoxShadow'
                [options]="editorOptions | async"
                [(ngModel)]="editableCode"
                class='monaco-editor-content'
                name='code'
                (ngModelChange)="modelChanged($event)"
                (onInit)='onMonacoEditorInit($event)'
              ></ngx-monaco-editor>
              <ngx-monaco-diff-editor *ngIf="diffMode"
                [originalModel]="originalLeftModel | async"
                [modifiedModel]="modifiedRightModel | async"
                [options]="diffEditorOptions$ | async"
                (onInit)="onDiffEditorInit($event)"
                class='monaco-editor-content default-inventory-box-shadow'
              ></ngx-monaco-diff-editor>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row position-relative">
          <adm4-inventory-editor-actions class="flex-grow-1" [class.w-50]="diffMode"
                                         [saveDisabled]="isSaveDisabled"
                                         [actionsDisabled]="!(editorActive$ | async)"
                                         [diffView]="diffMode"
                                         [readOnly]="readOnly"
                                         [nextMarkerActionEnabled]="0 < validationMarkers?.length"
                                         (inventoryAction)="onInventoryAction($event)"
                                         (editorAction)="onEditorAction($event)"
          ></adm4-inventory-editor-actions>
          <div class='diff-info w-50 d-flex align-items-center admn4-buttons-wrapper' *ngIf="diffMode">
              <i class="fa fa-info-circle color-info"></i>
              <span class="color-info">This inventory can not be edited.</span>
          </div>
        </div>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./inventory-editor.component.scss']
})
export class InventoryEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() code?: string | null;
  @Input() color: string;
  /** True if the user does NOT have `MODIFY_INVENTORY` permission for the currently selected inventory. */
  @Input() readOnly: boolean;
  @Input() inventoryKey: string;
  @Input() editorPosition: Maybe<FilePosition>;
  @Input() diffInventoryKey: Maybe<string>;
  @Input() diffCode: Maybe<string>;

  @Output() saveInventoryContent: EventEmitter<string> = new EventEmitter();
  /**
   * Emits value that indicates whether content of inventory is different from what it initially was, needed to see whether we can navigate away
   */
  @Output() dirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Emits value that is new content of inventory
   */
  @Output() codeChange: EventEmitter<string>= new EventEmitter<string>();

  public readonly initialEditorOptions: Readonly<IEditorOptions> = {
    theme: 'vs',
    language: 'yaml',
    scrollBeyondLastLine: false,
    automaticLayout: true, // Required for the editor to detect resizing of the scroll pane
    readOnly: true,
    wordBasedSuggestions: true,
    hover: {enabled: true, sticky: true},
    showFoldingControls: 'always',
    // allows tooltips and others to be shown outside the editor
    fixedOverflowWidgets: true,
  } as any as Readonly<IEditorOptions>;

  public readonly initialDiffEditorOptions: Adm4MonacoOptions = {
    theme: 'vs',
    language: 'yaml',
    scrollBeyondLastLine: false,
    automaticLayout: true, // Required for the editor to detect resizing of the scroll pane
    diffAlgorithm: 'advanced',
    smoothScrolling: true,

    // these make the left side editable and the right one readonly
    originalEditable: true,
    readOnly: true,

    wordBasedSuggestions: true,
    hover: {enabled: true, sticky: true},
    showFoldingControls: 'always',
    // allows tooltips and others to be shown outside the editor
    fixedOverflowWidgets: true,

    renderSideBySide: true,
    useInlineViewWhenSpaceIsLimited: false,
  };

  editorOptions: BehaviorSubject<Readonly<IEditorOptions>> = new BehaviorSubject(this.initialEditorOptions);
  diffEditorOptions: BehaviorSubject<Readonly<Adm4MonacoOptions>> = new BehaviorSubject(this.initialDiffEditorOptions);
  diffEditorOptions$: Observable<Readonly<Adm4MonacoOptions>> = this.diffEditorOptions.asObservable();
  validationMarkers: editor.IMarkerData[] = [];
  inventoryBoxShadow: string;
  editableCode = '';
  diffMode: boolean = false;
  sharedCursorPosition: Maybe<Position>;
  public isSaveDisabled: boolean = true;

  private readonly _originalLeftModel: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly originalLeftModel: Observable<DiffEditorModel> = this._originalLeftModel.pipe(map(codeToDiffModel));
  private readonly _modifiedRightModel: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly modifiedRightModel: Observable<DiffEditorModel> = this._modifiedRightModel.pipe(map(codeToDiffModel));

  public editorActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private editorActiveSub: Subscription = new Subscription();

  private readonly destroyed$: Subject<boolean> = new Subject();
  /** A disposable object with which we can dispose the completion provider for the monaco editor. */
  private completionItemProviderDisposable: IDisposable;
  private editor: ICodeEditor;
  private diffOriginalLeftEditor: ICodeEditor;
  private diffOriginalLeftModelSubscription: IDisposable;
  private editorModel: ITextModel;
  private loaded = false;

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  constructor(private readonly saver: SaverService,
              private readonly formGuardConnectorService: DirtyFormGuardConnectorService,
              private readonly inventoryEditorContext: InventoryEditorContextService,
              private readonly insertProjectVariablesDialogService: InsertProjectVariablesDialogService,
              private readonly insertInventoryAttachmentsDialogService: InsertInventoryAttachmentsDialogService,
              private readonly toastNotificationService: ToastNotificationService,
              private readonly cdRef: ChangeDetectorRef,
  ) {
    this.formGuardConnectorService.connect(this.dirty, () => this.reset());
    this.inventoryEditorContext.editorIssueMarkers$.pipe(takeUntil(this.destroyed$)).subscribe((newMarkers: editor.IMarkerData[]) => {
      this.applyValidationStatus(newMarkers);
    });
  }

  ngOnInit() {
    this.saver.onSave.pipe(
      filter(() => !this.isSaveDisabled),
      takeUntil(this.destroyed$)
    ).subscribe(() => this.save());
  }

  onMonacoEditorInit(initEditor: ICodeEditor): void {
    applyMonacoDefaultTheme();

    this.editor = initEditor;
    this.editorActiveSub.unsubscribe();
    this.editorActiveSub = this.trackEditorActivity(this.editor);
    this.completionItemProviderDisposable = languages.registerCompletionItemProvider('yaml', createColorCompletionItemProvider());
    const model: ITextModel | null = this.editor.getModel();
    if (_.isNil(model)) {
      return;
    }
    this.editorModel = model;
    this.editorModel.updateOptions({insertSpaces: true, tabSize: yamlBasicIndentation.length});
    this.editorModel.setValue(this.editableCode??'');
    this.applyValidationStatus(null);

    this.isSaveDisabled = this.calculateSaveDisabled();
    this.activateEditor(this.editor);
  }

  onDiffEditorInit(initDiffEditor: ICodeEditor) {
    applyMonacoReverseDiffTheme();

    this.diffOriginalLeftEditor = (<any>initDiffEditor).getOriginalEditor();
    this.editorActiveSub.unsubscribe();
    this.editorActiveSub = this.trackEditorActivity(this.diffOriginalLeftEditor);
    this.applyValidationStatus(null);

    this.diffOriginalLeftModelSubscription?.dispose();
    this.diffOriginalLeftModelSubscription = this.diffOriginalLeftEditor.onDidChangeModelContent((_change: editor.IModelContentChangedEvent) => {
      const newCode: string = this.diffOriginalLeftEditor.getModel()?.getValue() || '';
      this.editableCode = newCode;

      this.codeChange.emit(newCode);
      this.isSaveDisabled = this.calculateSaveDisabled();
      this.dirty.emit(!this.isSaveDisabled);
      SecretCreationHelper.highLightSecretURIs(this.diffOriginalLeftEditor);
    });

    this.isSaveDisabled = this.calculateSaveDisabled();
    this.activateEditor(this.diffOriginalLeftEditor);
  }

  private activateEditor(editorToActivate: editor.ICodeEditor): void {
    editorToActivate.focus();
    const position: Maybe<Position> = this.sharedCursorPosition;
    setTimeout(() => {
      if (position) {
        editorToActivate.setPosition(position);
        editorToActivate.revealLine(position.lineNumber, ScrollType.Smooth);
        this.sharedCursorPosition = null;
      }
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChangesLoaded(changes);
    if (changes['code'] && !_.isUndefined(changes['code'].currentValue)) {
      this.editableCode = this.code || '';
      this.dirty.emit(false);
      if (!_.isNil(this.editor) && !_.isNil(this.editorModel)) {
        this.editor.getModel()?.setValue(this.editableCode);
      }
      if (this.diffOriginalLeftEditor) {
        this._originalLeftModel.next(this.editableCode);
      }
    }
    if (changes['color']) {
      this.inventoryBoxShadow = InventoryColorHelper.getInventoryBoxShadowClassName(this.color);
    }

    if (changes['readOnly']) {
      this.editorOptions.next({...this.initialEditorOptions, readOnly: this.readOnly});
      this.diffEditorOptions.next({...this.initialDiffEditorOptions, originalEditable: !this.readOnly});
    }

    this.onChangeDiffInventoryKey(changes);
    if (changes.diffCode) {
      this._modifiedRightModel.next(this.diffCode??'');
    }
    this.isSaveDisabled = this.calculateSaveDisabled();
    this.onChangeEditorPositionKey(changes);
  }

  private onChangesLoaded(changes: SimpleChanges) {
    if (!!changes['code'] && !_.isNull(changes['code'].currentValue)) {
      this.loaded = true;
    }
  }

  private onChangeDiffInventoryKey(changes: SimpleChanges) {
    if (changes.diffInventoryKey) {
      this.diffMode = !!this.diffInventoryKey;
      if (this.diffMode) {
        // going to diff mode, getting the cursor position from the non-diff editor
        this.sharedCursorPosition = this.editor?.getPosition();
        // both editors push the code into `editableCode`,
        // so when switching to diffMode or staying but re-initing it because the selected diffInventory changes
        // we have to update the edited code
        this._originalLeftModel.next(this.editableCode);
      } else {
        // going to single mode, getting the cursor position from the diff original left editor
        this.sharedCursorPosition = this.diffOriginalLeftEditor?.getPosition();
      }
    }
  }
  private onChangeEditorPositionKey(changes: SimpleChanges) {
    // if the position has changed, or the code was reloaded
    if ((changes.editorPosition
        || (changes['code'] && !_.isUndefined(changes['code'].currentValue && this.editorPosition))
    ) && this.editorPosition) {
      this.sharedCursorPosition = new Position(this.editorPosition.line, this.editorPosition.column);
      const activeEditor: Maybe<ICodeEditor> = this.activeEditor();
      if (activeEditor) {
        this.activateEditor(activeEditor);
      }
    }
  }

  private applyValidationStatus(newMarkers:  editor.IMarkerData[] | null) {
    const prevMarkers = this.validationMarkers;
    if (newMarkers !== null) {
      this.validationMarkers = newMarkers;
    }
    const activeEditor: editor.ICodeEditor = this.activeEditor();
    if (!activeEditor) {
      return;
    }
    const activeModel: Maybe<ITextModel> = activeEditor.getModel();
    if (activeModel) {
      setMonacoMarkers(activeModel, OWNER_EDITOR_MODEL, this.validationMarkers);
    }

    if (newMarkers !== null) {
      if (prevMarkers.length === 0 && newMarkers.length > 0) {
        const position = this.activeEditor().getPosition();
        activeEditor.trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_NEXT_MARKER, null);
        if (position) {
          activeEditor.setPosition(position);
        }
      } else if (prevMarkers.length > 0 && newMarkers.length === 0) {
        activeEditor.trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_HIDE_MARKERS_NAVIGATION, null);
      }
    }
  }

  private activeEditor(): ICodeEditor {
    return this.diffMode ? this.diffOriginalLeftEditor : this.editor;
  }

  ngOnDestroy(): void {
    this.completionItemProviderDisposable?.dispose();
    this.diffOriginalLeftModelSubscription?.dispose();

    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
    this.formGuardConnectorService.disconnect();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.isSaveDisabled;
  }

  /**
   * Returns {Observable<boolean>} which emits true or false to indicate whether inventory can have active insert actions or not
   * Inserting is possible when inventory editor is focused or insert button split menu is active
   * To prevent insert functionality from being disabled when user clicks insert button itself disabling is delayed by 500 ms whereas activation happens instantly
   */
  private trackEditorActivity(currentEditor: editor.ICodeEditor): Subscription {
    const editorFocus$ = fromMonacoEditorEvent<void>(currentEditor, 'onDidFocusEditorText');
    const editorBlur$ = fromMonacoEditorEvent<void>(currentEditor, 'onDidBlurEditorText');
    return merge(
      editorFocus$.pipe(mapTo(true)),
      editorBlur$.pipe(delay(500), mapTo(false)),
    ).pipe(
      distinctUntilChanged(),
      tap((isEditorActive: boolean) => {
        this.editorActive$.next(isEditorActive);
        setTimeout(() => {
          this.isSaveDisabled = this.calculateSaveDisabled();
          this.cdRef.detectChanges();
        }, 0);
      }),
    ).subscribe();
  }

  modelChanged(newCode: string): void {
    this.dirty.emit(!this.isSaveDisabled);
    this.codeChange.emit(newCode);
    this._originalLeftModel.next(newCode);
    this.isSaveDisabled = this.calculateSaveDisabled();
    SecretCreationHelper.highLightSecretURIs(this.editor);
  }

  private calculateSaveDisabled(): boolean {
    const sameCode = this.code === this.editableCode;
    const sameEmptyCode = _.isNil(this.code) && _.isEmpty(this.editableCode);
    return sameCode || sameEmptyCode || !this.loaded;
  }

  save(): void {
    this.saveInventoryContent.emit(this.editableCode);
  }

  private reset(): void {
    this.activeEditor().trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_HIDE_MARKERS_NAVIGATION, null);
    this.editableCode = this.code || '';
    this._originalLeftModel.next(this.editableCode);
    this.editor?.getModel()?.setValue(this.editableCode);
    this.diffOriginalLeftEditor?.getModel()?.setValue(this.editableCode);
    this.dirty.emit(false);
    this.isSaveDisabled = this.calculateSaveDisabled();
  }

  onInventoryAction(action: InventoryEditorUserActions) {
    switch (action) {
      case 'RESET':
        this.reset();
        break;
      case 'INSERT-GLOBAL-CONSTANT':
        this.insertGlobalConstant();
        break;
      case 'INSERT-SECRET-URI':
        this.insertSecretURI();
        break;
      case 'ATTACH-SECRET-FILE':
        this.attachFile(true);
        break;
      case 'ATTACH-CERT':
        this.attachCertificate();
        break;
      case 'ATTACH-FILE':
        this.attachFile(false);
        break;
      case 'INSERT-PRJ-VARS':
        this.openInsertProjectVariablesDialog();
        break;
    }
  }

  onEditorAction(action: InventoryEditorTextActions) {
    switch (action) {
      case InventoryEditorTextActions.FOLD_ALL:
        this.activeEditor().trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_FOLD_ALL, null);
        break;
      case InventoryEditorTextActions.UNFOLD_ALL:
        this.activeEditor().trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_UNFOLD_ALL, null);
        setTimeout(() => this.activeEditor().focus(), 0);
        break;
      case InventoryEditorTextActions.NEXT_PEEK:
        this.activeEditor().trigger(OWNER_EDITOR_MODEL, MONACO_ACTION_NEXT_MARKER, null);
        break;
    }
  }

  insertGlobalConstant(): void {
    this.inventoryEditorContext.insertGlobalConstantToEditor(this.activeEditor());
  }

  insertSecretURI() {
    this.inventoryEditorContext.insertSecretToEditor(this.activeEditor(), this.inventoryKey);
  }

  openInsertProjectVariablesDialog(): void {
    const tenantKey: string = TenantHelper.getTenantFromKey(this.inventoryKey);
    this.insertProjectVariablesDialogService.openInsertProjectVariablesDialog(
        tenantKey,
        (content) => EditorInsertingHelper.appendCommentedOutContent(this.activeEditor(), content),
    );
  }

  attachFile(isSecret: boolean): void {
    this.insertInventoryAttachmentsDialogService.insertInventoryAttachments(
        this.inventoryKey,
        isSecret ? 'Attach secret file' : 'Attach file', isSecret ? InventoryAttachmentResourceMode.secretResourceFile : InventoryAttachmentResourceMode.resourceFile,
    ).pipe(take(1)).subscribe((inventoryAttachmentRefs: string) => {
      if (_.isEmpty(inventoryAttachmentRefs)) {
        return;
      }
      EditorInsertingHelper.insertTextAtCurrentPosition(this.activeEditor(), inventoryAttachmentRefs);
      this.inventoryEditorContext.loadInventoryResources(this.inventoryKey);
    });
  }

  attachCertificate(): void {
    this.insertInventoryAttachmentsDialogService.insertInventoryAttachments(this.inventoryKey, 'Attach certificate', InventoryAttachmentResourceMode.certificate)
      .pipe(take(1)).subscribe((inventoryAttachmentRefs: string | undefined) => {
      if (_.isNil(inventoryAttachmentRefs)) {
        this.toastNotificationService.showErrorToast('Thee is nothing to attach', 'Could not attach certificate');
        return;
      }
      if (_.isEmpty(inventoryAttachmentRefs)) return;
      EditorInsertingHelper.insertTextAtCurrentPosition(this.activeEditor(), inventoryAttachmentRefs);
      this.inventoryEditorContext.loadInventoryResources(this.inventoryKey);
      this.toastNotificationService.showSuccessToast('Certificate attached successfully');
    });
  }
}
