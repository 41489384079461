import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonModules } from '@common/common.module';

import { ValidatingComponent } from './validating/validating.component';
import { ValidatingItemsComponent } from './validating/validating-items/validating-items.component';
import { DeploymentFileViewComponent } from './deployment-file-view/deployment-file-view.component';


@NgModule({
  declarations: [
    ValidatingComponent,
    ValidatingItemsComponent,
    DeploymentFileViewComponent,
  ],
  imports: [
    CommonModule,
    CommonModules,
  ],
  providers: [],
  exports: [
    ValidatingComponent,
    ValidatingItemsComponent,
    DeploymentFileViewComponent,
  ],
})
export class DeploymentCommonModule { }
