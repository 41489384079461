import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, shareReplay, startWith } from 'rxjs/operators';

import { BackgroundDeploymentAppState } from '../background-deployment.reducer';
import {
  backgroundDeploymentInventoryKeyView,
  backgroundDeploymentProjectKeyView,
  backgroundDeploymentStatusDetailsView,
} from '../background-deployment.views';
import { DeploymentStatusModel } from '@deployment-common/generation-status.model';
import { DeploymentFileViewService } from '@deployment-common/deployment-file-view/deployment-file-view.service';
import {
  DeploymentFileContents,
  DeploymentFileInfo,
} from '@deployment-common/deployment-file-view/deployment-file-urls.model';

import { logViewerMonacoConfig } from '@common/utils/monaco-utils';
import { localStorageLogViewerWrapLines } from '@common/constants/local-storage-keys.constants';
import { LocalStorageService } from '@common/services/local-storage.service';
import { Maybe } from '@common/utils/utils';
import { TenantHelper } from '@common/helpers/tenant.helper';
import { editor } from 'monaco-editor';
import ICodeEditor = editor.ICodeEditor;
import IEditorOptions = editor.IEditorOptions;
import { DeploymentItemStatusModel, Status } from '@deployment-common/validating/deployment-status.model';

export interface BackgroundDeploymentDialogData {
  deploymentId: string;
}
const CLASS_DIALOG_FULL_HEIGHT = 'dialog-full-screen';

@Component({
  selector: 'adm4-background-deployment-dialog',
  template: `
      <adm4-dialog-title mat-dialog-title [title]="title$ | async"
                         [showClose]='true'
                         (close)="closeDialog()"/>
      @if (!(isLogOpen | async)) {
        <adm4-validating mat-dialog-content class="adm4-dialog-content adm4-override-dialog-content-height bg-screen-white position-relative"
                         [status]="backgroundDeploymentStatusDetails$ | async"
                         [validatingText]="'Deploying...'"
                         [validationDoneText]="'Deployment report'">
            <!-- the success class is needed for this to be selected as content-->
            <p class='success'>
                <span *ngIf='hasDoneItems$ | async'>{{doneItemsCount$ | async}} instances deployed successfully</span>
                <span *ngIf='hasFailedItems$ | async'> {{failedItemsCount$ | async}}
                    <ng-container *ngIf='hasDoneItems$ | async; else failed'> instances failed to deploy</ng-container>
                    <ng-template #failed> failed</ng-template>
                </span>
            </p>
        </adm4-validating>
      } @else {
        <div mat-dialog-content class="adm4-dialog-content adm4-override-dialog-content-height position-relative flex-grow-1 d-flex flex-column">
            <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                <h2 class="step-content-header">{{logsTitle$ | async}}</h2>
                <mat-slide-toggle [checked]="wrapLongLines | async" (change)="toggleWrapLines($event)">
                    <span class="action-btn-label font-smoothing-default">Wrap long lines</span>
                </mat-slide-toggle>
            </div>
            <div class="position-relative isolation-isolate default-inventory-box-shadow d-flex flex-column flex-grow-1">
              <ngx-monaco-editor class="h-100 monaco-height-fix z-index--1"
                      (onInit)="onMonacoEditorInit($event)"
                      [ngModel]="editorModel | async"
                      [options]="logviewerMonacoOptions | async"
              ></ngx-monaco-editor>
            </div>
        </div>
      }
      <mat-dialog-actions align="end" class="adm4-dialog-actions">
          <button type="button" class="admn4-button-ellipse-blue" (click)="closeDialog()">
              @if(isLogOpen | async) { Close viewer } @else { Close }
          </button>
      </mat-dialog-actions>
`,
  styleUrl: './background-deployment-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DeploymentFileViewService],
})
export class BackgroundDeploymentDialogComponent {

  public readonly wrapLongLines: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly title$: Observable<string>;
  public logsTitle$: Observable<string>;
  public readonly backgroundDeploymentStatusDetails$: Observable<DeploymentStatusModel | null> = this.store.select(backgroundDeploymentStatusDetailsView);

  public doneItemsCount$: Observable<number>;
  public hasDoneItems$: Observable<boolean>;
  public failedItemsCount$: Observable<number>;
  public hasFailedItems$: Observable<boolean>;

  public isLogOpen: Observable<boolean>;
  public editorReady: Promise<ICodeEditor>;
  public onMonacoEditorInit: (editor: ICodeEditor) => void;
  public editorModel: Observable<string>;
  public readonly logviewerMonacoOptions: Observable<Readonly<IEditorOptions>> = this.wrapLongLines.asObservable().pipe(
    takeUntilDestroyed(),
    map((wrapLongLines: boolean): Readonly<IEditorOptions> => {
      return {
        ...logViewerMonacoConfig,
        wordWrap: wrapLongLines ? 'on' : 'off',
      };
    }),
    startWith(logViewerMonacoConfig),
    shareReplay(1),
  );

  constructor(
    private store: Store<BackgroundDeploymentAppState>,
    private dialogRef: MatDialogRef<BackgroundDeploymentDialogComponent, void>,
    private fileViewer: DeploymentFileViewService,
    private localStorageService: LocalStorageService,
  ) {
    this.initWrapLines();
    const keyToLabel = (key: Maybe<string>): string => TenantHelper.cropTenantFromKey(key??'');
    this.title$ = combineLatest([
      this.store.select(backgroundDeploymentProjectKeyView).pipe(map(keyToLabel)),
      this.store.select(backgroundDeploymentInventoryKeyView).pipe(map(keyToLabel)),
    ]).pipe(
      map(([projectKey, inventoryKey]) => `Deployed project ${projectKey} to ${inventoryKey}`),
    );
    this.editorReady = new Promise<ICodeEditor>(resolve => {
      this.onMonacoEditorInit = (e: ICodeEditor) => resolve(e);
    });
    this.isLogOpen = this.fileViewer.viewingFileInfo.pipe(
      map((file: DeploymentFileInfo): boolean => !!file),
      shareReplay(1),
    );
    this.isLogOpen.pipe(takeUntilDestroyed()).subscribe((isLogOpen: boolean) => {
      if (isLogOpen) {
        this.dialogRef.addPanelClass(CLASS_DIALOG_FULL_HEIGHT);
        this.editorReady.then((e: ICodeEditor) => e.focus());
      } else {
        this.dialogRef.removePanelClass(CLASS_DIALOG_FULL_HEIGHT);
      }
    });
    this.editorModel = this.fileViewer.viewingFileContent.pipe(
      map((fileContent: DeploymentFileContents | null) => {
        if (fileContent) {
          return fileContent.localFileContent;
        } else {
          return '';
        }
      }),
    );
    this.logsTitle$ = this.fileViewer.viewingFileInfo.pipe(
      map((file: DeploymentFileInfo | null) => file ? file.path : ''),
      shareReplay(1),
    );

    this.doneItemsCount$ = this.backgroundDeploymentStatusDetails$.pipe(
      map((deploymentStats: DeploymentStatusModel | null): number => {
        return (deploymentStats?.items ?? [])
          .filter((item: DeploymentItemStatusModel) => Status.Done === item.status)
          .length;
      }),
    );
    this.hasDoneItems$ = this.doneItemsCount$.pipe(map((doneItemsCount: number) => doneItemsCount > 0));

    this.failedItemsCount$ = this.backgroundDeploymentStatusDetails$.pipe(
      map((deploymentStats: DeploymentStatusModel | null): number => {
        return (deploymentStats?.items ?? [])
          .filter((item: DeploymentItemStatusModel) => Status.Failed === item.status)
          .length;
      }),
    );
    this.hasFailedItems$ = this.failedItemsCount$.pipe(map((failedItemsCount: number) => failedItemsCount > 0));
  }

  public closeDialog(): void {
    this.isLogOpen.pipe(first()).subscribe((isLogOpen: boolean) => {
      if (isLogOpen) {
        this.fileViewer.closeViewer();
      } else {
        this.dialogRef.close();
      }
    });
  }

  public toggleWrapLines(e: MatSlideToggleChange): void {
    this.wrapLongLines.next(e.checked);
    this.localStorageService.setItem(localStorageLogViewerWrapLines, e.checked.toString());
  }

  private initWrapLines(): void {
    this.wrapLongLines.next(this.localStorageService.getBoolean(localStorageLogViewerWrapLines));
  }
}
