import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpgradeNotesModel, UpgradesConfirmation } from './project-dependencies.model';
import { ReportsHelper } from '../../reports/reports.helper';
import { UpgradeNote } from '../../resources/bundle-management/bundle.model';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';


@Component({
  selector: 'adm4-upgrade-notes',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='"Important notes for this upgrade"'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height-flex'>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class="full-height content-container overflow-auto scroll-shadows bg-screen-white color-screen-black">
              <div class="ptrn-migration-warning">
                <h2 class="mb-3 d-flex flex-row align-items-center color-warning">
                  <mat-icon class="mr-3">report_problem_outlined</mat-icon>
                  <span>Pattern Data Migration</span>
                </h2>
                <p>With this pattern library upgrade, an automatic migration will upgrade the project to accommodate the changes.
                    Be aware that not everything will be automatic, so check the project in all cases.</p>
                <p>Publish your project to Git to ensure you have a backup in case of breaking changes. To see the changes in your project, check the publish changes dialog.</p>
                <p>For further information, see the <em>Automatic migration</em> section in the upgrade notes for each version.</p>
                <div class="form-group">
                  <form [formGroup]="form">
                    <mat-radio-group aria-labelledby="ptrn-data-migration-warn" class="d-flex flex-column" formControlName="shouldMigrate">
                      <mat-radio-button [value]="true"><span class="confirm-label">Continue with the automatic data migration</span></mat-radio-button>
                      <mat-radio-button [value]="false"><span class="confirm-label">Skip the automatic data migration</span></mat-radio-button>
                    </mat-radio-group>
                  </form>
                </div>
              </div>
              <div class="relnotes-version" *ngFor="let note of notes">
                <h2>{{note.version}}</h2>
                <div class="content" [innerHTML]='formatHtml(note.html)'></div>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions>
          <button class='admn4-button-text' (click)='closeDialog()'>Cancel</button>
          <span ngbTooltip="Before accepting, select if you wish to go further with automatic migration or not." [disableTooltip]="form.valid"  >
            <button (click)="onUpdate()" class='admn4-button-ellipse-blue' [disabled]="!form.valid">Accept & save</button>
          </span>
        </div>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['upgrade-notes.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host adm4-mat-dialog'"},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeNotesComponent implements OnInit, OnDestroy {
  public notes: UpgradeNote[];
  public form: FormGroup<{shouldMigrate: FormControl<boolean | null>}>;

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: UpgradeNotesModel,
    private readonly dialogRef: MatDialogRef<UpgradeNotesComponent, UpgradesConfirmation>,
    fb: FormBuilder,
  ) {
    this.notes = data.notes;
    this.form = fb.group({
      shouldMigrate: new FormControl<boolean | null>(
        {value: null, disabled: false},
        {validators: [Validators.required]}
      ),
    });
  }

  formatHtml(htmlContent: string): string {
    return ReportsHelper.applyEdits(htmlContent, this.data.projectKey);
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onUpdate() {
    const shouldMigrate: boolean = !!this.form.value.shouldMigrate;
    this.dialogRef.close({shouldMigrate});
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
