import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NavigationService } from '../../navbar/navigation.service';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { MetaInfo } from '../../version-control/meta-info.model';
import { PublishDialogComponent } from '../../common/model/publish-changes/publish-dialog-component.model';
import { PublishInventoryContext } from './publish-inventory.context';
import { PublishInventoryChangesetContext } from './publish-inventory-changeset.context';
import { InventoryChangesetItem } from './inventory-changeset-item.model';

@Component({
  selector: 'adm4-publish-inventory-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title full-height-flex'
                             [header]='publishModalName'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog(false)">
      <adm4-publish-inventory class='full-height'
                            [changesetItems]='changesetItems$ | async'
                            [initialCommitMessage]='initialCommitMessage$ | async'
                            (publish)='triggerPublishInventory($event)'
                            (cancel)='closeDialog(false)'
                            (messageChanged)='onDirtyChanged($event)'></adm4-publish-inventory>
    </adm4-modal-dialog-title>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PublishInventoryContext,
    PublishInventoryChangesetContext
  ]
})
export class PublishInventoryDialogComponent implements PublishDialogComponent, OnInit, OnDestroy {

  publishModalName: string;
  isDirty = false;
  /** Needed for deactivation guard to find out that dialog can be closed regardless of dirty state */
  explicitExit = false;

  changesetItems$: Observable<InventoryChangesetItem<MetaInfo, any>[]>;
  initialCommitMessage$: Observable<string>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public inventoryKey: string,
              private dialogRef: MatDialogRef<PublishInventoryDialogComponent>,
              private navigationService: NavigationService,
              private publishInventoryChangesetContext: PublishInventoryChangesetContext,
              private publishInventoryContext: PublishInventoryContext) {
    this.publishModalName = 'Publish changes ' + TenantHelper.cropTenantFromKey(this.inventoryKey);
    this.changesetItems$ = this.publishInventoryChangesetContext.changesetItems$;
    this.initialCommitMessage$ = this.publishInventoryChangesetContext.initialCommitMessage$;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().pipe(
      filter((event: KeyboardEvent) => event.key === 'Escape'),
      takeUntil(this.dialogRef.beforeClosed()),
      takeUntil(this.destroyed$))
      .subscribe(() => this.closeDialog(false));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onDirtyChanged(messageChanged: boolean): void {
    this.isDirty = messageChanged;
  }

  triggerPublishInventory(commitMessage: string): void {
    this.publishInventoryContext.requestPublishInventoryChanges(commitMessage);
    this.closeDialog(true);
  }

  /**
   * By setting the param true, it can be signalled that the dialog can be closed regardless of the dirty flag.
   * @param explicitExit
   */
  closeDialog(explicitExit: boolean): void {
    this.explicitExit = explicitExit;
    this.navigationService.navigateAwayFromModalWindow();
  }

}
