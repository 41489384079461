import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Project } from '../../projects/project.model';
import { Pattern } from '../pattern.model';
import { PatternService } from '../pattern.service';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { InventoryValidationIssue, ValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import * as _ from 'lodash';
import { ResponseStatusHelper, StatusHelperConstants } from '../../common/helpers/response-status.helper';
import { PatternSortingHelper } from '../pattern-sorting.helper';
import { CopyMode, defaultPatternCopyOptions, PatternCopyOption, PatternCopyOptionName } from './batch-action.model';
import { PatternListData } from '../pattern-list-data.model';
import { singularPlural } from '../../projects/project-summary/project-summary.helper';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { localStorageCopyPatternLastProject } from '../../common/constants/local-storage-keys.constants';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { RouteParamHelper } from '../../common/helpers/route-param.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HTTP_STATUS_NOT_FOUND } from '../../shared/http-status-codes.constants';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { CopyActionHelper } from './copy-action.helper';
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-batch-action',
  template: `
    <div class='full-height-flex'>
      <div class="batch-action-content-container remaining-space-flex-content-wrapper">
          <adm4-admonition severity="info" alignment="start" class="mb-4">
              <p adm4-admonition-msg class="m-0 color-info">Referenced patterns are not copied automatically. See
                <adm4-external-link
                        [linkUrl]="'nevisadmin4/User-Guide/Configuration-Projects/Working-with-Patterns/Copying-Patterns' | docLink" [linkLabel]='"Copying pattern"' [displayStyle]="'inline'" [matIconName]="'open_in_new'" [openInNewTab]='true'>
                </adm4-external-link> for more details.
              </p>
          </adm4-admonition>
          <label for='target-project' class='input-label'>Target project</label>
          <mat-form-field class='full-width target-project-field'>
            <mat-select id='target-project'
                        #projectSelection
                        [placeholder]="'Please select'"
                        [(ngModel)]='targetProject'
                        (ngModelChange)='validateProject()'
                        [disableOptionCentering]="true"
                        (click)='focusDropdownInput()'>
              <adm4-searchable-dropdown-input *ngIf='projectSelection.focused'
                                              [sourceItems]='projects'
                                              [placeholder]="'Please select'"
                                              [searchableFormatFn]='searchableProjectFormatFn'
                                              [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                              (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
              <mat-option *ngFor="let project of projects" [value]="project" [hidden]='!isProjectFilteredOut(project)'>{{project.projectKey | cropTenantFromKey}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class='validation-message-container'>
            <adm4-validation-message [isWarning]='true' *ngIf="!(validationProgress$ | async) && hasBundleWarning"
                                     [message]='getBundleWarningDetail()'></adm4-validation-message>
          </div>
          <div *ngFor='let copyOption of copyOptions' class='copy-option-checkbox-container'>
            <mat-checkbox class='pattern-element-checkbox' [checked]='copyOption.checked' (change)='onCheckboxTick($event.checked, copyOption)'></mat-checkbox>
            <span class='checkbox-title'>{{copyOption.name}}</span>
            <ng-container *ngIf='isLinkedPatternCopyOption(copyOption)'>
              <i class="fa fa-info-circle help-icon" aria-hidden="true" [ngbTooltip]='linkToPatternPopover' placement='right'></i>
              <ng-template #linkToPatternPopover>
                <ul>
                  <li>If the checkbox is checked, each copied pattern will keep a link to the source pattern. If you copy the same pattern again, its contents in the target project will be updated.</li>
                  <li>If the checkbox is unchecked, each copied pattern will not have a link to the source pattern. Thus, you can copy them multiple times.</li>
                </ul>
              </ng-template>
            </ng-container>
          </div>
        <div class='section-title' *ngIf='selectedPatterns'>
          <label class='input-label'>
            <ng-container *ngIf='shouldCopyAsNew; else simplePatternSelectionSummary'>
              {{selectedPatternsSummary}} will be copied as:
              <input class='prefix-input' placeholder="prefix" [(ngModel)]="prefixText" (ngModelChange)='triggerPatternNameChange($event)'/>
              &lt;patternName&gt;
              <input class='prefix-input' placeholder="suffix" [(ngModel)]="suffixText" (ngModelChange)='triggerPatternNameChange($event)'/>
            </ng-container>
            <ng-template #simplePatternSelectionSummary>{{selectedPatternsSummary}} selected:</ng-template>
          </label>
        </div>
        <div class='selected-pattern-list' #patternListScrollArea>
          <div *ngFor='let pattern of selectedPatterns'>
            <adm4-selected-pattern-list-element [pattern]='pattern'
                                                [displayablePatternName]='resolveCopiedPatternName(pattern.pattern.name)'
                                                [isChecked]='patternIdsToCopy.includes(pattern.pattern.patternId)'
                                                [warningIssue]='getValidationIssuesDetail(pattern.pattern, validationWarnings)'
                                                [infoIssue]='getValidationIssuesDetail(pattern.pattern, validationInfos)'
                                                [isLinkedCopyMode]='!shouldCopyAsNew'
                                                (checkboxSelect)='changeSelection(pattern.pattern)'>
            </adm4-selected-pattern-list-element>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class='admn4-button-text' (click)='finished.emit()'>Cancel</button>
        <button class='admn4-button-ellipse-blue' [disabled]='(validationProgress$ | async) || isCopyDisabled' (click)="copyProject()">{{BUTTON_COPY}}</button>
      </div>
    </div>
  `,
  styleUrls: [
    '../../common/styles/component-specific/create-form.scss',
    '../../common/styles/component-specific/modal-window.scss',
    'batch-action.component.scss'
  ]
})
export class BatchActionComponent implements OnInit, OnDestroy {
  @Input() dialogRef: MatDialogRef<BatchActionComponent>;
  @Input() projects: Project[];
  @Input() selectedPatterns: PatternListData[];
  @Input() projectKey: string;
  /**
   * Emits when the dialog is finished. Emits `true` if the dialog has actually done copy operations,
   * and emits `undefined` if the dialog is canceled.
   */
  @Output() finished: EventEmitter<Maybe<boolean>> = new EventEmitter();
  @ViewChild('patternListScrollArea', {static: false}) patternListScrollArea: ElementRef<HTMLElement>;
  targetProject: Project;
  filteredProjectList: Project[];
  patternIdsToCopy: string[];
  copyOptions = defaultPatternCopyOptions;
  prefixText: string;
  suffixText: string;
  copiedPatternNameHasChanged: Subject<string> = new Subject<string>();

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  readonly BUTTON_COPY = 'Copy';
  readonly ERROR_COPY_MODAL_TITLE = 'Could not copy pattern(s)';
  readonly DEFAULT_COPY_ERROR_MESSAGE = 'It was not possible to copy the selected pattern(s).';

  private _validationProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  validationProgress$: Observable<boolean> = this._validationProgress$.asObservable();
  validationWarnings: ValidationIssue[] = [];
  bundleConflictWarning: ValidationIssue[] = [];
  validationInfos: ValidationIssue[] = [];
  destroyed$: Subject<boolean> = new Subject();

  constructor(private patternService: PatternService,
              private toastNotificationService: ToastNotificationService,
              private modalNotificationService: ModalNotificationService) {
  }

  ngOnInit() {
    this.setDefaultCopyOptionsState();
    this.patternIdsToCopy = this.selectedPatterns.map((pattern: PatternListData) => pattern.pattern.patternId);
    const projectsDir = _.keyBy(this.projects, 'projectKey');
    const preselectedKey: string | undefined = RouteParamHelper.takeKeyFromStore(this.projectKey, projectsDir, localStorageCopyPatternLastProject);
    if (!_.isNil(preselectedKey)) {
      this.targetProject = projectsDir[preselectedKey];
      this.validateProject();
    }
    this.copiedPatternNameHasChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.validateProject();
    });
  }

  setDefaultCopyOptionsState(): void {
    this.copyOptions.forEach((copyOption) => {
      copyOption.checked = _.isEqual(copyOption.name, PatternCopyOptionName.LINKED_COPY);
    });
  }

  get selectedPatternsSummary(): string {
    const patternCount = this.patternIdsToCopy.length;
    const what = singularPlural(patternCount, 'pattern', 'patterns');
    return `${patternCount} ${what}`;
  }

  get hasBundleWarning(): boolean {
    return !_.isEmpty(this.bundleConflictWarning);
  }

  get isCopyDisabled(): boolean {
    return _.isNil(this.targetProject) || this.patternIdsToCopy.length === 0 || this.shouldDisableCopyAsNew;
  }

  getValidationIssuesDetail(pattern: Pattern, validationIssues: ValidationIssue[]): string | undefined {
    return ResponseStatusHelper.unwrapSourceDetail(validationIssues, StatusHelperConstants.PATTERN, pattern.patternId);
  }

  getBundleWarningDetail(): string | undefined {
    return ResponseStatusHelper.unwrapSourceDetail(this.bundleConflictWarning, StatusHelperConstants.PROJECT, this.targetProject.projectKey);
  }

  getExistingPatterIds(validationIssues: ValidationIssue[]): string[] {
    return validationIssues.map((issue: ValidationIssue) => issue.sources[0][StatusHelperConstants.PATTERN]);
  }

  changeSelection(selectedPattern: Pattern): void {
    const selectedPatternIndex = this.patternIdsToCopy.findIndex((patternId: string) => patternId === selectedPattern.patternId);
    if (selectedPatternIndex !== -1) {
      this.patternIdsToCopy.splice(selectedPatternIndex, 1);
    } else {
      this.patternIdsToCopy.push(selectedPattern.patternId);
    }
  }

  validateProject(): void {
    if (_.isNil(this.targetProject)) return;
    this._validationProgress$.next(true);
    const targetCopyMode = this.shouldCopyAsNew ? CopyMode.DRY_RUN_NEW : CopyMode.DRY_RUN;
    const copyContext = CopyActionHelper.createCopyModelByMode(targetCopyMode, this.patternIdsToCopy, this.targetProject.projectKey, this.isCopyWithVariableSelected, this.prefixText, this.suffixText);
    this.patternService.copyPattern(this.projectKey, copyContext).pipe(
      map(response => response._status || null),
      map((status: ValidationStatus<InventoryValidationIssue> | null) => {
        if (_.isNil(status)) {
          this.validationWarnings = this.validationInfos = this.bundleConflictWarning = [];
          return;
        }
        this.validationWarnings = !_.isNil(status._warnings) ? ResponseStatusHelper.getWarningsFromStatus(status, StatusHelperConstants.PATTERN) : [];
        this.bundleConflictWarning = !_.isNil(status._warnings) ? ResponseStatusHelper.getWarningsFromStatus(status, StatusHelperConstants.PROJECT) : [];
        this.validationInfos = !_.isNil(status._infos) ? ResponseStatusHelper.getInfosFromStatus(status, StatusHelperConstants.PATTERN) : [];
        this.selectedPatterns.sort((p1: PatternListData, p2: PatternListData) => PatternSortingHelper.byIssueStatus(p1.pattern, p2.pattern, this.getExistingPatterIds(this.validationWarnings), this.getExistingPatterIds(this.validationInfos)));
      })).subscribe(() => {
        this._validationProgress$.next(false);
        this.patternListScrollArea.nativeElement.scrollTop = 0;
      },
      (error: HttpErrorResponse) => {
        if (error.status === HTTP_STATUS_NOT_FOUND) {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_COPY_MODAL_TITLE, description: ErrorHelper.getErrorDetail(error, this.DEFAULT_COPY_ERROR_MESSAGE)})
            .afterClosed().subscribe(() => this.finished.emit());
        }
      });
  }

  copyProject(): void {
    this._validationProgress$.next(true);
    const targetCopyMode = this.shouldCopyAsNew ? CopyMode.NEW : CopyMode.FORCE;
    const copyContext = CopyActionHelper.createCopyModelByMode(targetCopyMode, this.patternIdsToCopy, this.targetProject.projectKey, this.isCopyWithVariableSelected, this.prefixText, this.suffixText);
    this.patternService.copyPattern(this.projectKey, copyContext)
      .subscribe((responseStatus) => {
          if (_.isNil(responseStatus)) {
            this._validationProgress$.next(false);
            return;
          }
          if (_.isEmpty(responseStatus) || !_.isNil(responseStatus._status) && !_.isNil(responseStatus._status._errors) && _.isEmpty(responseStatus._status._errors)) {
            LocalStorageHelper.save(localStorageCopyPatternLastProject, this.targetProject.projectKey);
            const patternCount = this.patternIdsToCopy.length;
            const copySelectionText = singularPlural(patternCount, 'pattern is', 'patterns are');
            const targetProjectName: string = TenantHelper.cropTenantFromKey(this.targetProject.projectKey);
            const baseHref = location.origin + location.pathname;
            const targetProjectLink = `${baseHref}#/projects/${this.targetProject.projectKey}`;
            this.toastNotificationService.showSuccessToast(`${this.patternIdsToCopy.length} ${copySelectionText} successfully copied to <a href='${targetProjectLink}'>${targetProjectName}</a>`, 'Success');
          }
          this.finished.emit(true);
        },
        (error: HttpErrorResponse) => {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_COPY_MODAL_TITLE, description: ErrorHelper.getErrorDetail(error, this.DEFAULT_COPY_ERROR_MESSAGE)})
            .afterClosed().subscribe(() => {
            this.finished.emit();
          });
        });
  }

  searchableProjectFormatFn = (project: Project): string => {
    return TenantHelper.cropTenantFromKey(project.projectKey);
  };

  updateSearchResult(filteredList: Project[]): void {
    this.filteredProjectList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  isProjectFilteredOut(project: Project): boolean {
    return _.includes(this.filteredProjectList, project);
  }

  onCheckboxTick(checked: boolean, selectedCopyOption: PatternCopyOption): void {
    selectedCopyOption.checked = checked;
    if (this.isLinkedPatternCopyOption(selectedCopyOption)) {
      // cleanup prefix suffix
      this.prefixText = '';
      this.suffixText = '';
      this.validateProject();
    }
  }

  get isCopyWithVariableSelected(): boolean {
    const variableCopyOption = this.copyOptions.find(option => _.isEqual(option.name, PatternCopyOptionName.VARIABLE_COPY));
    return !!variableCopyOption && variableCopyOption.checked;
  }

  get shouldCopyAsNew(): boolean {
    const isLinkedCopyChecked = this.copyOptions.find(option => this.isLinkedPatternCopyOption(option))?.checked;
    return !isLinkedCopyChecked;
  }

  isLinkedPatternCopyOption(copyOption: PatternCopyOption): boolean {
    return _.isEqual(copyOption.name, PatternCopyOptionName.LINKED_COPY);
  }

  get shouldDisableCopyAsNew(): boolean {
    if(!this.shouldCopyAsNew) return false;
    return this.hasSelectedWarningPattern();
  }

  private hasSelectedWarningPattern() {
    return _.some(this.getExistingPatterIds(this.validationWarnings), patternID => _.includes(this.patternIdsToCopy, patternID));
  }

  resolveCopiedPatternName(originalPatternName: string): string {
    return _.join([this.prefixText, originalPatternName, this.suffixText], '');
  }

  triggerPatternNameChange($event): void {
    this.copiedPatternNameHasChanged.next($event);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
