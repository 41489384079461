import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { IOutputData } from 'angular-split/lib/interface';

import { ProjectChangesetItem } from './project-changeset-item.model';
import { MetaInfo } from '../../version-control/meta-info.model';
import { IssueModel } from '../../common/model/issue.model';
import { PublishProjectContext } from './publish-project.context';
import { PublishProjectInput } from '../project.model';
import { UserStateService } from '../../common/services/user/user.state.service';
import { DiffViewComponent } from '../../common/model/publish-changes/diff-view-component.model';
import { Dictionary } from '../../model/reducer';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';
import { ResizeHelper } from '../../common/helpers/resize.helper';

@Component({
  selector: 'adm4-publish-project',
  templateUrl: './publish-project.component.html',
  styleUrls: ['./publish-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([SplitMixin])
export class PublishProjectComponent implements ISplitMixin, OnInit, OnChanges {
  @Input() projectKey: string;
  @Input() changesetItems: ProjectChangesetItem<MetaInfo, any>[];
  @Input() initialCommitMessage = '';
  @Output() isDirty: EventEmitter<boolean> = new EventEmitter();
  @Output() publish: EventEmitter<PublishProjectInput> = new EventEmitter();
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @ViewChild('diffViewContainer', {read: ViewContainerRef, static: false}) diffViewContainer: ViewContainerRef;

  issues$: Observable<IssueModel[]>;
  commitMessage = this.initialCommitMessage;
  userInput: PublishProjectInput;
  currentUsername: string | null;
  selectedChangesetItem?: ProjectChangesetItem<MetaInfo, any>;

  readonly listSplitAreaKey = 'list';
  readonly diffViewSplitAreaKey = 'diff-view';
  readonly splitPaneConfigLocalStorageKey = 'publish-project-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.diffViewSplitAreaKey]: {order: 1, size: 70}
  };

  /** Implemented by SplitMixin */
  onResize: (event: IOutputData) => void;

  constructor(private publishProjectContext: PublishProjectContext,
              private userStateService: UserStateService,
  ) {
    this.issues$ = publishProjectContext.issues$;
    this.currentUsername = this.userStateService.username;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  ngOnInit(): void {
    this.publishProjectContext.preloadDiffs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialCommitMessage'] && _.isEmpty(this.commitMessage)) {
      this.commitMessage = this.initialCommitMessage;
    }
  }

  onChangesetItemSelected(changesetItem: ProjectChangesetItem<MetaInfo, any>): void {
    this.selectedChangesetItem = changesetItem;
    this.generateDynamicDiffView(changesetItem);
  }

  private generateDynamicDiffView(changesetItem: ProjectChangesetItem<MetaInfo, any>): void {
    this.diffViewContainer.clear();
    const componentRef: ComponentRef<DiffViewComponent<ProjectChangesetItem<MetaInfo, any>>> =
        this.diffViewContainer.createComponent(changesetItem.diffComponent);
    componentRef.instance.changesetItem = changesetItem;
  }

  onUserInputChanged(input: PublishProjectInput): void {
    this.userInput = input;
    const dirty: boolean = this.userInput.commitMessage !== this.initialCommitMessage || this.userInput.tags.length > 0;
    this.isDirty.emit(dirty);
  }

  triggerPublish(): void {
    this.publish.emit(this.userInput);
  }

  triggerCancel(): void {
    this.cancel.emit();
  }

}
