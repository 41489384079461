import { Injectable, OnDestroy } from '@angular/core';
import { PropertyWidgetContext } from '../property-widgets/property-widget.context';
import { Observable } from 'rxjs/internal/Observable';
import { AppState, Dictionary } from '../model/reducer';
import { select, Store } from '@ngrx/store';
import { allProjectsView, deployProcessView } from '../model/views';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DeploymentWizardContext } from './deployment-wizard.context';
import { PatternType } from '../plugins/pattern-type.model';
import { DeploymentProcessModel } from '@deployment-common/deployment-process.model';
import { Pattern } from '../patterns/pattern.model';
import { PatternService } from '../patterns/pattern.service';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { Unsubscriber } from '@common/helpers/unsubscriber';
import { Project } from '../projects/project.model';
import * as _ from 'lodash';
import { ResourceList } from '../patterns/pattern-attachment.model';
import { PropertyWidgetContextType } from '../property-widgets/property-widget-context-type.enum';
import { ViewAttachmentFileDiffEvent } from '../projects/publish-project/pattern-diff-view/pattern-diff-data.model';

@Injectable()
export class DeploymentPropertyContext implements PropertyWidgetContext, OnDestroy {
  private _allPatternResources$: BehaviorSubject<Dictionary<Dictionary<ResourceList>>> = new BehaviorSubject({});
  private _allPatternResourcesSubscription: Subscription;

  patternTypes$: Observable<Dictionary<PatternType> | null>;
  currentProject$: Observable<Project | null>;
  patterns$: Observable<Map<string, Pattern>>;
  allPatternResources$: Observable<Dictionary<Dictionary<ResourceList>>> = this._allPatternResources$.asObservable();
  type = PropertyWidgetContextType.DEPLOY;
  private destroyed$: Subject<boolean> = new Subject();

  public toggleDiffAttachmentResource(_item: ViewAttachmentFileDiffEvent): void {/* only used in the publish context */}

  constructor(private store$: Store<AppState>, private wizardContext: DeploymentWizardContext, private patternService: PatternService) {
    this.patternTypes$ = wizardContext.patternTypes;
    this.currentProject$ = combineLatest([
      this.store$.pipe(
        select(deployProcessView),
        filter(deploymentProcess => !!deploymentProcess),
        map((deploymentProcess: DeploymentProcessModel) => deploymentProcess.projectKey)
      ),
      this.store$.pipe(select(allProjectsView))
    ]).pipe(map(([projectKey, allProjects]: [string, Dictionary<Project>]) => allProjects[projectKey] || null));
    this.patterns$ = this.wizardContext.patterns;
  }

  loadAttachmentsForPatterns(patterns$: Observable<Pattern[]>): void {
    this.resetAttachments();
    this._allPatternResourcesSubscription = this.patternService.getResourcesOfPatterns(
      this.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.projectKey)),
      patterns$,
      this.patternTypes$,
      this.wizardContext.propertyTypes
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resourceListsOfAllPatterns: Dictionary<Dictionary<ResourceList>>) => this._allPatternResources$.next(resourceListsOfAllPatterns));
  }

  resetAttachments(): void {
    Unsubscriber.unsubscribeFrom(this._allPatternResourcesSubscription);
    this._allPatternResources$.next({});
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
