import { FileDownloader } from '../../common/helpers/file-downloader';
import { environment } from '../../../environments/environment';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-local-changes-project-confirmation-dialog',
  template: `
    You are about to reset your project to the version in Git.<br/><br/>
    Please note that there are local changes in your project and these will be lost.
    <br/>
    <span class='call-to-action'>To create a local backup now, click: <a (click)='exportProjectToZip()'>Export Project to Zip</a></span><br/>
    <br/>
    Do you want to continue?
  `,
  styleUrls: ['./local-changes-confirmation-dialog.component.scss']
})
export class LocalChangesProjectConfirmationDialogComponent {

  @Input() projectKey: string;

  exportProjectToZip(): void {
    FileDownloader.downloadFileFromUrl(`${environment.baseUrl}/projects/${this.projectKey}/file-export`);
  }
}
