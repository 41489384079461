import { NgModule } from '@angular/core';
import { SetVariableComponent } from './set-variable.component';
import { SetVariableContainerComponent } from './set-variable-container.component';
import { SetVariableService } from './set-variable.service';
import { CommonModules } from '../common/common.module';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { VariableModule } from '../variables/variable.module';
import { VariableService } from '../variables/variable.service';
import { AdmonitionComponent } from '@common/components/admonition/admonition.component';
import { DialogTitleComponent } from '../common/dialog/dialog-title/dialog-title.component';

@NgModule({
  imports: [
    CommonModules,
    ModalDialogModule,
    VariableModule,
    AdmonitionComponent,
    DialogTitleComponent,
  ],
  declarations: [
    SetVariableComponent,
    SetVariableContainerComponent
  ],
  providers: [
    SetVariableService,
    VariableService
  ]
})
export class SetVariableModule { }
