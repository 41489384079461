import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { VariableModel } from './variable.model';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PropertyType } from '../plugins/property-type.model';
import { IssueModel } from '../common/model/issue.model';
import { VariablesHelper } from './variables.helper';

@Component({
  selector: 'adm4-variable-property',
  template: `
    <div class='var-prop-container ui_list_item'
         [class.invalid]=' formGroup?.dirty'>
      <div *ngIf="withHeading" class='variable-heading' [ngClass]="{'dirty': formGroup?.dirty}">
        <label class="form_label">{{variable.variableKey}}</label>
      </div>
      <div *ngIf="showVariableType" class="variable-type">
        <span *ngIf="variable.requireOverloading"><strong>Sample Value</strong></span>
        <span *ngIf="!variable.requireOverloading"><strong>Default Value</strong></span>
      </div>
      <ng-container *ngIf='canVariableBeDefault; else noSampleValueVariable'>
        <ng-template adm4DynamicVariable
                     [variableName]='variable.variableKey'
                     [variableValue]='variable.value'
                     [variableParameters]='variable.parameters'
                     [formGroup]='formGroup'
                     [readOnly]='readOnly'
                     [variableType]='variableType'
                     (validate)='validate.emit($event)'></ng-template>
        <ng-container *ngTemplateOutlet='validationIssuesTemplate'></ng-container>
      </ng-container>

      <ng-template #noSampleValueVariable>
        <ng-container *ngTemplateOutlet='validationIssuesTemplate'></ng-container>
        <adm4-no-sample-value-variable [variableType]='variableType'></adm4-no-sample-value-variable>
      </ng-template>

      <ng-template #validationIssuesTemplate>
        <adm4-validation-issue *ngFor='let issue of issues'
                               [issue]='issue'>
        </adm4-validation-issue>
      </ng-template>
    </div>
  `,
  styleUrls: ['variable-property.scss']
})
export class VariablePropertyComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() variable: VariableModel;
  @Input() variableType?: PropertyType;
  @Input() formGroup: UntypedFormGroup;
  @Input() issues: IssueModel[];
  @Input() readOnly: boolean;
  @Input() withHeading = true;
  @Input() showVariableType: boolean = false;

  @Output() validate = new EventEmitter();
  @ViewChild('variableListTemplate', {read: ViewContainerRef, static: false}) variableListTemplate: ViewContainerRef;

  currentFormElement: AbstractControl;
  canVariableBeDefault = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['variable'] && this.variable) {
      this.currentFormElement = this.formGroup.controls[this.variable.variableKey];
      if (!this.currentFormElement) {
        console.error('couldnt determine form element for %s', JSON.stringify(this.variable));
      }
    }
    if (changes.variableType) {
      this.canVariableBeDefault = VariablesHelper.canVariableHaveSampleValue(this.variableType);
    }
  }
}
