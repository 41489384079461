<div class='issue-group' [id]='patternIssueGroup.pattern.patternId' [class.selected]='isSelected'>
	<div class='issue-group-heading-outer' [class.selected]='isSelected'>
    <a (click)="navigateToPattern(patternIssueGroup.pattern.patternId)">
      <div class='issue-group-heading'>
          <h2>{{patternIssueGroup.pattern.name}}</h2>
      </div>
    </a>
	</div>
	<div *ngIf='shouldDisplayGeneralIssues' class='issue-group-list'>
		<ng-container *ngFor="let issue of patternIssueGroup.generalIssues">
			<div class='issue-group-list-item-wrapper line-indicatable-validated' [ngClass]='getIssueIndicationCssClass(issue)'>
				<div class='issue-group-list-item'>
					<adm4-validation-issue [issue]="issue"></adm4-validation-issue>
				</div>
			</div>
		</ng-container>
	</div>
	<adm4-deployment-host *ngIf='shouldDisplayDeploymentHosts' [readOnly]='true'
												[form]='patternIssueGroup.form'
												[fcName]='deploymentHostsFormControlName'
												[issues]='patternIssueGroup.deploymentHostIssues'
	></adm4-deployment-host>
	<adm4-property-list [propertiesModel]='patternIssueGroup.propertiesData'
											[pattern]='patternIssueGroup.pattern'
											[projectKey]='projectKey'
											[variables]='variables'
											[form]='patternIssueGroup.form'
											[readOnly]='true'
	></adm4-property-list>
</div>
