import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { isErrorIssue, isInfoIssue, isWarningIssue } from '../../project-issues/project-issues.helper';
import { singularPlural } from '../project-summary.helper';
import { IssueModel } from '../../../common/model/issue.model';
import { NavigationService } from '../../../navbar/navigation.service';

@Component({
  selector: 'adm4-project-summary-issues',
  template: `
    <adm4-project-summary-section [title]='"Issues"'
                                  [summary]='issuesSummary'>
      <ul class='task-stats-list'>
        <li *ngIf='displayErrorIssues'>
          <adm4-validation-indicator [diameter]='10' [isDisplayed]='true' [isError]='true'></adm4-validation-indicator>
          <span>{{errorIssues}}</span>
        </li>
        <li *ngIf='displayWarningIssues'>
          <adm4-validation-indicator [diameter]='10' [isDisplayed]='true' [isWarning]='true'></adm4-validation-indicator>
          <span>{{warningIssues}}</span>
        </li>
        <li *ngIf='displayInfoIssues'>
          <adm4-validation-indicator [diameter]='10' [isDisplayed]='true' [isInfo]='true'></adm4-validation-indicator>
          <span>{{infoIssues}}</span>
        </li>
      </ul>
      <a (click)="navigateToIssues()">Go to Issues...</a>
    </adm4-project-summary-section>
  `,
  styleUrls: ['./project-summary-issues.component.scss', '../project-summary-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryIssuesComponent  {
  @Input() currentProjectKey: string;
  @Input() issues: IssueModel[];

  constructor(private navigationService: NavigationService) { }

  navigateToIssues(): void {
    this.navigationService.navigateToIssues(this.currentProjectKey);
  }

  get issuesSummary(): string {
    const taskCount = !!this.issues ? this.issues.length : 0;
    const what = singularPlural(taskCount, 'issue', 'issues');
    return `This project contains ${taskCount} ${what}.`;
  }

  get displayWarningIssues(): boolean {
    const warningCount = this.getIssueCountBy(isWarningIssue);
    return warningCount > 0;
  }

  get warningIssues(): string {
    const warningCount = this.getIssueCountBy(isWarningIssue);
    const what = singularPlural(warningCount, 'warning', 'warnings');
    return `${warningCount} ${what}`;
  }

  get displayErrorIssues(): boolean {
    const errorCount = this.getIssueCountBy(isErrorIssue);
    return errorCount > 0;
  }

  get errorIssues(): string {
    const errorCount = this.getIssueCountBy(isErrorIssue);
    const what = singularPlural(errorCount, 'error', 'errors');
    return `${errorCount} ${what}`;
  }

  get displayInfoIssues(): boolean {
    const infoCount = this.getIssueCountBy(isInfoIssue);
    return infoCount > 0;
  }

  get infoIssues(): string {
    const infoCount = this.getIssueCountBy(isInfoIssue);
    const what = singularPlural(infoCount, 'info', 'infos');
    return `${infoCount} ${what}`;
  }

  private getIssueCountBy(predicate: Function) {
    return _.filter(this.issues, predicate).length;
  }
}
