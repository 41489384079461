import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';

import { DeploymentCommonModule } from '@deployment-common/deployment-common.module';

import { BackgroundDeploymentEffects } from './background-deployment.effects';

import { CommonModules } from '@common/common.module';
import { BackgroundDeploymentContextService } from '@common/services/background-deployment-context.service';

import { BackgroundDeploymentDialogComponent } from './background-deployment-dialog/background-deployment-dialog.component';
import { DialogTitleComponent } from '@common/dialog/dialog-title/dialog-title.component';

@NgModule({
  imports: [
    CommonModule,
    CommonModules,
    DeploymentCommonModule,
    EffectsModule.forFeature([BackgroundDeploymentEffects]),
    DialogTitleComponent,
  ],
  declarations: [BackgroundDeploymentDialogComponent],
  providers: [BackgroundDeploymentContextService],
})
export class BackgroundDeploymentModule { }
