import { NgModule } from '@angular/core';
import { ProjectComponent } from './project.component';
import { ProjectRoutingModule } from './project-routing.module';
import { PropertyWidgetsModule } from '../property-widgets/property-widgets.module';
import { PatternMainComponent } from '../patterns/pattern-main.component';
import { PatternModule } from '../patterns/pattern.module';
import { CommonModules } from '../common/common.module';
import { AdmonitionComponent } from '../common/components/admonition/admonition.component';
import { ProjectSyncService } from './project-sync.service';
import { ProjectHeaderModule } from './project-header/project-header.module';
import { ProjectSummaryModule } from './project-summary/project-summary.module';
import { ProjectSettingsModule } from '../project-settings/project-settings.module';
import { IssuesModule } from '../issues/issues.module';
import { ReportsModule } from '../reports/reports.module';
import { PublishProjectModule } from './publish-project/publish-project.module';
import { ConnectProjectVersionControlContainerComponent } from './connect-project-version-control/connect-project-version-control-container.component';
import { ConnectProjectVersionControlComponent } from './connect-project-version-control/connect-project-version-control.component';
import { CreateProjectContainerComponent } from './create-project/create-project-container/create-project-container.component';
import { CreateProjectComponent } from './create-project/create-project/create-project.component';
import { ImportProjectComponent } from './import-project/import-project.component';
import { ImportProjectDialogComponent } from './import-project/import-project-dialog.component';
import { FileBasedImportProjectContainerComponent } from './file-based-import-project/file-based-import-project-container.component';
import { FileBasedImportProjectComponent } from './file-based-import-project/file-based-import-project.component';
import { ProjectMenuModule } from './project-menu/project-menu.module';
import { MatTabsModule } from '@angular/material/tabs';
import { BranchProjectContainerComponent } from './branch-project/branch-project-container/branch-project-container.component';
import { BranchProjectComponent } from './branch-project/branch-project/branch-project.component';
import { RevertProjectComponent } from './revert-project-component/revert-project.component';
import { CreateCustomProjectTemplateDialogComponent } from './project-templates/create-custom-project-template-dialog/create-custom-project-template-dialog.component';
import { ProjectTemplatesService } from './project-templates/project-templates.service';

@NgModule({
  imports: [
    ProjectRoutingModule,
    CommonModules,
    PropertyWidgetsModule,
    PatternModule,
    ProjectHeaderModule,
    IssuesModule,
    ProjectSummaryModule,
    ProjectSettingsModule,
    ReportsModule,
    PublishProjectModule,
    ProjectMenuModule,
    MatTabsModule,

    // standalone components
    AdmonitionComponent,
  ],
  declarations: [
    ProjectComponent,
    PatternMainComponent,
    CreateProjectContainerComponent,
    CreateProjectComponent,
    ConnectProjectVersionControlContainerComponent,
    ConnectProjectVersionControlComponent,
    ImportProjectComponent,
    ImportProjectDialogComponent,
    FileBasedImportProjectContainerComponent,
    FileBasedImportProjectComponent,
    BranchProjectComponent,
    BranchProjectContainerComponent,
    RevertProjectComponent,
    CreateCustomProjectTemplateDialogComponent,
  ],
  providers: [
    ProjectSyncService,
    ProjectTemplatesService,
  ]
})
export class ProjectModule {

}
