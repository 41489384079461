import { Injectable } from '@angular/core';
import { EventType, IsActiveMatchOptions, NavigationEnd, Router, UrlTree } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { asyncScheduler, BehaviorSubject, combineLatest, interval, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, share, startWith, switchMap, tap, timeout } from 'rxjs/operators';

import { TenantService } from '../../tenant/tenant.service';
import { AppState } from '../../model/reducer';
import {
  DeploymentHistoryItem,
  DeploymentHistoryJobStatus
} from '../model/deployment-history.model';
import { inventoryKeyView, projectKeyView, selectedTenantKeyView } from '../../model/views';
import { filterNotNil, Maybe } from '../utils/utils';
import { NavigationConstants } from '../constants/navigation.constants';
import { BackgroundDeploymentContextService } from './background-deployment-context.service';

export interface DeploymentActivity {
  projectKey: string;
  isProjectRedacted: boolean;
  inventoryKey: string;
  isInventoryRedacted: boolean;
  userKey: string;
  tenantKey: string;
  /** True if the activity comes from a locally started deployment job,
   * false if it comes from the deployment history */
  localJob: boolean;
}

export type DeploymentActivityIndicator = {
  hasActivity: true;
  inCurrentScope: boolean;
  activities: Array<DeploymentActivity>;
} | {hasActivity: false};

const REDACTED = '***';

const historyItemToDeploymentActivity = (hi: DeploymentHistoryItem, tenantKey: string): DeploymentActivity => {
  return {
    userKey: hi.userKey,
    tenantKey,
    projectKey: hi.projectKey,
    isProjectRedacted: hi.projectKey === REDACTED,
    inventoryKey: hi.inventoryKey,
    isInventoryRedacted: hi.inventoryKey === REDACTED,
    localJob: false,
  };
};
const activityToKey = (activity: DeploymentActivity): string => activity.projectKey + '-' + activity.inventoryKey;
const mergeDeploymentActivities = (allActivities: Array<DeploymentActivity>, others: DeploymentActivity[]): Array<DeploymentActivity> => {
  const activities: Map<string, DeploymentActivity> = new Map();
  allActivities.forEach((activity: DeploymentActivity) => {
    activities.set(activityToKey(activity), activity);
  });
  others.forEach((other: DeploymentActivity) => {
    activities.set(activityToKey(other), other);
  });
  return Array.from(activities.values());
};

const isActiveMatchOptions: IsActiveMatchOptions = {paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored'};

@Injectable()
export class DeploymentActivityContextService {
  private POLLING_INTERVAL_DEFAULT = 2000;
  // the timeout a bit shorter than the default polling interval, so that there's always a single request in progress
  private REQUEST_TIMEOUT = 1800;
  private POLLING_INTERVAL_MAX = 30000;
  private TWO_HOURS = 7200000;  // 3600 x 2 x 1000

  public deploymentActivityIndicator$: Observable<DeploymentActivityIndicator>;

  private pollingInterval: BehaviorSubject<number> = new BehaviorSubject(this.POLLING_INTERVAL_DEFAULT);

  constructor(
      private tenantApi: TenantService,
      private store$: Store<AppState>,
      backgroundDeploymentsContext: BackgroundDeploymentContextService,
      router: Router,
  ) {
    const takeUntilDestroyedOp: MonoTypeOperatorFunction<DeploymentActivityIndicator> = takeUntilDestroyed();
    const selectedTenantKey$: Observable<string> = this.store$.select(selectedTenantKeyView).pipe(filterNotNil());
    const navigationEnds: Observable<void> = router.events.pipe(
        filter((e): e is NavigationEnd => EventType.NavigationEnd === e.type),
        mapTo(undefined),
    );
    const historyPolling: Observable<Array<DeploymentHistoryItem>> = this.pollingInterval.asObservable().pipe(
        switchMap((currentInterval: number) => interval(currentInterval, asyncScheduler)),
        switchMap(() => selectedTenantKey$),
        switchMap((selectedTenantKey): Observable<Array<DeploymentHistoryItem>> => {
          const twoHoursAgo = new Date(new Date().getTime() - this.TWO_HOURS);
          return this.tenantApi.getTenantDeploymentHistory(selectedTenantKey, twoHoursAgo, DeploymentHistoryJobStatus.Running).pipe(
            timeout(this.REQUEST_TIMEOUT),
            tap(() => {
              // if the request was successful, and we're after an error, reset to the normal interval
              if (this.pollingInterval.getValue() !== this.POLLING_INTERVAL_DEFAULT) {
                this.pollingInterval.next(this.POLLING_INTERVAL_DEFAULT);
              }
            }),
            catchError(() => {
              const currentPollInterval = this.pollingInterval.getValue();
              const newInterval = Math.min(currentPollInterval * 2, this.POLLING_INTERVAL_MAX);
              this.pollingInterval.next(newInterval);
              return of([]);
            }),
          );
        }),
    );
    const projectRoute: UrlTree = router.parseUrl('/' + NavigationConstants.PROJECTS);
    const activeProject: Observable<string | false> = combineLatest([
      store$.select(projectKeyView),
      navigationEnds,
    ]).pipe(
        map(([projectKey]: [Maybe<string>, void]): string | false => {
          if (!projectKey || !router.isActive(projectRoute, isActiveMatchOptions)) {
            return false;
          }
          return projectKey;
        }),
    );
    const inventoryRoute: UrlTree = router.parseUrl('/' + NavigationConstants.INFRASTRUCTURE + '/' + NavigationConstants.INVENTORIES);
    const activeInventory: Observable<string | false> = combineLatest([
      store$.select(inventoryKeyView),
      navigationEnds,
    ]).pipe(
        map(([inventoryKey]: [Maybe<string>, void]): string | false => {
          if (!inventoryKey || !router.isActive(inventoryRoute, isActiveMatchOptions)) {
            return false;
          }
          return inventoryKey;
        }),
    );

    this.deploymentActivityIndicator$ = combineLatest([
      historyPolling, activeProject, activeInventory, backgroundDeploymentsContext.backgroundDeploymentActivity, selectedTenantKey$,
    ]).pipe(
      map((
        [historyItems, selectedProjectKey, selectedInventoryKey, allBgDeployments, selectedTenantKey]:
          [Array<DeploymentHistoryItem>, string | false, string | false, DeploymentActivity[], string]
      ): DeploymentActivityIndicator => {
        const tenantBgDeployments = allBgDeployments.filter((activity: DeploymentActivity) => activity.tenantKey === selectedTenantKey);
        const tenantHistoryActivities: Array<DeploymentActivity> = historyItems.map((items) => historyItemToDeploymentActivity(items, selectedTenantKey));
        const allActivities: Array<DeploymentActivity> = mergeDeploymentActivities(tenantHistoryActivities, tenantBgDeployments);
        if (allActivities.length === 0) {
          return {hasActivity: false};
        }
        const projectInCurrentScope: boolean = !!selectedProjectKey && allActivities.some((activity: DeploymentActivity) => activity.projectKey === selectedProjectKey);
        const inventoryInCurrentScope: boolean = !!selectedInventoryKey && allActivities.some((activity: DeploymentActivity) => activity.inventoryKey === selectedInventoryKey);
        return {
          hasActivity: true,
          activities: allActivities,
          inCurrentScope: projectInCurrentScope || inventoryInCurrentScope,
        };
      }),
      takeUntilDestroyedOp,
      startWith({hasActivity: false} as DeploymentActivityIndicator),
      share(),
    );
  }
}
