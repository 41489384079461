<adm4-modal-dialog-title class='modal-dialog-title'
												 [header]='dialogTitle'
												 [showClose]='true'
												 [isFullHeightContent]='true'
												 (closeClicked)="closeDialog()">
	<div class="full-height" [ngClass]='backgroundClass'>
		<adm4-deployment-file-view-container *ngIf='viewingFileView$ | async'
															 class='full-height'
															 [fileInfo]="viewingFileView$ | async"
															 [boxShadowClass]='boxShadowClass'></adm4-deployment-file-view-container>
		<mat-stepper orientation="horizontal" class='default-inventory-step' [hidden]='viewingFileView$ | async' #horizontalStepper>
			<ng-template matStepperIcon='edit'>
				<mat-icon>check</mat-icon>
			</ng-template>
			<mat-step>
				<ng-template matStepLabel>Select</ng-template>
                <adm4-deployment-selection [deploymentSelection]='deploymentSelection'
                                           [boxShadowClass]='boxShadowClass'
                                           (closeDialogClicked)='closeDialog()'
                                           (validateDeploymentClicked)='onValidateDeployment($event)'
                                           (inventorySelected)='onInventorySelected($event)'
                                           (backgroundDeploymentStarted)="startBackgroundDeployment($event)"
                                           (deploymentCommentChanged)='setDeploymentComment($event)'></adm4-deployment-selection>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Validate</ng-template>
				<adm4-validate-deployment
								*ngIf="shouldRenderFixIssues(deploymentProcess$ | async, inventoryValidationStatus$ | async); else nothingToValidate"
								[deploymentId]='deploymentProcessId$ | async'
								[boxShadowClass]='boxShadowClass'
								(abortGeneration)='onAbortGeneration($event)'
								(previewDeploymentClicked)="onPreviewDeployment($event)"
								(repeatValidation)='onRepeatValidation($event)'></adm4-validate-deployment>
				<ng-template #nothingToValidate>
					<adm4-inventory-has-errors
									*ngIf='inventoryHasErrors(inventoryValidationStatus$ | async)'
									[inventoryValidationStatus]='inventoryValidationStatus$ | async'
									[inventoryKey]='this.selectedInventoryKey$ | async'
									(backFromInventoryHasErrors)='onChangeInventoryAfterNothingToDeploy()'
									(navigateToInventoryClick)='navigateToInventory($event)'></adm4-inventory-has-errors>
					<adm4-nothing-to-deploy *ngIf='isNothingToDeploy(deploymentProcess$ | async)'
																	(backFromNothingToDeploy)='onChangeInventoryAfterNothingToDeploy()'></adm4-nothing-to-deploy>
				</ng-template>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Preview</ng-template>
				<div class="full-height" [ngClass]='backgroundClass'>
					<adm4-deployment-preview
									*ngIf='shouldRenderPreviewDeployment(deploymentProcess$ | async)'
									[deploymentId]='deploymentProcessId$ | async'
									[boxShadowClass]='boxShadowClass'
									(abortPlanning)='onAbortPlanning($event)'
									(repeatPlanning)="onRepeatPlanning($event)"
                  (forceRedeployment)='onForceRedeployment($event)'
									(closeWizard)='closeDialog()'
									(deploy)='onDeploy($event)'>
					</adm4-deployment-preview>
				</div>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Deploy</ng-template>
				<adm4-deployment
								*ngIf='isDeployStep(deploymentProcess$ | async)'
								[deploymentId]='deploymentProcessId$ | async'
								[selectedInventorySchemaType]='selectedInventorySchemaType$ | async'
								(finish)='onDoneStateReached()'
								(closeWizard)='closeDialog()'></adm4-deployment>
			</mat-step>
		</mat-stepper>
	</div>
</adm4-modal-dialog-title>
