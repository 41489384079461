import { Injectable } from '@angular/core';
import { VariableModel } from '../../variables/variable.model';
import * as _ from 'lodash';
import { skipIdent } from '../../common/utils/utils';
import { yamlBasicIndentation } from '../inventory-editor/yaml-code-editor.constants';
import { PropertyTypeClassEnum } from '../../plugins/plugin.enums';

@Injectable()
export class InsertProjectVariablesHelper {
  static convertVariableListToYaml(variables: VariableModel[], projectName: string): string {
    if (_.isEmpty(variables)) {
      return '';
    }

    const beginText =
      skipIdent`Variables added from project ${projectName}
      Please copy them to any vars:-section above, or uncomment them (shortcut: CTRL-/)

      vars:`;
    return this.convertVariablesToYaml(variables, beginText);
  }

  static convertCertificatesVariablesToYaml(fileList: string[]): string {
    if (_.isEmpty(fileList)) {
      return '';
    }

    const beginText =
      skipIdent`Uploaded certificate
      Please copy that to any vars:-section above, or uncomment them (shortcut: CTRL-/)

      vars:`;
    return fileList.reduce((yaml: string, certificate: string) => {
        return `${yaml}\n${yamlBasicIndentation}${yamlBasicIndentation}- ${this.indentYaml(certificate)}`;
      }, beginText + this.indentYaml('\ntrusted-certificates:'));
  }

  static convertVariablesToYaml(variables: VariableModel[], beginText: string): string {
    return variables.map(variable => this.convertVariableToYaml(variable))
      .reduce((yaml: string, variableYaml: string) => {
        return `${yaml}\n  ${this.indentYaml(variableYaml)}`;
      }, beginText);
  }

  private static convertVariableToYaml(variable: VariableModel): string {
    const variableValue: any = variable.value;
    if (variable.className ===  PropertyTypeClassEnum.KeyValue) {
      return this.keyValueToYaml(variable);
    } else if (typeof variableValue === 'string' && variable.className === PropertyTypeClassEnum.TextProperty) {
      const complexVariableValueYaml = this.indentYaml(variableValue);
      return `${variable.variableKey}: |\n${yamlBasicIndentation}${complexVariableValueYaml}`;
    } else if (typeof variableValue === 'string') {
      return `${variable.variableKey}: ${variableValue}`;
    } else if (_.isArray(variableValue) && variable.value.length === 1) {
      return `${variable.variableKey}: ${variable.value[0]}`;
    } else if (Array.isArray(variableValue)) {
      const multiLineVariableValueYaml = variableValue.join(`\n${yamlBasicIndentation}- `);
      return `${variable.variableKey}:\n${yamlBasicIndentation}- ${multiLineVariableValueYaml}`;
    } else if (_.isBoolean(variable.value)) {
      return `${variable.variableKey}: ${variable.value.toString()}`;
    }
    return `${variable.variableKey}: ${this.valueToStringFallback(variableValue)}`;
  }

  private static keyValueToYaml(variable: VariableModel): string {
    const variableValue: any = variable.value;
    if (Array.isArray(variableValue)) {
      const valueString: string = variableValue.map(
        (currentValue: any) => `${yamlBasicIndentation}${Object.keys(currentValue)[0]}: ${Object.values(currentValue)[0]}\n`
      ).join('');
      return `${variable.variableKey}:\n${valueString}`;
    } else {
      return `${variable.variableKey}: ${this.valueToStringFallback(variableValue)}`;
    }
  }

  private static valueToStringFallback(variableValue: any): string {
    if (variableValue === undefined) {
      return '';
    } else if (variableValue === null) {
      return 'null';
    } else if (typeof variableValue === 'string') {
      return variableValue;
    }
    return JSON.stringify(variableValue);
  }

  private static indentYaml(yamlCode: string): string {
    return yamlCode.split('\n').join(`\n${yamlBasicIndentation}`);
  }
}
