import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';

import { Subject } from 'rxjs';

import * as _ from 'lodash';

import { DeployToOption } from '../deploy-to-option.model';
import { DeployToOptionHelper } from '../deploy-to-option.helper';
import {
  CanaryRoutingConstant,
  CanaryRoutingOption, defaultCanaryPercentage,
  defaultCanaryValue,
} from '@deployment-common/deployment-process.model';
import { FormHelper } from '@common/helpers/form.helper';
import { DeploymentHistoryItem } from '@common/model/deployment-history.model';

@Component({
  selector: 'adm4-secondary-deployment-settings',
  template: `
    <div class='secondary-deployment-container' [formGroup]='form'>
      <mat-radio-group class="secondary-radio-group"
                       [value]='selectedSecondaryRoutingOption'
                       (change)='setSecondaryRoutingOption($event)'>
        <mat-radio-button class="left-align" *ngFor="let option of secondaryRoutingOptions" [value]="option">
          {{option}}
        </mat-radio-button>
      </mat-radio-group>
      <ng-container *ngIf='shouldDisplayRoutingBasedOnSettings'>
        <div class="input-label routing-label">Routing based on</div>
        <div class='form-group'>
          <div class='routing-step-container'>
            <div class='mat-step-icon'>
              <div class='mat-step-icon-content'>1</div>
            </div>
            <label class='label-with-info input-label' for='header'>
              <span>Header name</span>
              <span class='info-icon-container' [ngbTooltip]='headerNameInfo' placement='left'><i class="fa fa-question-circle help-icon" aria-hidden="true" title=''></i></span>
              <ng-template #headerNameInfo>
                <p>You can direct the traffic based on the Header. Insert a header name like an example below:</p>
                <ul>
                  <li>canary</li>
                </ul>
                Find further details at
                <adm4-external-link [linkUrl]="DOCS_SIDE_BY_SIDE_URL | docLink"
                                    [linkLabel]='DOCS_SIDE_BY_SIDE_LABEL'
                                    [displayStyle]="'inline'"
                                    [openInNewTab]='true'></adm4-external-link>
              </ng-template>
            </label>
          </div>
          <div class="input-container bordered-container">
            <input id='header' class="form-control admn4-text-input"
                   [(ngModel)]='canaryRoutingModel.header'
                   (ngModelChange)='updateRoutingOptions()'
                   [formControlName]='ROUTING_HEADER_FORM_CONTROL_NAME'/>
            <div class='validation-message-container'>
              <adm4-validation-message *ngIf='shouldShowInvalidCharacterError("pattern", ROUTING_HEADER_FORM_CONTROL_NAME)'
                                       [isError]='true'
                                       [message]='INVALID_CHARACTER_ERROR_MESSAGE'
              ></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowRoutingSettingErrorByErrorKey("leastOneRoutingRequired")'
                                       [isError]='true'
                                       [message]='EMPTY_ROUTING_ERROR_MESSAGE'></adm4-validation-message>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <div class='routing-step-container'>
            <div class='mat-step-icon'>
              <div class='mat-step-icon-content'>2</div>
            </div>
            <label class='label-with-info input-label' for='cookie'>
              <span>Cookie name</span>
              <span class='info-icon-container' [ngbTooltip]='cookieInfo' placement='left'>
                      <i class="fa fa-question-circle help-icon" aria-hidden="true" title=''></i>
                    </span>
              <ng-template #cookieInfo>
                <p>You can direct the traffic based on the Cookie if the Header based routing is not applied. Insert a cookie like an example below:</p>
                <ul>
                  <li>canary</li>
                </ul>
                Find further details at
                <adm4-external-link [linkUrl]="DOCS_SIDE_BY_SIDE_URL | docLink"
                                    [linkLabel]='DOCS_SIDE_BY_SIDE_LABEL'
                                    [displayStyle]="'inline'"
                                    [openInNewTab]='true'></adm4-external-link>
              </ng-template>
            </label>
          </div>
          <div class="input-container bordered-container">
            <input id='cookie' class="form-control admn4-text-input"
                   [(ngModel)]='canaryRoutingModel.cookie'
                   (ngModelChange)='updateRoutingOptions()'
                   [formControlName]='ROUTING_COOKIE_FORM_CONTROL_NAME'/>
            <div class='validation-message-container cookie-validation-error-container' *ngIf='shouldShowInvalidCharacterError("pattern", ROUTING_COOKIE_FORM_CONTROL_NAME) || shouldShowRoutingSettingErrorByErrorKey("leastOneRoutingRequired")'>
              <adm4-validation-message *ngIf='shouldShowInvalidCharacterError("pattern", ROUTING_COOKIE_FORM_CONTROL_NAME)'
                                       [isError]='true'
                                       [message]='INVALID_CHARACTER_ERROR_MESSAGE'
              ></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowRoutingSettingErrorByErrorKey("leastOneRoutingRequired")'
                                       [isError]='true'
                                       [message]='EMPTY_ROUTING_ERROR_MESSAGE'></adm4-validation-message>
            </div>
            <div class='checkbox-container'>
              <div class='checkbox-with-info'>
                <mat-checkbox id='checkbox'
                              [(ngModel)]='canaryRoutingModel.query'
                              (ngModelChange)='updateRoutingOptions()'
                              [formControlName]='ROUTING_QUERY_FORM_CONTROL_NAME'></mat-checkbox>
                <label class='label-with-info input-label checkbox-label' for='checkbox'>
                  <span class='checkbox-label'>Auto set cookie on query parameter</span>
                  <span class='info-icon-container' [ngbTooltip]='queryParamInfo' placement='left' closeDelay="3000">
                      <i class="fa fa-question-circle help-icon" aria-hidden="true" title=''></i>
                    </span>
                  <ng-template #queryParamInfo>
                    <p>You can set the cookie by enabling this feature. Sample URL with the query parameter:</p>
                    <ul>
                      <li>
                        <span>&lt;yourURL>/?canary=always // enable routing to the secondary</span>
                      </li>
                      <li>
                        <span>&lt;yourURL>/?canary=never // disable routing to the secondary</span>
                      </li>
                    </ul>
                    where "canary" is the cookie name. It is recommended to set "Header name" as well. Find further details at
                    <adm4-external-link [linkUrl]="DOCS_SIDE_BY_SIDE_URL | docLink"
                                        [linkLabel]='DOCS_SIDE_BY_SIDE_LABEL'
                                        [displayStyle]="'inline'"
                                        [openInNewTab]='true'></adm4-external-link>
                  </ng-template>
                </label>
              </div>
              <div class='validation-message-container' *ngIf='shouldShowRoutingSettingErrorByErrorKey("queryParamError")'>
                <adm4-validation-message [isError]='true' [message]='COOKIE_NAME_REQUIRED_ERROR_MESSAGE'></adm4-validation-message>
              </div>
            </div>
          </div>
        </div>
        <div class='form-group' *ngIf='isSelectedSecondaryOptionType(secondaryRoutingOptionTypes.CUSTOM)'>
          <div class='routing-step-container'>
            <div class='mat-step-icon'>
              <div class='mat-step-icon-content'>3</div>
            </div>
            <label class='label-with-info input-label percentage-label' for='percentage'>
              <span>User base (%)</span>
            </label>
          </div>
          <div class="input-container traffic-container">
            <ng-container *ngTemplateOutlet='percentageSliderTemplate'></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf='isSelectedSecondaryOptionType(secondaryRoutingOptionTypes.AB)'>
        <ng-container *ngTemplateOutlet='percentageSliderTemplate'>
        </ng-container>
      </ng-container>
      <ng-template #percentageSliderTemplate>
        <div class='percentage-split-container'>
          <span class='strong-title'>A</span>
          <mat-slider aria-label="unit(s)" #percentageSlider
                      [displayWith]="formatPercentageLabel"
                      (adm4AfterViewInit)='routingRatioThumb.focus()'
                      [discrete]="true"
                      min="0"
                      max="100">
              <input matSliderThumb #routingRatioThumb=matSliderThumb
                     [value]="100 - canaryRoutingModel.percentage"
                     (change)="sliderValueChange(routingRatioThumb.value)"/>
          </mat-slider>
          <span class='strong-title'>B</span>
        </div>
        <div class='percentage-split-container'>
          <div class='percentage-side-input-wrapper'>Primary
            <div><input type="number" min="0" max="100" class='percentage-input'
                        (change)='primaryPercentageChanged($event.target.value)'
                        [value]='100 - canaryRoutingModel.percentage'>%
            </div>
          </div>
          <div class='percentage-side-input-wrapper'>Secondary
            <div><input type="number" min="0" max="100" class='percentage-input'
                        [(ngModel)]="canaryRoutingModel.percentage"
                        (ngModelChange)='updateRoutingOptions()'
                        [formControlName]='ROUTING_PERCENTAGE_FORM_CONTROL_NAME'>%
            </div>
          </div>
        </div>
        <div class='percentage-info-text' *ngIf='isSelectedSecondaryOptionType(secondaryRoutingOptionTypes.AB)'>
          The cookie is also used in A/B for traffic routing. After each new secondary deployment, random header and cookie names are generated and used for routing the traffic.
          In case of the next deployment, if the percentage is not changed, the traffic allocation stays the same (including the header and cookie names).
          <div>Find further details at
            <adm4-external-link [linkUrl]="DOCS_SIDE_BY_SIDE_URL | docLink"
                                [linkLabel]='DOCS_SIDE_BY_SIDE_LABEL'
                                [displayStyle]="'inline'"
                                [openInNewTab]='true'></adm4-external-link>
          </div>
        </div>
        <div class='percentage-info-text' *ngIf='isSelectedSecondaryOptionType(secondaryRoutingOptionTypes.CUSTOM)'>
          If you want to set up a completely new traffic allocation by changing the percentage, please also consider changing the cookie name. 
          If you do not change the cookie name, the new traffic allocation will only effect the users who don't have the cookie yet. 
          <div>Find further details at 
          <adm4-external-link [linkUrl]="DOCS_SIDE_BY_SIDE_URL | docLink"
                              [linkLabel]='DOCS_SIDE_BY_SIDE_LABEL'
                              [displayStyle]="'inline'"
                              [openInNewTab]='true'></adm4-external-link>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./deploy-target-k8s-list.component.scss', './secondary-deployment-settings.component.scss']
})
export class SecondaryDeploymentSettingsComponent implements OnInit, OnChanges, OnDestroy {

  public readonly DOCS_SIDE_BY_SIDE_URL = 'nevisadmin4/User-Guide/Deployment-of-the-Configuration/Kubernetes-Deployment/Side-by-side-Deployment/';
  public readonly DOCS_SIDE_BY_SIDE_LABEL = 'Side-by-side Deployment';

  @Input() preSelectedProjectKey: string | undefined;
  @Input() selectedItem: DeployToOption;
  @Input() form: UntypedFormGroup;
  @Input() inventoryDeploymentHistory: DeploymentHistoryItem[];
  @Output() canaryRoutingChanged: EventEmitter<DeployToOption> = new EventEmitter();

  canaryRoutingModel: CanaryRoutingOption;
  selectedSecondaryRoutingOption: CanaryRoutingConstant;
  previousSecondaryDeployment: DeploymentHistoryItem | undefined;
  readonly secondaryRoutingOptionTypes = CanaryRoutingConstant;
  readonly secondaryRoutingOptions = [CanaryRoutingConstant.CANARY, CanaryRoutingConstant.AB, CanaryRoutingConstant.CUSTOM];
  readonly ROUTING_HEADER_FORM_CONTROL_NAME = 'header';
  readonly ROUTING_COOKIE_FORM_CONTROL_NAME = 'cookie';
  readonly ROUTING_PERCENTAGE_FORM_CONTROL_NAME = 'percentage';
  readonly ROUTING_QUERY_FORM_CONTROL_NAME = 'query';
  readonly INVALID_CHARACTER_ERROR_MESSAGE = 'The field contains an invalid character. It must be within the format of [A-Z0-9_-].';
  readonly EMPTY_ROUTING_ERROR_MESSAGE = 'At least one of the routing parameters has to be defined.';
  readonly COOKIE_NAME_REQUIRED_ERROR_MESSAGE = 'Header name and cookie name are required to auto set the cookie with URL.';

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.addRoutingFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventoryDeploymentHistory || changes.preSelectedProjectKey) {
      this.previousSecondaryDeployment = this.getPreviousSecondaryDeploymentIfExists();
      this.selectedSecondaryRoutingOption = this.getSecondaryRoutingOptionBasedOnHistory(this.previousSecondaryDeployment);
      this.canaryRoutingModel = this.getCanaryRoutingConfig(this.previousSecondaryDeployment);
    }
  }

  addRoutingFormControl(): void {
    this.form.addControl(this.ROUTING_HEADER_FORM_CONTROL_NAME, this.fb.control(this.canaryRoutingModel.header, Validators.pattern(/^[a-zA-Z0-9_-]+$/)));
    this.form.addControl(this.ROUTING_COOKIE_FORM_CONTROL_NAME, this.fb.control(this.canaryRoutingModel.cookie, Validators.pattern(/^[a-zA-Z0-9_-]+$/)));
    this.form.addControl(this.ROUTING_PERCENTAGE_FORM_CONTROL_NAME, this.fb.control(this.canaryRoutingModel.percentage, [Validators.min(0), Validators.max(100)]));
    this.form.addControl(this.ROUTING_QUERY_FORM_CONTROL_NAME, this.fb.control(null));
    this.form.setValidators([this.emptyRoutingValidator(), this.checkQueryParamValidity()]);
  }

  checkQueryParamValidity(): ValidatorFn {
    return (group: UntypedFormGroup) => {
      const headerFormControlValue = group.value[this.ROUTING_HEADER_FORM_CONTROL_NAME];
      const cookieFormControlValue = group.value[this.ROUTING_COOKIE_FORM_CONTROL_NAME];
      const queryParamFormControlValue = group.value[this.ROUTING_QUERY_FORM_CONTROL_NAME];
      if (queryParamFormControlValue && (_.isEmpty(headerFormControlValue) || _.isEmpty(cookieFormControlValue))) {
        return {queryParamError: true};
      }
      return null;
    };
  }

  updateRoutingOptions(): void {
    const updatedSelectedDeployToOption = this.getCanaryRoutingSettingBySecondaryOption(this.selectedItem, this.canaryRoutingModel);
    if (!_.isNil(updatedSelectedDeployToOption.canaryRouting)) {
      this.canaryRoutingModel.header = updatedSelectedDeployToOption.canaryRouting?.header;
      this.canaryRoutingModel.cookie = updatedSelectedDeployToOption.canaryRouting?.cookie;
      this.canaryRoutingModel.percentage = updatedSelectedDeployToOption.canaryRouting?.percentage;
      this.canaryRoutingModel.query = updatedSelectedDeployToOption.canaryRouting?.query;
    }
    this.canaryRoutingChanged.next(updatedSelectedDeployToOption);
  }

  getCanaryRoutingSettingBySecondaryOption(actualValue: DeployToOption, canaryRoutingModel: CanaryRoutingOption): DeployToOption {
    let headerValue, cookieValue, percentageValue, query;
    switch (this.selectedSecondaryRoutingOption) {
      case CanaryRoutingConstant.CANARY:
        headerValue = _.isNil(canaryRoutingModel.header) ? defaultCanaryValue : canaryRoutingModel.header;
        cookieValue = _.isNil(canaryRoutingModel.cookie) ? defaultCanaryValue : canaryRoutingModel.cookie;
        query = canaryRoutingModel.query || undefined;
        break;
      case CanaryRoutingConstant.AB:
        const shouldSetRandomHeaderAndCookieValue = !_.isNil(canaryRoutingModel.header) && !_.isNil(canaryRoutingModel.cookie)
          && (_.isNil(canaryRoutingModel?.percentage) || this.hasPercentageChanged() || !this.isHeaderAndCookieRandomGenerated(this.previousSecondaryDeployment?.canaryRouting));
        headerValue = shouldSetRandomHeaderAndCookieValue ? DeployToOptionHelper.createRandomString(DeployToOptionHelper.randomCookieTargetLength, DeployToOptionHelper.possibleCharactersForRandomSecondarySettings, DeployToOptionHelper.randomSecondarySettingPrefix) : this.previousSecondaryDeployment?.canaryRouting?.header;
        cookieValue = shouldSetRandomHeaderAndCookieValue ? DeployToOptionHelper.createRandomString(DeployToOptionHelper.randomCookieTargetLength, DeployToOptionHelper.possibleCharactersForRandomSecondarySettings, DeployToOptionHelper.randomSecondarySettingPrefix) : this.previousSecondaryDeployment?.canaryRouting?.cookie;
        percentageValue = _.isNil(canaryRoutingModel.percentage) ? defaultCanaryPercentage : canaryRoutingModel.percentage;
        query = undefined;
        break;
      case CanaryRoutingConstant.CUSTOM:
        headerValue = _.isNil(canaryRoutingModel.header) ? defaultCanaryValue : canaryRoutingModel.header;
        cookieValue = _.isNil(canaryRoutingModel.cookie) ? defaultCanaryValue : canaryRoutingModel.cookie;
        percentageValue = _.isNil(canaryRoutingModel.percentage) ? defaultCanaryPercentage : canaryRoutingModel.percentage;
        query = canaryRoutingModel.query || undefined;
        break;
    }
    return {
      ...actualValue,
      canaryRouting: {
        header: headerValue,
        cookie: cookieValue,
        percentage: percentageValue,
        query: query
      }
    };
  }

  hasPercentageChanged(): boolean {
    return !_.isEqual(this.canaryRoutingModel.percentage, this.previousSecondaryDeployment?.canaryRouting?.percentage);
  }

  getCanaryRoutingConfig(previousSecondaryDeployment: DeploymentHistoryItem | undefined): CanaryRoutingOption {
    const hasPreviousCanaryRouting = _.isNil(previousSecondaryDeployment) || _.isNil(previousSecondaryDeployment.canaryRouting);
    const prevModel = {
      header: previousSecondaryDeployment?.canaryRouting?.header,
      cookie: previousSecondaryDeployment?.canaryRouting?.cookie,
      percentage: previousSecondaryDeployment?.canaryRouting?.percentage,
      query: previousSecondaryDeployment?.canaryRouting?.query
    };
    const result = hasPreviousCanaryRouting ? {
      header: defaultCanaryValue,
      cookie: defaultCanaryValue,
      percentage: defaultCanaryPercentage
    } : prevModel;
    return result;
  }

  emptyRoutingValidator(): ValidatorFn {
    return (group: UntypedFormGroup) => {
      const headerFormControl = group.value[this.ROUTING_HEADER_FORM_CONTROL_NAME];
      const cookieFormControl = group.value[this.ROUTING_COOKIE_FORM_CONTROL_NAME];
      if (_.isEmpty(headerFormControl) && _.isEmpty(cookieFormControl)) {
        return {leastOneRoutingRequired: true};
      }
      return null;
    };
  }

  // create test
  getSecondaryRoutingOptionBasedOnHistory(previousSecondaryDeployment: DeploymentHistoryItem | undefined): CanaryRoutingConstant {
    if (_.isNil(previousSecondaryDeployment)) return CanaryRoutingConstant.CANARY;
    if (_.isNil(previousSecondaryDeployment.canaryRouting?.percentage)) return CanaryRoutingConstant.CANARY;
    const hasRandomGeneratedCanarySettings = this.isHeaderAndCookieRandomGenerated(previousSecondaryDeployment.canaryRouting);
    return hasRandomGeneratedCanarySettings ? CanaryRoutingConstant.AB : CanaryRoutingConstant.CUSTOM;
  }

  // create test
  isHeaderAndCookieRandomGenerated(canaryRoutingOption: CanaryRoutingOption | undefined): boolean {
    return _.startsWith(canaryRoutingOption?.header, DeployToOptionHelper.randomSecondarySettingPrefix)
      && _.startsWith(canaryRoutingOption?.cookie, DeployToOptionHelper.randomSecondarySettingPrefix)
      && _.isEqual(canaryRoutingOption?.header?.length, DeployToOptionHelper.randomCookieTargetLength)
      && _.isEqual(canaryRoutingOption?.cookie?.length, DeployToOptionHelper.randomCookieTargetLength);
  }

  getPreviousSecondaryDeploymentIfExists(): DeploymentHistoryItem | undefined {
    return this.inventoryDeploymentHistory?.find((deployment: DeploymentHistoryItem) => {
      return _.isEqual(deployment.projectKey, this.preSelectedProjectKey);
    });
  }

  shouldShowInvalidCharacterError(errorKey: string, formControlName: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[formControlName], errorKey);
  }

  shouldShowRoutingSettingErrorByErrorKey(errorKey: string): boolean {
    return this.form.hasError(errorKey);
  }

  get shouldDisplayRoutingBasedOnSettings(): boolean {
    return this.isSelectedSecondaryOptionType(CanaryRoutingConstant.CANARY) || this.isSelectedSecondaryOptionType(CanaryRoutingConstant.CUSTOM);
  }

  isSelectedSecondaryOptionType(secondaryOptionType: CanaryRoutingConstant): boolean {
    return _.isEqual(this.selectedSecondaryRoutingOption, secondaryOptionType);
  }

  formatPercentageLabel(value: number): string {
    return (100 - value) + '%';
    // return `Secondary: ${(100 - value)}%`;
  }

  primaryPercentageChanged(updatedValue: number): void {
    this.canaryRoutingModel.percentage = 100 - updatedValue;
  }

  sliderValueChange(value: unknown): void {
    if (typeof value === 'number') {
      this.canaryRoutingModel.percentage = 100 - value;
    }
  }

  /**
   * Need to remove the routing control and update the validity when the component is destroyed due to the deployment inventory selection change
   */
  removeRoutingFormControlFromParentFormControl(): void {
    this.form.removeControl(this.ROUTING_HEADER_FORM_CONTROL_NAME);
    this.form.removeControl(this.ROUTING_COOKIE_FORM_CONTROL_NAME);
    this.form.removeControl(this.ROUTING_PERCENTAGE_FORM_CONTROL_NAME);
    this.form.removeControl(this.ROUTING_QUERY_FORM_CONTROL_NAME);
    this.form.setValidators(null);
    this.form.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.removeRoutingFormControlFromParentFormControl();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setSecondaryRoutingOption(change: MatRadioChange) {
    this.previousSecondaryDeployment = this.getPreviousSecondaryDeploymentIfExists();
    this.canaryRoutingModel = this.getCanaryRoutingConfig(this.previousSecondaryDeployment);
    this.selectedItem.canaryRouting = this.canaryRoutingModel;
    this.selectedSecondaryRoutingOption = change.value;
    this.updateRoutingOptions();
  }
}
