import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { InventoryService } from '../../../inventory/inventory.service';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { FileDownloader } from '../../../common/helpers/file-downloader';
import { FileService } from '../../../common/services/file/file.service';
import {
  KubernetesCertificateWrapper,
  ResourceWrapperWithUsage, SecretResourceWrapperWithUsage, SecretWrapperWithUsage, TenantResourceFlag,
} from '../../../inventory/inventory.model';
import { InventoryResourceTypeHelper } from '../inventory-resource-type.helper';
import { SecretManagementHelper } from '../secret-management.helper';
import { InventoryResourceContentActionDialogPayload } from './inventory-resource-action-dialog-payload.model';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { ErrorHelper } from '../../../common/helpers/error.helper';
import { InventoryResourceActionDialogHelper } from './inventory-resource-action-dialog.helper';
import { isASCIIContent } from '../../../file-utils';

@Component({
  selector: 'adm4-inventory-resource-action-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='header'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height-flex'>
        <div class='secret-content-wrapper content-container'>
          <div *ngIf='!isPlainTextSecret' class='download-row'>
            <button class='file-download-button' (click)="downloadFile()">
              <mat-icon>save_alt</mat-icon>
              Download file
            </button>
          </div>
          <form [formGroup]="form">
            <textarea class='form-control admn4-textarea-input file-content-textarea code'
                      [readOnly]='isReadOnly || !isPlainTextSecret'
                      [ngModel]='resourceContent$ | async'
                      [placeholder]='placeholderText'
                      [formControlName]='TEXTAREA_FORM_CONTROL_NAME'
                      *ngIf='!contentDisplayDisabled; else canNotDisplayMsg'></textarea>
            <ng-template #canNotDisplayMsg>
              <div class="binary-file-placeholder">
                <p>The content cannot be displayed.<br/>You can download the file.</p>
              </div>
            </ng-template>
            <label *ngIf='!showDescription' class='input-label'>Description (optional)</label>
            <textarea *ngIf='!showDescription'
                      class='form-control admn4-textarea-input description-textarea'
                      [placeholder]="isReadOnly ? '-' : 'Add your description (max 150 characters)'"
                      maxlength='150'
                      [readOnly]='isReadOnly || !isPlainTextSecret'
                      [formControlName]='DESCRIPTION_FORM_CONTROL_NAME'></textarea>
          </form>
        </div>
        <mat-dialog-actions>
          <button class='admn4-button-text' (click)='closeDialog()'>{{closeBtnText}}</button>
          <button *ngIf='!isReadOnly'
                  class="admn4-button-ellipse-blue"
                  [disabled]='shouldDisableSaveBtn(resourceContent$ | async)'
                  (click)='onSaveClicked()'>Save</button>
        </mat-dialog-actions>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './inventory-resource-action.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryResourceActionDialogComponent implements OnInit, OnDestroy {

  private readonly resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper) & TenantResourceFlag);
  private readonly hasViewPermission: boolean;
  contentDisplayDisabled = false;

  public form: UntypedFormGroup;
  public TEXTAREA_FORM_CONTROL_NAME = 'resourceText';
  public DESCRIPTION_FORM_CONTROL_NAME = 'description';

  public readonly isReadOnly: boolean;
  public readonly resourceContent$: Observable<string> | undefined;
  public readonly header: string;
  public readonly showDescription: boolean;
  public readonly isPlainTextSecret: boolean;
  public readonly placeholderText: string;
  public readonly closeBtnText: string;

  private readonly NOT_DISPLAYABLE_CONTENT = 'The content cannot be displayed.\nYou can download the file.';
  private readonly NO_PERMISSION_TO_DISPLAY_CONTENT = 'The content of the selected secret cannot be displayed.\nYou have no access to view it but you can provide a new value.';

  private destroyed$: Subject<boolean> = new Subject();
  private readonly typeSpecificId;
  private textAreaControl: UntypedFormControl;
  private descriptionControl: UntypedFormControl;

  constructor(@Inject(MAT_DIALOG_DATA) public payload: InventoryResourceContentActionDialogPayload,
              private dialogRef: MatDialogRef<InventoryResourceActionDialogComponent>,
              private inventoryService: InventoryService,
              private fileService: FileService,
              private fb: UntypedFormBuilder,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService) {
    this.resourceItem = this.payload.resourceItem;
    this.showDescription = 'kubernetesSecretKey' in this.resourceItem;
    this.isPlainTextSecret = InventoryResourceTypeHelper.isPlainTextSecretType(this.resourceItem);
    this.typeSpecificId = InventoryResourceTypeHelper.getTypeSpecificIdWithName(this.resourceItem);

    this.isReadOnly = this.payload.isReadOnly;
    this.closeBtnText = this.isReadOnly ? 'Close' : 'Cancel';

    this.header = this.getHeaderText(this.isReadOnly);
    this.hasViewPermission = this.payload.hasViewPermission;
    this.placeholderText = this.hasViewPermission ? '' : this.NO_PERMISSION_TO_DISPLAY_CONTENT;

    this.resourceContent$ = this.fetchResourceContentBasedOnType().pipe(shareReplay(1));
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.textAreaControl = new UntypedFormControl({value: '', disabled: this.isReadOnly || !this.isPlainTextSecret});
    this.form.addControl(this.TEXTAREA_FORM_CONTROL_NAME, this.textAreaControl);
    this.descriptionControl = new UntypedFormControl({value: this.resourceItem?.description, disabled: this.isReadOnly || !this.isPlainTextSecret});
    this.form.addControl(this.DESCRIPTION_FORM_CONTROL_NAME, this.descriptionControl);
  }

  private getHeaderText(isReadonly: boolean): string {
    const actionType = isReadonly ? 'View' : 'Edit';
    const resourceItemId = this.typeSpecificId;
    return `${actionType} content of ${resourceItemId}`;
  }

  private fetchResourceContentBasedOnType(): Observable<string> {
    if (!this.hasViewPermission) return of('');
    if (this.isPlainTextSecret) {
      return SecretManagementHelper.getSecretResourceContent(this.inventoryService, this.resourceItem as ((SecretWrapperWithUsage) & TenantResourceFlag))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.closeDialog();
          this.handleResourceActionError(error, 'Something went wrong during opening the secret content.');
          return of('');
        }));
    }
    return SecretManagementHelper.getResourceFileContent(this.fileService, this.resourceItem).pipe(map((resourceContent: string) => {
      if (!isASCIIContent(resourceContent)) {
        this.contentDisplayDisabled = true;
        return this.NOT_DISPLAYABLE_CONTENT;
      }
      return resourceContent;
    }), catchError(async (error: HttpErrorResponse) => {
      this.closeDialog();
      const extractedErrorFromBlobResponse = await (new Response(error.error)).json();
      this.handleResourceActionError(error, extractedErrorFromBlobResponse.error.detail);
      return '';
    }));
  }

  downloadFile(): void {
    const targetFileUrl = SecretManagementHelper.getResourceContentUrlByType(this.resourceItem);
    this.fileService.loadFile(<string>(targetFileUrl))
      .subscribe((blob: Blob) => {
        let fileName = this.getFileName(this.resourceItem);
        FileDownloader.downloadFile(blob, fileName);
      });
  }

  /**
   * TODO: zvitkoczi add test, + get the filename from the header of the response, instead from the resource item
   * @param resourceItem
   * @private
   */
  private getFileName(resourceItem: (SecretWrapperWithUsage & TenantResourceFlag) | (SecretResourceWrapperWithUsage & TenantResourceFlag) | (ResourceWrapperWithUsage & TenantResourceFlag) | (KubernetesCertificateWrapper & TenantResourceFlag)) {
    let fileName;
    if ('secretResourceName' in resourceItem) {
      fileName = resourceItem.secretResourceName;
    } else if ('resourceName' in resourceItem) {
      fileName = resourceItem.resourceName;
    } else if ('kubernetesSecretKey' in resourceItem) {
      fileName = resourceItem.kubernetesSecretKey + '.pem';
    }
    return fileName;
  }

  onSaveClicked(): void {
    this.resourceContent$?.pipe(
      first(),
      tap((resourceContent: string) => {
        if (_.isEqual(this.textAreaControl.value, resourceContent)
        && !_.isEqual(this.descriptionControl.value, this.resourceItem?.description)) {
          const updatedSecret = {
            ...this.resourceItem,
            value: this.textAreaControl.value,
            description: this.descriptionControl.value
          } as SecretWrapperWithUsage & TenantResourceFlag;

          this.patchSecretResource(updatedSecret).pipe(take(1)).subscribe();
        } else {
          const updatedSecret = {
            ...this.resourceItem,
            value: this.textAreaControl.value,
            description: this.descriptionControl.value
          } as SecretWrapperWithUsage & TenantResourceFlag;

          if (!_.isNil(updatedSecret.usedIn) && updatedSecret.usedIn.length > 1) {
            this.modalNotificationService.openConfirmDialog({
              headerTitle: `Warning`,
              title: 'Secret content change ',
              description: this.getDescription(updatedSecret.usedIn)
            }, {
              confirmButtonText: 'Save changes',
              cancelButtonText: 'Cancel'
            }).afterClosed().pipe(switchMap((confirmed?: boolean) => {
              if (confirmed) {
                return this.updateSecretResource(updatedSecret);
              }
              return of(false);
            })).pipe(take(1)).subscribe();
          } else {
            this.updateSecretResource(updatedSecret).pipe(take(1)).subscribe();
          }
        }
      })
    ).subscribe();

  }

  private updateSecretResource(updatedSecret: SecretWrapperWithUsage & TenantResourceFlag): Observable<boolean> {
    return of(updatedSecret?.isTenantScoped).pipe(switchMap((isTenantScoped) => {
        return isTenantScoped ? this.inventoryService.updateTenantSecretContent(updatedSecret.scope, updatedSecret) : this.inventoryService.updateInventorySecretContent(updatedSecret.scope, updatedSecret);
      }),
      map((secretResourceId?: string) => {
        if (secretResourceId) {
          this.toastNotificationService.showSuccessToast(`The ${secretResourceId} content has been successfully updated`, 'Successfully updated');
          this.payload.onSaveCallback();
          this.closeDialog();
        }
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleResourceActionError(error, 'Something went wrong during updating the secret.');
        return of(false);
      }));
  }

  private patchSecretResource(updatedSecret: SecretWrapperWithUsage & TenantResourceFlag): Observable<boolean> {
    return of(updatedSecret?.isTenantScoped).pipe(switchMap((isTenantScoped) => {
        return isTenantScoped ? this.inventoryService.patchTenantSecret(updatedSecret.scope, updatedSecret.secretId, updatedSecret.description) : this.inventoryService.patchInventorySecret(updatedSecret.scope, updatedSecret.secretId, updatedSecret.description);
      }),
      map(() => {
        this.toastNotificationService.showSuccessToast(`The ${updatedSecret.secretId} description has been successfully updated`, 'Successfully updated');
        this.payload.onSaveCallback();
        this.closeDialog();
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleResourceActionError(error, 'Something went wrong during updating the secret description.');
        return of(false);
      }));
  }

  private getDescription(usages: string[]): string {
    const listOfUsedInventories = InventoryResourceActionDialogHelper.getResourceUsageDescription(usages);
    return `You have edited the secret <em><b>${this.typeSpecificId}</b></em>${listOfUsedInventories}`;
  }

  private handleResourceActionError(error: HttpErrorResponse, defaultErrorMsg: string): void {
    const errorMessage = ErrorHelper.getErrorDetail(error, defaultErrorMsg);
    this.modalNotificationService.openErrorDialog({title: 'Error', description: errorMessage});
  }

  shouldDisableSaveBtn(resourceContent: string): boolean {
    if (_.isEmpty(this.textAreaControl.value)) {
      return true;
    }
    return _.isEqual(this.textAreaControl.value, resourceContent)
      && _.isEqual(this.descriptionControl.value, this.resourceItem?.description);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
