import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Inventory } from '../../../../inventory/inventory.model';
import { InventorySchemaTypeHelper } from '../inventory-schema-type.helper';
import { Maybe } from '../../../../common/utils/utils';
import {
  DeploymentActivity,
  DeploymentActivityIndicator
} from '../../../../common/services/deployment-activity-context.service';

@Component({
  selector: 'adm4-deployment-inventory-list-item',
  template: `
<div class="menu-item-wrapper" [class.selected]='isSelected' [ngClass]='inventoryColorClassName'
     [adm4ScrollTarget]="inventory.inventoryKey"
     [scrollMode]="'instant'">
    <ng-template #publishRequiredTooltip>
        <span>This inventory requires a published project and inventory for deployment.</span>
    </ng-template>
    <button mat-button #selectionList class="border-radius-4"
            (keydown)='onKeyDown($event)'
            (click)='onSelected()'>
      <span class='button-flex-container'>
        <span>
          <mat-icon>{{inventoryIconName}}</mat-icon>
          <span class="ml-1 color-screen-black" title="{{inventory.inventoryKey | cropTenantFromKey}}">{{inventory.inventoryKey | cropTenantFromKey}}</span>
        </span>
        <span *ngIf='inventory.publishRequired'>
          <mat-icon class='warning'
                    [ngbTooltip]='publishRequiredTooltip' placement='right'>warning</mat-icon>
        </span>
        <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'" *ngIf="hasActivity"
                              [ngbTooltip]="activityTooltip" [disableTooltip]="!hasActivity" [placement]="'top-right'"
        ></mat-progress-spinner>
        <ng-template #activityTooltip>
          <p>
            <ng-container *ngIf="!currentActivity.isProjectRedacted; else projectRedacted">
              The project <strong>{{currentActivity.projectKey | cropTenantFromKey}}</strong>
            </ng-container>
            <ng-template #projectRedacted>
              An undisclosed project
            </ng-template>
            is being deployed to this inventory by user <strong>{{currentActivity.userKey}}</strong>.
          </p>
        </ng-template>
      </span>
    </button>
</div>
  `,
  styleUrls: ['./deployment-inventory-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentInventoryListItemComponent implements OnChanges {

  @Input() inventory: Inventory;
  @Input() selectedInventoryKey: Maybe<string>;
  @Input() inventoryColorClassName: string;
  @Input() public deploymentActivity: Maybe<DeploymentActivityIndicator>;

  @Output() inventoryKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() selected: EventEmitter<void> = new EventEmitter();

  public inventoryIconName: string;
  public isSelected: boolean;
  public hasActivity: boolean = false;
  public currentActivity: Maybe<DeploymentActivity>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventory) {
      this.inventoryIconName = InventorySchemaTypeHelper.getInventoryIconName(this.inventory.schemaType);
    }
    if (changes.inventory || changes.selectedInventoryKey) {
      this.isSelected = !!this.inventory && this.inventory.inventoryKey === this.selectedInventoryKey;
    }
    if (changes.inventory || changes.deploymentActivity) {
      if (!this.inventory || !(this.deploymentActivity?.hasActivity)) {
        this.hasActivity = false;
      } else {
        this.currentActivity = this.deploymentActivity.activities.find((a) => a.inventoryKey === this.inventory.inventoryKey);
        this.hasActivity = !!this.currentActivity;
      }
    }
  }

  public onKeyDown(event: KeyboardEvent) {
    this.inventoryKeyDown.emit(event);
  }

  public onSelected() {
    this.selected.emit();
  }

}
