import { Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';

import * as _ from 'lodash';
import { editor, IDisposable, Position } from 'monaco-editor';

import ICodeEditor = editor.ICodeEditor;
import IEditorOptions = editor.IEditorOptions;
import ScrollType = editor.ScrollType;
import ITextModel = editor.ITextModel;

import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { InventoryService } from '../../../inventory/inventory.service';
import { FileDownloader } from '../../helpers/file-downloader';
import { FileService } from '../../services/file/file.service';
import { Pod } from '../../../inventory/inventory-kubernetes-status/deployed-service.model';
import { SystemApiService } from '../../../shared/system-api.service';
import { Maybe } from '../../utils/utils';
import { LocalStorageHelper } from '../../helpers/local-storage.helper';
import { localStorageLogViewerWrapLines } from '../../constants/local-storage-keys.constants';
import { logViewerMonacoConfig } from '@common/utils/monaco-utils';

export type ViewLogsDialogPayload = ViewPodLogsPayload | ViewAdmin4LogsPayload;

export interface ViewPodLogsPayload {
  type: 'pod';
  inventoryKey: string;
  pod: Pod;
}

export interface ViewAdmin4LogsPayload {
  type: 'admin4';
  logOptions: [];
}

const logLineInfoMessage: string = `--------------------------------------------------------------------------------
Please note that only the last 100.000 lines of the logs are displayed on this screen.
Download them to get the complete logs for the selected filters.
--------------------------------------------------------------------------------\n\n`;

@Component({
  selector: 'adm4-deployed-service-logs-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='headerLabel'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height-flex'>
        <div class="remaining-space-flex-content-wrapper content-container">
          <div class='controls-header'>
            <div class='controls-wrapper'>
              <ng-container *ngIf="logOptions.length > 0">
                <label for='log-option' class='input-label'>{{optionsLabel}}</label>
                <mat-form-field class='full-width'>
                  <mat-select id='log-option' class="placeholder-black"
                              [(ngModel)]='selectedOption'
                              (selectionChange)='getSelectedLogs()'
                              [disableOptionCentering]='true'>
                    <mat-option *ngFor='let option of logOptions' [value]='option'>
                      {{option}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <label for='log-time-period' class='input-label time-period-selection'>Last: </label>
              <mat-form-field class='full-width'>
                <mat-select id='log-time-period' class="placeholder-black"
                            [(ngModel)]='selectedTimePeriod'
                            placeholder='All'
                            [disableRipple]='true'
                            (selectionChange)='getSelectedLogs()'
                            [disableOptionCentering]='true'>
                  <mat-option *ngFor='let time of timePeriods' [value]='time'>
                    {{displayTimePeriod(time)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class='action-button-wrapper'>
              <mat-slide-toggle [checked]="wrapLongLines | async" (change)="toggleWrapLines($event)">
                  <span class="action-btn-label font-smoothing-default">Wrap long lines</span>
              </mat-slide-toggle>
              <button class='action-btn' (click)="getSelectedLogs()">
                <mat-icon class="step-content-text-button-icon">sync</mat-icon>
                <span class="action-btn-label">Refresh</span>
              </button>
              <button class='action-btn' (click)="downloadLogs()">
                <mat-icon class="step-content-text-button-icon">save_alt</mat-icon>
                <span class="action-btn-label">Download logs</span>
              </button>
            </div>
          </div>
          <div class='log-content-container default-inventory-box-shadow'>
            <ngx-monaco-editor *ngIf="isLogPresent" class="monaco-height-fix"
                               [ngModel]="logContent" [options]="logviewerMonacoOptions | async"
                               (onInit)="onEditorInit($event)"
            ></ngx-monaco-editor>
            <adm4-empty-resource *ngIf="!isLogPresent">
              <span>No logs found for the selected container and time period.</span>
            </adm4-empty-resource>
          </div>
        </div>
        <div mat-dialog-actions>
          <button class='admn4-button-text' (click)='closeDialog()'>Close</button>
        </div>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: [
    '../../styles/component-specific/modal-window.scss',
    'view-logs-dialog.component.scss',
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
})
export class ViewLogsDialogComponent implements OnDestroy {

  public readonly wrapLongLines: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly logviewerMonacoOptions: Observable<Readonly<IEditorOptions>> = this.wrapLongLines.asObservable().pipe(
    takeUntilDestroyed(),
    map((wrapLongLines: boolean): Readonly<IEditorOptions> => {
      return {
        ...logViewerMonacoConfig,
        wordWrap: wrapLongLines ? 'on' : 'off',
      };
    }),
    startWith(logViewerMonacoConfig),
    shareReplay(1),
  );

  headerLabel: string;

  optionsLabel: string;
  logOptions: string[];
  selectedOption: string;

  logContent: string;
  isLogPresent: boolean = false;
  selectedTimePeriod: number | undefined;
  timePeriods: (number | undefined) [] = [1800, 3600, 10800, 36000, 86400, 259200, 604800, undefined];
  readonly DEFAULT_MAX_LOG_LINE = 100000;
  readonly DEFAULT_TIME_PERIOD = 3600;

  private modelChangeSub: IDisposable;
  private destroyed$: Subject<boolean> = new Subject();
  private readonly window: Window;

  constructor(
      @Inject(DOCUMENT) document: Document,
      @Inject(MAT_DIALOG_DATA) public payload: ViewLogsDialogPayload,
      private dialogRef: MatDialogRef<ViewLogsDialogComponent>,
      private inventoryService: InventoryService,
      private systemApi: SystemApiService,
      private fileService: FileService,
  ) {
    this.window = document.defaultView!;
    this.initWrapLines();
    switch (this.payload.type) {
      case 'pod':
        this.headerLabel = 'Logs of ' + this.payload.pod.podKey;
        this.optionsLabel = 'Container: ';
        this.logOptions = this.payload.pod.containers;
        break;
      case 'admin4':
        this.headerLabel = 'Logs of nevisAdmin 4';
        this.optionsLabel = 'Log type: ';
        this.logOptions = this.payload.logOptions;
        break;
      default:
        // this forces us to handle if a new type is introduced
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unexpectedType: never = this.payload;
        throw new Error('Unexpected log type in payload ' + this.payload);
    }
    this.selectDefaultSelection();
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  selectDefaultSelection(): void {
    this.selectedOption = this.logOptions[0];
    this.selectedTimePeriod = this.DEFAULT_TIME_PERIOD;
    this.getSelectedLogs();
  }

  getSelectedLogs(): void {
    switch (this.payload.type) {
      case 'pod':
        this.inventoryService.getPodLogs(
            this.payload.inventoryKey, this.payload.pod.podKey, this.selectedOption, this.selectedTimePeriod, this.DEFAULT_MAX_LOG_LINE, this.payload.pod.namespace,
        ).pipe(take(1)).subscribe((podLog: string) => {
          this.setLogContent(podLog);
        });
        break;
      case 'admin4':
        this.systemApi.getSystemLogs(this.selectedTimePeriod, this.DEFAULT_MAX_LOG_LINE).pipe(take(1))
            .subscribe((sysLogs: string) => this.setLogContent(sysLogs));
        break;
    }
  }

  private setLogContent(logContent: Maybe<string>): void {
    if (typeof logContent === 'string' && logContent.length > 0) {
      this.isLogPresent = true;

      const logLineCount = logContent.split(/\r\n|\r|\n/).length;
      if (logLineCount > this.DEFAULT_MAX_LOG_LINE) {
        this.logContent = logLineInfoMessage + logContent;
      } else {
        this.logContent = logContent;
      }
    } else {
      this.isLogPresent = false;
      this.logContent = '';
    }
  }

  onEditorInit(newEditor: ICodeEditor) {
    this.initEditorModel(newEditor);
    this.modelChangeSub?.dispose();
    this.modelChangeSub = newEditor.onDidChangeModelContent((_change: editor.IModelContentChangedEvent) => {
      this.initEditorModel(newEditor);
    });

    fromEvent(this.window, 'resize').pipe(
      takeUntil(this.destroyed$),
      debounceTime(300),
    ).subscribe(() => {
      // wrong typing in `ICodeEditor`, `ICodeEditor#layout` does need an empty object
      newEditor.layout({} as editor.IDimension);
    });
  }

  private initEditorModel(currentEditor: ICodeEditor) {
    const model: Maybe<ITextModel> = currentEditor.getModel();
    if (model) {
      const lineCount = model.getFullModelRange().endLineNumber;
      const position: Position = new Position(lineCount, 0);
      currentEditor.setPosition(position);
      currentEditor.revealLine(position.lineNumber, ScrollType.Smooth);
    }
  }

  public toggleWrapLines(e: MatSlideToggleChange): void {
    this.wrapLongLines.next(e.checked);
    LocalStorageHelper.save(localStorageLogViewerWrapLines, e.checked.toString());
  }

  private initWrapLines(): void {
    const raw = LocalStorageHelper.retrieve(localStorageLogViewerWrapLines);
    this.wrapLongLines.next(raw === 'true');
  }

  downloadLogs(): void {
    switch (this.payload.type) {
      case 'pod':
        const pod: Pod = this.payload.pod;
        const targetFileUrl = `/inventories/${this.payload.inventoryKey}/pods/${this.payload.pod.podKey}/log`;
        let podParams: HttpParams = new HttpParams().set('container', this.selectedOption)
            .set('namespace', this.payload.pod.namespace);
        if (!_.isNil(this.selectedTimePeriod)) {
          podParams = podParams.set('sinceSeconds', this.selectedTimePeriod.toString());
        }
        this.fileService.loadFile(<string>(targetFileUrl), undefined, podParams).pipe(take(1))
            .subscribe((blob: Blob) => {
              const fileName = pod.podKey + '-' + this.selectedOption + '.log';
              FileDownloader.downloadFile(blob, fileName);
            });
        break;
      case 'admin4':
        const {url, params} = SystemApiService.createGetSystemLogsUrl(this.selectedTimePeriod);
        this.fileService.loadFile(url, undefined, params).pipe(take(1))
            .subscribe((blob: Blob) => {
              const fileName = `nevisAdmin4-${new Date().toISOString()}.log`;
              FileDownloader.downloadFile(blob, fileName);
            });
        break;
    }
  }

  displayTimePeriod(seconds?: number): string {
    if (_.isNil(seconds)) {
      return 'All';
    }
    switch (true) {
      case (seconds < 3600):
        return seconds / 60 + ' minutes';
      case (seconds === 3600):
        return '1 hour';
      case (seconds <= 86400):
        return seconds / 3600 + ' hours';
      case (seconds > 86400):
        return seconds / 3600 / 24 + ' days';
      default:
        return 'All';
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
