import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PatternListData } from '../pattern-list-data.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { PatternVersionInfo } from '../../version-control/pattern-meta-info.model';
import { Observable, Subject } from 'rxjs';
import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { filter, takeUntil } from 'rxjs/operators';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { BatchSelectionContext } from '../batch-actions/batch-selection.context';
import { FlattenedGroupedPatternListData, FlattenedLabel, FlattenedPatternListData, GroupedPatternListData, isFlattenedLabelType } from './grouped-pattern-list-data.model';
import { GroupedPatternListContext } from './grouped-pattern-list.context';
import { localStorageExpandedGroups, localStorageScrollBufferSize } from '../../common/constants/local-storage-keys.constants';
import { LabelActionConnectService } from '../batch-actions-menu/label-action-connect.service';
import { PatternListScrollViewportConstants } from './pattern-list-scroll-viewport.constants';
import { IssueSeverity } from '../../common/model/issue.model';

const UNLABELLED_PATTERNS: string = 'Unlabelled patterns';

@Component({
  selector: 'adm4-pattern-list',
  template: `
    <cdk-virtual-scroll-viewport class="gkz-viewport cdk-virtual-scroll-width-100 pl-pattern-detail" [itemSize]='scrollConstants.itemSize' [minBufferPx]='scrollConstants.minBufferSize' [maxBufferPx]='scrollConstants.maxBufferSize'>
      <ng-container *cdkVirtualFor="let node of flattenDataSource; templateCacheSize: 0; trackBy: trackByGroupingFn">
        <div class="node" [style.padding-left]="node.level + 'px'" *ngIf='shouldDisplayLabelNode(node); else patternElement'>
          <div class='main-category' (click)="onExpandIconClick(node)">
            <button class='expand-btn'>
              <mat-icon class="mat-icon-rtl-mirror">
                {{node.isExpanded ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <div [ngbTooltip]='labelPopover' class='label-group-name' placement='top-right'>{{node.labelName}}</div>
            <ng-template #labelPopover>Labelled as: <strong>{{node.labelName}}</strong></ng-template>
          </div>
        </div>
        <ng-template #patternElement>
          <adm4-pattern-list-element [class.active]='node.patternListData.pattern.patternId === selection'
                                     [patternDetail]='node.patternListData'
                                     [selectedPattern]='selection'
                                     [active]="node.patternListData.pattern?.patternId === selection"
                                     [textToHighLight]='textToHighLight'
                                     [projectKey]='projectKey'
                                     [scrollArea]='scrollArea'
                                     [selectedCategories]='selectedCategories'
                                     [statusFiltersSelected]='statusFiltersSelected'
                                     [patternMetaInfo]='patternMetaInfos ? patternMetaInfos[node.patternListData.pattern.patternId] : undefined'
                                     [versioned]='versioned'
                                     [isChecked]='isPartOfMultiSelection(node.patternListData)'
                                     (checkboxSelect)='multiSelectionChange(node.patternListData)'
                                     (triggerScroll)='scrollToSelectedPattern($event)'>
          </adm4-pattern-list-element>
        </ng-template>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  `,
  styleUrls: ['./pattern-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternListComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() public patterns: PatternListData[];
  @Input() public textToHighLight;
  @Input() public selection: string;
  @Input() public projectKey: string;
  @Input() public scrollArea: any;
  @Input() public selectedCategories: Map<string, boolean>;
  @Input() public statusFiltersSelected: Map<IssueSeverity, boolean>;
  @Input() public patternMetaInfos: Dictionary<PatternVersionInfo>;
  @Input() versioned: boolean;
  @Input() batchSelection: PatternListData[];
  @Input() isLabelView: boolean;
  @Input() mainCheckBoxClicked: boolean;
  @Input() filterText: string;
  @ViewChild(CdkVirtualScrollViewport, {static: true}) viewPort: CdkVirtualScrollViewport;
  private renderedElementsRange: ListRange;
  private destroyed$: Subject<boolean> = new Subject();

  expandedGroups: string[] = [];
  dataSource: GroupedPatternListData[];
  patternLabelChanged$: Observable<string | null>;
  scrollConstants;

  constructor(private batchSelectionContext: BatchSelectionContext,
              private patternListContext: GroupedPatternListContext,
              private labelActionConnectService: LabelActionConnectService) {
  }

  ngOnInit(): void {
    this.expandedGroups = this.isLabelView ? this.storedExpandedGroups() : [''];
    const filterTextFilled = !_.isEmpty(this.filterText);
    this.patternListContext.updatePatternGroups(this.patterns, this.isLabelView, this.selection, this.expandedGroups, filterTextFilled);
    this.patternListContext.dataChange.subscribe((groupedPatternsToDisplay: GroupedPatternListData[]) => {
      this.dataSource = groupedPatternsToDisplay;
    });
    this.viewPort.renderedRangeStream.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((range: ListRange) => {
      this.renderedElementsRange = range;
    });
    this.patternLabelChanged$ = this.labelActionConnectService.labelChange$;
    this.patternLabelChanged$.pipe(
      filter((labelName: string | null) => !_.isNil(labelName)),
      takeUntil(this.destroyed$)
    ).subscribe((labelName: string) => {
      this.updateExpandedGroups(true, labelName);
    });
    this.setScrollConstants();
  }

  /**
   * It tracks the list by groupName (if FlattenedGroupedPatternListData's level is 0 - it's a label item) or
   * by patternId (if FlattenedGroupedPatternListData's level is 1 - it's a pattern list item)
   * this makes sure that ngFor elements are rerendered just when the group name or patternId changed
   * @param index
   * @param {FlattenedGroupedPatternListData} node
   * @returns {string}
   */
  trackByGroupingFn(_index, node: FlattenedLabel | FlattenedPatternListData): string {
    if (isFlattenedLabelType(node)) return node.labelName;
    return node.patternListData.pattern.patternId;
  }

  get shouldShowEmptyResultMessage(): boolean {
    return _.isEmpty(this.patterns);
  }

  storedExpandedGroups(): string[] {
    const retrieve = LocalStorageHelper.retrieve(LocalStorageHelper.prefixKey(localStorageExpandedGroups, this.projectKey));
    return _.isNil(retrieve) ? [] : JSON.parse(retrieve);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['patterns'] || changes['isLabelView']) && !_.isNil(this.dataSource)) {
      // Need to update the pattern list when the patterns or sorting or grouping options changed:
      // 1. set the expandedGroups based on local storage if labelView and set '' to be able to merge all the pattern into 1 group
      // 2. group the patterns based on labelView or not (in normal view just 1 group will be displayed)
      // 3. update the expand status of the dataNodes based on the expandedGroups to display the remembered state
      this.expandedGroups = this.isLabelView ? this.storedExpandedGroups() : [''];
      const filterTextFilled = !_.isEmpty(this.filterText);
      this.patternListContext.updatePatternGroups(this.patterns, this.isLabelView, this.selection, this.expandedGroups, filterTextFilled);
      this.scrollToSelectedPatternIfThatIsNotRendered(this.selection);
    }
    if (changes.selection && !changes.selection.firstChange) {
      const selectedPattern = this.patterns.find((patterListElement: PatternListData) => patterListElement.pattern.patternId === this.selection);
      if (selectedPattern && this.isLabelView) {
        const labelName = selectedPattern.pattern.label || UNLABELLED_PATTERNS;
        this.updateExpandedGroups(true, labelName);
      }
      this.scrollToSelectedPatternIfThatIsNotRendered(changes.selection.currentValue);
    }
    if (changes['mainCheckBoxClicked'] && !_.isNil(changes['mainCheckBoxClicked'].currentValue) && !_.isNil(this.dataSource)) {
      const isAllSelected = changes['mainCheckBoxClicked'].currentValue;
      this.mainCheckBoxChange(isAllSelected);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!_.isEmpty(this.selection)) this.scrollToSelectedPattern(this.selection);
    });
  }

  mainCheckBoxChange(isChecked: boolean): void {
    const targetPatterns: PatternListData[] = [];
    this.dataSource.filter((group: GroupedPatternListData) => {
      return group.isExpanded;
    }).forEach((visibleGroup: GroupedPatternListData) => {
      targetPatterns.push(...visibleGroup.patterns);
    });
    isChecked ? this.batchSelectionContext.multiplePatternSelectionChange(targetPatterns) : this.batchSelectionContext.multiplePatternSelectionChange([]);
  }

  private scrollToSelectedPatternIfThatIsNotRendered(selectedPatternId: string) {
    const index = _.isNil(selectedPatternId) ? 0 : this.getIndexOfSelectedPattern(selectedPatternId);

    // If the element not in the renderedElementsRange, then we are sure that it is not visible
    if (!this.isIndexWithinRenderedElement(index, this.renderedElementsRange)) {
      this.scrollToSelectedPattern(selectedPatternId);
    } else {
      // if the element is in the rendered range, then it still not necessarily mean that it is visible, so let the child decide if scrolling is required
    }
  }

  public scrollToSelectedPattern(pattern) {
    const index = this.getIndexOfSelectedPattern(pattern);
    this.viewPort.scrollToIndex(index);
  }

  private isIndexWithinRenderedElement(index: number, renderedElementsRange: ListRange) {
    if (_.isNil(renderedElementsRange)) {
      return true;
    }
    return index >= renderedElementsRange.start && index <= renderedElementsRange.end;
  }

  isPartOfMultiSelection(pattern: PatternListData): boolean {
    return this.batchSelection.some((selectionItem: PatternListData) => selectionItem.pattern.patternId === pattern.pattern.patternId);
  }

  multiSelectionChange(selectedPattern: PatternListData): void {
    this.batchSelectionContext.singlePatternSelectionChange(selectedPattern);
  }

  // calculate the proper index of the selected pattern
  // in label view it needs to track the parent label nodes
  private getIndexOfSelectedPattern(selectedPatternId: string): number {
    if (!this.isLabelView) {
      return _.findIndex(this.patterns, (line: PatternListData) => {
        return line.pattern.patternId === this.selection;
      });
    }
    let finalIndex = 0;
    const categoryCounter = _.findIndex(this.dataSource, (line: GroupedPatternListData) => {
      if (line.isExpanded) {
        const canBeFoundInThisCategory = _.findIndex(line.patterns, (elem: PatternListData) => {
          return elem.pattern.patternId === selectedPatternId;
        });
        if (canBeFoundInThisCategory !== -1) {
          finalIndex += canBeFoundInThisCategory;
          return true;
        }
        finalIndex += line.patterns.length;
        return false;
      }
      return false;
    });
    return finalIndex + categoryCounter + 1;
  }


  onExpandIconClick(flattenedNode: FlattenedLabel): void {
    const elementToExpand: GroupedPatternListData | undefined = _.find(this.dataSource, (group) => flattenedNode.labelName === group.labelName);
    if (!_.isNil(elementToExpand)) {
      const newExpanded: boolean = !elementToExpand.isExpanded;
      elementToExpand.isExpanded = newExpanded;
      this.updateExpandedGroups(newExpanded, flattenedNode.labelName || UNLABELLED_PATTERNS);
    }
  }

  private updateExpandedGroups(isExpanded: boolean, groupName: string): void {
    if (isExpanded && !this.expandedGroups.includes(groupName)) {
      this.expandedGroups.push(groupName);
    } else if (!isExpanded) {
      _.pull(this.expandedGroups, groupName);
    }
    LocalStorageHelper.save(LocalStorageHelper.prefixKey(localStorageExpandedGroups, this.projectKey), JSON.stringify(this.expandedGroups));
  }

  isAllGroupCollapsed(): boolean {
    return !this.dataSource.some((patternGroup: GroupedPatternListData) => patternGroup.isExpanded);
  }

  setScrollConstants(): void {
    const retrieve = LocalStorageHelper.retrieve(localStorageScrollBufferSize);
    this.scrollConstants = {
      itemSize: PatternListScrollViewportConstants.ITEM_SIZE,
      minBufferSize: _.isNil(retrieve) || _.isNaN(Number(JSON.parse(retrieve)[0])) ? PatternListScrollViewportConstants.MIN_BUFFER_SIZE : JSON.parse(retrieve)[0],
      maxBufferSize: _.isNil(retrieve) || _.isNaN(Number(JSON.parse(retrieve)[1])) ? PatternListScrollViewportConstants.MAX_BUFFER_SIZE : JSON.parse(retrieve)[1]
    };
    LocalStorageHelper.save(localStorageScrollBufferSize, JSON.stringify([this.scrollConstants.minBufferSize, this.scrollConstants.maxBufferSize]));
  }

  get flattenDataSource(): FlattenedGroupedPatternListData[] {
    return this.patternListContext.flattenDataSource(this.dataSource, this.isLabelView);
  }

  shouldDisplayLabelNode(node: FlattenedGroupedPatternListData): boolean {
    return this.isLabelView && isFlattenedLabelType(node);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
