import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'adm4-concurrent-changes-inventory-confirmation-dialog',
  template: `
    <br/>There has been a change in the inventory while you are editing. Please refresh the inventory and re-write your changes:
    <span class='call-to-action'><a (click)='triggerInventoryRefresh.emit()'>Refresh inventory.</a></span>
    <br/><br/>If you still want to save your changes, please note that the previous updates will be lost.
  `,
  styleUrls: ['./local-changes-confirmation-dialog.component.scss']
})
export class ConcurrentChangesInventoryConfirmationDialogComponent {
  @Output() triggerInventoryRefresh: EventEmitter<void> = new EventEmitter();
}
