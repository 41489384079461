import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base.service';
import { Bundle, ParsedBundle, UpgradeNote } from './bundle.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BundleHelper } from './bundle.helper';
import { ProjectBundle } from '../../projects/project.model';

@Injectable({
  providedIn: 'root'
})
export class BundleService extends BaseService {
  getAllBundles(): Observable<ParsedBundle[]> {
    const url = `/bundles`;
    return this.httpGetUnwrapped(url).pipe(
      map((bundleKeys: string[]) => BundleHelper.parseBundlesFromBundleKeys(bundleKeys))
    );
  }
  getBundleUpgradeNotes(bundle: ProjectBundle): Observable<UpgradeNote[]> {
    const url = `/bundles/${bundle.symbolicName}:${bundle.latestVersion}/upgrade-notes`;
    const params: HttpParams = new HttpParams().set('from', bundle.symbolicName + ':' + bundle.selectedVersion);
    return this.httpGetUnwrapped(url, undefined, params);
  }

  uploadBundle(bundle: File): Observable<Bundle> {
    const url = `/bundles`;
    const formData: FormData = new FormData();
    formData.append('bundle', bundle, bundle.name);
    return this.httpPostFormData(url, formData);
  }

  deleteBundle(bundle: ParsedBundle): Observable<void> {
    const url = `/bundles/${bundle.bundleKey}`;
    return this.httpDelete(url);
  }
}
