import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { AppState } from '../model/reducer';
import { NavigationService } from '../navbar/navigation.service';
import { ModalNotificationService } from '../notification/modal-notification.service';
import * as _ from 'lodash';
import { InventoryService } from './inventory.service';
import { Mixin } from '../common/decorators/mixin.decorator';
import { IInventoryGuardMixin, InventoryGuardMixin } from '../common/mixins/inventory-guard.mixin';
import { NevisAdminAction } from '../model/actions';

@Injectable({
  providedIn: 'root'
})
@Mixin([InventoryGuardMixin])
export class InventoryGuard  implements IInventoryGuardMixin {

  canActivateInventoryScreen: (next: ActivatedRouteSnapshot) => Observable<boolean>;

  constructor(public store$: Store<AppState>,
              public actions$: Actions<NevisAdminAction<any>>,
              public inventoryService: InventoryService,
              public navigationService: NavigationService,
              public modalNotificationService: ModalNotificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateInventoryScreen(next);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateInventoryScreen(childRoute);
  }

  public showCannotOpenInventoryWindow(inventoryKey: string, additionalText?: string): void {
    const title = `Could not open inventory ${inventoryKey}`;
    const secondLine: string = _.isNil(additionalText) ? '' : `<br>${additionalText}`;
    this.modalNotificationService.openErrorDialog({title: title, description: `The inventory does not exist or you are not permitted to view it.${secondLine}`});
  }

  navigateToInventoryScreen(inventoryKey?: string): Promise<boolean> {
    return _.isNil(inventoryKey) ? this.navigationService.navigateToInventories() : this.navigationService.navigateToInventory(inventoryKey, true);
  }
}
