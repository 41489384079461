import * as _ from 'lodash';
import { yamlBasicIndentation } from './yaml-code-editor.constants';
import { requireNonNull } from '../../common/utils/utils';
import { editor, Range, Position } from 'monaco-editor';

const multiLineRegExp = new RegExp('[\\n\\r]+');
export class EditorInsertingHelper {
  static appendCommentedOutContent(editorToAppend: editor.ICodeEditor, content: string): void {
    // don't insert anything is content is empty
    const model = requireNonNull<editor.ITextModel>(editorToAppend.getModel(), 'editor.getModel()');
    if (_.isEmpty(content)) {
      return;

    }
    const lineCount = model.getLineCount();
    const lastLineLength = model.getLineMaxColumn(lineCount);

    const range = new Range(lineCount, lastLineLength, lineCount, lastLineLength);

    editorToAppend.executeEdits('', [
      {range: range, text: `\n${content}\n`}
    ]);

    // select inserted content and comment it out
    const newLineCount = model.getLineCount();
    const newLastLineLength = model.getLineMaxColumn(newLineCount);
    editorToAppend.setSelection(new Range(lineCount + 1, lastLineLength, newLineCount, newLastLineLength));
    editorToAppend.getAction('editor.action.commentLine')?.run();
    editorToAppend.setSelection(new Range(0, 0, 0, 0));

    // scroll to inserted content
    editorToAppend.revealLineInCenter(newLineCount);
  }

  /**
   * Inserts string value into editor at current position after transforming it into proper YAML
   * Multiline values will be treated separately
   *
   * @param insertEditor
   * @param content
   */
  static insertTextAtCurrentPosition(insertEditor: editor.ICodeEditor, content: string): void {
    // don't insert anything if content is empty
    if (_.isEmpty(content)) {
      return;
    }
    const insertableContent: string = multiLineRegExp.test(content) ? this.getMultiLineYamlContent(insertEditor, content) : content;
    this.insertContentAtCurrentPosition(insertEditor, insertableContent);
  }

  /**
   * Inserts list of strings into editor at current position after transforming it into proper YAML
   * @param insertEditor
   * @param contents
   */
  static insertListAtCurrentPosition(insertEditor: editor.ICodeEditor, contents: string): void {
    // don't insert anything if content is empty
    if (_.isEmpty(contents)) {
      return;
    }
    const insertableContent: string = this.getYamlListContent(insertEditor, [contents]);
    this.insertContentAtCurrentPosition(insertEditor, insertableContent);
  }

  /**
   * Inserts string content at current position
   * @param insertEditor
   * @param content
   */
  static insertContentAtCurrentPosition(insertEditor: editor.ICodeEditor, content: string): void {
    const currentPosition = requireNonNull<Position>(insertEditor.getPosition());
    // don't insert anything if content is empty
    if (_.isEmpty(content)) {
      return;
    }
    const range = new Range(currentPosition.lineNumber, currentPosition.column, currentPosition.lineNumber, currentPosition.column);
    insertEditor.executeEdits('', [{
      range: range,
      text: content
    }]);
  }

  /**
   * Creates correct YAML for list of strings
   *
   * Example output:
   *  - value1
   *  - value2
   *  - value3
   *
   * @param editorWithYaml
   * @param contents
   */
  static getYamlListContent(editorWithYaml: editor.ICodeEditor, contents: string[]): string {
    const model = requireNonNull<editor.ITextModel>(editorWithYaml.getModel(), 'editor.getModel()');
    const currentPosition = requireNonNull<Position>(editorWithYaml.getPosition());
    const currentLine = model.getLineContent(currentPosition.lineNumber);
    const currentIndentRegExp = new RegExp('^[ ]*');
    const currentIndent = currentLine.match(currentIndentRegExp) || '';
    const content = `- ${contents.join('\n- ')}`;
    return `\n${this.indentYamlContent(content, currentIndent + yamlBasicIndentation)}`;
  }

  /**
   * Creates correct YAML for multiline string
   *
   * Example output:
   *  |
   *    Some text
   *    multiline text
   *    more lines
   *
   * @param editorWithYaml
   * @param content
   */
  static getMultiLineYamlContent(editorWithYaml: editor.ICodeEditor, content: string): string {
    const model = requireNonNull<editor.ITextModel>(editorWithYaml.getModel(), 'editor.getModel()');
    const currentPosition = requireNonNull<Position>(editorWithYaml.getPosition());
    const currentLine = model.getLineContent(currentPosition.lineNumber);
    const currentIndentRegExp = new RegExp('^[ ]*');
    const currentIndent = currentLine.match(currentIndentRegExp) || '';
    return `|\n${this.indentYamlContent(content, currentIndent + yamlBasicIndentation)}`;
  }

  static indentYamlContent(content: string, indent: string): string {
    return content.split(new RegExp(multiLineRegExp)).map((line: string) => indent + line).join('\n');
  }
}
