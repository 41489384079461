import { Dictionary } from '../../model/reducer';

export interface JobStatus {
  jobId: string;
  jobKey: string;
  type: string;
  name: string;
  /**
   * number from 0 to 1 indicating progress of job
   */
  progress: number;
  userKey?: string
  startTime?: string;
  endTime?: string;
  log: string[];
  statusEntries: Dictionary<any>;
}

export enum JobStatusProgress {
  Start = 0,
  Done = 1
}

export type QuickDeploymentStatus = 'pending' | 'running' | 'failed' | 'done';

/**
 * How to interpret the status:
 * - If `progress` is 0, startTime has no value and endTime has no value -> **pending**
 * - If `progress` is 0 <= p < 1, startTime has value and endTime has no value -> **running**
 * - If `progress` is 0 <= p < 1, startTime has value and endTime has value -> **failed**
 * - If `progress` is 1, startTime has value and endTime has value -> **done**
 */
export const extractQuickDeploymentStatus = (status: JobStatus): QuickDeploymentStatus => {
  if (status.progress === 0 && !status.startTime && !status.endTime) {
    return 'pending';
  }
  if (status.progress < 1 && status.startTime && !status.endTime) {
    return 'running';
  }
  if (status.progress < 1 && status.startTime && status.endTime) {
    return 'failed';
  }
  if (status.progress === 1 && status.startTime && status.endTime) {
    return 'done';
  }
  console.error('extractQuickDeploymentStatus, unexpected status: ', status);
  throw new Error('Unexpected jobStatus');
};
